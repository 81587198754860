import React, { useEffect, useState } from "react";
import { ajaxRequester } from "../../services/ajax-requester";
import {
  PageHeader,
  Form,
  Button,
  Input,
  InputNumber,
  Radio,
  Row,
  Col,
  Card,
  Upload,
  Typography,
  Space,
  message,
  Select,
} from "antd";
import {
  UploadOutlined,
  PlusOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import UploadConfigFile from "../../components/common/upload-config-file";

const EditSettings = (props) => {
  const [data, setConfiguration] = useState({});
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 14 },
  };

  const termsCol = {
    labelCol: { span: 4 },
    wrapperCol: { span: 19 },
  };

  const col2 = {
    labelCol: { span: 16 },
    wrapperCol: { span: 8 },
  };

  const layoutWithOutLabel = { wrapperCol: { span: 19, offset: 4 } };

  const { Text } = Typography;

  // Gettng Seller Details
  const getConfiguration = () => {
    let reqUrl = "configuration/detail";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        setConfiguration(data?.configuration_details);
        setDefaultData(data?.configuration_details);
      },
      "GET"
    );
  };

  const setDefaultData = (data) => {
    form.setFieldsValue({
      provider_name: data?.provider_name,
      office_phone: data?.office_phone,
      office_email: data?.office_email,
      address1: data?.address1,
      address2: data?.address2,
      city_id: data?.city_id,
      city_name: data?.city_name,
      state_id: data?.state_id,
      state_name: data?.state_name,
      pin_code: data?.pin_code,
      website: data?.website,

      poc_name: data?.poc_name,
      poc_phone_no: data?.poc_phone_no,
      poc_email: data?.poc_email,

      bank_name: data?.bank_name,
      bank_acct_no: data?.bank_acct_no,
      bank_ifsc_code: data?.bank_ifsc_code,
      cancelled_cheque: "cancelled_cheque",

      gst_number: data?.gst_number,
      gst_certificate: data?.get_doc_path,

      company_code: data?.inventory_settings?.company_code,
      inventory_id_start_from:
        data?.inventory_settings?.inventory_id_start_from,
      inventory_master_default_sorting:
        data?.inventory_settings?.inventory_master_default_sorting,

      enable_blocking: data?.plan_settings?.enable_blocking,
      blocking_time: data?.plan_settings?.blocking_time,
      terms_and_conditions: data?.plan_settings?.terms_and_conditions || [""],

      restrict_invoice_edit_days:
        data?.sales_estimate_settings?.restrict_invoice_edit_days,
      invoice_due_date_days:
        data?.sales_estimate_settings?.invoice_due_date_days || 1,
      invoice_terms_and_conditions: data?.sales_estimate_settings
        ?.terms_and_conditions || [""],
      restrict_cn_edit_days:
        data?.sales_estimate_settings?.restrict_cn_edit_days || 0,
      cgst_per: data?.sales_estimate_settings?.cgst_per || 0,
      sgst_per: data?.sales_estimate_settings?.sgst_per || 0,
      igst_per: data?.sales_estimate_settings?.igst_per || 0,

      sac_code: data?.sac_code,
      image_quality: data?.image_quality,
    });
  };

  const viewDocument = (item) => {
    window.open(item);
  };

  const handleUploadLogoReq = (path) => {
    console.log(path);
  };

  const onFinish = (values) => {
    setLoading(true);
    let reqUrl = "configuration/update";
    let reqObj = {
      request: {
        provider_config_id: data?.provider_config_id,
        provider_name: values?.provider_name,
        office_phone: values?.office_phone,
        office_email: values?.office_email,
        address1: values?.address1,
        address2: values?.address2,
        city_id: values?.city_id,
        city_name: values?.city_name,
        state_id: values?.state_id,
        state_name: values?.state_name,
        pin_code: values?.pin_code,
        website: values?.website,

        poc_name: values?.poc_name,
        poc_phone_no: values?.poc_phone_no,
        poc_email: values?.poc_email,
        bank_name: values?.bank_name,
        bank_acct_no: values?.bank_acct_no,
        bank_ifsc_code: values?.bank_ifsc_code,
        gst_number: values?.gst_number,
        inventory_settings: {
          company_code: values?.company_code,
          inventory_id_start_from: values?.inventory_id_start_from,
          inventory_master_default_sorting:
            values?.inventory_master_default_sorting,
        },
        plan_settings: {
          enable_blocking: values?.enable_blocking,
          blocking_time: values?.blocking_time,
          terms_and_conditions: values?.terms_and_conditions,
        },
        sales_estimate_settings: {
          restrict_invoice_edit_days: values?.restrict_invoice_edit_days,
          invoice_due_date_days: values?.invoice_due_date_days,
          terms_and_conditions: values?.invoice_terms_and_conditions,
          restrict_cn_edit_days: values?.restrict_cn_edit_days,
          cgst_per: values?.cgst_per,
          sgst_per: values?.sgst_per,
          igst_per: values?.igst_per,
        },
        sac_code: values?.sac_code && values?.sac_code.toString(),
        invoice_due_date_days: values?.invoice_due_date_days,
        image_quality: values?.image_quality,
      },
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      setLoading(false);
      if (data?.success) {
        message.success("Configuration Updated Successfully.");
      } else {
        message.error(data?.detail || "Someting went wrong.");
      }
    });
  };

  useEffect(() => {
    getConfiguration();
  }, []);

  return (
    <div>
      <React.Fragment>
        <PageHeader title={"Configuration"} style={{ paddingLeft: "0px" }} />
        <Form
          name="edit_configuration"
          {...layout}
          onFinish={onFinish}
          className="edit-configuration"
          form={form}
        >
          <Card bodyStyle={{ paddingBottom: "0px" }} title="Company Profile">
            <Row>
              <Col span={12} xs={24} xl={12}>
                <Form.Item
                  label="Company Trade Name"
                  name="provider_name"
                  rules={[
                    {
                      required: true,
                      message: "Please Input Company Trade Name !",
                    },
                  ]}
                >
                  <Input placeholder="Company Trade Name" />
                </Form.Item>
                <Form.Item label="Address1" name="address1">
                  <Input placeholder="Address1" />
                </Form.Item>
                <Form.Item label="Address2" name="address2">
                  <Input placeholder="Address2" />
                </Form.Item>
                <Form.Item label="City" name="city_name">
                  <Input placeholder="City" />
                </Form.Item>
                <Form.Item label="State" name="state_name">
                  <Input placeholder="State" />
                </Form.Item>
                <Form.Item label="Pin Code" name="pin_code">
                  <Input placeholder="State" />
                </Form.Item>
                <Form.Item label="City" name="city_id" hidden={true}>
                  <Input placeholder="City" />
                </Form.Item>
                <Form.Item label="State" name="state_id" hidden={true}>
                  <Input placeholder="State" />
                </Form.Item>
              </Col>
              <Col span={12} xs={24} xl={12}>
                <Form.Item label="Office Email Address" name="office_email">
                  <Input placeholder="Office Email Address" />
                </Form.Item>
                <Form.Item label="Office Phone Number" name="office_phone">
                  <Input placeholder="Office Phone Number" />
                </Form.Item>
                <Form.Item label="Website" name="website">
                  <Input placeholder="Website" />
                </Form.Item>
                <Form.Item label="Company Logo" name="logo_name">
                  <UploadConfigFile
                    id={data?.provider_config_id}
                    uploadPath={"configuration/document/upload"}
                    deletePath={"configuration/document/delete"}
                    photoPath={data?.documents?.logo?.at(-1)?.data_url}
                    type={1}
                    {...data}
                  />

                  <Text
                    type="secondary"
                    style={{ display: "block", marginTop: "8px" }}
                  >
                    Required size in pixels should (500x150) and supported
                    format JPEG, PNG, GIF
                  </Text>
                </Form.Item>
              </Col>
            </Row>
          </Card>

          <Card
            title="Point Of Contact"
            style={{ marginTop: "16px" }}
            bodyStyle={{ paddingBottom: "0px" }}
          >
            <Row>
              <Col span={12} xs={24} xl={12}>
                <Form.Item label="Point Of Contact Name" name="poc_name">
                  <Input placeholder="Point Of Contact Name" />
                </Form.Item>
              </Col>
              <Col span={12} xs={24} xl={12}>
                <Form.Item label="Point Of Contact Number" name="poc_phone_no">
                  <Input placeholder="Point Of Contact Number" />
                </Form.Item>
              </Col>
              <Col span={12} xs={24} xl={12}>
                <Form.Item label="Point Of Contact Email" name="poc_email">
                  <Input placeholder="Point Of Contact Email" />
                </Form.Item>
              </Col>
            </Row>
          </Card>

          <Card
            title="Banking Details"
            style={{ marginTop: "16px" }}
            bodyStyle={{ paddingBottom: "0px" }}
          >
            <Row>
              <Col span={12} xs={24} xl={12}>
                <Form.Item label="Bank Name" name="bank_name">
                  <Input placeholder="Bank Name" />
                </Form.Item>
              </Col>
              <Col span={12} xs={24} xl={12}>
                <Form.Item label="Account Number" name="bank_acct_no">
                  <Input placeholder="Account Number" />
                </Form.Item>
              </Col>
              <Col span={12} xs={24} xl={12}>
                <Form.Item label="IFSC Code" name="bank_ifsc_code">
                  <Input placeholder="IFSC Code" />
                </Form.Item>
              </Col>
              <Col span={12} xs={24} xl={12}>
                <Form.Item label="Cancelled Cheque" name="cancelled_cheque">
                  <UploadConfigFile
                    id={data?.provider_config_id}
                    uploadPath={"configuration/document/upload"}
                    deletePath={"configuration/document/delete"}
                    docPath={
                      data?.documents?.cancelled_cheque?.at(-1)?.data_url
                    }
                    docName={data?.documents?.cancelled_cheque?.at(-1)?.name}
                    type={2}
                    {...data}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>

          <Card
            title="GST Details"
            style={{ marginTop: "16px" }}
            bodyStyle={{ paddingBottom: "0px" }}
          >
            <Row>
              <Col span={12} xs={24} xl={12}>
                <Form.Item label="GST Number" name="gst_number">
                  <Input placeholder="GST Number" />
                </Form.Item>
              </Col>
              <Col span={12} xs={24} xl={12}>
                <Form.Item label="GST Certificate" name="gst_certificate">
                  <UploadConfigFile
                    id={data?.provider_config_id}
                    uploadPath={"configuration/document/upload"}
                    deletePath={"configuration/document/delete"}
                    docPath={data?.documents?.gst_certificate?.at(-1)?.data_url}
                    docName={data?.documents?.gst_certificate?.at(-1)?.name}
                    type={3}
                    {...data}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>

          <Card
            title="One Time Inventory Skeleton"
            style={{ marginTop: "16px" }}
            bodyStyle={{ paddingBottom: "0px" }}
            hidden={true}
          >
            <Row>
              <Col span={12} xs={24} xl={12}>
                {" "}
                <Form.Item label="Company Code" name="company_code">
                  <Input placeholder="Company Code" />
                </Form.Item>
              </Col>
              <Col span={12} xs={24} xl={12}>
                <Form.Item
                  label="Inventory Start Number"
                  name="inventory_id_start_from"
                >
                  <InputNumber min={0} placeholder="001" />
                </Form.Item>
              </Col>
              <Col span={12} xs={24} xl={12}>
                <Form.Item
                  label="Inventory Master Sorting"
                  name="inventory_master_default_sorting"
                >
                  <Radio.Group>
                    <Radio
                      style={{ minWidth: "42%", marginBottom: "4px" }}
                      value={1}
                    >
                      Last Modified
                    </Radio>
                    <Radio
                      style={{ minWidth: "42%", marginBottom: "4px" }}
                      value={2}
                    >
                      IID Ascending
                    </Radio>
                    <Radio
                      style={{ minWidth: "42%", marginBottom: "4px" }}
                      value={3}
                    >
                      IID Descending
                    </Radio>
                    <Radio
                      style={{ minWidth: "42%", marginBottom: "4px" }}
                      value={4}
                    >
                      District City Area
                    </Radio>
                    <Radio
                      style={{ minWidth: "42%", marginBottom: "4px" }}
                      value={5}
                    >
                      Base Rates High to Low
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </Card>

          <Card
            title="Plan"
            style={{ marginTop: "16px" }}
            bodyStyle={{ paddingBottom: "0px" }}
          >
            <Row>
              <Col span={12} xs={24} xl={12}>
                <Form.Item label="Enable Blocking" name="enable_blocking">
                  <Radio.Group>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={12} xs={24} xl={12}>
                <Form.Item label="Blocking Hours" name="blocking_time">
                  <InputNumber placeholder="Hours" min={0} />
                </Form.Item>
              </Col>
              <Col span={24} xs={24} xl={24}>
                <Form.List name="terms_and_conditions">
                  {(fields, { add, remove }) => {
                    return (
                      <div>
                        {fields.map((field, index) => (
                          <Space key={field.key}>
                            <Form.Item
                              {...field}
                              label={index === 0 ? "Terms and Conditions" : ""}
                              {...(index === 0 ? termsCol : layoutWithOutLabel)}
                            >
                              <Input placeholder="Terms and Conditions" />
                            </Form.Item>

                            {fields.length > 1 ? (
                              <MinusCircleOutlined
                                className="dynamic-delete-button"
                                onClick={() => {
                                  remove(field.name);
                                }}
                              />
                            ) : null}
                          </Space>
                        ))}
                        <Form.Item {...{ wrapperCol: { offset: 4 } }}>
                          <Button
                            type="dashed"
                            onClick={() => {
                              add();
                            }}
                          >
                            <PlusOutlined /> Add
                          </Button>
                        </Form.Item>
                      </div>
                    );
                  }}
                </Form.List>
              </Col>
            </Row>
          </Card>

          <Card
            title="Invoice"
            style={{ marginTop: "16px" }}
            bodyStyle={{ paddingBottom: "0px" }}
          >
            <Row>
              <Col span={6} xs={24} xl={6}>
                <Form.Item
                  label="Restrict Invoice Edits"
                  name="restrict_invoice_edit_days"
                  {...col2}
                >
                  <InputNumber min={0} placeholder="Days" />
                </Form.Item>
                {/* <Text type="secondary">After days of new month</Text> */}
              </Col>
              <Col span={6} xs={24} xl={6}>
                <Form.Item
                  label="Restrict CN Edits"
                  name="restrict_cn_edit_days"
                  {...col2}
                >
                  <InputNumber placeholder="Days" min={0} />
                </Form.Item>
                {/* <Text type="secondary">After days of new month</Text> */}
              </Col>
              <Col span={6} xs={24} xl={6}>
                <Form.Item
                  label="Invoice Due Date"
                  name="invoice_due_date_days"
                  {...col2}
                >
                  <InputNumber placeholder="Days" min={1} max={365} />
                </Form.Item>
              </Col>
              <Col span={6} xs={24} xl={6} {...col2}>
                <Form.Item label="SAC Code" name="sac_code">
                  <InputNumber placeholder="SAC Code" />
                </Form.Item>
              </Col>
              <Col span={6} xs={24} xl={6}>
                <Form.Item label="CGST" name="cgst_per" {...col2}>
                  <InputNumber placeholder="CGST" min={0} />
                </Form.Item>
              </Col>
              <Col span={6} xs={24} xl={6}>
                <Form.Item label="SGST" name="sgst_per" {...col2}>
                  <InputNumber placeholder="SGST" min={0} max={100} />
                </Form.Item>
              </Col>
              <Col span={6} xs={24} xl={6}>
                <Form.Item label="IGST" name="igst_per" {...col2}>
                  <InputNumber placeholder="IGST" min={0} max={100} />
                </Form.Item>
              </Col>

              <Col span={24} xs={24} xl={24}>
                <Form.List name="invoice_terms_and_conditions">
                  {(fields, { add, remove }) => {
                    return (
                      <div>
                        {fields.map((field, index) => (
                          <Space key={field.key}>
                            <Form.Item
                              {...field}
                              label={index === 0 ? "Terms and Conditions" : ""}
                              {...(index === 0 ? termsCol : layoutWithOutLabel)}
                            >
                              <Input placeholder="Invoice Terms and Conditions" />
                            </Form.Item>

                            {fields.length > 1 ? (
                              <MinusCircleOutlined
                                className="dynamic-delete-button"
                                onClick={() => {
                                  remove(field.name);
                                }}
                              />
                            ) : null}
                          </Space>
                        ))}
                        <Form.Item {...{ wrapperCol: { offset: 4 } }}>
                          <Button
                            type="dashed"
                            onClick={() => {
                              add();
                            }}
                          >
                            <PlusOutlined /> Add
                          </Button>
                        </Form.Item>
                      </div>
                    );
                  }}
                </Form.List>
              </Col>
            </Row>
          </Card>

          <Card
            title="General Settings"
            style={{ marginTop: "16px" }}
            bodyStyle={{ paddingBottom: "0px" }}
          >
            <Row>
              <Col span={6} xs={24} xl={6}>
                <Form.Item label="Image Quality" name="image_quality" {...col2}>
                  <Select>
                    <Select.Option value="Original">Original</Select.Option>
                    <Select.Option value="2k">2K</Select.Option>
                    <Select.Option value="3k">3K</Select.Option>
                    <Select.Option value="4k">4K</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Card>

          <Form.Item
            wrapperCol={{ span: 24 }}
            style={{ marginTop: "24px", textAlign: "center" }}
          >
            <Button
              loading={loading}
              type="primary"
              size="large"
              htmlType="submit"
            >
              Update
            </Button>
          </Form.Item>
        </Form>
      </React.Fragment>
    </div>
  );
};

export default EditSettings;
