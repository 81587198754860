import {useState, useEffect} from "react";
import {Alert, Button, Col, Form, Input, Modal, PageHeader, Popover, Row, Select, Switch, Table, Tag} from "antd";
import {PlusOutlined, ReloadOutlined} from "@ant-design/icons";
import AdminApi from "../api/admin-api";
import PortalUser from "./portal-user";


const RolePicker = (props) => {

    const [roles, setRoles] = useState(props.roles.filter(x => x));

    return (
        <Select style={{
            minWidth: "200px"
        }} mode={"tags"} disabled={AdminApi.getCurrentUser().Id === props.user?.id} onChange={(val)=> {

            setRoles([...val]);

            if(props.onChange) {
                props.onChange(val);
                return;
            }
            AdminApi.setRoles(props.user.id, val).then(x => {

            }).catch(()=> {

            });
        }} value={roles}>
            {
                Object.keys(AdminApi.roles).map(roleName => <Select.Option key={AdminApi.roles[roleName]}>{roleName}</Select.Option>)
            }
        </Select>
    )
}


const ManagerUsers = (props) => {

    const [users, setUsers] = useState(null);
    const [loading, setLoading] = useState(true);
    const [query, setQuery] = useState('');
    const [showAddUserModal, setAddUserModal] = useState(false);
    const [formData, setFormData] = useState({});
    const [formError, setFormError] = useState(null);

    const columns = [
        {
            title: 'User',
            dataIndex: 'user',
            key: 'user',
            render: (x, record) => <PortalUser user={record.id}/>
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: 'Phone Number',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber'
        },
        {
            title: 'Roles',
            dataIndex: 'roles',
            key: 'roles',
            render: (roles, user) => <RolePicker user={user} roles={roles} />
        },
        {
            title : "Activation",
            dataIndex: 'isActive',
            key: 'isActive',
            render: (isActive, user) => <Switch disabled={AdminApi.getCurrentUser().Id === user.id} checkedChildren={"Active"} unCheckedChildren={"Inactive"} defaultChecked={isActive} onChange={(isActive) => {
                if(isActive) {
                    AdminApi.activateUser(user.id).then(x => {}).catch(x =>{})
                } else {
                    AdminApi.deactivateUser(user.id).then(x => {}).catch(x =>{})
                }
            }} />
        }
    ]

    const refresh = () => {
        setLoading(true);
        AdminApi.queryUsers(query, 0, 9999).then(users => {
            setUsers(null);
            setUsers(users.result);
            setLoading(false);
        })
    }

    useEffect(()=> {
        refresh();
    }, [query]);

    useEffect(() => {
        refresh();
    }, [])

    return (
        <PageHeader
            title={"Manage Users"}
            extra={[
                <Input type={"text"} placeholder={"Search"} style={{"width": 300}} allowClear={true} onChange={e => {
                    setQuery(e.target.value ?? '');
                }} />,
                <Button key="1" icon={<PlusOutlined/>} type="primary" onClick={()=> setAddUserModal(true)}>
                    Add User
                </Button>,
                <Button key={2} onClick={refresh} icon={<ReloadOutlined/>} type={"primary"}/>
            ]}>

            <Table
                loading={loading}
                pagination={false}
                dataSource={users}
                columns={columns}
                bordered
            />

            <Modal
                title={"Add User"}
                visible={showAddUserModal}
                onOk={()=> {
                    return new Promise(((resolve, reject) => {
                        AdminApi.createUser(formData).then(()=> {
                            resolve();
                            setAddUserModal(false);
                            refresh();
                        }).catch((err)=> {
                            setFormError(err);
                        });
                    }));
                }}
                onCancel={()=> {
                    setFormData({});
                    setAddUserModal(false);
                }}
                destroyOnClose={true}
            >


                {formError && <Alert message={formError} type="error" showIcon />}

                <Form
                    layout={"vertical"}
                    onValuesChange={(val, obj) => {
                        setFormError(null);
                        setFormData(obj);
                    }}
                >

                    <Form.Item
                        label={"Username"}
                        name={"userName"}
                        rules={[
                            {
                                required: true,
                                message: "Username is required"
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Row>
                        <Col style={{
                            paddingRight: "5px"
                        }} span={12}>
                            <Form.Item label={"First Name"} name={"firstName"} rules={[
                                {
                                    required: true
                                }
                            ]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={"Last Name"} name={"lastName"} rules={[
                                {
                                    required: true
                                }
                            ]}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item
                        label={"Email"}
                        name={"email"}
                        rules={[
                            {
                                required: true,
                                message: "Email is required",
                                validator: (_, value) => {
                                    if(value) {
                                        if(value.search(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/gm) !== -1) return Promise.resolve();
                                        return Promise.reject("invalid email address")
                                    }
                                }
                            }
                        ]}>
                        <Input type={"email"} />
                    </Form.Item>

                    <Form.Item
                        label={"Phone Number"}
                        name={"phoneNumber"}
                        rules={[
                            {
                                required: false,
                                message : "invalid phone number, format should be like +91-1234567890",
                                validator: (_, value) => {
                                    if(value) {
                                        if(value.search(/^\+\d{1,3}-\d{8,10}$/gm) !== -1) return Promise.resolve();
                                        return Promise.reject("invalid phone number, format should be like +91-1234567890")
                                    }
                                    return Promise.resolve();
                                }
                            }
                        ]}>
                        <Input type={"email"} />
                    </Form.Item>

                    <Form.Item
                        label={"Roles"}
                        name={"roles"}
                        rules={[
                            {
                                required: true
                            }
                        ]}
                        >
                        <RolePicker roles={[]} onChange={roles => {

                        }} />
                    </Form.Item>

                </Form>

            </Modal>

        </PageHeader>
    )
}

export default ManagerUsers;
