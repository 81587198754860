import React, { Component } from "react";
import { Form, Button, DatePicker, Tabs } from "antd";
import moment from "moment";
import AddEditOperationsPhotos from "./add-edit-operations-photos";

class AddMountingUnmounting extends Component {
  state = {};

  formRef = React.createRef();

  onFinish = (values) => {
    this.props.handleAddMounting(values);
  };

  disabledDate = (current) => {
    let start_date = this?.props?.selectedRows[0]?.start_date;
    return current && current < moment(start_date, "YYYY-MM-DD");
  };

  setData = () => {
    let data = this?.props?.editRecord;

    let mounting_date =
      this?.props?.modalAction === "edit-mounting"
        ? data?.mounting_date
        : this?.props?.modalAction === "edit-monitoring"
        ? data?.monitoring_performed_date
        : data?.unmounting_date;

    this.formRef.current.setFieldsValue({
      mounting_date: mounting_date && moment(mounting_date),
    });
  };

  componentDidMount() {
    (this?.props?.modalAction === "edit-mounting" ||
      this?.props?.modalAction === "edit-unmounting-photo" ||
      this?.props?.modalAction === "edit-monitoring" ||
      this?.props?.modalAction === "edit-mounting-unmounting") &&
      this.setData();
  }

  render() {
    const { TabPane } = Tabs;
    const { modalTitle, modalAction, page, selectedRows } = this.props;
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };

    return (
      <Form
        name="operation-modal"
        {...layout}
        onFinish={this.onFinish}
        ref={this.formRef}
      >
        <Form.Item name="mounting_date" label="Job done Date">
          <DatePicker
            disabled={
              this?.props?.modalAction === "edit-mounting-unmounting"
                ? true
                : false
            }
            inputReadOnly={true}
          />
        </Form.Item>

        <Tabs defaultActiveKey="1" className="customer-tabs">
          {this.props?.editRecord?.mount_operation_id && (
            <TabPane tab="Upload Mounting Photo" key="1" forceRender="true">
              <AddEditOperationsPhotos
                selectedId={this.props?.editRecord?.mount_operation_id}
                selectedRows={selectedRows[0]}
                modalAction={modalAction}
                currentTab={"mounting"}
              />
            </TabPane>
          )}

          {this.props?.editRecord?.unmount_operation_id && (
            <TabPane tab="Upload Unmounting Photo" key="2" forceRender="true">
              <AddEditOperationsPhotos
                selectedId={this.props?.editRecord?.unmount_operation_id}
                selectedRows={selectedRows[0]}
                modalAction={modalAction}
                currentTab={"unmounting"}
              />
            </TabPane>
          )}
        </Tabs>

        <Form.Item
          wrapperCol={{ offset: 8 }}
          style={{ marginTop: "16px", marginBottom: "0px" }}
          className="model-btns"
        >
          <Button
            type="primary"
            htmlType="submit"
            disabled={
              this?.props?.modalAction === "edit-mounting-unmounting"
                ? true
                : false
            }
          >
            {modalTitle}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default AddMountingUnmounting;
