import React, { Component } from "react";
import { Row, Col, Switch, Radio } from "antd";

class InventoryNav extends Component {
  state = {
    options: [],
    value: "",
    mediaTypeList: [],
    inventoryType: this.props.inventoryType,
    hideSwith: this.props.hideSwith,
    showMapToggle: this.props.showMapToggle,
    isMapview: this.props.isMapview,
  };

  setNav = () => {
    const { mediaTypeList, filters } = this.props;

    let currentTab = filters ? filters.split("=").slice(-1).pop() : "";

    let totalCount = 0;
    mediaTypeList &&
      mediaTypeList.map((x) => (totalCount = x.total_count + totalCount));

    let options = [{ label: "All (" + totalCount + ")", value: "" }];

    mediaTypeList &&
      mediaTypeList.map((x) =>
        options.push({
          label: x.media_type + " (" + x.total_count + ")",
          value: x.media_type,
        })
      );

    this.setState({ options, value: currentTab });
  };

  handleToggle = () => {
    this.props.handleToggle();
  };

  handleListMapToggle = () => {
    this.props.handleListMapToggle();
  };

  onChange = (e) => {
    this.setState({
      value: e.target.value,
    });
    this.props.handleNav(e.target.value);
  };

  componentDidMount() {
    this.setNav();
  }

  render() {
    const {
      inventoryType,
      value,
      options,
      hideSwith,
      showMapToggle,
      isMapview,
    } = this.state;

    return (
      <Row className="inventory-nav">
        <Col
          span={showMapToggle ? 18 : 20}
          xs={24}
          xl={showMapToggle ? 18 : 20}
          className="inventory-nav-tabs"
        >
          <Radio.Group
            options={options}
            onChange={this.onChange}
            value={options.find((x) => x.value === value) ? value : ""}
            optionType="button"
            buttonStyle="solid"
            loading={true}
          />
        </Col>

        {showMapToggle && (
          <Col span="3" xs={8} xl={3} className="inventory-nav-switch">
            <Switch
              onChange={this.handleListMapToggle}
              size="small"
              defaultChecked={isMapview}
            ></Switch>
            <label>Map View</label>
          </Col>
        )}

        {!hideSwith && (
          <Col
            span={showMapToggle ? 3 : 4}
            xs={8}
            xl={showMapToggle ? 3 : 4}
            className="inventory-nav-switch"
          >
            <Switch
              onChange={this.handleToggle}
              size="small"
              defaultChecked={
                inventoryType === "hainventory" ||
                inventoryType === "tainventory"
                  ? false
                  : true
              }
            ></Switch>
            <label>Show Group</label>
          </Col>
        )}
      </Row>
    );
  }
}

export default InventoryNav;
