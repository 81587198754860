import React, { Component } from "react";
import { Row, Col, Modal, Table, Button, message, Popconfirm } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  CheckCircleTwoTone,
} from "@ant-design/icons";
import PageTitle from "../../components/common/page-title";
import { ajaxRequester } from "../../services/ajax-requester";
import PointOfContactAdd from "../../components/customers/pointofcontact-add";
import ListFilters from "../../components/common/list-filters";
import { RolesPermissions } from "../../helpers/roles-permissions";
import AdminApi from "../../iam/api/admin-api";

class PointOfContacts extends Component {
  state = {
    data: [],
    pagination: {
      current: 1,
      pageSize: 20,
    },
    loading: false,
    isModal: false,
    columns: [],
    columnsDefault: [],
    filterColumns: [],
    editMode: false,
    isError: false,
    screen: "pointofcontacts",
    refreshId: 1,
  };

  // Get Point Of Contacts List
  getPointOfContacts = (params = {}) => {
    this.setState({ loading: true });

    let reqUrl =
      "pointofcontact/list?showunverified=false&pageno=" +
      params.pagination.current +
      "&pagesize=" +
      params.pagination.pageSize +
      (params.filters ? params.filters : "");

    let reqObj = "";
    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({
          loading: false,
          data: data.response,
          pagination: {
            ...params.pagination,
            total: data.page_info.total_records,
          },
        });
      },
      "GET"
    );
  };

  // Setting Columns
  setColumns = () => {
    let columns = [
      {
        title: "POC Name",
        dataIndex: "contact_name",
      },
      {
        title: "Designation",
        dataIndex: "designation",
      },
      {
        title: "Number",
        dataIndex: "phone_no",
        render: (text, record) => (
          <React.Fragment>
            {text}
            {record.is_phone_verified && (
              <CheckCircleTwoTone
                style={{ position: "relative", top: "2px", left: "8px" }}
                twoToneColor="#52c41a"
              />
            )}
          </React.Fragment>
        ),
      },
      {
        title: "Email",
        dataIndex: "email",
        render: (text, record) => (
          <React.Fragment>
            {text}
            {record.is_email_verified && (
              <CheckCircleTwoTone
                style={{ position: "relative", top: "2px", left: "8px" }}
                twoToneColor="#52c41a"
              />
            )}
          </React.Fragment>
        ),
      },
      {
        title: "Customer Name",
        dataIndex: "customer_name",
      },
      {
        title: "Notes",
        dataIndex: "notes",
      },

      {
        title: "Actions",
        dataIndex: "",
        key: "x",
        render: (record) =>
          record.email !== "***" &&
          record.phone_no !== "***" &&
          RolesPermissions("ad") && (
            <React.Fragment>
              <Button
                onClick={() => this.handlePocModal(record)}
                icon={<EditOutlined />}
                className="ant-btn-reset"
              />

              <Popconfirm
                title="Are you sure delete?"
                placement="left"
                okText="Yes"
                cancelText="No"
                onConfirm={() => this.deletePointOfContact(record)}
              >
                <Button
                  icon={<DeleteOutlined />}
                  className="ant-btn-reset"
                  style={{ marginLeft: "12px" }}
                />
              </Popconfirm>
            </React.Fragment>
          ),
      },
    ];

    const filterColumns = [
      { columnName: "contact_name", displayName: "POC Name", default: true },
      { columnName: "designation", displayName: "Designation" },
      { columnName: "phone_no", displayName: "Number" },
      { columnName: "email", displayName: "Email" },
      { columnName: "customer_name", displayName: "Customer Name" },
      { columnName: "notes", displayName: "Notes" },
    ];

    this.setState({ columnsDefault: columns, filterColumns }, () =>
      this.setDefaultColumns(columns)
    );
  };

  // Change Pagging Sorting and Filters
  handleTableChange = (pagination, filters, sorter) => {
    this.getPointOfContacts({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  };

  // Handle Global Filters
  handleFilters = (filterColumn, filterValue) => {
    const { pagination } = this.state;

    let filters = "";

    if (filterValue) {
      filters =
        "&columnName=" +
        filterColumn.columnName +
        "&columnValue=" +
        filterValue;
    }

    this.getPointOfContacts({ pagination, filters });
  };

  // Show Hide Point Of Contact Add Modal
  handlePocModal = (record) => {
    this.setState({
      isModal: !this.state.isModal,
      editMode: record,
    });
  };

  // Add Point of Contact
  addPointOfContact = (data) => {
    let reqUrl = "pointofcontact/create";
    let reqObj = {
      request: {
        contact_name: data.contact_name,
        phone_no: data.phone_no,
        email: data.email,
        designation_id: data.designation_id,
        customer_id: data.customer_id,
        notes: data.notes,
        is_phone_verified: data.is_phone_verified || false,
        is_email_verified: data.is_email_verified || false,
        created_by: "varun",
        created_date: "0001-01-01T00:00:00",
      },
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      if (data.success) {
        // this.handlePocModal();
        this.setState({
          isModal: !this.state.isModal,
          editMode: false,
          refreshId: this.state.refreshId + 1,
        });
        this.successPocAdd();
        const { pagination } = this.state;
        this.getPointOfContacts({ pagination });
      } else {
        this.errorPocAdd(data);
      }
    });
  };

  // Update Point of Contact
  updatePointOfContact = (data) => {
    let reqUrl = "pointofcontact/update";
    let reqObj = {
      request: {
        contact_name: data.contact_name,
        phone_no: data.phone_no,
        email: data.email,
        designation_id: data.designation_id,
        customer_id: data.customer_id,
        notes: data.notes,
        customer_contact_id: data.customer_contact_id,
        is_phone_verified: data.is_phone_verified || false,
        is_email_verified: data.is_email_verified || false,
        is_active: true,
        updated_by: "varun",
      },
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      if (data.success) {
        // this.handlePocModal();
        this.setState({
          isModal: !this.state.isModal,
          editMode: false,
          refreshId: this.state.refreshId + 1,
        });
        this.successPocAdd();
        const { pagination } = this.state;
        this.getPointOfContacts({ pagination });
      } else {
        message.error("Something went wrong.");
      }
    });
  };

  // Show Success  if Point of Contact added
  successPocAdd = () => {
    let msg =
      "Point of Contact " +
      (!this.state.editMode ? "added" : "updated") +
      " successfully.";
    message.success(msg);
  };

  // Show Erroes if Point of Contact added
  errorPocAdd = (data) => {
    let msg = data.detail;
    message.error(msg);
    this.setState({ isError: true });
  };

  // Handle Setting show hide columns
  handleSettings = (values) => {
    let columnsDefault = this.state.columnsDefault;
    let columns = this.state.columns;
    columns = columnsDefault.filter(({ title }) =>
      values.some((exclude) => exclude.title === title)
    );
    this.setState({ columns });
  };

  // Delete Customer List based on Id
  deletePointOfContact = (pointofcontact) => {
    this.setState({ isLoading: true });
    let reqUrl =
      "pointofcontact/delete?customercontactid=" +
      pointofcontact.customer_contact_id;
    let reqObj = {
      request: {},
    };
    ajaxRequester(
      reqUrl,
      reqObj,
      () => {
        const { pagination } = this.state;
        this.getPointOfContacts({ pagination });
      },
      "DELETE"
    );
  };

  // Get Designation List
  getDesignationList = () => {
    console.log("getDesignationList");
    let reqUrl = "lookup/designation";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        console.log("getDesignationList suc", data);
        this.setState({ designationList: data.response });
      },
      "GET"
    );
  };

  // Get Designation List
  getMappedCustomerList = () => {
    let reqUrl = "customer/lookup";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ mappedCustomerlist: data.response });
      },
      "GET"
    );
  };

  // Set Default Columns
  setDefaultColumns = (columns) => {
    AdminApi.syncColumsSettings()
      .then((columnsSettings) => {
        this.setState({ columnsSettings: columnsSettings || {} }, () =>
          this.applyDefaultColums(columns)
        );
      })
      .catch((nothing) => {});
  };

  // Apply Default Columns
  applyDefaultColums = (columns) => {
    let { columnsSettings, columnsDefault, screen } = this.state;
    if (screen && columnsSettings) {
      let colConfig = columnsSettings[screen];

      if (colConfig && colConfig.length > 1) {
        columns = columnsDefault.filter(({ title }) =>
          colConfig.some((exclude) => exclude.title === title)
        );
      }
      this.setState({ columns });
    }
  };

  componentDidMount() {
    const { pagination } = this.state;
    this.getPointOfContacts({ pagination });
    this.setColumns();
    this.getDesignationList();
    this.getMappedCustomerList();
  }

  render() {
    const {
      data,
      columns,
      pagination,
      loading,
      isModal,
      editMode,
      isError,
      filterColumns,
      screen,
      refreshId,
    } = this.state;

    return (
      <React.Fragment>
        <Row>
          <Col span={24}>
            <PageTitle
              titleText="Point of Contacts"
              isAddBtn={RolesPermissions("ad") && "Add POC"}
              handleAdd={() => this.handlePocModal(false)}
            />

            <ListFilters
              items={filterColumns}
              handleSettings={this.handleSettings}
              handleFilters={this.handleFilters}
              key={filterColumns}
              screen={screen}
            />

            <Table
              columns={columns}
              rowKey={(record) => record.customer_contact_id}
              dataSource={data}
              pagination={pagination}
              loading={loading}
              onChange={this.handleTableChange}
              bordered
              scroll={{ x: 400 }}
              size="small"
            />
          </Col>
        </Row>

        <Modal
          title={(editMode ? "Update" : "Add") + " Point of Contact"}
          visible={isModal}
          onOk={this.handleOk}
          onCancel={this.handlePocModal}
          footer={null}
          centered
        >
          <PointOfContactAdd
            {...this.state}
            key={isModal + refreshId}
            handleAdd={this.addPointOfContact}
            handleUpdate={this.updatePointOfContact}
            isError={isError}
          />
        </Modal>
      </React.Fragment>
    );
  }
}

export default PointOfContacts;
