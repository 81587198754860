import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { Layout } from "antd";
import AppHeader from "./components/common/app-header";
import AppFooter from "./components/common/app-footer";
import CommonList from "./screens/common/common-list";
import Customers from "./screens/customers/customers";
import CustomerAdd from "./screens/customers/customer-add";
import CustomerEdit from "./screens/customers/customer-edit";
import CustomerDetails from "./screens/customers/customer-details";
import Inventories from "./screens/inventories/inventories";
import InventoryAdd from "./screens/inventories/inventory-add";
import PointOfContacts from "./screens/customers/pointofcontacts";
import InventoryDetails from "./screens/inventories/inventory-details";
import InventoryEdit from "./screens/inventories/inventory-edit";
import InventoryStatus from "./screens/inventories/inventory-status";
import InventoriesTracking from "./screens/inventories/inventories-tracking";
import InventoriesContract from "./screens/inventories/inventories-contract";
import inventoriesMap from "./screens/inventories/inventories-map";
import InventoriesMaster from "./screens/inventories/inventories-master";
import Plans from "./screens/plan/plans";
import PlanDetails from "./screens/plan/plan-details";
import InventoryBookingStatus from "./screens/inventories/inventory-booking-status";
import PlanView from "./screens/plan/plan-view";
import InventoriesDeleted from "./screens/inventories/inventories-deleted";
import { LoginAuthorization } from "./iam/components/admin-helpers";
import Campaigns from "./screens/campaign/campaigns";
import CampaignDetails from "./screens/campaign/campaign-details";
import UserSettings from "./iam/components/user-settings";
import OperationList from "./screens/operations/operation-list";
import InventoriesImport from "./screens/inventories/inventories-import";
import AppHeaderPublic from "./components/common/app-header-public";
import AppFooterPublic from "./components/common/app-footer-public";
import BranchManagement from "./screens/operations/branch-management";
import BranchAdd from "./screens/operations/branch-add";
import SalesEstimate from "./screens/sales-estimate/sales-estimate-list";
import InvoiceList from "./screens/sales-estimate/invoice-list";
import SalesEstimateDetails from "./screens/sales-estimate/sales-estimate-details";
import SalesEstimateEdit from "./screens/sales-estimate/sales-estimate-edit";
import SalesEstimateAdd from "./screens/sales-estimate/sales-estimate-add";
import ApproveRejectInvoices from "./screens/sales-estimate/approve-reject-invoices";
import InvoiceDateNumber from "./screens/sales-estimate/invoice-date-number";
import PendingInvoices from "./screens/sales-estimate/pending-invoices";
import CreditNotes from "./screens/sales-estimate/credit-notes";
import JournalVoucher from "./screens/sales-estimate/journal-voucher";
import InvoiceView from "./screens/sales-estimate/invoice-view";
import CreditNoteAddEdit from "./screens/sales-estimate/credit-note-add-edit";
import JournalVoucherAddEdit from "./screens/sales-estimate/journal-voucher-add-edit";
import ManualInvoiceAdd from "./screens/sales-estimate/manual-invoice-add";
import CityUpdate from "./screens/operations/city-update";
import ProformaInvoices from "./screens/sales-estimate/proforma-invoices";
import Masters from "./screens/masters/masters";
import MastersImport from "./screens/masters/masters-import";
import PhotoBucket from "./screens/photobucket/photobucket";
import PhotoBucketReviewer from "./screens/photobucket/photobucket-reviewer";
import PayeeList from "./screens/purchase/payee-list";
import PayoutEstimate from "./screens/purchase/payout-estimate";
import PayeeAddEdit from "./screens/purchase/payee-add-edit";
import SisterCompanyList from "./screens/purchase/sister-company-list";
import EstimateTypeList from "./screens/purchase/estimate-type-list";
import PayoutEstimateAddEdit from "./screens/purchase/payout-estimate-add-edit";
import ScheduledTransactions from "./screens/purchase/scheduled-transactions";
import OneTimeTransactions from "./screens/purchase/one-time-transactions";
import TransactionsAddEdit from "./screens/purchase/transactions-add-edit";
import ManualTransactionsAddEdit from "./screens/purchase/manual-transactions-add-edit";
import VerifyEmail from "./screens/purchase/verify-email";

class App extends Component {
  state = {
    theme: "dark",
    loginStatus: JSON.parse(localStorage.getItem("loginStatus")),
  };

  handleTheme = () => {
    this.setState({ theme: this.state.theme === "dark" ? "light" : "dark" });
  };

  handleLogin = () => {
    this.setState({ loginStatus: true });
  };

  render() {
    const { Content } = Layout;
    const { theme } = this.state;
    let isPublic = false;
    if (
      this.props.location.pathname.includes("/plans/plan-view") ||
      this.props.location.pathname.includes("/campaigns/campaign-view") ||
      this.props.location.pathname.includes("/verify-email")
    ) {
      isPublic = true;
    }

    return (
      <React.Fragment>
        {!isPublic && (
          <LoginAuthorization>
            <Layout className={"layout " + (theme + "-theme")}>
              <AppHeader theme={theme} {...this.state} {...this.props} />

              <Content>
                <div className="site-layout-content">
                  <Switch>
                    <Route exact path="/" component={Customers} />
                    <Route path="/customers/customers" component={Customers} />
                    <Route
                      path="/customers/customer-add"
                      component={CustomerAdd}
                    />
                    <Route
                      path="/customers/customer-edit/:id"
                      component={CustomerEdit}
                    />
                    <Route
                      path="/customers/customer-details/:id"
                      component={CustomerDetails}
                    />
                    <Route
                      path="/customers/pointofcontacts"
                      component={PointOfContacts}
                    />
                    <Route
                      path="/inventories/inventories"
                      component={Inventories}
                      key="ha-inventories"
                    />
                    <Route
                      path="/inventories/ta-inventories"
                      component={Inventories}
                      key="ta-inventories"
                    />
                    <Route
                      path="/inventories/inventory-add"
                      component={InventoryAdd}
                    />
                    <Route
                      path="/inventories/ta-inventory-add"
                      component={InventoryAdd}
                    />
                    <Route
                      path="/inventories/inventory-add-group"
                      component={InventoryAdd}
                    />
                    <Route
                      path="/inventories/ta-inventory-add-group"
                      component={InventoryAdd}
                    />
                    <Route
                      path="/inventories/inventory-edit/:id"
                      component={InventoryEdit}
                    />
                    <Route
                      path="/inventories/ta-inventory-edit/:id"
                      component={InventoryEdit}
                    />
                    <Route
                      path="/inventories/inventory-group-edit/:id"
                      component={InventoryEdit}
                    />
                    <Route
                      path="/inventories/ta-inventory-group-edit/:id"
                      component={InventoryEdit}
                    />
                    <Route
                      path="/inventories/inventory-details/:type/:id"
                      component={InventoryDetails}
                    />
                    <Route
                      path="/inventories/inventory-status/:id/:title"
                      component={InventoryStatus}
                    />
                    <Route
                      path="/inventories/inventory-booking-status/:id/:title"
                      component={InventoryBookingStatus}
                    />
                    <Route
                      path="/inventories/inventories-tracking"
                      component={InventoriesTracking}
                    />
                    <Route
                      path="/inventories/inventories-contract"
                      component={InventoriesContract}
                    />
                    <Route
                      path="/inventories/inventories-map"
                      component={inventoriesMap}
                    />

                    <Route
                      path="/inventories/inventories-master"
                      component={InventoriesMaster}
                      key="ha-inventories-master"
                    />

                    <Route
                      path="/inventories/ta-inventories-master"
                      component={InventoriesMaster}
                      key="ta-inventories-master"
                    />

                    <Route
                      path="/inventories/inventories-deleted"
                      component={InventoriesDeleted}
                    />

                    <Route path="/plans/plans" component={Plans} />

                    <Route
                      path="/plans/plan-details/:id"
                      component={PlanDetails}
                    />

                    <Route path="/common-list" component={CommonList} />

                    <Route path="/campaigns/campaigns" component={Campaigns} />

                    <Route
                      path="/campaigns/campaign-details/:id"
                      component={CampaignDetails}
                    />

                    <Route
                      path="/operations/waiting-for-flex"
                      key="waiting-for-flex"
                      component={OperationList}
                    />

                    <Route
                      path="/operations/change-of-site"
                      key="change-of-site"
                      component={OperationList}
                    />

                    <Route
                      path="/operations/pending-mounting"
                      key="pending-mounting"
                      component={OperationList}
                    />

                    <Route
                      path="/operations/monitoring"
                      key="monitoring"
                      component={OperationList}
                    />

                    <Route
                      path="/operations/pending-unmounting-photo"
                      key="pending-unmounting-photo"
                      component={OperationList}
                    />

                    <Route
                      path="/operations/pending-unmounting"
                      key="pending-unmounting"
                      component={OperationList}
                    />

                    <Route
                      path="/operations/stock-management"
                      key="stock-management"
                      component={OperationList}
                    />

                    <Route
                      path="/operations/mounting-unmounting"
                      key="mounting-unmounting"
                      component={OperationList}
                    />

                    <Route
                      path="/operations/campaign-operations/:id"
                      key="campaign-operations"
                      component={OperationList}
                    />

                    <Route
                      path="/inventories/branch-management"
                      component={BranchManagement}
                    />

                    <Route
                      path="/inventories/branch-add"
                      component={BranchAdd}
                    />

                    <Route
                      path="/inventories/branch-edit/:id"
                      component={BranchAdd}
                    />

                    <Route
                      path="/inventories/inventories-import"
                      component={InventoriesImport}
                      key="ha-inventories-import"
                    />

                    <Route
                      path="/inventories/ta-inventories-import"
                      component={InventoriesImport}
                      key="ta-inventories-import"
                    />

                    <Route
                      path="/sales-estimate/sales-estimate-list"
                      component={SalesEstimate}
                    />

                    <Route
                      path="/sales-estimate/sales-estimate-details/:id/:title"
                      component={SalesEstimateDetails}
                    />

                    <Route
                      path="/sales-estimate/sales-estimate-add/:id"
                      component={SalesEstimateAdd}
                    />

                    <Route
                      path="/sales-estimate/sales-estimate-edit/:id"
                      component={SalesEstimateAdd}
                    />

                    <Route
                      path="/sales-estimate/invoice/:action/:id"
                      component={InvoiceView}
                    />

                    <Route
                      path="/sales-estimate/proforma-invoices"
                      component={ProformaInvoices}
                    />

                    <Route
                      path="/sales-estimate/approve-reject-invoices"
                      component={ApproveRejectInvoices}
                    />

                    <Route
                      path="/sales-estimate/invoice-date-number"
                      component={InvoiceDateNumber}
                    />

                    <Route
                      path="/sales-estimate/invoice-list"
                      component={InvoiceList}
                    />

                    <Route
                      path="/sales-estimate/pending-invoices"
                      component={PendingInvoices}
                    />

                    <Route
                      path="/sales-estimate/credit-notes"
                      component={CreditNotes}
                    />

                    <Route
                      path="/sales-estimate/credit-note-add"
                      component={CreditNoteAddEdit}
                    />

                    <Route
                      path="/sales-estimate/credit-note-edit/:id"
                      component={CreditNoteAddEdit}
                    />

                    <Route
                      path="/sales-estimate/journal-voucher"
                      component={JournalVoucher}
                    />

                    <Route
                      path="/sales-estimate/journal-voucher-add"
                      component={JournalVoucherAddEdit}
                    />

                    <Route
                      path="/sales-estimate/journal-voucher-edit/:id"
                      component={JournalVoucherAddEdit}
                    />

                    <Route
                      path="/sales-estimate/manual-invoice-add"
                      component={ManualInvoiceAdd}
                    />

                    <Route
                      path="/inventories/city-update"
                      component={CityUpdate}
                    />

                    <Route path={"/settings"} component={UserSettings} />

                    <Route path={"/masters"} component={Masters} />
                    <Route path={"/masters-import"} component={MastersImport} />
                    <Route path="/photobucket-list" component={PhotoBucket} />
                    <Route
                      path="/reviewer-list"
                      component={PhotoBucketReviewer}
                    />
                    <Route path="/purchase/payee-list" component={PayeeList} />
                    <Route
                      path="/purchase/payee-add"
                      component={PayeeAddEdit}
                    />
                    <Route
                      path="/purchase/payee-edit/:id"
                      component={PayeeAddEdit}
                    />
                    <Route
                      path="/purchase/payout-estimate"
                      component={PayoutEstimate}
                    />
                    <Route
                      path="/purchase/payout-estimate-add"
                      component={PayoutEstimateAddEdit}
                    />
                    <Route
                      path="/purchase/payout-estimate-edit/:id"
                      component={PayoutEstimateAddEdit}
                    />
                    <Route
                      path="/purchase/sister-company-list"
                      component={SisterCompanyList}
                    />
                    <Route
                      path="/purchase/estimate-type-list"
                      component={EstimateTypeList}
                    />
                    <Route
                      path="/purchase/transactions"
                      component={ScheduledTransactions}
                      key="transactions"
                    />
                    <Route
                      path="/purchase/transactions-approval"
                      component={ScheduledTransactions}
                      key="transactions-approval"
                    />
                    <Route
                      path="/purchase/one-time-transactions"
                      component={OneTimeTransactions}
                    />
                    <Route
                      path="/purchase/transactions-add/:id"
                      component={TransactionsAddEdit}
                    />
                    <Route
                      path="/purchase/transactions-edit/:id"
                      component={TransactionsAddEdit}
                    />
                    <Route
                      path="/purchase/manual-transactions-add"
                      component={ManualTransactionsAddEdit}
                    />
                    <Route
                      path="/purchase/manual-transactions-edit/:id"
                      component={ManualTransactionsAddEdit}
                    />
                  </Switch>
                </div>
              </Content>

              <AppFooter handleTheme={this.handleTheme} />
            </Layout>
          </LoginAuthorization>
        )}

        {isPublic && (
          <Layout className={"layout " + (theme + "-theme")}>
            <AppHeaderPublic />

            <Content>
              <div className="site-layout-content">
                <Switch>
                  <Route path="/plans/plan-view/:id" component={PlanView} />
                  <Route
                    path="/campaigns/campaign-view/:id"
                    component={PlanView}
                  />
                  <Route path="/verify-email" component={VerifyEmail} />
                </Switch>
              </div>
            </Content>

            <AppFooterPublic />
          </Layout>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(App);
