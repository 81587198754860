import React, { Component } from "react";
import { ajaxRequester } from "../../services/ajax-requester";
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  Button,
  DatePicker,
  Upload,
  message,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import moment from "moment";

class InvoiceAdd extends Component {
  state = {
    results: "",
    isLoading: true,
    campaign: "",
    customer: "",
    companyList: [],
    gstList: [],
    addressList: [],
    isModal: false,
    fileList: [],
  };

  formRef = React.createRef();

  setDefaultData = () => {
    const { customer, campaign } = this.state;
    const { display_name } = campaign || "";

    let gstList = customer?.companies[0]?.gst_detail || [];
    let addressList = customer?.companies[0]?.gst_detail[0]?.addresses || [];
    let poList = campaign?.documents?.purchase_order || [];

    this.setState({ gstList, addressList, poList });

    this.formRef.current.setFieldsValue({
      display_name,
      company_id: customer?.companies[0]?.company_id,
      gstin: gstList[0]?.gstin,
      address: addressList[0]?.address,
      invoice_format_id: 1,
      place_of_supply_id: 24,
    });

    let data = {
      display_name,
      company_id: customer?.companies[0]?.company_id,
      gstin: gstList[0]?.gstin || "-",
      address: addressList[0]?.address || "-",
      invoice_format_id: 1,
      customer_id: customer?.customer_id,
      customer_name: customer?.customer_name,
      customer_code: customer?.customer_code,
      company_name: customer?.companies[0]?.company_name,
      campaign_id: campaign?.campaign_id,
      employee_id: campaign?.employee_id,
      place_of_supply_id: 24,
      pan:
        (gstList[0]?.gstin && gstList[0]?.gstin?.substring(2, 12)) ||
        customer?.companies[0]?.pan,
    };

    this.props.handleAddInvoiceForm(data);
  };

  // Gettng Seller Details
  getSallerDetails = () => {
    let reqUrl = "configuration/detail";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({
          sallerDetails: data?.configuration_details,
        });
      },
      "GET"
    );
  };

  // Gettng Invoice Formats
  getPlaceOfSupply = () => {
    let reqUrl = "lookup/placeofsupply";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({
          placeOfSupplyList: data?.response,
        });
      },
      "GET"
    );
  };

  // Gettng Invoice Formats
  getInvoiceFormat = () => {
    let reqUrl = "lookup/invoiceformat";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({
          formatList: data?.response,
        });
      },
      "GET"
    );
  };

  // Get Campaign Details
  getDetails = () => {
    var reqUrl = "campaign/detail?campaignId=" + Number(this.props.id);
    var reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState(
          {
            campaign: data.response,
          },
          () => this.getCustomerDetails()
        );
      },
      "GET"
    );
  };

  // Get Customer Details
  getCustomerDetails = () => {
    const customer_id = this.state?.campaign?.customer_id;

    var reqUrl = "customer/detail?customerid=" + Number(customer_id);
    var reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ customer: data.response }, () =>
          this.setCustomerData()
        );
      },
      "GET"
    );
  };

  setCustomerData = () => {
    const { customer } = this.state;
    let companyList = customer.companies.map((x) => x);
    this.setState(
      { companyList },
      () => !this.props?.editMode && this.setDefaultData()
    );
  };

  ValuesChange = (value, values) => {
    const { campaign, customer, companyList, gstList, placeOfSupplyList } =
      this.state;

    if (value["company_id"]) {
      let gst_detail = companyList.find(
        (x) => x.company_id === value["company_id"]
      ).gst_detail;
      this.setState({ gstList: gst_detail });
      this.formRef.current.setFieldsValue({ gstin: null });
      this.formRef.current.setFieldsValue({ address: null });
      values.address = null;
    }

    if (value["gstin"]) {
      let addresses = gstList.find((x) => x.gstin === value["gstin"]).addresses;
      this.setState({ addressList: addresses });
      this.formRef.current.setFieldsValue({ address: null });
    }

    let data = {
      ...values,
      customer_id: customer?.customer_id,
      customer_name: customer?.customer_name,
      customer_code: customer?.customer_code,
      company_name: companyList.find(
        (x) => x.company_id === values["company_id"]
      )?.company_name,
      campaign_id: campaign?.campaign_id,
      employee_id: campaign?.employee_id,
      place_of_supply: placeOfSupplyList.find(
        (x) => x.place_of_supply_id === values["place_of_supply_id"]
      )?.place_of_supply,
      pan:
        (values?.gstin && values?.gstin?.substring(2, 12)) ||
        companyList.find((x) => x.company_id === values["company_id"])?.pan,
    };

    this.props.handleAddInvoiceForm(data);
  };

  setDetails = () => {
    this.setState(
      {
        campaign: this.props,
        poList: [],
      },
      () => this.getCustomerDetails()
    );

    this.formRef.current.setFieldsValue({
      company_id: this.props?.company_id,
      gstin: this.props?.gstin,
      address: this.props?.address,
      display_name: this.props?.display_name,
      place_of_supply_id: this.props?.place_of_supply_id,
      invoice_format_id: this.props?.invoice_format_id,
      po_number: this.props?.po_number,
      notes: this.props?.notes,
      purchase_order_id: this.props?.purchase_order_id,
    });

    this.props?.po_date &&
      this.props?.po_date !== "0001-01-01T00:00:00" &&
      this.formRef.current.setFieldsValue({
        po_date: this.props?.po_date && moment(this.props?.po_date),
      });

    let data = {
      display_name: this.props?.display_name,
      company_id: this.props?.company_id,
      gstin: this.props?.gstin,
      address: this.props?.address,
      invoice_format_id: this.props?.invoice_format_id,
      customer_id: this.props?.customer_id,
      customer_name: this.props?.customer_name,
      customer_code: this.props?.customer_code,
      company_name: this.props?.company_name,
      campaign_id: this.props?.campaign_id,
      employee_id: this.props?.employee_id,
      place_of_supply_id: this.props?.place_of_supply_id,
      notes: this.props?.notes,
      pan:
        (this.props?.gstin && this.props?.gstin?.substring(2, 12)) ||
        this.props?.gstin,
    };

    this.props.handleAddInvoiceForm(data);
  };

  uploadImage = ({ file }) => {
    let fileList = this.state.fileList;

    fileList.push({
      uid: file.uid,
      name: file.name,
      status: "uploading",
    });

    this.setState({ fileList });

    this.getBase64(file, (image) => {
      this.handleUpload(file, image);
    });
  };

  getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  handleUpload = (file, imageUrl) => {
    let reqUrl = "campaign/document/upload";

    let reqObj = {
      request: {
        reference_id: Number(this.props.id),
        is_public: true,
        tags: [file.name.split(".")[0]],
        data_extension: "." + [...file.name.split(".")].pop(),
        type: file.type,
        data: imageUrl.split(",")[1],
        document_type: "2",
        name: file.name,
      },
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      if (data?.id) {
        message.success(`${file.name} file uploaded successfully`);
        this.setState({ fileList: [] });
        this.handlePOUpload(data?.id, file?.name);
      } else {
        message.error(data?.detail || `${file.name} file upload failed.`);
        this.setState({ fileList: [] });
      }
    });
  };

  handlePOUpload = (id, name) => {
    let poList = [...this.state.poList];
    poList.push({
      name,
      id,
    });
    this.setState({ poList });
    this.formRef.current.setFieldsValue({ purchase_order_id: id }, () =>
      this.ValuesChange()
    );
  };

  componentDidMount() {
    this.getSallerDetails();
    this.getInvoiceFormat();
    this.getPlaceOfSupply();
    !this.props?.editMode ? this.getDetails() : this.setDetails();
  }

  render() {
    const {
      formatList,
      placeOfSupplyList,
      companyList,
      gstList,
      addressList,
      poList,
      fileList,
    } = this.state;

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };

    const { Option } = Select;

    return (
      <React.Fragment>
        <Form
          name="invoice_add"
          {...layout}
          onFinish={this.onFinish}
          ref={this.formRef}
          onValuesChange={this.ValuesChange}
          className="invoice-add-form"
        >
          <Row>
            <Col span={8} xs={24} xl={8}>
              <Form.Item
                label="Company Name"
                name="company_id"
                rules={[{ required: true, message: "Select Company Name" }]}
              >
                <Select placeholder="Select Customer Name" allowClear={true}>
                  {companyList.map((x) => (
                    <Option key={x.company_id} value={x.company_id}>
                      {x.company_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8} xs={24} xl={8}>
              <Form.Item
                label="GSTIN"
                name="gstin"
                // rules={[{ required: true, message: "Select GSTIN" }]}
                tooltip={
                  this.props?.editMode
                    ? "Clear Company Name to get updated GSTIN"
                    : ""
                }
              >
                <Select placeholder="Select GSTIN">
                  {gstList.map((x) => (
                    <Option key={x.gstin} value={x.gstin}>
                      {x.gstin}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8} xs={24} xl={8}>
              <Form.Item
                label="Address"
                name="address"
                allowClear={true}
                // rules={[{ required: true, message: "Select Address" }]}
                tooltip={
                  this.props?.editMode
                    ? "Clear Company Name to get updated Address"
                    : ""
                }
              >
                <Select placeholder="Select Address">
                  {addressList &&
                    addressList.map((x) => (
                      <Option key={x.address} value={x.address}>
                        {x.address}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={16} xs={24} xl={16}>
              <Row>
                <Col span={12} xs={24} xl={12}>
                  <Form.Item label="Display Name" name="display_name">
                    <Input disabled />
                  </Form.Item>
                </Col>

                <Col span={12} xs={24} xl={12}>
                  <Form.Item
                    label="Place of Supply"
                    name="place_of_supply_id"
                    rules={[
                      { required: true, message: "Select Place of Supply" },
                    ]}
                  >
                    {placeOfSupplyList && (
                      <Select
                        placeholder="Select Place of Supply"
                        allowClear={true}
                      >
                        {placeOfSupplyList.map((x) => (
                          <Option
                            key={x.place_of_supply_id}
                            value={x.place_of_supply_id}
                          >
                            {x.place_of_supply}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={12} xs={24} xl={12}>
                  <Form.Item
                    label="Invoice Format"
                    name="invoice_format_id"
                    rules={[{ required: true, message: "Invoice Format" }]}
                  >
                    {formatList && (
                      <Select
                        placeholder="Select Invoice Format"
                        allowClear={true}
                      >
                        {formatList.map((x) => (
                          <Option
                            key={x.invoice_format_id}
                            value={x.invoice_format_id}
                          >
                            {x.invoice_format}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>

                <Col span={12} xs={24} xl={12}>
                  <Form.Item label="PO">
                    <Form.Item
                      style={{
                        display: "inline-block",
                        width: "85%",
                        margin: "0px 0px 8px 0px",
                      }}
                      name="purchase_order_id"
                    >
                      {poList && (
                        <Select placeholder="Select PO" allowClear={true}>
                          {poList.map((x) => (
                            <Option key={x.id} value={x.id}>
                              {x.name}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>

                    <Form.Item
                      style={{
                        display: "inline-block",
                        width: "calc(15% - 8px)",
                        height: "32px",
                        margin: "0 0px 0 8px",
                        overflow: "hidden",
                      }}
                    >
                      <Upload
                        fileList={fileList}
                        customRequest={this.uploadImage}
                      >
                        <Button
                          type="secondary"
                          icon={<UploadOutlined />}
                        ></Button>
                      </Upload>
                    </Form.Item>

                    <Form.Item
                      name="po_number"
                      style={{
                        display: "inline-block",
                        width: "50%",
                        margin: "0px",
                      }}
                    >
                      <Input placeholder="PO Number" />
                    </Form.Item>

                    <Form.Item
                      name="po_date"
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                        margin: "0 0px 0 8px",
                      }}
                    >
                      <DatePicker placeholder="PO Date" inputReadOnly={true} />
                    </Form.Item>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={8} xs={24} xl={8}>
              <Row>
                <Col span={24}>
                  <Form.Item name="notes" label="Notes">
                    <Input.TextArea
                      placeholder="Notes"
                      style={{ height: "88px" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    );
  }
}

export default InvoiceAdd;
