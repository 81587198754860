function isValidDate(date) {
    return date && Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date);
}

// Local storage based cache

const cache = {
    store : (key, value, ttl) => {
        localStorage.setItem(key, JSON.stringify({
            data : value,
            ttl : (ttl !== undefined) ? (!ttl) ? new Date().getTime() + 12 * 60 * 60 * 1000 : (isValidDate(ttl) ? ttl.getTime() : ttl) : null
        }));
    },
    get : (key) => {

        let item = localStorage.getItem(key);
        if(item) {
            let jsonData = JSON.parse(item);

            if(!jsonData.ttl) return jsonData.data;

            if(jsonData.ttl > new Date().getTime()) {
                return jsonData.data;
            } else {
                cache.remove(key);
            }
        }
        return null;
    },
    remove : (key) => {
        localStorage.removeItem(key);
    },
    clear : () => {
        localStorage.clear();
    }
};

export default cache;
