import React from "react";
import { Form, Checkbox, Space, Select, Row, Col, Input } from "antd";

const CustomerAddNotifications = (props) => {
  const { Option } = Select;

  const layout = {
    wrapperCol: { span: 24 },
  };

  const getNotification = (code) => {
    return (
      props.emailnotificationsList &&
      props.emailnotificationsList.find((x) => x.notification_code === code)
        .notification
    );
  };

  const getSelected = (code) => {
    return props.results
      ? props.results.email_notifications.find((x) => x.code === code).selected
      : true;
  };

  const getSelectedValue = (code) => {
    return props.results
      ? props.results.email_notifications.find((x) => x.code === code).value
      : "";
  };

  return (
    <div>
      <Form.List name="email_notifications">
        {(fields) => (
          <div>
            {fields.map((field) => (
              <Space key={field.key}>
                <Row>
                  <Col span="8" offset="1" xs={15} xl={8}>
                    <Form.Item
                      {...field}
                      {...layout}
                      name={[field.name, "BER"]}
                      fieldKey={[field.fieldKey, "BER"]}
                      valuePropName="checked"
                      initialValue={getSelected("BER")}
                    >
                      <Checkbox>{getNotification("BER")}</Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span="4" xs={8} xl={4}>
                    <Form.Item
                      {...field}
                      {...layout}
                      name={[field.name, "BERInput"]}
                      fieldKey={[field.fieldKey, "BERInput"]}
                      initialValue={getSelectedValue("BER")}
                    >
                      <Input
                        style={{ width: "100%" }}
                        placeholder="0, 2, 5 Hours"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span="8" offset="1" xs={15} xl={8}>
                    <Form.Item
                      {...field}
                      {...layout}
                      name={[field.name, "MMUP"]}
                      fieldKey={[field.fieldKey, "MMUP"]}
                      valuePropName="checked"
                      initialValue={getSelected("MMUP")}
                    >
                      <Checkbox>{getNotification("MMUP")}</Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span="4" xs={8} xl={4}>
                    <Form.Item
                      {...field}
                      {...layout}
                      name={[field.name, "MMUPInput"]}
                      fieldKey={[field.fieldKey, "MMUPInput"]}
                      initialValue={getSelectedValue("MMUP") || "onEvent"}
                    >
                      <Select placeholder="Select">
                        <Option value="onEvent">onEvent</Option>
                        <Option value="Compilation">Compilation</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span="8" offset="1" xs={15} xl={8}>
                    <Form.Item
                      {...field}
                      {...layout}
                      name={[field.name, "CER"]}
                      fieldKey={[field.fieldKey, "CER"]}
                      valuePropName="checked"
                      initialValue={getSelected("CER")}
                    >
                      <Checkbox>{getNotification("CER")}</Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span="4" xs={8} xl={4}>
                    <Form.Item
                      {...field}
                      {...layout}
                      name={[field.name, "CERInput"]}
                      fieldKey={[field.fieldKey, "CERInput"]}
                      initialValue={getSelectedValue("CER")}
                    >
                      <Input
                        style={{ width: "100%" }}
                        placeholder="0, 3, 5, 7 Days"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span="8" offset="1" xs={15} xl={8}>
                    <Form.Item
                      {...field}
                      {...layout}
                      name={[field.name, "SIC"]}
                      fieldKey={[field.fieldKey, "SIC"]}
                      valuePropName="checked"
                      initialValue={getSelected("SIC")}
                    >
                      <Checkbox>{getNotification("SIC")}</Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span="8" offset="1" xs={15} xl={8}>
                    <Form.Item
                      {...field}
                      {...layout}
                      name={[field.name, "SCNC"]}
                      fieldKey={[field.fieldKey, "SCNC"]}
                      valuePropName="checked"
                      initialValue={getSelected("SCNC")}
                    >
                      <Checkbox>{getNotification("SCNC")}</Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span="8" offset="1" xs={15} xl={8}>
                    <Form.Item
                      {...field}
                      {...layout}
                      name={[field.name, "SOR"]}
                      fieldKey={[field.fieldKey, "SOR"]}
                      valuePropName="checked"
                      initialValue={getSelected("SOR")}
                    >
                      <Checkbox>{getNotification("SOR")}</Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span="4" xs={8} xl={4}>
                    <Form.Item
                      {...field}
                      {...layout}
                      name={[field.name, "SORInput"]}
                      fieldKey={[field.fieldKey, "SORInput"]}
                      initialValue={getSelectedValue("SOR") || "Monthly"}
                    >
                      <Select>
                        <Option value="Monthly">Monthly</Option>
                        <Option value="Weekly">Weekly</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span="8" offset="1" xs={15} xl={8}>
                    <Form.Item
                      {...field}
                      {...layout}
                      name={[field.name, "SPR"]}
                      fieldKey={[field.fieldKey, "SPR"]}
                      valuePropName="checked"
                      initialValue={getSelected("SPR")}
                    >
                      <Checkbox>{getNotification("SPR")}</Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span="8" offset="1" xs={15} xl={8}>
                    <Form.Item
                      {...field}
                      {...layout}
                      name={[field.name, "GACR"]}
                      fieldKey={[field.fieldKey, "GACR"]}
                      valuePropName="checked"
                      initialValue={getSelected("GACR")}
                    >
                      <Checkbox>{getNotification("GACR")}</Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span="4" xs={8} xl={4}>
                    <Form.Item
                      {...field}
                      {...layout}
                      name={[field.name, "GACRInput"]}
                      fieldKey={[field.fieldKey, "GACRInput"]}
                      initialValue={getSelectedValue("GACR")}
                    >
                      <Input
                        style={{ width: "100%" }}
                        placeholder="Before 7, 16 Days"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Space>
            ))}
          </div>
        )}
      </Form.List>
    </div>
  );
};

export default CustomerAddNotifications;
