import React, { Component } from "react";
import { ajaxRequester } from "../../services/ajax-requester";
import { Row, Col, Spin, Button, message, Menu, Dropdown, Modal } from "antd";
import PageTitleDetails from "../../components/common/page-title-detail";
import {
  dateFormatter,
  currencyFormatter,
} from "../../components/common/common-formatter";
import InvoiceDetails from "../../components/sales-estimate/invoice-details";
import InvoiceFormat from "../../components/sales-estimate/invoice-format";
import {
  EditOutlined,
  CloseCircleOutlined,
  EllipsisOutlined,
  DownloadOutlined,
  CheckOutlined,
  UnorderedListOutlined,
  StopOutlined,
  RotateLeftOutlined,
} from "@ant-design/icons";
import { RolesPermissions } from "../../helpers/roles-permissions";
import InvoiceActivityLog from "../../components/sales-estimate/invoice-activity-log";
import InvoicePrint from "../../components/sales-estimate/invoice-print";

class InvoiceView extends Component {
  state = {
    invoiceId: this.props.match.params.id,
    results: "",
    isLoading: true,
    sallerDetails: "",
    isApprovalBtns: this.props.match.params.action === "approval",
    isDropdownMenu: this.props.match.params.action === "edit",
    isActivityLog: false,
  };

  // Redirect to Sales Estimate List
  handleBack = () => {
    this.props.history.goBack();
  };

  // Get Inventory Details
  getInvoiceDetails = () => {
    const { invoiceId } = this.state;

    var reqUrl = "salesestimate/invoice/detail?invoiceid=" + Number(invoiceId);
    var reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({
          ...data.response,
          invoice_format_id:
            this?.props?.match?.params.action === "approval" &&
            data?.response?.is_client_approved
              ? 1
              : data?.response?.invoice_format_id,
          isLoading: false,
        });
      },
      "GET"
    );
  };

  // Setting Columns
  setColumns = () => {
    let columns = [
      {
        title: "Description",
        dataIndex: "description",
        render: (text, record) =>
          (text && text) + record.campaign_inventory_id + " " + record.notes,
      },
      {
        title: "Start Date",
        dataIndex: "start_date",
        render: (text) => dateFormatter(text),
        width: 124,
      },
      {
        title: "End Date",
        dataIndex: "end_date",
        render: (text) => dateFormatter(text),
        width: 124,
      },
      {
        title: "Days",
        dataIndex: "no_of_days",
        width: 92,
      },
      {
        title: "Monthly Rate",
        dataIndex: "monthly_rate",
        render: (text) => currencyFormatter(parseInt(text)),
        width: 168,
      },
      {
        title: "Cost",
        dataIndex: "rental_cost",
        render: (text) => currencyFormatter(parseInt(text)),
        width: 168,
      },
    ];

    this.setState({ columns });
  };

  // Gettng Seller Details
  getsallerDetails = () => {
    let reqUrl = "configuration/detail";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({
          sallerDetails: data?.configuration_details,
        });
      },
      "GET"
    );
  };

  // handle Action Menu
  handleMenuClick = ({ key }) => {
    const { invoiceId } = this.state;
    key === "cancel" && this.handleCancel();
    key === "edit" &&
      this.props.history.push(
        "/sales-estimate/sales-estimate-edit/" + Number(invoiceId)
      );
  };

  // Approve Reject Revert Invoice
  handleApproval = (action) => {
    const { invoiceId } = this.state;

    var reqUrl = "salesestimate/invoice/" + action;
    var reqObj = {
      request: { invoice_id: Number(invoiceId) },
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      if (data?.success) {
        if (action === "approve") message.success("Approved successfully");
        if (action === "reject") message.success("Rejected successfully");
        if (action === "revert") message.success("Reverted successfully");
        this.handleBack();
      } else {
        message.error(
          data?.detail || "An error occured while processing your request."
        );
      }
    });
  };

  // Handle Cancel Invoie
  handleCancel = () => {
    const { invoiceId } = this.state;
    var reqUrl = "salesestimate/invoice/cancel?invoiceid=" + Number(invoiceId);
    var reqObj = "";
    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        if (data?.success) {
          message.success("Invoice Cancellation Sent For Approval");
          this.handleBack();
        } else {
          message.error(
            data?.detail || "An error occured while processing your request."
          );
        }
      },
      "DELETE"
    );
  };

  handleActivityLog = () => {
    this.setState({ isActivityLog: !this.state.isActivityLog });
  };

  // Get Activity Log
  getInvoiceActivityLog = () => {
    const { invoiceId } = this.state;

    let reqUrl = "salesestimate/invoice/activitylog?invoiceid=" + invoiceId;
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({
          isShowActivityLogButton:
            data?.response && data?.response?.length > 0 ? true : false,
        });
      },
      "GET"
    );
  };

  // Approve Reject Revert Invoice
  handleApprovalByClient = () => {
    const { invoiceId } = this.state;

    var reqUrl = "salesestimate/invoice/clientapproved";
    var reqObj = {
      request: { invoice_id: Number(invoiceId) },
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      if (data?.success) {
        message.success("Approved by Client successfully");
        this.handleBack();
      } else {
        message.error(
          data?.detail || "An error occured while processing your request."
        );
      }
    });
  };

  handlePrint = () => {
    window.print();
  };

  componentDidMount() {
    this.getInvoiceDetails();
    this.getsallerDetails();
    this.setColumns();
    this.getInvoiceActivityLog();
  }

  render() {
    const {
      isLoading,
      isApprovalBtns,
      isDropdownMenu,
      invoice_no,
      invoice_type,
      isActivityLog,
      isShowActivityLogButton,
      is_client_approved,
    } = this.state;

    return (
      <Row>
        <Col span={24}>
          {!isLoading && (
            <React.Fragment>
              <div className="print-hide">
                <PageTitleDetails
                  titleText="Invoice Preview"
                  isBackBtn="true"
                  handleBack={this.handleBack}
                />
              </div>

              <div
                className="page-title-custom-btn print-hide"
                style={{ display: "flex" }}
              >
                {isApprovalBtns &&
                  is_client_approved &&
                  RolesPermissions("su") && (
                    <div>
                      <Button
                        type="primary"
                        className="custom-btn-approve icon-btn"
                        onClick={() => this.handleApproval("approve")}
                        icon={<CheckOutlined />}
                      >
                        Approve
                      </Button>

                      {!invoice_no && (
                        <Button
                          type="primary"
                          className="custom-btn-reject icon-btn"
                          onClick={() => this.handleApproval("reject")}
                          icon={<StopOutlined />}
                        >
                          Reject
                        </Button>
                      )}

                      {invoice_no && (
                        <Button
                          type="primary"
                          onClick={() => this.handleApproval("revert")}
                          style={{ marginRight: "16px" }}
                          className="icon-btn"
                          icon={<RotateLeftOutlined />}
                        >
                          Revert
                        </Button>
                      )}

                      {isShowActivityLogButton && (
                        <Button
                          type="primary"
                          onClick={() => this.handleActivityLog()}
                          className="icon-btn"
                          icon={<UnorderedListOutlined />}
                        >
                          Activity Log
                        </Button>
                      )}
                    </div>
                  )}

                {!is_client_approved && (
                  <div>
                    <Button
                      type="primary"
                      className="custom-btn-approve icon-btn"
                      onClick={() => this.handleApprovalByClient()}
                      icon={<CheckOutlined />}
                    >
                      Approve by Client
                    </Button>

                    <Button
                      type="primary"
                      onClick={() => this.handleMenuClick({ key: "edit" })}
                      className="icon-btn"
                      icon={<EditOutlined />}
                    >
                      Edit
                    </Button>
                  </div>
                )}

                <Button
                  type="primary"
                  onClick={() => this.handlePrint()}
                  style={{ marginLeft: "16px" }}
                  icon={<DownloadOutlined />}
                  className="icon-btn"
                >
                  Download
                </Button>

                {isDropdownMenu && (
                  <div style={{ marginLeft: "16px" }}>
                    <Dropdown
                      overlay={
                        <Menu onClick={(key) => this.handleMenuClick(key)}>
                          {RolesPermissions("ad") && (
                            <Menu.Item
                              key="cancel"
                              icon={<CloseCircleOutlined />}
                            >
                              Cancel Invoice
                            </Menu.Item>
                          )}

                          {invoice_type !== "M" && RolesPermissions("ad") && (
                            <Menu.Item key="edit" icon={<EditOutlined />}>
                              Edit Invoice
                            </Menu.Item>
                          )}

                          <Menu.Item
                            key={"downloadpo"}
                            icon={<DownloadOutlined />}
                          >
                            Download PO
                          </Menu.Item>
                        </Menu>
                      }
                      trigger={["click"]}
                      placement="bottomRight"
                    >
                      <Button
                        style={{ paddingLeft: "8px", paddingRight: "8px" }}
                      >
                        <EllipsisOutlined />
                      </Button>
                    </Dropdown>
                  </div>
                )}
              </div>

              <div className="print-hide">
                <InvoiceDetails {...this.state} />
              </div>

              <div className="invoice-print-view" style={{ display: "none" }}>
                <InvoicePrint {...this.state} />
              </div>

              <div className="print-hide" style={{ marginTop: "24px" }}>
                <InvoiceFormat {...this.state} />
              </div>

              <Modal
                title={"Activity Log"}
                visible={isActivityLog}
                key={isActivityLog}
                onCancel={() => this.setState({ isActivityLog: false })}
                footer={null}
                centered
              >
                <InvoiceActivityLog key={isActivityLog} {...this.state} />
              </Modal>
            </React.Fragment>
          )}

          {isLoading && (
            <div className="list-loader">
              <Spin />
            </div>
          )}
        </Col>
      </Row>
    );
  }
}

export default InvoiceView;
