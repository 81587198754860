import React from "react";
import { Form, Input, Button, Space } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const CustomerAddGstin = ({ gstfields }) => {
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
  };
  return (
    <Form.List name={[gstfields.name, "gst_detail"]}>
      {(fields, { add, remove }) => {
        return (
          <div className="gst-info">
            {fields.map((field) => (
              <React.Fragment key={field.key}>
                <Space>
                  <Form.Item
                    {...field}
                    name={[field.name, "gstin"]}
                    fieldKey={[field.fieldKey, "gstin"]}
                    label="GSTIN"
                    {...layout}
                  >
                    <Input placeholder="Enter - if GSTIN not available" />
                  </Form.Item>

                  {fields.length > 1 ? (
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => {
                        remove(field.name);
                      }}
                    />
                  ) : null}
                </Space>

                <Form.List name={[field.name, "addresses"]}>
                  {(address, { add, remove }) => {
                    return (
                      <div>
                        {address.map((item) => (
                          <div key={item.key} style={{ position: "relative" }}>
                            <Form.Item
                              {...item}
                              name={[item.name, "address"]}
                              fieldKey={[item.fieldKey, "address"]}
                              label="Invoice Address"
                              {...layout}
                            >
                              <Input placeholder="Invoice Address" />
                            </Form.Item>

                            {address.length > 1 ? (
                              <MinusCircleOutlined
                                className="dynamic-delete-button"
                                onClick={() => {
                                  remove(item.name);
                                }}
                              />
                            ) : null}
                          </div>
                        ))}

                        <Form.Item
                          {...{ wrapperCol: { offset: 6, span: 10 } }}
                          className="fields-list-btn"
                        >
                          <Button
                            type="dashed"
                            onClick={() => {
                              add();
                            }}
                          >
                            <PlusOutlined /> Add Address
                          </Button>
                        </Form.Item>
                      </div>
                    );
                  }}
                </Form.List>
              </React.Fragment>
            ))}

            <Form.Item
              {...{ wrapperCol: { offset: 6, span: 10 } }}
              className="fields-list-btn"
            >
              <Button
                type="dashed"
                onClick={() => {
                  add();
                }}
              >
                <PlusOutlined /> Add Another GSTIN
              </Button>
            </Form.Item>
          </div>
        );
      }}
    </Form.List>
  );
};

export default CustomerAddGstin;
