import React, { Component } from "react";
import { Row, Col, Table, Modal, message } from "antd";
import PageTitleDetails from "../../components/common/page-title-detail";
import OperationsBase from "../../base/operations-base";
import ListFilters from "../../components/common/list-filters";
import OperationNav from "../../components/operations/operation-nav";
import AssignMounter from "../../components/operations/assign-mounter";
import AddMounting from "../../components/operations/add-mounting";
import ReceiveUnmountedFlex from "../../components/operations/receive-unmounted-flex";
import OperationTabs from "../../components/operations/operation-tabs";
import SoldItems from "../../components/operations/sold-items";
import CampaignOperationTabs from "../../components/operations/campaign-operation-tabs";
import AddCreative from "../../components/operations/add-creative";
import PhotoGallery from "../../components/common/photo-gallery";
import ExportOperations from "../../components/operations/export-operations";
import AddMountingUnmounting from "../../components/operations/add-mounting-unmounting";
import AdditionalPrintingMounting from "../../components/operations/additional-printing-mounting";

class OperationList extends OperationsBase {
  state = {
    page: "",
    data: [],
    pagination: {
      current: 1,
      pageSize: 20,
    },
    loading: false,
    columns: [],
    columnsDefault: [],
    filterColumns: [],
    sorting: [],
    selectedRowKeys: [],
    selectedRows: [],
    isModal: false,
    groupDetails: false,
    isShowPhotos: false,
    refreshId: 1,
    screen: "",
  };

  componentDidMount() {
    this.getPage();
  }

  render() {
    const {
      page,
      data,
      columns,
      filterColumns,
      pagination,
      loading,
      title,
      selectedRowKeys,
      isModal,
      modalTitle,
      modalAction,
      listReq,
      groupDetails,
      isShowPhotos,
      photos,
      refreshId,
      screen,
      photosTitle,
    } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const isRowSelection =
      listReq?.includes("history") ||
      listReq?.includes("campaign/") ||
      listReq?.includes("changeofsite");
    return (
      <React.Fragment>
        <Row>
          <Col span={24}>
            <PageTitleDetails
              titleText={title}
              key={page + "title"}
              isBackBtn={listReq?.includes("campaign/") ? true : false}
              handleBack={this.handleBack}
            />

            <OperationNav
              handleAction={this.handleAction}
              handleSwitch={this.handleSwitch}
              {...this.state}
              key={page + "nav"}
            />

            {page === "stock-management" && (
              <OperationTabs
                {...this.state}
                handleTabEvent={this.handleTabEvent}
              />
            )}

            {page === "campaign-operations" && (
              <CampaignOperationTabs
                {...this.state}
                handleTabEvent={this.handleTabEvent}
              />
            )}

            <ListFilters
              items={filterColumns}
              handleSettings={this.handleSettings}
              handleFilters={this.handleFilters}
              key={filterColumns}
              screen={screen}
            />

            <Table
              columns={columns}
              rowKey={(record) =>
                page === "monitoring"
                  ? record.operation_monitoring_id
                  : screen === "campaign-operations-monitoring"
                  ? record.operation_monitoring_id
                  : screen === "mounting-unmounting"
                  ? record.unmount_operation_id +
                    "-" +
                    record.mount_operation_id
                  : record.operation_id
              }
              dataSource={data}
              pagination={pagination}
              loading={loading}
              onChange={this.handleTableChange}
              bordered
              scroll={{ x: 400 }}
              size="small"
              rowSelection={!isRowSelection ? rowSelection : false}
              key={this.state.activeTab}
            />
          </Col>
        </Row>

        {modalAction !== "add-additional-creative" &&
          modalAction !== "add-campaign-monitoring" && (
            <Modal
              centered
              title={modalTitle}
              visible={isModal}
              onCancel={this.handleModal}
              footer={false}
            >
              {(modalAction === "assign-mounter" ||
                modalAction === "assign-unmounter") && (
                <AssignMounter
                  key={isModal + refreshId}
                  {...this.state}
                  handleAssignMounter={this.handleAssignMounter}
                  handleAssignMounterUnmounter={
                    this.handleAssignMounterUnmounter
                  }
                />
              )}

              {(modalAction === "add-mounting" ||
                modalAction === "edit-mounting" ||
                modalAction === "add-unmounting-photo" ||
                modalAction === "edit-unmounting-photo" ||
                modalAction === "add-monitoring" ||
                modalAction === "edit-monitoring") && (
                <AddMounting
                  key={isModal + refreshId}
                  {...this.state}
                  handleAddMounting={this.handleAddMounting}
                />
              )}

              {modalAction === "receive-unmounted-flex" && (
                <ReceiveUnmountedFlex
                  key={isModal + refreshId}
                  {...this.state}
                  handleReceiveUnmountedFlex={this.handleReceiveUnmountedFlex}
                />
              )}

              {modalAction === "sold" && (
                <SoldItems
                  key={groupDetails}
                  {...this.state}
                  handleCreateGroup={this.handleCreateGroup}
                  handleSold={this.handleSold}
                />
              )}

              {(modalAction === "add-mounting-unmounting" ||
                modalAction === "edit-mounting-unmounting") && (
                <AddMountingUnmounting
                  key={isModal + refreshId}
                  {...this.state}
                  handleAddMounting={this.handleAddMounting}
                />
              )}

              {modalAction === "excel-export" && (
                <ExportOperations key={isModal} {...this.state} />
              )}
            </Modal>
          )}

        {(modalAction === "add-additional-creative" ||
          modalAction === "add-campaign-monitoring") && (
          <Modal
            centered
            title={modalTitle}
            visible={isModal}
            onCancel={this.handleModal}
            footer={false}
            width={"82%"}
          >
            {(modalAction === "add-additional-creative" ||
              modalAction === "add-campaign-monitoring") && (
              <AddCreative
                key={isModal + refreshId}
                {...this.state}
                handleAddCreative={this.handleAddCreative}
                handleAddMonitoring={this.handleAddMonitoring}
              />
            )}
          </Modal>
        )}

        {modalAction === "additional-printing-mounting" && (
          <Modal
            centered
            title={modalTitle}
            visible={isModal}
            onCancel={this.handleModal}
            footer={false}
            width={"82%"}
          >
            {modalAction === "additional-printing-mounting" && (
              <AdditionalPrintingMounting
                key={isModal + refreshId}
                {...this.state}
                handleAdditionalPrintingMounting={
                  this.handleAdditionalPrintingMounting
                }
              />
            )}
          </Modal>
        )}

        {isShowPhotos && (
          <PhotoGallery
            photos={photos}
            photosTitle={photosTitle}
            handlePhotos={this.handlePhotos}
          />
        )}
      </React.Fragment>
    );
  }
}

export default OperationList;
