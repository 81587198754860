import {useState, useEffect} from "react";
import LoginForm from "./login-form";
import AdminApi from "../api/admin-api";
import {Spin} from "antd";

export const LoginAuthorization = (props) => {

    const [loginStatus, setLoginStatus] = useState(-1);

    const reloadStatus = async () => {
        if (await AdminApi.isLoggedIn()) {
            setLoginStatus(1);
        } else {
            setLoginStatus(0);
        }
    }

    useEffect( ()=> {
        reloadStatus().then();
    }, [])

    return (
        <>
            {(loginStatus === 0 || loginStatus === -1) && <LoginForm isLoading={loginStatus === -1} onLoginSucces={reloadStatus} />}
            {loginStatus === 1 && props.children}
        </>
    )
};

export const RoleAuthorization = (props) => {

    const [user, setUser] = useState(AdminApi.getCurrentUser());

    return (
        <>
            {user.Roles?.some(x => props.roles.includes(x)) && props.children}
        </>
    )
}
