import React from "react";
import { Row, Col, Table, Button, Popover, Badge, Popconfirm } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import PageTitle from "../../components/common/page-title";
import ListFilters from "../../components/common/list-filters";
import CommonListBase from "../../base/common-list-base";

class CommonList extends CommonListBase {
  state = {
    listReq: "customer/list?",
    addReq: "customers/customer-add",
    editReq: "customers/customer-edit/",
    delReq: "customer/delete?customerid=",
    dtlReq: "customers/customer-details/",
    data: [],
    pagination: {
      current: 1,
      pageSize: 20,
    },
    loading: false,
    columns: [],
    columnsDefault: [],
    filterColumns: [],
    sorting: [],
  };

  // Setting Columns
  setColumns = () => {
    let columns = [
      {
        title: "Code",
        dataIndex: "customer_code",
      },
      {
        title: "Customer Name",
        dataIndex: "customer_name",
        render: (text, record) => (
          <Button
            style={{ padding: "0px" }}
            type="link"
            onClick={() => this.detailsItem(record.customer_id)}
          >
            {text}
          </Button>
        ),
      },
      {
        title: "GST",
        dataIndex: "companies",
        render: (text) => text[0].gst_detail[0]?.gstin,
      },
      {
        title: "POC Name",
        dataIndex: "point_of_contact",
        render: (text) => this.showPopover(text),
      },
      {
        title: "POC Number",
        dataIndex: "point_of_contact",
        render: (text) => this.showPopoverPoc(text),
      },
      {
        title: "Assigned Employee",
        dataIndex: "assigned_employee",
        render: (text) => this.showPopover(text),
      },
      {
        title: "Actions",
        dataIndex: "",
        key: "x",
        render: (record) => (
          <React.Fragment>
            <Button
              onClick={() => this.editItem(record.customer_id)}
              icon={<EditOutlined />}
              className="ant-btn-reset"
            />
            <Popconfirm
              title="Are you sure delete?"
              placement="left"
              okText="Yes"
              cancelText="No"
              onConfirm={() => this.deleteItem(record.customer_id)}
            >
              <Button
                icon={<DeleteOutlined />}
                className="ant-btn-reset"
                style={{ marginLeft: "12px" }}
              />
            </Popconfirm>
          </React.Fragment>
        ),
      },
    ];

    const filterColumns = [
      { columnName: "customer_name", displayName: "Customer Name" },
      { columnName: "customer_code", displayName: "Code" },
      { columnName: "gst_detail", displayName: "GST" },
      { columnName: "contact_name", displayName: "POC Name" },
      { columnName: "phone_no", displayName: "POC Number" },
      { columnName: "employee_name", displayName: "Assigned Employee" },
    ];

    this.setState({ columns, columnsDefault: columns, filterColumns });
  };

  // Show Tooltip for multiple records
  showPopover = (text) => {
    let content =
      text &&
      text.length > 0 &&
      text.map((x) => {
        let name =
          (x.first_name && x.first_name + " " + x.last_name) || x.contact_name;
        return (
          <div key={name} style={{ margin: "8px 0px" }}>
            {name}
          </div>
        );
      });

    let popover =
      content.length > 1 ? (
        <Popover content={content}>
          <Badge
            count={content.length}
            size="small"
            offset={[8, 12]}
            style={{ background: "none", color: "#2db7f5" }}
          >
            {content[0]}
          </Badge>
        </Popover>
      ) : (
        content[0]
      );

    return popover;
  };

  // Show Tooltip for multiple records for Point of Contacts
  showPopoverPoc = (text) => {
    let content =
      text &&
      text.length > 0 &&
      text.map((x) => {
        let name = x.phone_no + " - " + x.contact_name;
        return (
          <div key={name} style={{ margin: "8px 0px" }}>
            {name}
          </div>
        );
      });

    let contentPhone =
      text &&
      text.length > 0 &&
      text.map((x) => {
        let phone = x.phone_no;
        return (
          <div key={phone} style={{ margin: "8px 0px" }}>
            {phone}
          </div>
        );
      });

    let popover =
      content.length > 1 ? (
        <Popover content={content}>
          <Badge
            count={content.length}
            size="small"
            offset={[8, 12]}
            style={{ background: "none", color: "#2db7f5" }}
          >
            {contentPhone[0]}
          </Badge>
        </Popover>
      ) : (
        contentPhone[0]
      );

    return popover;
  };

  componentDidMount() {
    // Get List
    const { pagination } = this.state;
    this.getResults({ pagination });
    this.setColumns();
  }

  render() {
    const { data, columns, pagination, loading, filterColumns } = this.state;

    return (
      <Row>
        <Col span={24}>
          <PageTitle
            titleText="Customers List"
            isAddBtn={"Add Customer"}
            handleAdd={this.addItem}
          />

          <ListFilters
            items={filterColumns}
            handleSettings={this.handleSettings}
            handleFilters={this.handleFilters}
            key={filterColumns}
          />

          <Table
            columns={columns}
            rowKey={(record) => record.customer_id}
            dataSource={data}
            pagination={pagination}
            loading={loading}
            onChange={this.handleTableChange}
            bordered
            scroll={{ x: 400 }}
            size="small"
          />
        </Col>
      </Row>
    );
  }
}

export default CommonList;
