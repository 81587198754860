import React from "react";
import {
  Row,
  Col,
  Table,
  Button,
  Menu,
  Dropdown,
  Modal,
  Switch,
  Drawer,
  message,
  Popconfirm,
} from "antd";
import {
  CopyOutlined,
  ShareAltOutlined,
  EllipsisOutlined,
  MergeCellsOutlined,
  DeleteOutlined,
  UnorderedListOutlined,
  ExportOutlined,
  TrademarkCircleTwoTone,
} from "@ant-design/icons";
import PageTitle from "../../components/common/page-title";
import ListFilters from "../../components/common/list-filters";
import CommonListBase from "../../base/common-list-base";
import PlanActions from "../../components/plan/plan-actions";
import { ajaxRequester } from "../../services/ajax-requester";
import {
  currencyFormatter,
  dateFormatter,
} from "../../components/common/common-formatter";
import PortalUser from "../../iam/components/portal-user";
import CampaignsMerge from "../../components/campaign/campaigns-merge";
import { Link } from "react-router-dom";
import { RolesPermissions } from "../../helpers/roles-permissions";

class Campaigns extends CommonListBase {
  state = {
    listReq: "campaign/list?employeeid=1&",
    delReq: "campaign/delete?campaignId=",
    dtlReq: "campaign-details/",
    data: [],
    pagination: {
      current: 1,
      pageSize: 20,
    },
    loading: false,
    columns: [],
    columnsDefault: [],
    filterColumns: [],
    sorting: [],
    isModal: false,
    record: "",
    selectedRowKeys: [],
    selectedRows: [],
    isDrawer: false,
    screen: "campaigns",
    isCompaleted: false,
  };

  handleMenuClick = ({ key }, record) => {
    record.action = key;
    key === "delete" && (record.title = "Delete Campaign");
    key === "block" && (record.title = "Block Plan");
    key === "unblock" && (record.title = "Unblock Plan");
    key === "copy" && (record.title = "Copy as Plan");
    key === "share" && (record.title = "Share as Plan");
    key === "merge" && (record.title = "Merge Campaigns");
    key === "activity" && (record.title = "Campaign Activities");
    key === "activity" && this.getActivityLog(record.campaign_id);
    key === "publiclink" &&
      window.open("/#/campaigns/campaign-view/" + record.publish_id, "_blank");

    key !== "publiclink" && this.setState({ record }, () => this.handleModal());
  };

  // Setting Columns
  setColumns = () => {
    let columns = [
      {
        title: "Project Id",
        dataIndex: "project_id",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 100,
      },
      {
        title: "Employee",
        dataIndex: "employee_id",
        render: (text) => <PortalUser user={text} />,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 140,
      },
      {
        title: "Customer Name",
        dataIndex: "customer_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 140,
      },
      {
        title: "Display",
        dataIndex: "display_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text, record) => (
          <Link
            style={{ padding: "0px", whiteSpace: "normal", textAlign: "left" }}
            type="link"
            to={this.state.dtlReq + record.campaign_id}
          >
            {text}
            {record?.is_rotational && (
              <TrademarkCircleTwoTone
                twoToneColor="#52c41a"
                style={{ marginLeft: "8px" }}
              />
            )}
          </Link>
        ),
      },
      {
        title: "From",
        dataIndex: "start_date",
        render: (text) => dateFormatter(text),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "To",
        dataIndex: "end_date",
        render: (text) => dateFormatter(text),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Days",
        dataIndex: "no_of_days",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "SQFT",
        dataIndex: "total_sqft",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Amount",
        dataIndex: "total_amount",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => currencyFormatter(parseInt(text)),
      },
      {
        title: "QoS",
        dataIndex: "base_rate_diff",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => (
          <span className={text > 0 ? "ant-color-green" : "ant-color-red"}>
            {text}%
          </span>
        ),
      },
      {
        title: "Status",
        dataIndex: "campaign_status",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => text,
      },
      {
        title: "Actions",
        dataIndex: "",
        key: "x",
        render: (record) => (
          <div style={{ textAlign: "center" }}>
            <Dropdown
              overlay={
                <Menu onClick={(key) => this.handleMenuClick(key, record)}>
                  <Menu.Item key="copy" icon={<CopyOutlined />}>
                    Copy as Plan
                  </Menu.Item>
                  <Menu.Item key="share" icon={<ShareAltOutlined />}>
                    Share as Plan
                  </Menu.Item>
                  {record.campaign_status === "Approved" && (
                    <Menu.Item key="delete" icon={<DeleteOutlined />}>
                      Delete Campaign
                    </Menu.Item>
                  )}
                  <Menu.Item key="activity" icon={<UnorderedListOutlined />}>
                    Activity
                  </Menu.Item>
                  <Menu.Item key="publiclink" icon={<ExportOutlined />}>
                    Public Link
                  </Menu.Item>
                </Menu>
              }
              placement="bottomRight"
              trigger={["click"]}
            >
              <Button className="ant-btn-reset">
                <EllipsisOutlined />
              </Button>
            </Dropdown>
          </div>
        ),
      },
    ];

    const filterColumns = [
      { columnName: "project_id", displayName: "Project Id" },
      { columnName: "employee_id", displayName: "Employee" },
      {
        columnName: "customer_name",
        displayName: "Customer Name",
      },
      { columnName: "display_name", displayName: "Display", default: true },
      { columnName: "start_date", displayName: "From" },
      { columnName: "end_date", displayName: "To" },
      { columnName: "no_of_days", displayName: "Days" },
      { columnName: "total_sqft", displayName: "SQFT" },
      { columnName: "total_amount", displayName: "Amount" },
      { columnName: "base_rate_diff", displayName: "QoS" },
      { columnName: "campaign_status", displayName: "Status" },
    ];

    this.setState({ columnsDefault: columns, filterColumns }, () =>
      this.setDefaultColumns(columns)
    );
  };

  // Handle Popup
  handleModal = () => {
    this.setState({ isModal: !this.state.isModal });
  };

  // Handle Popup Actions
  handleActions = (data) => {
    this.handleModal();
    const { action, campaign_id } = this.state.record;
    action === "delete" && this.deleteItem(campaign_id);
    action === "block" && this.blockUnblockItem(action, campaign_id);
    action === "unblock" && this.blockUnblockItem(action, campaign_id);
    action === "copy" && this.planActions(action, campaign_id, data);
    action === "share" && this.planActions(action, campaign_id, data);
    action === "merge" && this.handleDrawer(data);
  };

  // Plan Actions
  planActions = (action, campaign_id, data) => {
    var reqUrl =
      "campaign/" +
      (action === "copy"
        ? "copyasplan"
        : action === "share"
        ? "shareasplan"
        : action);
    var reqObj = {
      request: {
        campaign_id: campaign_id,
        display_name: data.display_name,
        shared_with_employee_id: data.employee_id?.value,
      },
    };

    ajaxRequester(reqUrl, reqObj, () => {
      const { pagination } = this.state;
      this.getResults({ pagination });
    });
  };

  // Plan Block Unblock
  blockUnblockItem = (action, plan_id) => {
    var reqUrl = "plan/" + action + "?planid=" + plan_id;
    var reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      () => {
        const { pagination } = this.state;
        this.getResults({ pagination });
      },
      "GET"
    );
  };

  // Get Plan Activities
  getActivityLog = (id) => {
    this.setState({ activityLog: "" });

    var reqUrl = "campaign/activitylog?campaignid=" + id;
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ activityLog: data.response });
      },
      "GET"
    );
  };

  // Add Plan
  addPlan = () => {
    this.props.history.push(`/inventories/inventories-master`);
  };

  // Check Box Selection
  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedRowKeys, selectedRows });
  };

  handleDrawer = (data) => {
    this.setState({ mergeCampagin: data, isDrawer: !this.state.isDrawer });
  };

  handleMergeCampaign = () => {
    this.handleDrawer();
    const { pagination } = this.state;
    this.getResults({ pagination });
  };

  handleExpiredCamapignsToggle = (e) => {
    const { pagination } = this.state;
    let listReq = this.state.listReq.includes("showexpired=true&")
      ? this.state.listReq.replace("showexpired=true&", "")
      : this.state.listReq + "showexpired=true&";
    this.setState({ listReq }, () => this.getResults({ pagination }));
    this.setState({ isCompaleted: e });
  };

  // Setup Background Colors
  setColors = (record) => {
    return (
      record.campaign_status.includes("Pending for Approval") && "row-bg-red"
    );
  };

  handleHardDelete = () => {
    const { selectedRowKeys } = this.state;

    var reqUrl = "campaign/delete/final";
    var reqObj = {
      request: {
        campaign_ids: selectedRowKeys,
      },
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      if (data?.response?.status === "success") {
        message.success("Campaign(s) has been deleted successfully");
        const { pagination } = this.state;
        this.getResults({ pagination });
        this.setState({ selectedRowKeys: [], selectedRowKeys: [] });
      } else {
        let statusArr = data?.response?.delete_campaign_statuses || [];
        statusArr = statusArr.filter((x) => x.status !== "success");

        let errorString = "";
        statusArr?.length > 0 &&
          statusArr.map((x) => (errorString = x?.message + "," + errorString));
        message.error(errorString);
        this.setState({ selectedRowKeys: [], selectedRowKeys: [] });
      }
    });
  };

  componentDidMount() {
    // Get List
    const { pagination } = this.state;
    this.getResults({ pagination });
    this.setColumns();
  }

  render() {
    const {
      data,
      columns,
      pagination,
      loading,
      filterColumns,
      isModal,
      record,
      selectedRowKeys,
      isDrawer,
      screen,
      isCompaleted,
    } = this.state;

    const rowSelection = {
      selectedRowKeys,
      preserveSelectedRowKeys: true,
      onChange: this.onSelectChange,

      getCheckboxProps: (record) => ({
        disabled: record.is_read,
        is_read: record.is_read,
      }),
    };

    return (
      <React.Fragment>
        <Row>
          <Col span={24}>
            <PageTitle titleText="Campaign List" />

            <ListFilters
              items={filterColumns}
              handleSettings={this.handleSettings}
              handleFilters={this.handleFilters}
              key={filterColumns}
              screen={screen}
            />

            <div
              style={{
                top: "-48px",
                float: "right",
                right: "48px",
                marginBottom: "-48px",
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
              class="title-btns"
            >
              <Switch
                size="small"
                onChange={this.handleExpiredCamapignsToggle}
              ></Switch>
              <label style={{ marginLeft: "8px", marginRight: "16px" }}>
                Completed<span className="mobile-hide"> Campaigns</span>
              </label>
              {this.state.selectedRowKeys.length > 1 && (
                <Dropdown
                  overlay={
                    <Menu
                      onClick={(key) =>
                        this.handleMenuClick(key, { action: key })
                      }
                    >
                      <Menu.Item key="merge" icon={<MergeCellsOutlined />}>
                        Merge Campaigns
                      </Menu.Item>
                    </Menu>
                  }
                  placement="bottomRight"
                  trigger={["click"]}
                >
                  <Button style={{ paddingLeft: "8px", paddingRight: "8px" }}>
                    <EllipsisOutlined />
                  </Button>
                </Dropdown>
              )}

              {isCompaleted &&
                this.state.selectedRowKeys.length > 0 &&
                RolesPermissions("su") && (
                  <Popconfirm
                    title="Are you sure delete?"
                    placement="left"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => this.handleHardDelete()}
                  >
                    <Button
                      style={{
                        paddingLeft: "8px",
                        paddingRight: "8px",
                        marginLeft: "8px",
                      }}
                    >
                      <DeleteOutlined />
                    </Button>
                  </Popconfirm>
                )}
            </div>

            <Table
              columns={columns}
              rowKey={(record) => record.campaign_id}
              dataSource={data}
              pagination={pagination}
              loading={loading}
              onChange={this.handleTableChange}
              bordered
              scroll={{ x: 400 }}
              size="small"
              rowSelection={rowSelection}
              rowClassName={(record) => this.setColors(record)}
            />
          </Col>
        </Row>

        <Modal
          centered
          title={record.title}
          visible={isModal}
          onCancel={this.handleModal}
          width={
            record.action === "delete"
              ? 280
              : record.action === "activity"
              ? 800
              : 400
          }
          footer={false}
        >
          <PlanActions
            key={isModal}
            {...record}
            {...this.state}
            handleActions={this.handleActions}
          />
        </Modal>

        <Drawer
          title={"Merge Campaigns"}
          placement="right"
          visible={isDrawer}
          onClose={this.handleDrawer}
          width={"90%"}
          key={isDrawer}
        >
          <CampaignsMerge
            key={isDrawer}
            {...this.state}
            handleActions={this.handleMergeCampaign}
          />
        </Drawer>
      </React.Fragment>
    );
  }
}

export default Campaigns;
