import React from "react";
import { Row, Col, Table, Button, Drawer } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import PageTitle from "../../components/common/page-title";
import ListFilters from "../../components/common/list-filters";
import CommonListBase from "../../base/common-list-base";
import {
  currencyFormatter,
  dateFormatter,
} from "../../components/common/common-formatter";
import PortalUser from "../../iam/components/portal-user";
import { Link } from "react-router-dom";
import { RolesPermissions } from "../../helpers/roles-permissions";
import ExportInvoices from "./export-invoices";

class PendingInvoices extends CommonListBase {
  state = {
    listReq: "salesestimate/pendinginvoicelist?employeeid=1&",
    delReq: "salesestimate/pendinginvoicedelete?campaignId=",
    dtlReq: "sales-estimate-add/",
    data: [],
    pagination: {
      current: 1,
      pageSize: 20,
    },
    loading: false,
    columns: [],
    columnsDefault: [],
    filterColumns: [],
    sorting: [],
    screen: "pending-invoices",
    isDrawer: false,
  };

  // Setting Columns
  setColumns = () => {
    let columns = [
      {
        title: "Project Id",
        dataIndex: "project_id",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 100,
      },
      {
        title: "Employee",
        dataIndex: "employee_id",
        render: (text) => <PortalUser user={text} />,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 140,
      },
      {
        title: "Customer Name",
        dataIndex: "customer_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 140,
      },
      {
        title: "Display",
        dataIndex: "display_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text, record) =>
          RolesPermissions("ad") || RolesPermissions("sr") ? (
            <Link
              style={{
                padding: "0px",
                whiteSpace: "normal",
                textAlign: "left",
              }}
              type="link"
              to={this.state.dtlReq + record.campaign_id}
            >
              {text}
            </Link>
          ) : (
            <span>{text}</span>
          ),
        width: 118,
      },
      {
        title: "From",
        dataIndex: "start_date",
        render: (text) => dateFormatter(text),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 110,
      },
      {
        title: "To",
        dataIndex: "end_date",
        render: (text) => dateFormatter(text),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 98,
      },
      {
        title: "Invoice Amount (without Tax)",
        dataIndex: "invoice_amount",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => currencyFormatter(parseInt(text)),
        width: 142,
      },
      {
        title: "Invoice Amount (with Tax)",
        dataIndex: "total_amount",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => currencyFormatter(parseInt(text)),
        width: 142,
      },
      {
        title: "HA Markup",
        dataIndex: "ha_base_rate_diff_amt",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text, record) => (
          <span className={text > 0 ? "ant-color-green" : "ant-color-red"}>
            {currencyFormatter(parseInt(text))} ({record?.ha_base_rate_diff}%)
          </span>
        ),
      },
      {
        title: "TA Markup",
        dataIndex: "ta_base_rate_diff_amt",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text, record) => (
          <span className={text > 0 ? "ant-color-green" : "ant-color-red"}>
            {currencyFormatter(parseInt(text))} ({record?.ta_base_rate_diff}%)
          </span>
        ),
      },
      {
        title: "QoS",
        dataIndex: "qos",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text, record) => (
          <span className={text > 0 ? "ant-color-green" : "ant-color-red"}>
            {currencyFormatter(parseInt(text))} ({record.qos_per}%)
          </span>
        ),
      },
      {
        title: "Ageing From Start Date",
        dataIndex: "aging_days",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 132,
      },
    ];

    const filterColumns = [
      { columnName: "project_id", displayName: "Project Id" },
      { columnName: "employee_id", displayName: "Employee" },
      {
        columnName: "customer_name",
        displayName: "Customer Name",
      },
      { columnName: "display_name", displayName: "Display", default: true },
      { columnName: "start_date", displayName: "From", hideFilter: true },
      { columnName: "end_date", displayName: "To", hideFilter: true },
      {
        columnName: "invoice_amount",
        displayName: "Invoice Amount (without Tax)",
        hideFilter: true,
      },
      {
        columnName: "total_amount",
        displayName: "Invoice Amount (with Tax)",
        hideFilter: true,
      },
      {
        columnName: "ha_base_rate_diff_amt",
        displayName: "HA Markup",
        hideFilter: true,
      },
      {
        columnName: "ta_base_rate_diff_amt",
        displayName: "TA Markup",
        hideFilter: true,
      },
      { columnName: "qos", displayName: "QoS", hideFilter: true },
      {
        columnName: "aging_days",
        displayName: "Ageing From Start Date",
        hideFilter: true,
      },
    ];

    this.setState({ columnsDefault: columns, filterColumns }, () =>
      this.setDefaultColumns(columns)
    );
  };

  // Handle Drawer
  handleDrawer = () => {
    this.setState({ isDrawer: !this.state.isDrawer });
  };

  componentDidMount() {
    // Get List
    const { pagination } = this.state;
    this.getResults({ pagination });
    this.setColumns();
  }

  render() {
    const {
      data,
      columns,
      pagination,
      loading,
      filterColumns,
      screen,
      isDrawer,
    } = this.state;

    return (
      <React.Fragment>
        <Row>
          <Col span={24}>
            <PageTitle titleText="Pending Invoices" />

            <div className="page-title-custom-btn">
              <Button
                type="primary"
                onClick={this.handleDrawer}
                icon={<DownloadOutlined />}
                className="icon-btn"
              >
                Excel Export
              </Button>
            </div>

            <ListFilters
              items={filterColumns}
              handleSettings={this.handleSettings}
              handleFilters={this.handleFilters}
              key={filterColumns}
              screen={screen}
            />

            <Table
              columns={columns}
              rowKey={(record) => record.project_id}
              dataSource={data}
              pagination={pagination}
              loading={loading}
              onChange={this.handleTableChange}
              bordered
              scroll={{ x: 400 }}
              size="small"
            />
          </Col>
        </Row>

        <Drawer
          title="Pending Invoices Excel Export"
          placement="right"
          visible={isDrawer}
          onClose={this.handleDrawer}
          width={"478px"}
          key={isDrawer}
        >
          <ExportInvoices key={isDrawer} />
        </Drawer>
      </React.Fragment>
    );
  }
}

export default PendingInvoices;
