import { getImageRelayUrl, getRelayUrl } from "../../services/ajax-requester";
import pptxgen from "pptxgenjs";
import {
  getImageBlobDataWithDimension,
  getImageDimensions,
  getImageHeight,
  getImageWidth,
} from "../../helpers/image-helper";
import {
  dateFormatter,
  timeFormatter,
  currencyFormatter,
} from "../../components/common/common-formatter";
import moment from "moment";

const getEmployeeDetails = (employee_id, employee_phone) => {
  let employeeDetails = localStorage.getItem("user:" + employee_id);
  employeeDetails = employeeDetails ? JSON.parse(employeeDetails) : "";
  let employeeName =
    employeeDetails?.data?.firstName + " " + employeeDetails?.data?.lastName;
  let employeeNumber = employeeDetails.data?.phoneNumber;
  return !employee_phone ? employeeName : employeeNumber;
};

const resizeHeight = 920;
const quality = 50;

export const exportPlanToPPTx = (
  details,
  configuration,
  settings,
  callBack
) => {
  let isCardRates = settings.includes("Card Rates");
  let isDiscountedRates = settings.includes("Discounted Rates");
  let isAvailableFrom = settings.includes("Available From");
  let isReplaceWithAvailable = settings.includes("Replace Blocked");

  const bannerLogo =
    configuration?.configuration_details?.documents?.logo?.at(-1)?.data_url ||
    "";

  const website = configuration?.configuration_details?.website || "";

  const TermsAndConditions =
    configuration?.configuration_details?.plan_settings?.terms_and_conditions ||
    [];

  let inventories =
    details.plan_inventory_details ??
    details.response?.plan_inventory_details ??
    details.campaign_inventory_details ??
    details.response.campaign_inventory_details;

  // Fetch all URLs
  let urls = [];
  inventories
    .filter((inventory) => inventory.ha_images?.length > 0)
    .forEach((inventory) => {
      inventory.ha_images.forEach((img) => {
        urls.push(
          getImageRelayUrl(img.data_url, 0, resizeHeight, quality, true)
        );
      });
    });

  let imagePromises = urls.map((x) => getImageBlobDataWithDimension(x, true));
  Promise.all(imagePromises)
    .then((results) => {
      let imageIndex = {};
      results.forEach((x) => {
        imageIndex[x.url] = x;
      });

      let pres = new pptxgen();
      pres.layout = "LAYOUT_4x3";

      // Introduction Slide
      let introSlide = pres.addSlide();

      introSlide.addShape(pres.ShapeType.rect, {
        x: 0,
        y: 0,
        w: 0.07,
        h: "100%",
        fill: "#3782C3",
      });

      // Adding Logo
      if (bannerLogo) {
        introSlide.addImage({
          path: getImageRelayUrl(bannerLogo),
          x: 0.5,
          y: 1,
          w: 5,
          h: 1.5,
          sizing: { type: "contain", w: 5, h: 1.5 },
        });
      }

      introSlide.addShape(pres.shapes.LINE, {
        x: 0.5,
        y: 3,
        w: 9.0,
        h: 0.0,
        line: { width: 1 },
      });

      if (localStorage.getItem("user:" + details?.employee_id)) {
        introSlide.addText(
          (details?.employee_id && getEmployeeDetails(details?.employee_id)) ||
            "Employee Name",
          {
            x: 0.5,
            y: 3.25,
            w: 5,
            h: 0.4,
            fontSize: 16,
            fontFace: "Calibri",
          }
        );

        introSlide.addText(
          (details?.employee_id &&
            getEmployeeDetails(details?.employee_id, "phone")) ||
            "Employee Number",
          {
            x: 0.5,
            y: 3.65,
            w: 5,
            h: 0.4,
            fontSize: 16,
            fontFace: "Calibri",
          }
        );
      }

      introSlide.addText(dateFormatter(new Date()), {
        x: 5.5,
        y: 3.25,
        w: 4,
        h: 0.4,
        fontSize: 18,
        bold: true,
        fontFace: "Calibri",
        align: "right",
      });

      introSlide.addText("Live Availability with Maps", {
        x: 0.5,
        y: 6.75,
        w: 4,
        h: 0.4,
        fontSize: 15,
        fontFace: "Calibri",
        hyperlink: {
          url:
            window.location.origin +
            (!details?.campaign_key
              ? "/#/plans/plan-view/"
              : "/#/campaigns/campaign-view/") +
            details.publish_id,
        },
      });

      if (website) {
        introSlide.addText(website, {
          x: 5.5,
          y: 6.75,
          w: 4,
          h: 0.4,
          fontSize: 15,
          fontFace: "Calibri",
          align: "right",
          hyperlink: {
            url:
              "http://" +
              website.replace("http://", "").replace("https://", ""),
            tooltip: website,
          },
        });
      }

      // Inventories Slides
      inventories
        .filter((inventory) => inventory.ha_images?.length > 0)
        .forEach((inventory, index) => {
          inventory.ha_images.forEach((img, idx) => {
            let inventorySlide = pres.addSlide();
            let image =
              imageIndex[
                getImageRelayUrl(
                  img.data_url,
                  null,
                  resizeHeight,
                  quality,
                  true
                )
              ];

            let imageWidth = 9.5;
            let imageHeight = 5.25;

            if (image.height > image.width) {
              imageWidth = getImageWidth(imageHeight, image);
            }
            if (image.width > image.height) {
              imageHeight = getImageHeight(imageWidth, image);
            }
            let imageOptions = {
              x: 0.25,
              y: 0.25,
              w: imageWidth,
              h: imageHeight,
              sizing: {
                type: "contain",
                w: 9.5,
                h: 5.25,
              },
            };
            if (image.data) {
              imageOptions.data = image.data;
            } else {
              imageOptions.path = image.url;
            }
            inventorySlide.addImage(imageOptions);

            let c1 = {
              text:
                index +
                1 +
                ") " +
                inventory?.inventory_code +
                " - " +
                inventory?.city +
                ", " +
                inventory?.area +
                ", " +
                inventory?.location +
                " - " +
                inventory?.size +
                " - " +
                inventory?.light_type,
              options: {
                bold: true,
                align: "center",
                colspan: 2,
                fill: "#dddddd",
              },
            };

            let displayQuantity =
              inventory?.quantity > 1 || inventory?.side_type === "double"
                ? " (Qty " +
                  inventory?.quantity +
                  " / Display " +
                  inventory?.quantity *
                    (inventory?.side_type === "double" ? 2 : 1) +
                  ")"
                : "";

            let c2 = [
              { text: "Media Type : ", options: { bold: true } },
              {
                text: inventory?.media_type + displayQuantity,
              },
            ];

            let c3 = [
              { text: "Availability : ", options: { bold: true } },
              {
                text:
                  inventory.booking_status === "B" && !isReplaceWithAvailable
                    ? "Blocked till " +
                      (inventory.blocked_till_date &&
                        dateFormatter(inventory.blocked_till_date) +
                          " " +
                          timeFormatter(inventory.blocked_till_date))
                    : inventory.booking_status === "N"
                    ? inventory?.total_blocked_count > 0
                      ? dateFormatter(inventory.available_from) +
                        (!isReplaceWithAvailable ? " / Blocked" : "")
                      : dateFormatter(inventory.available_from)
                    : "Available Now",
              },
            ];

            let c4 = [
              {
                text:
                  "Card Rates (" +
                  Number(
                    moment(inventory?.end_date, "YYYY-MM-DD").diff(
                      moment(inventory?.start_date, "YYYY-MM-DD"),
                      "days"
                    ) + 1
                  ) +
                  " days) : ",
                options: { bold: true },
              },
              {
                text:
                  currencyFormatter(
                    parseInt(
                      (inventory.card_rate / 30) *
                        (moment(inventory?.end_date, "YYYY-MM-DD").diff(
                          moment(inventory?.start_date, "YYYY-MM-DD"),
                          "days"
                        ) +
                          1)
                    )
                  ) +
                  (inventory?.quantity > 1
                    ? " (" +
                      currencyFormatter(
                        parseInt(
                          ((inventory.card_rate / 30) *
                            (moment(inventory?.end_date, "YYYY-MM-DD").diff(
                              moment(inventory?.start_date, "YYYY-MM-DD"),
                              "days"
                            ) +
                              1)) /
                            inventory?.quantity
                        )
                      ) +
                      " / unit)"
                    : ""),
              },
            ];

            let c5 = [
              {
                text:
                  "Discounted Rates (" +
                  Number(
                    moment(inventory?.end_date, "YYYY-MM-DD").diff(
                      moment(inventory?.start_date, "YYYY-MM-DD"),
                      "days"
                    ) + 1
                  ) +
                  " days) : ",
                options: { bold: true },
              },
              {
                text:
                  currencyFormatter(parseInt(inventory.rental_cost)) +
                  (inventory?.quantity > 1
                    ? " (" +
                      currencyFormatter(
                        parseInt(inventory.rental_cost / inventory?.quantity)
                      ) +
                      " / unit)"
                    : ""),
                options: { bold: true, color: "FF0000" },
              },
            ];

            let row1 = [c1];
            //let row2 = [{ text: c2 }, { text: c3 }];
            //let row3 = [{ text: c4 }, { text: c5 }];
            //let row4 = [{ text: c5, options: { colspan: 2 } }];
            //let row5 = [{ text: c5, options: { colspan: 2 } }];

            let table = [];
            table.push(row1);

            if (isCardRates && isDiscountedRates && isAvailableFrom) {
              table.push([{ text: c2 }, { text: c3 }]);
              table.push([{ text: c4 }, { text: c5 }]);
            }

            if (!isCardRates && isDiscountedRates && isAvailableFrom) {
              table.push([{ text: c2 }, { text: c3 }]);
              table.push([{ text: c5, options: { colspan: 2 } }]);
            }

            if (isCardRates && !isDiscountedRates && isAvailableFrom) {
              table.push([{ text: c2 }, { text: c3 }]);
              table.push([{ text: c4, options: { colspan: 2 } }]);
            }

            if (!isCardRates && !isDiscountedRates && isAvailableFrom) {
              table.push([{ text: c2 }, { text: c3 }]);
            }

            if (isCardRates && isDiscountedRates && !isAvailableFrom) {
              table.push([{ text: c2, options: { colspan: 2 } }]);
              table.push([{ text: c4 }, { text: c5 }]);
            }

            if (!isCardRates && isDiscountedRates && !isAvailableFrom) {
              table.push([{ text: c2 }, { text: c5 }]);
            }

            if (isCardRates && !isDiscountedRates && !isAvailableFrom) {
              table.push([{ text: c2 }, { text: c4 }]);
            }

            if (!isCardRates && !isDiscountedRates && !isAvailableFrom) {
              table.push([{ text: c2, options: { colspan: 2 } }]);
            }

            if (inventory?.remarks) {
              inventorySlide.addText(inventory?.remarks, {
                x: 0,
                y: 0,
                h: 0.5,
                w: 3,
                fontSize: 16,
                fill: "#1890ff",
                color: "#ffffff",
                bold: true,
                fontFace: "Calibri",
                align: "center",
              });
            }

            //isDiscountedRates && !isAvailableFrom && table.push(row4);
            //!isDiscountedRates && isAvailableFrom && table.push(row5);

            inventorySlide.addTable(table, {
              x: 0.25,
              y: 5.75,
              w: 9.5,
              rowH: [0.6, 0.4, 0.4],
              valign: "middle",
              fontFace: "Calibri",
              fontSize: 16,
            });
          });
        });

      // Terms And Conditions Slide
      let TermsBullets = [];

      TermsAndConditions.map((term) => {
        TermsBullets.push({
          text: term,
          options: { bullet: { type: "number" }, breakLine: true },
        });
      });

      let termsSlide = pres.addSlide();

      termsSlide.addShape(pres.ShapeType.rect, {
        x: 0,
        y: 0,
        w: 0.07,
        h: "100%",
        fill: "#3782C3",
      });

      termsSlide.addText("Terms and Conditions - ", {
        x: 0.5,
        y: 0.25,
        w: 9,
        h: 0.6,
        fontSize: 24,
        bold: true,
        fontFace: "Calibri",
      });

      termsSlide.addText(TermsBullets, {
        x: 0.5,
        y: 1,
        w: 9.0,
        h: 4,
        fontSize: 16,
        fontFace: "Calibri",
        valign: "top",
        lineSpacing: 20,
        paraSpaceAfter: 8,
      });

      termsSlide.addShape(pres.shapes.LINE, {
        x: 0.5,
        y: 6.0,
        w: 9.0,
        h: 0.0,
        line: { width: 1 },
      });

      if (localStorage.getItem("user:" + details?.employee_id)) {
        termsSlide.addText(
          (details?.employee_id && getEmployeeDetails(details?.employee_id)) ||
            "Employee Name",
          {
            x: 0.5,
            y: 6.25,
            w: 9,
            h: 0.5,
            fontSize: 18,
            fontFace: "Calibri",
            align: "right",
          }
        );

        termsSlide.addText(
          (details?.employee_id &&
            getEmployeeDetails(details?.employee_id, "phone")) ||
            "Employee Number",
          {
            x: 0.5,
            y: 6.75,
            w: 9,
            h: 0.5,
            fontSize: 18,
            fontFace: "Calibri",
            align: "right",
          }
        );
      }

      termsSlide.addText("Live Availability with Maps", {
        x: 0.5,
        y: 6.75,
        w: 4,
        h: 0.4,
        fontSize: 15,
        fontFace: "Calibri",
        hyperlink: {
          url:
            window.location.origin +
            (!details?.campaign_key
              ? "/#/plans/plan-view/"
              : "/#/campaigns/campaign-view/") +
            details.publish_id,
        },
      });

      pres
        .writeFile({
          fileName: configuration.exportName ?? "presentation.pptx",
          compression: true,
        })
        .then(() => callBack(true))
        .catch(() => callBack(false));
    })
    .catch((e) => {
      console.log(e);
    });
};
