import React from "react";
import PageTitleDetails from "../../components/common/page-title-detail";
import { Row, Col, Spin } from "antd";
import CustomerAddForm from "../../components/customers/customer-add-form";
import { ajaxRequester } from "../../services/ajax-requester";
import CustomersBase from "../../base/customers-base";

class CustomerEdit extends CustomersBase {
  state = {
    isSuccess: false,
    customerKey: 1,
    assignedEmployeeList: "",
    segmentList: "",
    designationList: "",
    emailnotificationsList: "",
    results: "",
    isLoading: true,
    mode: "edit",
  };

  // Get Customer Details
  getCustomerDetails = () => {
    const customerId = this.props.match.params.id;

    var reqUrl = "customer/detail?customerid=" + Number(customerId);
    var reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ results: data.response, isLoading: false });
      },
      "GET"
    );
  };

  componentDidMount() {
    this.getCustomerDetails();
    this.getLookupData();
  }

  render() {
    const { isLoading, customerKey } = this.state;

    return (
      <Row>
        <Col span={24}>
          <PageTitleDetails
            titleText="Edit Customer"
            isBackBtn="true"
            handleBack={this.handleBack}
          />
          {!isLoading && (
            <CustomerAddForm
              {...this.state}
              key={customerKey}
              handleAddCustomers={this.handleUpdateCustomers}
              handleBack={this.handleBack}
            />
          )}

          {isLoading && (
            <div className="list-loader">
              <Spin />
            </div>
          )}
        </Col>
      </Row>
    );
  }
}

export default CustomerEdit;
