import React from "react";
import { Row, Col, Table, Button, Switch, Modal, message } from "antd";
import { ajaxRequester } from "../../services/ajax-requester";
import {
  PlusOutlined,
  DownloadOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import PageTitle from "../../components/common/page-title";
import ListFilters from "../../components/common/list-filters";
import CommonListBase from "../../base/common-list-base";
import {
  currencyFormatter,
  dateFormatter,
} from "../../components/common/common-formatter";
import PortalUser from "../../iam/components/portal-user";
import InvoiceAddNumberDate from "../../components/sales-estimate/invoice-add-number-date";
import { Link } from "react-router-dom";
import { RolesPermissions } from "../../helpers/roles-permissions";

class InvoiceDateNumber extends CommonListBase {
  state = {
    listReq: "salesestimate/approvedinvoicelist?employeeid=1&",
    delReq: "salesestimate/approvedinvoicedelete?campaignId=",
    dtlReq: "/sales-estimate/invoice/view/",
    data: [],
    pagination: {
      current: 1,
      pageSize: 20,
    },
    loading: false,
    columns: [],
    columnsDefault: [],
    filterColumns: [],
    sorting: [],
    selectedRowKeys: [],
    selectedRows: [],
    isModal: false,
    screen: "invoice-date-number",
  };

  // Setting Columns
  setColumns = () => {
    let columns = [
      {
        title: "Project Id",
        dataIndex: "project_id",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 100,
      },
      {
        title: "Type",
        dataIndex: "invoice_type",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => (text === "E" ? "From Estimate" : "Manual"),
        width: 120,
      },
      {
        title: "Invoice Number",
        dataIndex: "invoice_no",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text, record) =>
          text ||
          ((RolesPermissions("su") ||
            RolesPermissions("ad") ||
            RolesPermissions("ac")) && (
            <Button
              className="ant-btn-reset"
              type="link"
              icon={<PlusOutlined />}
              onClick={() =>
                this.handleModalActions("Add Invoice Number", record)
              }
            >
              Add
            </Button>
          )),
        width: 140,
      },
      {
        title: "Employee",
        dataIndex: "employee_id",
        render: (text) => <PortalUser user={text} />,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 140,
      },
      {
        title: "Customer Name",
        dataIndex: "customer_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 140,
      },
      {
        title: "Display",
        dataIndex: "display_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 118,
        render: (text, record) => (
          <Link
            style={{
              padding: "0px",
              whiteSpace: "normal",
              textAlign: "left",
            }}
            type="link"
            to={this.state.dtlReq + record.invoice_id}
          >
            {text}
          </Link>
        ),
      },
      {
        title: "Invoice Date",
        dataIndex: "invoice_date",
        render: (text) => (text ? dateFormatter(text) : " - "),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 118,
      },
      {
        title: "From",
        dataIndex: "duration_from",
        render: (text) => (text ? dateFormatter(text) : ""),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "To",
        dataIndex: "duration_to",
        render: (text) => (text ? dateFormatter(text) : ""),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Status",
        dataIndex: "invoice_status",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Rent Amount",
        dataIndex: "total_display_cost",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => currencyFormatter(parseInt(text)),
      },
      {
        title: "Print Amount",
        dataIndex: "total_printing_cost",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => currencyFormatter(parseInt(text)),
      },
      {
        title: "Installation Amount",
        dataIndex: "total_installing_cost",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => currencyFormatter(parseInt(text)),
      },
      {
        title: "Total Without Tax",
        dataIndex: "invoice_amount",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => currencyFormatter(parseInt(text)),
        width: 118,
      },
      {
        title: "Total With Tax",
        dataIndex: "total_amount",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => currencyFormatter(parseInt(text)),
        width: 102,
      },
      {
        title: "Campaign Difference",
        dataIndex: "campaign_difference",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => (
          <span className={text > 0 ? "ant-color-green" : "ant-color-red"}>
            {currencyFormatter(parseInt(text))}
          </span>
        ),
      },
      {
        title: "HA Markup",
        dataIndex: "ha_markup",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text, record) => (
          <span className={text > 0 ? "ant-color-green" : "ant-color-red"}>
            {currencyFormatter(parseInt(text))} ({record.ha_markup_per}%)
          </span>
        ),
      },
      {
        title: "TA Markup",
        dataIndex: "ta_markup",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text, record) => (
          <span className={text > 0 ? "ant-color-green" : "ant-color-red"}>
            {currencyFormatter(parseInt(text))} ({record.ta_markup_per}%)
          </span>
        ),
      },
      {
        title: "QoS",
        dataIndex: "qos",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text, record) =>
          text && (
            <span className={text > 0 ? "ant-color-green" : "ant-color-red"}>
              {currencyFormatter(parseInt(text))} ({record.qos_per}%)
            </span>
          ),
      },
      {
        title: "Download PO",
        dataIndex: "po_url",
        render: (text) => (
          <Button
            icon={<DownloadOutlined />}
            className="ant-btn-reset"
            disabled={text ? false : true}
            onClick={() => window.open(text)}
          />
        ),
        align: "center",
      },
    ];

    const filterColumns = [
      { columnName: "project_id", displayName: "Project Id" },
      { columnName: "invoice_type", displayName: "Type", hideFilter: true },
      {
        columnName: "invoice_no",
        displayName: "Invoice Number",
        hideFilter: true,
      },
      { columnName: "employee_id", displayName: "Employee" },
      {
        columnName: "customer_name",
        displayName: "Customer Name",
      },
      { columnName: "display_name", displayName: "Display", default: true },
      {
        columnName: "invoice_date",
        displayName: "Invoice Date",
        hideFilter: true,
      },
      { columnName: "duration_from", displayName: "From", hideFilter: true },
      { columnName: "duration_to", displayName: "To", hideFilter: true },
      { columnName: "invoice_status", displayName: "Status", hideFilter: true },
      {
        columnName: "total_display_cost",
        displayName: "Rent Amount",
        hideFilter: true,
      },
      {
        columnName: "total_printing_cost",
        displayName: "Print Amount",
        hideFilter: true,
      },
      {
        columnName: "total_installing_cost",
        displayName: "Installation Amount",
        hideFilter: true,
      },
      {
        columnName: "invoice_amount",
        displayName: "Total Without Tax",
        hideFilter: true,
      },
      {
        columnName: "total_amount",
        displayName: "Total With Tax",
        hideFilter: true,
      },
      {
        columnName: "campaign_difference",
        displayName: "Campaign Difference",
        hideFilter: true,
      },
      { columnName: "ha_markup", displayName: "HA Markup", hideFilter: true },
      { columnName: "ta_markup", displayName: "TA Markup", hideFilter: true },
      { columnName: "base_rate_diff", displayName: "QoS", hideFilter: true },
      {
        columnName: "po_url",
        displayName: "Download PO",
        hideFilter: true,
      },
    ];

    this.setState({ columnsDefault: columns, filterColumns }, () =>
      this.setDefaultColumns(columns)
    );
  };

  // Handle Closed Switch
  handleAllToggle = (e) => {
    const { pagination } = this.state;
    let filters = "&showall=" + e;
    this.getResults({ pagination, filters });
  };

  // Handle Popup
  handleModal = () => {
    this.setState({ isModal: !this.state.isModal });
  };

  // Handle Actions Add Date or Number
  handleModalActions = (action, record) => {
    this.setState({ action, record }, () => this.handleModal());
  };

  // Handle Add Number and Date
  handleAddNumberDate = (values) => {
    const { action, record, selectedRowKeys } = this.state;

    this.handleModal();

    var reqUrl =
      "salesestimate/invoice/" +
      (action === "Add Invoice Number" ? "assignno" : "assigndate");
    var reqObj = {
      request: {
        invoice_id:
          action === "Add Invoice Number" ? record.invoice_id : selectedRowKeys,
        invoice_no: values.invoice_no,
        invoice_date: values.invoice_date,
      },
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      if (data.success) {
        message.success(
          action === "Add Invoice Number"
            ? "Invoice Number added successfully"
            : "Invoice Date added successfully"
        );
      } else {
        message.error(
          data?.detail || "An error occured while processing your request."
        );
      }

      const { pagination } = this.state;
      let filters = this.state?.filters;
      this.getResults({ pagination, filters });
    });
  };

  // Check Box Selection
  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedRowKeys, selectedRows });
  };

  componentDidMount() {
    // Get List
    const { pagination } = this.state;
    this.getResults({ pagination });
    this.setColumns();
  }

  render() {
    const {
      data,
      columns,
      pagination,
      loading,
      filterColumns,
      selectedRowKeys,
      isModal,
      action,
      screen,
    } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    return (
      <React.Fragment>
        <Row>
          <Col span={24}>
            <PageTitle titleText="Invoice Date and Number" />

            <ListFilters
              items={filterColumns}
              handleSettings={this.handleSettings}
              handleFilters={this.handleFilters}
              key={filterColumns}
              screen={screen}
            />

            <div
              style={{
                top: "-48px",
                float: "right",
                right: "42px",
                marginBottom: "-48px",
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
              className="title-btns"
            >
              <Switch size="small" onChange={this.handleAllToggle}></Switch>
              <label style={{ marginLeft: "8px", marginRight: "16px" }}>
                All <span className="mobile-hide">Invoices</span>
              </label>

              {(RolesPermissions("su") ||
                RolesPermissions("ad") ||
                RolesPermissions("ac")) && (
                <Button
                  type="primary"
                  disabled={!hasSelected}
                  onClick={() => this.handleModalActions("Set Date", "")}
                  icon={<CalendarOutlined />}
                  className="icon-btn"
                >
                  Set Date
                </Button>
              )}
            </div>

            <Table
              columns={columns}
              rowKey={(record) => record.invoice_id}
              dataSource={data}
              pagination={pagination}
              loading={loading}
              onChange={this.handleTableChange}
              bordered
              scroll={{ x: 400 }}
              size="small"
              rowSelection={rowSelection}
            />
          </Col>
        </Row>

        <Modal
          centered
          title={action}
          visible={isModal}
          onCancel={this.handleModal}
          footer={false}
        >
          <InvoiceAddNumberDate
            key={isModal}
            {...this.state}
            handleAddNumberDate={this.handleAddNumberDate}
          />
        </Modal>
      </React.Fragment>
    );
  }
}

export default InvoiceDateNumber;
