import React, { Component } from "react";
import { Modal, Spin, Button } from "antd";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { getImageRelayUrl } from "../../services/ajax-requester";
import { DownloadOutlined } from "@ant-design/icons";

class PhotoGallery extends Component {
  state = { images: "", imageTitle: "", currentIndex: 0 };

  _onSlide(index) {
    if (this?.props?.photoBucket) {
      this.setState({
        imageTitle: this?.props?.photos[index]?.imageTitle || "",
      });
    }
    this.setState({
      currentIndex: index,
    });
  }

  handleDownload = () => {
    const { images, imageTitle, currentIndex } = this.state;
    let img = images[currentIndex].original || "";
    let imgpath = getImageRelayUrl(img, null, 940, 100, true);
    let imageExt = "." + img.split(".").at(-1);
    const imageUrl = imgpath + "&downloadName=" + imageTitle + imageExt;

    const link = document.createElement("a");
    link.href = imageUrl;
    // link.download = "image.jpg";
    link.click();
    link.remove();
  };

  componentDidMount() {
    const { photos, photosTitle } = this.props;

    let images = [];

    photos &&
      photos.map((x) =>
        images.push({
          original: x.data_url,
          thumbnail: x.thumb_url,
          title: x.data_url.split("/").pop(),
        })
      );

    let imageTitle =
      (photosTitle?.inventory_code ? photosTitle?.inventory_code : "") +
        (photosTitle?.city ? " - " + photosTitle?.city : "") +
        (photosTitle?.location ? ", " + photosTitle?.location : "") +
        (photosTitle?.size ? " - " + photosTitle?.size : "") +
        (photosTitle?.light_type ? " - " + photosTitle?.light_type : "") || "";

    if (this?.props?.photoBucket) {
      imageTitle =
        this?.props?.photos[this?.props?.startIndex || 0]?.imageTitle;
    }

    this.setState({ images, imageTitle });
  }

  render() {
    const { imageTitle, images, loaded } = this.state;

    return (
      <Modal
        centered
        title={imageTitle}
        visible={true}
        onCancel={this.props.handlePhotos}
        footer={null}
        width={980}
      >
        <div
          style={{ minHeight: "480px" }}
          className="photo-gallery-min-height"
        >
          {images && loaded && (
            <ImageGallery
              items={images.map((x) => ({
                ...x,
                original: getImageRelayUrl(
                  x.original,
                  null,
                  940,
                  50,
                  true,
                  x.original.split("/").pop()
                ),
              }))}
              onSlide={this._onSlide.bind(this)}
              lazyLoad={true}
              showThumbnails={false}
              startIndex={this?.props?.startIndex || 0}
            />
          )}

          {images && !loaded && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "200px",
              }}
            >
              <Spin />
            </div>
          )}

          {images && images.length > 0 && (
            <img
              src={images[0].original}
              onLoad={() => this.setState({ loaded: true })}
              style={{ display: "none" }}
            />
          )}

          <Button
            type="primary"
            className="photo-download-btn"
            onClick={() => this.handleDownload()}
          >
            <DownloadOutlined />
          </Button>
        </div>
      </Modal>
    );
  }
}

export default PhotoGallery;
