import React from "react";
import { Form, Input, Button, Space, Select } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const CustomerAddPocs = (props) => {
  const { Option } = Select;
  const { designationList } = props;

  let isDisabled =
    props?.results?.point_of_contact.map((x) =>
      x?.email === "***" || x?.phone_no === "***" ? true : false
    ) || false;

  const getEmailRule = (isAllow) => {
    return !isAllow
      ? [
          {
            type: "email",
            message: "POC Email is not a valid email!",
          },
        ]
      : [];
  };

  return (
    <Form.List name="point_of_contact">
      {(fields, { add, remove }) => {
        return (
          <div>
            {fields.map((field) => (
              <Space key={field.key}>
                <Form.Item
                  {...field}
                  name={[field.name, "contact_name"]}
                  fieldKey={[field.fieldKey, "contact_name"]}
                  label="POC Name"
                  disabled
                >
                  <Input placeholder="POC Name" />
                </Form.Item>

                <Form.Item
                  {...field}
                  name={[field.name, "phone_no"]}
                  fieldKey={[field.fieldKey, "phone_no"]}
                  label="POC Number"
                >
                  <Input
                    placeholder="POC Number"
                    disabled={isDisabled && isDisabled[field.key]}
                  />
                </Form.Item>

                <Form.Item
                  {...field}
                  name={[field.name, "email"]}
                  fieldKey={[field.fieldKey, "email"]}
                  label="POC Email"
                  rules={getEmailRule(isDisabled && isDisabled[field.key])}
                >
                  <Input
                    placeholder="POC Email"
                    disabled={isDisabled && isDisabled[field.key]}
                  />
                </Form.Item>

                <Form.Item
                  {...field}
                  name={[field.name, "designation_id"]}
                  fieldKey={[field.fieldKey, "designation_id"]}
                  label="POC Designation"
                >
                  {designationList && (
                    <Select placeholder="Select Designation">
                      {designationList.map((x) => (
                        <Option key={x.designation_id} value={x.designation_id}>
                          {x.designation}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>

                <Form.Item
                  {...field}
                  name={[field.name, "customer_id"]}
                  fieldKey={[field.fieldKey, "customer_id"]}
                  label="Customer Id"
                  hidden="true"
                >
                  <Input placeholder="Customer Id" />
                </Form.Item>

                <Form.Item
                  {...field}
                  name={[field.name, "customer_contact_id"]}
                  fieldKey={[field.fieldKey, "customer_contact_id"]}
                  label="Customer Contact Id"
                  hidden="true"
                >
                  <Input placeholder="Customer Contact Id" />
                </Form.Item>

                {fields.length > 1 ? (
                  isDisabled && isDisabled[field.key] ? null : (
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => {
                        remove(field.name);
                      }}
                    />
                  )
                ) : null}
              </Space>
            ))}

            <Form.Item
              {...{ wrapperCol: { offset: 4, span: 10 } }}
              className="fields-list-btn"
            >
              <Button
                type="dashed"
                onClick={() => {
                  add();
                }}
              >
                <PlusOutlined /> Add Another POC
              </Button>
            </Form.Item>
          </div>
        );
      }}
    </Form.List>
  );
};

export default CustomerAddPocs;
