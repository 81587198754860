import React, { Component } from "react";
import { Form, Input, Button, Radio, Tabs, message, Row, Col } from "antd";
import { UserAddOutlined } from "@ant-design/icons";
import CustomerAddPocs from "./customer-add-pocs";
import CustomerAddEmployees from "./customer-add-employees";
import CustomerAddCompany from "./customer-add-company";
import CustomerAddNotifications from "./customer-add-notifications";
import UploadFile from "../common/upload-file";

class CustomerAddForm extends Component {
  state = { isLoading: false, companyType: "single" };
  formRef = React.createRef();

  // Set Data if Edit Mode
  setData = () => {
    const data = this.props.results;
    this.formRef.current.setFieldsValue({
      companyType: data.companies.length > 1 ? "multi" : "single",
      customer_name: data.customer_name,
      companies: data.companies,
      point_of_contact:
        data.point_of_contact.length > 0 ? data.point_of_contact : [{}],
      assigned_employee:
        data.assigned_employee.length > 0 ? data.assigned_employee : [{}],
    });
  };

  // On Form Submited Succesfull Create Request
  onFinish = (values) => {
    this.props.handleAddCustomers(values);
    this.setState({ isLoading: true });
  };

  // On Form Submited Failed Show Errors
  onFinishFailed = () => {
    this.warning();
  };

  // Show Success Massage
  success = () => {
    message.success("Customer Updated successfully.");
    this.props.handleBack();
  };

  // Show Warning Massage
  warning = () => {
    message.warning("Please enter required fields.");
  };

  componentDidMount() {
    this.props.mode === "edit" && this.setData();
    const { isSuccess } = this.props;
    isSuccess && this.success();
  }

  render() {
    const { TabPane } = Tabs;
    const { isLoading, companyType } = this.state;
    const { mode } = this.props;

    const layout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 10 },
    };

    const layout2Col = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };

    const tailLayout = {
      wrapperCol: { offset: 4, span: 10 },
    };

    return (
      <React.Fragment>
        <Form
          name="customerAddForm"
          {...layout}
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
          ref={this.formRef}
          initialValues={{
            companyType: companyType,
            companies: [{ gst_detail: [{ addresses: [""] }] }],
            assigned_employee: [""],
            point_of_contact: [""],
            email_notifications: [""],
          }}
        >
          <Row>
            <Col span="12" xs={24} xl={12}>
              <Form.Item
                {...layout2Col}
                label="Company Name"
                name="customer_name"
                rules={[
                  { required: true, message: "Please input Company Name!" },
                ]}
              >
                <Input placeholder="Company Name" />
              </Form.Item>
            </Col>

            <Col span="9" xs={24} xl={9}>
              <Form.Item {...layout2Col} label="Photo / Logo" name="image_name">
                <UploadFile
                  id={this.props?.results?.customer_id}
                  uploadPath={"customer/photo/upload"}
                  deletePath={"customer/photo/delete"}
                  photoPath={this.props?.results?.photo?.data_url}
                  mode={mode}
                  handleUploadLogoReq={this.props.handleUploadLogoReq}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            name="companyType"
            {...tailLayout}
            className="company-type"
          >
            <Radio.Group
              onChange={(e) => this.setState({ companyType: e.target.value })}
            >
              <Radio value="single">Single Company</Radio>
              <Radio value="multi">Multi Company</Radio>
            </Radio.Group>
          </Form.Item>

          <Tabs
            defaultActiveKey="1"
            className="customer-tabs customer-tabs-new"
          >
            <TabPane tab="Company Details" key="1" forceRender="true">
              <CustomerAddCompany
                {...this.props}
                key={companyType}
                companyType={companyType}
              />
            </TabPane>

            <TabPane tab="POC Details" key="2" forceRender="true">
              <CustomerAddPocs {...this.props} />
            </TabPane>

            <TabPane tab="Assigned Employee" key="3" forceRender="true">
              <CustomerAddEmployees {...this.props} />
            </TabPane>

            <TabPane
              tab="Email Notifications"
              key="4"
              forceRender="true"
              disabled
            >
              <CustomerAddNotifications {...this.props} />
            </TabPane>
          </Tabs>

          <Form.Item
            className="ant-text-center"
            style={{ marginTop: "24px" }}
            wrapperCol={{ span: 24 }}
          >
            <Button
              type="primary"
              size={"large"}
              icon={<UserAddOutlined />}
              htmlType="submit"
              loading={isLoading ? true : false}
            >
              {mode === "edit" ? "Update" : "Add"} Customer
            </Button>
          </Form.Item>
        </Form>
      </React.Fragment>
    );
  }
}

export default CustomerAddForm;
