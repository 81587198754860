import { useState, useEffect } from "react";
import { Avatar, Select, Spin } from "antd";
import { UserOutlined } from "@ant-design/icons";
import styled from "styled-components";
import AdminApi from "../api/admin-api";

const SmallAvatar = styled(Avatar)`
  margin-right: 10px;
  margin-top: -4px;
`;

const { Option } = Select;

const UserPickerDropDown = (props) => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState();

  useEffect(() => {
    queryUsers();
  }, []);

  const onFocus = () => {
    queryUsers("");
  };

  const onClear = () => {
    setSelectedUser("");
    props.onClear?.();
  };

  const onSelect = (item) => {
    setSelectedUser(item);
    props.onSelect?.(users.filter((x) => x.id === item)[0]);
  };

  const queryUsers = (q) => {
    setLoading(true);
    AdminApi.queryUsers(q)
      .then((x) => {
        x?.result && setUsers(x.result.filter(x => x.isActive));
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  return (
    <Select
      {...props}
      placeholder={"Select user"}
      onSearch={queryUsers}
      onFocus={onFocus}
      onClear={onClear}
      onSelect={onSelect}
      showSearch={true}
      allowClear={true}
      notFoundContent={loading ? <Spin size="small" /> : null}
      filterOption={false}
      style={{
        minWidth: "160px",
      }}
      value={selectedUser ?? props.user}
    >
      {users.map((item, index) => (
        <Option key={index} value={item.id}>
          <SmallAvatar size={20} icon={<UserOutlined />} />
          {item.firstName
            ? `${item.firstName} ${item.lastName}`
            : item.userName}
        </Option>
      ))}
    </Select>
  );
};

export default UserPickerDropDown;
