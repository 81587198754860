import React, { Component } from "react";
import { Button, Collapse, Select, Form, Checkbox } from "antd";
import { ajaxRequester } from "../../services/ajax-requester";
import { ajaxRequesterPublic } from "../../services/ajax-requester-public";
import moment from "moment";
import { exportCampaignToXlsx } from "../../screens/campaign/campaign-to-xlsx";
import { exportCampaignToPPTx } from "../../screens/campaign/campaign-to-ppt";
import { exportCampaignToZip } from "../../screens/campaign/campaign-to-zip";

class ExportReport extends Component {
  state = {
    mountingList: [],
    monitoringList: [],
    unmountingList: [],
    mountingDropdownList: ["All"],
    monitoringDropdownList: ["All"],
    unmountingDropdownList: ["All"],
    isDownloading: false,
    isMountingCheck: false,
    isMonitoringCheck: false,
    isUnmountingCheck: false,
  };

  formRef = React.createRef();

  // Get Mounting List
  getMountingList = () => {
    const { campaign_id } = this.props.results;

    var reqUrl =
      "campaign/mounting/list?campaign_id=" +
      campaign_id +
      "&pageno=1&pagesize=9999";
    var reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        let mountingList =
          data?.response.filter(
            (x) => x.operation_status !== "Pending Mounting"
          ) || [];
        this.setState({
          mountingList,
        });
      },
      "GET"
    );
  };

  // Get Monitoring List
  getMonitoringList = () => {
    const { campaign_id } = this.props.results;

    var reqUrl =
      "campaign/monitoring/list?campaign_id=" +
      campaign_id +
      "&pageno=1&pagesize=9999";
    var reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({
          monitoringList: data.response || [],
        });
      },
      "GET"
    );
  };

  // Get Unmounting List
  getUnmountingList = () => {
    const { campaign_id } = this.props.results;

    var reqUrl =
      "campaign/unmounting/list?campaign_id=" +
      campaign_id +
      "&pageno=1&pagesize=9999";
    var reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({
          unmountingList: data.response || [],
        });
      },
      "GET"
    );
  };

  // Get Mounting List Public
  getMountingListPublic = () => {
    const { campaign_id } = this.props.results;
    const { publicId } = this?.props;

    var reqUrl =
      "campaign/mounting/list/publish?campaignId=" +
      publicId +
      "&pageno=1&pagesize=9999";
    var reqObj = "";

    ajaxRequesterPublic(
      reqUrl,
      reqObj,
      (data) => {
        let mountingList =
          data?.response.filter(
            (x) => x.operation_status !== "Pending Mounting"
          ) || [];
        this.setState({
          mountingList,
        });
      },
      "GET"
    );
  };

  // Get Monitoring List Public
  getMonitoringListPublic = () => {
    const { campaign_id } = this.props.results;
    const { publicId } = this?.props;

    var reqUrl =
      "campaign/monitoring/list/publish?campaignId=" +
      publicId +
      "&pageno=1&pagesize=9999";
    var reqObj = "";

    ajaxRequesterPublic(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({
          monitoringList: data.response || [],
        });
      },
      "GET"
    );
  };

  // Get Unmounting List Public
  getUnmountingListPublic = () => {
    const { campaign_id } = this.props.results;
    const { publicId } = this?.props;

    var reqUrl =
      "campaign/unmounting/list/publish?campaignId=" +
      publicId +
      "&pageno=1&pagesize=9999";
    var reqObj = "";

    ajaxRequesterPublic(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({
          unmountingList: data.response || [],
        });
      },
      "GET"
    );
  };

  handleDownload = (report) => {
    let { mountingList, monitoringList, unmountingList } = this.state;

    let { campaign_inventory_details } = this.props.results;
    campaign_inventory_details = campaign_inventory_details.map((x, key) => ({
      ...x,
      sr: key + 1,
    }));

    mountingList = mountingList.map((x) => ({
      ...x,
      sr: campaign_inventory_details.find(
        (y) => y.campaign_inventory_id === x.campaign_inventory_id
      ).sr,
    }));

    monitoringList = monitoringList.map((x) => ({
      ...x,
      sr: campaign_inventory_details.find(
        (y) => y.campaign_inventory_id === x.campaign_inventory_id
      ).sr,
    }));

    unmountingList = unmountingList.map((x) => ({
      ...x,
      sr: campaign_inventory_details.find(
        (y) => y.campaign_inventory_id === x.campaign_inventory_id
      ).sr,
    }));

    mountingList.sort((a, b) => parseFloat(a.sr) - parseFloat(b.sr));
    monitoringList.sort((a, b) => parseFloat(a.sr) - parseFloat(b.sr));
    unmountingList.sort((a, b) => parseFloat(a.sr) - parseFloat(b.sr));

    let inventory_details = [];
    let exportColumns = [
      "Sr",
      "Media Type",
      "IID",
      "State",
      "District",
      "City",
      "Area",
      "Location",
      "W",
      "H",
      "SQFT",
      "Light",
      "Qty",
      "Display Qty",
    ];

    let reportName = "";
    let reportType = "";
    let checkType = [];

    if (report === "campaign") {
      reportName = "Campaign Report";
      inventory_details = campaign_inventory_details || [];
      exportColumns.push("Start Date", "End Date", "Date of Installation");
      reportType = "Spreadsheet";
    }

    if (report === "mounting") {
      reportName = "Mounting Report";
      exportColumns.push("Mounting Date", "End Date", "Creative Name");
      reportType = this.formRef.current.getFieldValue("mounting_report_type");
      checkType = this.formRef.current.getFieldValue("mounting_checkType");
      let creativeName =
        this.formRef.current.getFieldValue("mounting_dropdown");

      if (creativeName === "All") {
        inventory_details = mountingList;
      } else {
        inventory_details = mountingList.filter(
          (x) => x?.creative_name === creativeName
        );
      }
    }

    if (report === "monitoring") {
      reportName = "Monitoring Report";
      exportColumns.push("Monitoring Name", "Monitoring Performed On");
      reportType = this.formRef.current.getFieldValue("monitoring_report_type");
      checkType = this.formRef.current.getFieldValue("monitoring_checkType");
      let monitoringName = this.formRef.current.getFieldValue(
        "monitoring_dropdown"
      );

      if (monitoringName === "All") {
        inventory_details = monitoringList;
      } else {
        inventory_details = monitoringList.filter(
          (x) => x?.monitoring_name === monitoringName
        );
      }
    }

    if (report === "unmounting") {
      reportName = "Unmounting Report";
      exportColumns.push(
        "Start Date",
        "End Date",
        "Unmounting Photo Date",
        "Creative Name"
      );
      reportType = this.formRef.current.getFieldValue("unmounting_report_type");
      checkType = this.formRef.current.getFieldValue("unmounting_checkType");
      let creativeName = this.formRef.current.getFieldValue(
        "unmounting_dropdown"
      );

      if (creativeName === "All") {
        inventory_details = unmountingList;
      } else {
        inventory_details = unmountingList.filter(
          (x) => x?.creative_name === creativeName
        );
      }
    }

    if (reportType === "Spreadsheet") {
      exportCampaignToXlsx(
        reportName,
        inventory_details,
        {
          exportName: `${
            this.props.results.display_name
          } - ${reportName} - ${moment().format("Do MMMM YYYY")}.xlsx`,
        },
        exportColumns
      );
    }

    if (reportType === "PhotoAlbum") {
      this.setState({ isDownloading: true });

      let photoType = [];

      if (checkType && checkType?.includes("Newspaper")) {
        photoType.push("N");
      }
      if (checkType && checkType?.includes("Long Shot")) {
        photoType.push("L");
      }
      if (checkType && checkType?.includes("Short Shot")) {
        photoType.push("S");
      }

      exportCampaignToPPTx(
        reportName,
        inventory_details,
        {
          display_name: this.props?.results?.display_name,
          employee_id: this.props?.results?.employee_id,
          no_of_days: this.props?.results?.no_of_days,
        },
        {
          ...this.props.configurationDetails,
          exportName: `${
            this.props.results.display_name
          } - ${reportName}- ${moment().format("Do MMMM YYYY")}.pptx`,
        },
        ["Rates", "Available From"],
        photoType,
        () => {
          this.setState({ isDownloading: false });
        }
      );
    }

    if (reportType === "Photographs") {
      this.setState({ isDownloading: true });
      exportCampaignToZip(
        reportName,
        inventory_details,
        {
          exportName: `${
            this.props.results.display_name
          } - ${reportName}- ${moment().format("Do MMMM YYYY")}.zip`,
        },
        () => {
          this.setState({ isDownloading: false });
        }
      );
    }
  };

  ValuesChange = (value, values) => {
    if (
      this.formRef.current.getFieldValue("mounting_report_type") ===
      "PhotoAlbum"
    ) {
      this.setState({ isMountingCheck: true });
    } else {
      this.setState({ isMountingCheck: false });
    }

    if (
      this.formRef.current.getFieldValue("monitoring_report_type") ===
      "PhotoAlbum"
    ) {
      this.setState({ isMonitoringCheck: true });
    } else {
      this.setState({ isMonitoringCheck: false });
    }

    if (
      this.formRef.current.getFieldValue("unmounting_report_type") ===
      "PhotoAlbum"
    ) {
      this.setState({ isUnmountingCheck: true });
    } else {
      this.setState({ isUnmountingCheck: false });
    }
  };

  componentDidMount() {
    const { publicId } = this?.props;
    if (!publicId) {
      this.getMountingList();
      this.getMonitoringList();
      this.getUnmountingList();
    } else {
      this.getMountingListPublic();
      this.getMonitoringListPublic();
      this.getUnmountingListPublic();
    }
  }

  render() {
    const { Panel } = Collapse;
    const { Option } = Select;

    const layout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 12 },
    };

    const {
      mountingList,
      monitoringList,
      unmountingList,
      isDownloading,
      isMountingCheck,
      isMonitoringCheck,
      isUnmountingCheck,
    } = this.state;

    let mountingDropdownList = ["All"];
    let unmountingDropdownList = ["All"];
    let monitoringDropdownList = ["All"];

    mountingList.length > 0 &&
      mountingList.map(
        (x) => x?.creative_name && mountingDropdownList.push(x?.creative_name)
      );

    monitoringList.length > 0 &&
      monitoringList.map(
        (x) =>
          x?.monitoring_name && monitoringDropdownList.push(x?.monitoring_name)
      );

    unmountingList.length > 0 &&
      unmountingList.map(
        (x) => x?.creative_name && unmountingDropdownList.push(x?.creative_name)
      );

    mountingDropdownList = [...new Set(mountingDropdownList)];
    monitoringDropdownList = [...new Set(monitoringDropdownList)];
    unmountingDropdownList = [...new Set(unmountingDropdownList)];

    return (
      <div>
        <Form
          name="campaign_report"
          {...layout}
          onFinish={this.onFinish}
          ref={this.formRef}
          className="campaign_report"
          onValuesChange={this.ValuesChange}
          initialValues={{
            mounting_dropdown: "All",
            mounting_report_type: "Spreadsheet",
            monitoring_dropdown: "All",
            monitoring_report_type: "Spreadsheet",
            unmounting_dropdown: "All",
            unmounting_report_type: "Spreadsheet",
            mounting_checkType: ["Newspaper"],
            monitoring_checkType: ["Newspaper"],
            unmounting_checkType: ["Newspaper"],
          }}
        >
          <Collapse defaultActiveKey={["1"]} accordion>
            <Panel header={"Campaign Report"} key="1">
              <Button
                type="primary"
                onClick={() => this.handleDownload("campaign")}
              >
                Download Campaign Tenure
              </Button>
            </Panel>

            <Panel header={"Installation Report"} key="2">
              <Form.Item name="mounting_dropdown" label="Select Creative">
                <Select placeholder="Select Creative">
                  {mountingDropdownList.map((x) => (
                    <Option key={x} value={x}>
                      {x}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item name="mounting_report_type" label="Report Type">
                <Select placeholder="Report Type">
                  <Option value={"Spreadsheet"}>Spreadsheet</Option>
                  <Option value={"PhotoAlbum"}>PhotoAlbum</Option>
                  <Option value={"Photographs"}>Photographs</Option>
                </Select>
              </Form.Item>

              {isMountingCheck && (
                <Form.Item name="mounting_checkType" label="Select Photo">
                  <Checkbox.Group
                    options={["Newspaper", "Long Shot", "Short Shot"]}
                    className="export-checkbox"
                  />
                </Form.Item>
              )}

              <Form.Item
                wrapperCol={{ offset: 6 }}
                style={{ marginBottom: "0px" }}
              >
                <Button
                  onClick={() => this.handleDownload("mounting")}
                  type="primary"
                  disabled={mountingList.length > 0 ? false : true}
                  loading={isDownloading}
                >
                  Download
                </Button>
              </Form.Item>
            </Panel>

            <Panel header={"Monitoring Report"} key="3">
              <Form.Item name="monitoring_dropdown" label="Select Monitoring">
                <Select placeholder="Select Monitoring">
                  {monitoringDropdownList.map((x) => (
                    <Option key={x} value={x}>
                      {x}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item name="monitoring_report_type" label="Report Type">
                <Select placeholder="Report Type">
                  <Option value={"Spreadsheet"}>Spreadsheet</Option>
                  <Option value={"PhotoAlbum"}>PhotoAlbum</Option>
                  <Option value={"Photographs"}>Photographs</Option>
                </Select>
              </Form.Item>

              {isMonitoringCheck && (
                <Form.Item name="monitoring_checkType" label="Select Photo">
                  <Checkbox.Group
                    options={["Newspaper", "Long Shot", "Short Shot"]}
                    className="export-checkbox"
                  />
                </Form.Item>
              )}

              <Form.Item
                wrapperCol={{ offset: 6 }}
                style={{ marginBottom: "0px" }}
              >
                <Button
                  onClick={() => this.handleDownload("monitoring")}
                  type="primary"
                  disabled={monitoringList.length > 0 ? false : true}
                  loading={isDownloading}
                >
                  Download
                </Button>
              </Form.Item>
            </Panel>

            <Panel header={"Un-Installation Report"} key="4">
              <Form.Item name="unmounting_dropdown" label="Select Creative">
                <Select placeholder="Select Creative">
                  {unmountingDropdownList.map((x) => (
                    <Option key={x} value={x}>
                      {x}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item name="unmounting_report_type" label="Report Type">
                <Select placeholder="Report Type">
                  <Option value={"Spreadsheet"}>Spreadsheet</Option>
                  <Option value={"PhotoAlbum"}>PhotoAlbum</Option>
                  <Option value={"Photographs"}>Photographs</Option>
                </Select>
              </Form.Item>

              {isUnmountingCheck && (
                <Form.Item name="unmounting_checkType" label="Select Photo">
                  <Checkbox.Group
                    options={["Newspaper", "Long Shot", "Short Shot"]}
                    className="export-checkbox"
                  />
                </Form.Item>
              )}

              <Form.Item
                wrapperCol={{ offset: 6 }}
                style={{ marginBottom: "0px" }}
              >
                <Button
                  onClick={() => this.handleDownload("unmounting")}
                  type="primary"
                  disabled={unmountingList.length > 0 ? false : true}
                  loading={isDownloading}
                >
                  Download
                </Button>
              </Form.Item>
            </Panel>
          </Collapse>
        </Form>
      </div>
    );
  }
}

export default ExportReport;
