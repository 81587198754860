import React, { Component } from "react";
import { ajaxRequester } from "../../services/ajax-requester";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Select,
  message,
  Drawer,
  Table,
  Popconfirm,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import PageTitleDetails from "../../components/common/page-title-detail";
import AssignTerritory from "../../components/operations/assign-territory";
import UserRolePickerDropDown from "../../iam/components/user-role-picker-dropdown";

class BranchAdd extends Component {
  state = {
    isDrawer: false,
    branch_territories: [],
    details: "",
  };

  formRef = React.createRef();

  // Adding Branch
  onFinish = (values) => {
    var reqUrl = "branch/" + (this.state.mode === "edit" ? "update" : "create");

    var reqObj = {
      request: {
        branch_id:
          this.props?.match?.params?.id &&
          Number(this.props?.match?.params?.id),
        branch_name: values.branch_name,
        is_active: values.is_active === "Active" ? true : false,
        branch_territories: this.getSelectedRecords(),
        branch_managers: this.getSelectedDropDown(values, "branch_managers"),
        branch_mounters: this.getSelectedDropDown(values, "branch_mounters"),
        branch_photographers: this.getSelectedDropDown(
          values,
          "branch_photographers"
        ),
      },
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      if (data.success) {
        message.success(
          "Branch " +
            (this.state.mode === "edit" ? "updated" : "added") +
            " successfully"
        );
        this.props.history.push("/inventories/branch-management");
      } else {
        message.error(data?.detail);
      }
    });
  };

  // Redirect to Back Page
  handleBack = () => {
    this.props.history.goBack();
  };

  getDetails = () => {
    const branchId = this.props.match.params.id;
    var reqUrl = "branch/detail?branchId=" + Number(branchId);
    var reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ details: data?.response }, () => this.setData());
      },
      "GET"
    );
  };

  // Set Data in case of Edit Mode
  setData = () => {
    const { details } = this.state;

    this.formRef.current.setFieldsValue({
      branch_name: details.branch_name,
      is_active: details.is_active ? "Active" : "Inactive",
      branch_managers: details.branch_managers.map((x) => x.employee_id),
      branch_mounters: details.branch_mounters.map((x) => x.employee_id),
      branch_photographers: details.branch_photographers.map(
        (x) => x.employee_id
      ),
    });

    let branch_territories = details.branch_territories;
    this.setState({ branch_territories });
  };

  // Handle Drawer
  handleDrawer = () => {
    this.setState({
      isDrawer: !this.state.isDrawer,
    });
  };

  handleAssignTerritory = (selected_territories) => {
    this.handleDrawer();
    let branch_territories = [...this.state.branch_territories];
    branch_territories = branch_territories.concat(selected_territories);
    this.setState({ branch_territories });
  };

  getSelectedRecords = () => {
    let branch_territories = [];
    this.state.branch_territories.map((x) =>
      branch_territories.push({
        state_id: x.state_id,
        district_id: x.district_id,
        city_id: x.city_id,
        area_id: x.area_id,
        branch_territory_id: x.branch_territory_id,
      })
    );
    return branch_territories;
  };

  deleteTerritory = (record) => {
    let branch_territories = [...this.state.branch_territories];
    branch_territories = branch_territories.filter(
      (x) => x.area_id !== record.area_id
    );

    this.setState({ branch_territories });
  };

  getSelectedDropDown = (values, role) => {
    let selectedValues = values[role].map((x) => ({
      employee_id: x,
      is_active: true,
    }));

    return selectedValues;
  };

  // Setting Columns
  setColumns = () => {
    let columns = [
      {
        title: "State",
        dataIndex: "state_name",
      },
      {
        title: "District",
        dataIndex: "district_name",
      },
      {
        title: "City",
        dataIndex: "city_name",
      },
      {
        title: "Area",
        dataIndex: "area_name",
      },
      {
        title: "Actions",
        dataIndex: "",
        key: "x",
        render: (record) => (
          <Popconfirm
            title="Are you sure delete?"
            placement="left"
            okText="Yes"
            cancelText="No"
            onConfirm={() => this.deleteTerritory(record)}
          >
            <Button
              icon={<DeleteOutlined />}
              className="ant-btn-reset"
              style={{ marginLeft: "16px" }}
            />
          </Popconfirm>
        ),
        width: 92,
      },
    ];

    const filterColumns = [
      { columnName: "state_name", displayName: "State", default: true },
      { columnName: "district_name", displayName: "District" },
      { columnName: "city_name", displayName: "City" },
      { columnName: "area_name", displayName: "Area" },
    ];

    this.setState({ columns, columnsDefault: columns, filterColumns });
  };

  componentDidMount() {
    let mode = this.props?.match?.params?.id && "edit";
    this.setState({ mode });
    mode === "edit" && this.getDetails();
    this.setColumns();
  }

  render() {
    const { mode, isDrawer, branch_territories, columns } = this.state;

    const { Option } = Select;

    const hasSelected = branch_territories?.length > 0;

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };

    let existing_branch_managers =
      mode === "edit"
        ? this.state?.details?.branch_managers &&
          this.state?.details?.branch_managers[0]["employee_id"]
        : null;

    let existing_branch_mounters =
      mode === "edit"
        ? this.state?.details?.branch_mounters &&
          this.state?.details?.branch_mounters[0]["employee_id"]
        : null;

    let existing_branch_photographers =
      mode === "edit"
        ? this.state?.details?.branch_photographers &&
          this.state?.details?.branch_photographers[0]["employee_id"]
        : null;

    return (
      <React.Fragment>
        <Row>
          <Col span={24}>
            <PageTitleDetails
              titleText={mode !== "edit" ? "Add New Branch" : "Edit Branch"}
              isBackBtn="true"
              handleBack={this.handleBack}
            />

            <div className="page-title-custom-btn">
              <Button
                type="primary"
                htmlType="submit"
                disabled={!hasSelected}
                onClick={() => this.formRef.current.submit()}
              >
                {mode !== "edit" ? "Save Branch" : "Update Branch"}
              </Button>
            </div>

            <div
              className="ant-border-b"
              style={{
                paddingTop: "8px",
                marginBottom: "20px",
              }}
            >
              <Form
                {...layout}
                name="add-new-branch"
                onFinish={this.onFinish}
                ref={this.formRef}
                className="add_new_branch"
              >
                <Row gutter="16">
                  <Col span={8} xs={24} xl={8}>
                    <Form.Item
                      name="branch_name"
                      label="Branch Name"
                      rules={[
                        {
                          required: true,
                          message: "Please Input Branch Name !",
                        },
                      ]}
                    >
                      <Input placeholder="Branch Name" />
                    </Form.Item>
                  </Col>

                  <Col span={8} xs={24} xl={8}>
                    <Form.Item
                      name="is_active"
                      label="Status"
                      rules={[
                        {
                          required: true,
                          message: "Please Select Status !",
                        },
                      ]}
                    >
                      <Select placeholder="Status">
                        <Option value="Active">Active</Option>
                        <Option value="Inactive">Inactive</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter="16">
                  <Col span={8} xs={24} xl={8}>
                    <Form.Item
                      name="branch_managers"
                      label="Branch Manager"
                      rules={[
                        {
                          required: true,
                          message: "Please Select Branch Manager !",
                        },
                      ]}
                    >
                      <UserRolePickerDropDown
                        user={existing_branch_managers}
                        role="bm"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={8} xs={24} xl={8}>
                    <Form.Item
                      name="branch_mounters"
                      label="Mounter"
                      rules={[
                        {
                          required: true,
                          message: "Please Select Mounter !",
                        },
                      ]}
                    >
                      <UserRolePickerDropDown
                        user={existing_branch_mounters}
                        role="mo"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={8} xs={24} xl={8}>
                    <Form.Item
                      name="branch_photographers"
                      label="Photographer"
                      rules={[
                        {
                          required: true,
                          message: "Please Select Photographer !",
                        },
                      ]}
                    >
                      <UserRolePickerDropDown
                        user={existing_branch_photographers}
                        role="ph"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>

            {hasSelected && (
              <Table
                columns={columns}
                rowKey={(record) => record.id}
                dataSource={branch_territories}
                pagination={false}
                bordered
                scroll={{ x: 400 }}
                size="small"
                style={{ marginBottom: "16px" }}
              />
            )}

            <Button type="primary" onClick={() => this.handleDrawer()}>
              Assign Territory
            </Button>
          </Col>
        </Row>

        <Drawer
          title="Assign Territory"
          placement="right"
          visible={isDrawer}
          onClose={this.handleDrawer}
          width="80%"
          key={isDrawer}
        >
          <AssignTerritory
            {...this.state}
            handleAssignTerritory={this.handleAssignTerritory}
            key={isDrawer}
          />
        </Drawer>
      </React.Fragment>
    );
  }
}

export default BranchAdd;
