import React from "react";
import PageTitleDetails from "../../components/common/page-title-detail";
import {
  Form,
  Select,
  Button,
  Input,
  DatePicker,
  InputNumber,
  Row,
  Col,
  Drawer,
  Table,
} from "antd";
import { FileAddOutlined, PlusOutlined } from "@ant-design/icons";
import SelectCustomerWiseInvoices from "../../components/sales-estimate/select-customer-wise-invoice";
import SalesEstimateBase from "../../base/sales-estimate-base";

class JournalVoucherAddEdit extends SalesEstimateBase {
  state = {
    page: "journalvoucher",
    loading: false,
    customer: "",
    customersList: [],
    companyList: [],
    gstList: [],
    addressList: [],
    mode: this.props.match.path.includes("add") ? "Add" : "Edit",
    results: "",
    isDrawer: false,
    selectedInvoices: [],
  };

  formRef = React.createRef();

  componentDidMount() {
    this.getCustomers();
    this.setColumns();
    this.state.mode === "Edit" && this.getDetails();
  }

  render() {
    const {
      mode,
      loading,
      customer,
      customersList,
      companyList,
      gstList,
      addressList,
      isDrawer,
      selectedInvoices,
      columns,
      results,
    } = this.state;

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };

    const layout2 = {
      labelCol: { span: 3 },
      wrapperCol: { span: 21 },
    };

    const { Option } = Select;

    const creditNoteTypeList = [
      { label: "General", value: "G" },
      { label: "CD", value: "C" },
      { label: "VD", value: "V" },
    ];

    const gstTypeList = [
      { label: "IGST", value: "I" },
      { label: "CGST SGST", value: "C" },
    ];

    return (
      <React.Fragment>
        <Row>
          <Col span={24}>
            <PageTitleDetails
              titleText={mode + " Journal Voucher"}
              isBackBtn="true"
              handleBack={this.handleBack}
            />

            <Form
              name="add_edit_creditnote"
              {...layout}
              onFinish={this.onFinish}
              className="add_edit_creditnote"
              onValuesChange={this.ValuesChange}
              ref={this.formRef}
            >
              <Row>
                <Col span={8}>
                  <Form.Item
                    label="Customer"
                    name="customer_id"
                    rules={[{ required: true, message: "Select Customer" }]}
                  >
                    <Select placeholder="Select Customer" allowClear={true}>
                      {customersList.map((x) => (
                        <Option key={x.customer_id} value={x.customer_id}>
                          {x.customer_name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label="Customer Code" name="customer_code">
                    <Input disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Supplier Invoice"
                    name="purchase_invoice_no"
                    rules={[
                      {
                        required: true,
                        message: "Enter Supplier Invoice Number",
                      },
                    ]}
                  >
                    <Input placeholder="Supplier Invoice Number" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={8}>
                  <Form.Item
                    label="Company Name"
                    name="company_id"
                    rules={[{ required: true, message: "Select Company Name" }]}
                  >
                    <Select placeholder="Select Company Name" allowClear={true}>
                      {companyList.map((x) => (
                        <Option key={x.company_id} value={x.company_id}>
                          {x.company_name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="GSTIN"
                    name="gstin"
                    rules={[{ required: true, message: "Select GSTIN" }]}
                  >
                    <Select placeholder="Select GSTIN" allowClear={true}>
                      {gstList.map((x) => (
                        <Option key={x.gstin} value={x.gstin}>
                          {x.gstin}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Address"
                    name="address"
                    rules={[{ required: true, message: "Select Address" }]}
                  >
                    <Select placeholder="Select Address" allowClear={true}>
                      {addressList.map((x) => (
                        <Option key={x.address} value={x.address}>
                          {x.address}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={16}>
                  <Row>
                    <Col span={12}>
                      <Form.Item name="credit_note_amount" label="JV Amount">
                        <InputNumber
                          min={0}
                          style={{ width: "100%" }}
                          placeholder="JV Amount without tax"
                          disabled
                        />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        name="credit_note_date"
                        label="JV Date"
                        rules={[
                          {
                            required: true,
                            message: "Select JV Date",
                          },
                        ]}
                      >
                        <DatePicker inputReadOnly={true} />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={12}>
                      <Form.Item
                        name="gst_type"
                        label="Apply GST"
                        rules={[
                          {
                            required: true,
                            message: "Select Apply GST",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select Apply GST"
                          allowClear={true}
                        >
                          {gstTypeList.map((x) => (
                            <Option key={x.value} value={x.value}>
                              {x.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item label="JV No" name="journal_voucher_no">
                        <Input placeholder="JV No" />
                      </Form.Item>
                    </Col>

                    {/* <Col span={12}>
                      <Form.Item
                        name="credit_note_type"
                        label="Type"
                        rules={[
                          {
                            required: true,
                            message: "Select Credit Note Type",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select Credit Note Type"
                          allowClear={true}
                        >
                          {creditNoteTypeList.map((x) => (
                            <Option key={x.value} value={x.value}>
                              {x.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col> */}
                  </Row>
                </Col>

                <Col span={8}>
                  <Form.Item name="remarks" label="Remarks">
                    <Input.TextArea
                      placeholder="Remarks"
                      style={{ height: "88px" }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={8}>
                  <Form.Item name="select_invoices" label="Select Invoices">
                    <Button
                      type="primary"
                      onClick={() => this.handleDrawer()}
                      icon={<PlusOutlined />}
                      disabled={!customer && !results}
                    >
                      Add Invoices
                    </Button>
                  </Form.Item>
                </Col>
              </Row>

              {selectedInvoices.length > 0 && (
                <Row>
                  <Col span={24}>
                    <Table
                      columns={columns}
                      rowKey={(record) => record.invoice_id}
                      dataSource={selectedInvoices}
                      pagination={false}
                      scroll={{ x: 400 }}
                      size="small"
                      bordered
                    />
                  </Col>
                </Row>
              )}

              <Form.Item
                className="ant-text-center"
                style={{ marginTop: "24px" }}
                wrapperCol={{ span: 24 }}
              >
                <Button
                  type="primary"
                  size={"large"}
                  icon={<FileAddOutlined />}
                  htmlType="submit"
                  loading={loading}
                  disabled={!selectedInvoices.length > 0}
                >
                  {mode} Journal Voucher
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>

        <Drawer
          title={"Select Invoices"}
          placement="right"
          visible={isDrawer}
          onClose={this.handleDrawer}
          width={"82%"}
          key={isDrawer}
        >
          <SelectCustomerWiseInvoices
            {...this.state}
            handleSelection={this.handleSelection}
          />
        </Drawer>
      </React.Fragment>
    );
  }
}

export default JournalVoucherAddEdit;
