import * as JSZip from "jszip";
import { getRelayUrl } from "../../services/ajax-requester";
import { getImageBlobData } from "../../helpers/image-helper";

export const exportPhotosToZip = (photos, exportName, callBack) => {
  let zip = new JSZip();

  let urls = [];
  let index = 1;

  let downloadPhotos = photos;

  downloadPhotos.forEach((image, imageIndex) => {
    try {
      urls.push({
        url: getRelayUrl(image.data_url),
        name: `${index++}) ${image.name}`,
      });
    } catch (e) {
      // ignore
    }
  });

  let imagePromises = urls.map((x) => getImageBlobData(x.name, x.url));

  Promise.all(imagePromises).then((results) => {
    results.forEach((x) => {
      zip.file(x.name, x.data, { base64: true });
    });
    zip.generateAsync({ type: "blob" }).then((buffer) => {
      let blob = new Blob([buffer], { type: "application/zip" });
      let a = document.createElement("a");
      a.href = window.URL.createObjectURL(blob);
      a.style.display = "none";
      a.download = exportName ?? "photos.zip";
      document.body.appendChild(a);
      a.click();
      callBack(true);
    });
  });
};
