import React from "react";
import { ajaxRequester } from "../../services/ajax-requester";
import PageTitleDetails from "../../components/common/page-title-detail";
import {
  Row,
  Col,
  Spin,
  Descriptions,
  Collapse,
  Typography,
  List,
  Button,
} from "antd";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import CustomersBase from "../../base/customers-base";
import PortalUser from "../../iam/components/portal-user";
import { RolesPermissions } from "../../helpers/roles-permissions";

class CustomerDetails extends CustomersBase {
  state = { results: "", isLoading: true };

  // Get Customer Details
  getCustomerDetails = () => {
    const customerId = this.props.match.params.id;

    var reqUrl = "customer/detail?customerid=" + Number(customerId);
    var reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ results: data.response, isLoading: false });
      },
      "GET"
    );
  };

  // Redirect to customer edit form
  editCustomer = () => {
    const customerId = this.props.match.params.id;
    this.props.history.push(`/customers/customer-edit/${customerId}`);
  };

  // on component load get details based on id from url
  componentDidMount() {
    this.getCustomerDetails();
    this.getLookupData();
  }

  render() {
    const {
      results,
      isLoading,
      designationList,
      segmentList,
      emailnotificationsList,
    } = this.state;

    const { Panel } = Collapse;
    const { Title } = Typography;

    return (
      <Row className="customer-details">
        <Col span={24}>
          {!isLoading && (
            <React.Fragment>
              <PageTitleDetails
                titleText={
                  results.customer_name + " - " + results.customer_code
                }
                isBackBtn="true"
                handleBack={this.handleBack}
              />

              {RolesPermissions("ad") && (
                <div className="page-title-custom-btn">
                  <Button type="primary" onClick={this.editCustomer}>
                    Edit
                  </Button>
                </div>
              )}

              <Collapse defaultActiveKey={["1", "2", "3", "4"]} ghost>
                <Panel
                  header={<Title level={4}>Company Details</Title>}
                  key="1"
                >
                  {results.companies.map((company, key) => (
                    <React.Fragment key={key}>
                      <Row>
                        <Col span={20} xs={24} xl={20}>
                          <Descriptions
                            column={{ xs: 1, xl: 2 }}
                            className={key > 0 && "multi-company"}
                          >
                            <Descriptions.Item label="Company Name">
                              <b>{company.company_name}</b>
                            </Descriptions.Item>

                            <Descriptions.Item label="Address">
                              <b>{company.address}</b>
                            </Descriptions.Item>

                            <Descriptions.Item label="Office Phone">
                              <b>{company.office_phone}</b>
                            </Descriptions.Item>

                            <Descriptions.Item label="Office Email">
                              <b>{company.office_email}</b>
                            </Descriptions.Item>

                            <Descriptions.Item label="Segment">
                              <b>
                                {segmentList &&
                                  segmentList.find(
                                    (x) => x.segment_id === company.segment_id
                                  )?.segment}
                              </b>
                            </Descriptions.Item>

                            <Descriptions.Item label="Credit Days">
                              <b>{company.credit_days}</b>
                            </Descriptions.Item>

                            <Descriptions.Item label="PAN">
                              <b>{company.pan}</b>
                            </Descriptions.Item>

                            <Descriptions.Item label="ACC Number">
                              <b>{company.bank_acct_no}</b>
                            </Descriptions.Item>

                            <Descriptions.Item label="IFSC Code">
                              <b>{company.bank_ifsc_code}</b>
                            </Descriptions.Item>
                          </Descriptions>
                        </Col>

                        <Col span={4} xs={12} xl={4}>
                          {results?.photo?.data_url && (
                            <img
                              src={results.photo.data_url}
                              alt="logo"
                              className="customer-logo"
                            />
                          )}
                        </Col>
                      </Row>

                      {company.gst_detail.length > 0 && (
                        <React.Fragment>
                          <Title level={5} style={{ marginTop: "8px" }}>
                            GST Details :
                          </Title>

                          <List
                            itemLayout="horizontal"
                            dataSource={company.gst_detail}
                            bordered
                            style={{ borderColor: "#f0f0f0" }}
                            renderItem={(item) => (
                              <List.Item>
                                <Row style={{ width: "100%" }}>
                                  <Col span="6" xs={24} xl={6}>
                                    {item.gstin}
                                  </Col>
                                  <Col span="18" xs={24} xl={18}>
                                    <Row>
                                      {item?.addresses?.map((x, key) => (
                                        <Col key={key} span="8" xs={24} xl={8}>
                                          {x.address}
                                        </Col>
                                      ))}
                                    </Row>
                                  </Col>
                                </Row>
                              </List.Item>
                            )}
                          />
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  ))}
                </Panel>

                <Panel
                  header={<Title level={4}>Point of Contact</Title>}
                  key="2"
                >
                  {results.point_of_contact.length > 0 && (
                    <List
                      itemLayout="horizontal"
                      dataSource={results.point_of_contact}
                      bordered
                      style={{ borderColor: "#f0f0f0" }}
                      renderItem={(item) => (
                        <List.Item>
                          <Row style={{ width: "100%" }}>
                            <Col span="6" xs={24} xl={6}>
                              {item.contact_name}
                            </Col>
                            <Col span="6" xs={24} xl={6}>
                              {item.phone_no}
                            </Col>
                            <Col span="6" xs={24} xl={6}>
                              {item.email}
                            </Col>
                            <Col span="6" xs={24} xl={6}>
                              {designationList &&
                                designationList.find(
                                  (x) =>
                                    x.designation_id === item.designation_id
                                )?.designation}
                            </Col>
                          </Row>
                        </List.Item>
                      )}
                    />
                  )}
                </Panel>

                <Panel
                  header={<Title level={4}>Assigned Employee</Title>}
                  key="3"
                >
                  {results.assigned_employee.length > 0 && (
                    <List
                      itemLayout="horizontal"
                      dataSource={results.assigned_employee}
                      bordered
                      style={{ borderColor: "#f0f0f0" }}
                      renderItem={(item) => (
                        <List.Item>
                          <PortalUser user={item.employee_id} />
                        </List.Item>
                      )}
                    />
                  )}
                </Panel>

                {/* <Panel
                  header={<Title level={4}>Email Notifications</Title>}
                  key="4"
                >
                  <List
                    itemLayout="horizontal"
                    dataSource={results.email_notifications}
                    bordered
                    style={{ borderColor: "#f0f0f0" }}
                    renderItem={(item) => (
                      <List.Item>
                        <Row style={{ width: "100%" }}>
                          <Col
                            span="16"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {item.selected ? (
                              <CheckCircleTwoTone
                                style={{
                                  fontSize: "1.2rem",
                                  marginRight: "16px",
                                }}
                                twoToneColor="#52c41a"
                              />
                            ) : (
                              <CloseCircleTwoTone
                                style={{
                                  fontSize: "1.2rem",
                                  marginRight: "16px",
                                }}
                                twoToneColor="#ff4d4f"
                              />
                            )}
                            {emailnotificationsList &&
                              emailnotificationsList.find(
                                (x) => x.notification_code === item.code
                              ).notification}
                          </Col>
                          <Col className="8">
                            {item.selected && item?.value}
                          </Col>
                        </Row>
                      </List.Item>
                    )}
                  />
                </Panel> */}
              </Collapse>
            </React.Fragment>
          )}

          {isLoading && (
            <div className="list-loader">
              <Spin />
            </div>
          )}
        </Col>
      </Row>
    );
  }
}

export default CustomerDetails;
