import React from "react";
import {
  Row,
  Col,
  Table,
  Switch,
  Button,
  Popconfirm,
  Drawer,
  message,
  Form,
  Input,
} from "antd";
import PageTitle from "../../components/common/page-title";
import ListFilters from "../../components/common/list-filters";
import CommonListBase from "../../base/common-list-base";
import { ajaxRequester } from "../../services/ajax-requester";
import {
  EditOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import PortalUser from "../../iam/components/portal-user";
import {
  currencyFormatter,
  dateFormatter,
} from "../../components/common/common-formatter";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import TransactionsTabs from "../../components/purchase/transactions-tabs";
import AdminApi from "../../iam/api/admin-api";

class ScheduledTransactions extends CommonListBase {
  state = {
    listReq:
      "scheduledtransaction/list?showApproved=" +
      !this.props.match.path.includes("approval") +
      "&",
    delReq: "scheduledtransaction/payment/delete?paymentdetailid=",
    dtlReq: "transactions-add/",
    data: [],
    pagination: {
      current: 1,
      pageSize: 20,
    },
    loading: false,
    columns: [],
    columnsDefault: [],
    filterColumns: [],
    sorting: [],
    screen: !this.props.match.path.includes("approval")
      ? "scheduled-transaction"
      : "scheduled-transaction-approval",
    isDrawer: false,
    record: "",
    isEditMode: false,
    estimate_type_list: [],
    navKey: 1,
    activeTab: 0,
  };

  // Setting Columns
  setColumns = () => {
    let isHistory = this.state.listReq.includes("history");
    let isApproved = this.state.screen !== "scheduled-transaction";

    let columns = [
      {
        title: "PEID",
        dataIndex: "peid",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Type",
        dataIndex: "estimate_type",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "District",
        dataIndex: "district_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "City",
        dataIndex: "city_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Authority ID",
        dataIndex: "authority_id",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 120,
      },
      {
        title: "Payout Estimate Name",
        dataIndex: "payout_estimate_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 178,
      },
      {
        title: "Payout From",
        dataIndex: "payout_from_account_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 140,
      },

      {
        title: "PID",
        dataIndex: "pid",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Account Name",
        dataIndex: "payee_account_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 162,
        render: (text, record) =>
          !isHistory ? (
            <Link
              style={{
                padding: "0px",
                whiteSpace: "normal",
                textAlign: "left",
              }}
              type="link"
              to={this.state.dtlReq + record?.pending_estimate_id}
            >
              {text}
            </Link>
          ) : (
            text
          ),
      },
      {
        title: "From",
        dataIndex: "billing_duration_from",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => dateFormatter(text),
      },
      {
        title: "To",
        dataIndex: "billing_duration_to",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => dateFormatter(text),
      },
      {
        title: "Days",
        dataIndex: "billing_duration_days",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Amount",
        dataIndex: !isHistory ? "payout_amount" : "net_amount",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => currencyFormatter(parseInt(text)),
      },

      {
        title: "Payment Approval",
        dataIndex: "payout_approval_by_id",
        render: (text) => <PortalUser user={text} />,
        width: 154,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Task Date",
        dataIndex: "estimate_gen_date",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => dateFormatter(text),
        width: 102,
      },
      {
        title: "Payment Date",
        dataIndex: "payment_date",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => dateFormatter(text),
        width: 124,
      },
      {
        title: "Ageing",
        dataIndex: "aging_days",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Status",
        dataIndex: "is_approved",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) =>
          text ? "Approved" : isHistory ? "Approved" : "Pending",
      },
      {
        title: "Tenure Status",
        dataIndex: "tenure_status",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 128,
      },
    ];

    if (isHistory) {
      columns = columns.filter((x) => x.dataIndex !== "aging_days");
      columns = columns.filter((x) => x.dataIndex !== "tenure_status");
    }

    if (!isHistory) {
      columns = columns.filter((x) => x.dataIndex !== "payment_date");
    }

    if (isApproved) {
      columns = columns.filter((x) => x.dataIndex !== "tenure_status");
      columns = columns.filter((x) => x.dataIndex !== "payment_date");
    }

    if (isHistory && !isApproved) {
      columns.push({
        title: "Actions",
        dataIndex: "",
        key: "x",
        render: (record) => (
          <React.Fragment>
            <Button
              icon={<EditOutlined />}
              className="ant-btn-reset"
              onClick={() => this.handleEdit(record?.pending_estimate_id)}
            />

            <Popconfirm
              title="Are you sure want to delete?"
              placement="left"
              okText="Yes"
              cancelText="No"
              onConfirm={() => this.deleteItem(record?.payment_detail_id)}
            >
              <Button
                icon={<DeleteOutlined />}
                className="ant-btn-reset"
                style={{ marginLeft: "12px" }}
              />
            </Popconfirm>
          </React.Fragment>
        ),
        align: "center",
      });
    }

    if (isApproved && !isHistory) {
      columns.push({
        title: "Actions",
        dataIndex: "",
        key: "x",
        render: (record) => (
          <React.Fragment>
            {!record?.is_approved && (
              <Button
                icon={<CheckCircleOutlined />}
                className="ant-btn-reset"
                onClick={() => this.handleDrawer(record)}
              />
            )}
          </React.Fragment>
        ),
        align: "center",
      });
    }

    const filterColumns = [
      { columnName: "peid", displayName: "PEID" },
      {
        columnName: "estimate_type",
        displayName: "Type",
      },
      { columnName: "district_name", displayName: "District" },
      { columnName: "city_name", displayName: "City" },
      { columnName: "authority_id", displayName: "Authority ID" },
      {
        columnName: "payout_estimate_name",
        displayName: "Payout Estimate Name",
        default: true,
      },
      {
        columnName: "payout_from_account_name",
        displayName: "Payout From",
      },
      {
        columnName: "pid",
        displayName: "PID",
      },
      {
        columnName: "payee_account_name",
        displayName: "Account Name",
      },
      {
        columnName: "billing_duration_from",
        displayName: "From",
        hideFilter: true,
      },
      {
        columnName: "billing_duration_to",
        displayName: "To",
        hideFilter: true,
      },
      {
        columnName: "billing_duration_days",
        displayName: "Days",
        hideFilter: true,
      },
      {
        columnName: "payout_amount",
        displayName: "Amount",
        hideFilter: true,
      },
      {
        columnName: "payout_approval_by_id",
        displayName: "Payment Approval",
      },
      {
        columnName: "estimate_gen_date",
        displayName: "Task Date",
        hideFilter: true,
      },
      {
        columnName: "payment_date",
        displayName: "Payment Date",
        hideFilter: true,
      },

      {
        columnName: "aging_days",
        displayName: "Ageing",
        hideFilter: true,
      },
      { columnName: "is_approved", displayName: "Status", hideFilter: true },
      {
        columnName: "tenure_status",
        displayName: "Tenure Status",
        hideFilter: true,
      },
    ];

    this.setState({ columnsDefault: columns, filterColumns }, () =>
      this.setDefaultColumns(columns)
    );
  };

  //Add Payout Estimate
  handleAdd = () => {
    this.props.history.push("/purchase/manual-transactions-add");
  };

  //Edit Payout Estimate
  handleEdit = (payout_estimate_id) => {
    this.props.history.push(
      "/purchase/transactions-edit/" + payout_estimate_id
    );
  };

  // Handle Drawer
  handleDrawer = (record) => {
    this.setState({ isDrawer: !this.state.isDrawer, record });
  };

  handleApproval = (values) => {
    const { record } = this.state;

    let reqUrl = "scheduledtransaction/approve";
    let reqObj = {
      request: {
        pending_estimate_id: record?.pending_estimate_id,
        approved_date: moment(),
        approved_by_id: AdminApi?.getCurrentUser()?.Id,
        remarks: values?.remarks,
      },
    };
    ajaxRequester(reqUrl, reqObj, (data) => {
      if (data.success) {
        message.success("Scheduled Transaction has been Approved");
        const { pagination } = this.state;
        this.getResults({ pagination });
        this.handleDrawer("");
      } else {
        message.error(
          data?.detail ||
            data?.title ||
            "An error occured while processing your request."
        );
      }
    });
  };

  handleActiveToggle = (e) => {
    const { pagination } = this.state;

    this.setState(
      {
        listReq: e
          ? "scheduledtransaction/history?showApproved=" +
            !this.props.match.path.includes("approval") +
            "&"
          : "scheduledtransaction/list?showApproved=" +
            !this.props.match.path.includes("approval") +
            "&",
      },

      () => this.getResults({ pagination }, this.setColumns())
    );
  };

  handleTabs = (tab) => {
    let listReq =
      "scheduledtransaction/list?showApproved=" +
      !this.props.match.path.includes("approval") +
      "&estimateTypeId=" +
      tab +
      "&";
    let { pagination, filters } = this.state;
    pagination.current = 1;

    let activeTab = tab || 0;

    this.setState({ activeTab, listReq }, () =>
      this.getResults({ pagination, filters })
    );
  };

  componentDidMount() {
    const { pagination } = this.state;
    this.getResults({ pagination });
    this.setColumns();
  }

  render() {
    const {
      data,
      columns,
      pagination,
      loading,
      filterColumns,
      screen,
      isDrawer,
      navKey,
      listReq,
    } = this.state;

    const { TextArea } = Input;

    const layout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
    };

    return (
      <React.Fragment>
        <Row>
          <Col span={24}>
            {screen === "scheduled-transaction" && (
              <PageTitle titleText={"Scheduled Transactions"} />
            )}

            {screen === "scheduled-transaction-approval" && (
              <PageTitle titleText={"Scheduled Transactions Approval"} />
            )}

            <ListFilters
              items={filterColumns}
              handleSettings={this.handleSettings}
              handleFilters={this.handleFilters}
              key={filterColumns}
              screen={screen}
            />

            {screen !== "scheduled-transaction-approval" && (
              <div
                style={{
                  top: "-44px",
                  float: "right",
                  right: "42px",
                  marginBottom: "-44px",
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                }}
                class="title-btns"
              >
                <Switch
                  size="small"
                  onChange={this.handleActiveToggle}
                ></Switch>
                <label style={{ marginLeft: "8px", marginRight: "16px" }}>
                  History
                </label>
              </div>
            )}

            {!listReq.includes("history") && (
              <TransactionsTabs
                {...this.state}
                key={navKey}
                handleTabs={this.handleTabs}
              />
            )}

            <Table
              columns={columns}
              rowKey={(record) => record?.pending_estimate_id}
              dataSource={data}
              pagination={pagination}
              loading={loading}
              onChange={this.handleTableChange}
              bordered
              scroll={{ x: 400 }}
              size="small"
            />
          </Col>
        </Row>

        <Drawer
          title={"Transactions Approval"}
          placement="right"
          visible={isDrawer}
          onClose={this.handleDrawer}
          width={"424px"}
          key={isDrawer}
        >
          <Form
            name="transactions_approval"
            className="transactions_approval"
            onFinish={this.handleApproval}
            {...layout}
          >
            <Form.Item label="Remarks" name="remarks">
              <TextArea rows={4} placeholder="Remarks" />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 6 }}>
              <Button
                type="primary"
                icon={<CheckCircleOutlined />}
                htmlType="submit"
                loading={loading}
              >
                Approve
              </Button>
            </Form.Item>
          </Form>
        </Drawer>
      </React.Fragment>
    );
  }
}

export default ScheduledTransactions;
