import React from "react";
import { Layout, Button } from "antd";

const AppFooter = (props) => {
  const { Footer } = Layout;
  return (
    <Footer>
      All Rights Reserved EasyOutdoor
      <Button type="link" onClick={props.handleTheme}>
        Switch Theme
      </Button>
    </Footer>
  );
};

export default AppFooter;
