import React from "react";
import { Typography, Button } from "antd";
import {
  PlusOutlined,
  LeftOutlined,
  TrademarkCircleTwoTone,
} from "@ant-design/icons";

const PageTitleDetails = (props) => {
  const { Title } = Typography;
  const { titleText, isAddBtn, isBackBtn } = props;

  return (
    <div className="page-title page-title-detail">
      <Title level={4}>
        {isBackBtn && (
          <Button onClick={props.handleBack} icon={<LeftOutlined />}></Button>
        )}
        {titleText}
        {isAddBtn && (
          <Button
            onClick={props.handleAdd}
            type="primary"
            icon={<PlusOutlined />}
          >
            {isAddBtn}
          </Button>
        )}
        {props?.is_rotational && (
          <TrademarkCircleTwoTone
            twoToneColor="#52c41a"
            style={{ marginLeft: "8px" }}
          />
        )}
      </Title>
    </div>
  );
};

export default PageTitleDetails;
