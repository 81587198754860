import React, { Component } from "react";
import { ajaxRequester } from "../../services/ajax-requester";
import { Upload, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

class UploadFile extends Component {
  state = { loading: false };

  getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }

    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }

    return isJpgOrPng && isLt2M;
  };

  handleChange = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      this.setState({ loading: false });
      return;
    }
  };

  handleUpload = (file, imageUrl) => {
    let reqUrl = this.props.uploadPath;

    let reqObj = {
      request: {
        reference_id: this.props.id,
        name: file.name,
        is_public: true,
        tags: [file.name.split(".")[0]],
        data_extension: "." + [...file.name.split(".")].pop(),
        type: file.type,
        document_type: "1",
        data: imageUrl.split(",")[1],
      },
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      if (data?.success || data) {
        message.success(`${file.name} file uploaded successfully`);
        this.setState({
          imageUrl,
          loading: false,
        });
      } else {
        message.error(`${file.name} file upload failed.`);
      }
    });
  };

  handleUploadLocal = (file, imageUrl) => {
    let logoReq = {
      is_public: true,
      tags: [file.name.split(".")[0]],
      data_extension: "." + [...file.name.split(".")].pop(),
      type: file.type,
      data: imageUrl.split(",")[1],
    };

    this.setState({
      imageUrl,
      logoReq,
      loading: false,
    });

    this.props.handleUploadLogoReq(logoReq);
  };

  uploadImage = ({ file }) => {
    this.getBase64(file, (image) => {
      this.props.mode
        ? this.handleUpload(file, image)
        : this.handleUploadLocal(file, image);
    });
  };

  render() {
    const { loading, imageUrl } = this.state;
    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );

    return (
      <React.Fragment>
        <Upload
          listType="picture-card"
          beforeUpload={this.beforeUpload}
          customRequest={this.uploadImage}
          onChange={this.handleChange}
          showUploadList={false}
        >
          {imageUrl || this.props?.photoPath ? (
            <img
              src={imageUrl || this.props?.photoPath}
              alt="logo"
              style={{ width: "100%" }}
            />
          ) : (
            uploadButton
          )}
        </Upload>
      </React.Fragment>
    );
  }
}

export default UploadFile;
