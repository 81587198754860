import React, { Component } from "react";
import {
  Form,
  Input,
  Button,
  Avatar,
  Comment,
  List,
  Select,
  Checkbox,
} from "antd";
import { ExclamationCircleTwoTone, UserOutlined } from "@ant-design/icons";
import UserPickerDropDown from "../../iam/components/user-picker-dropdown";
import { ajaxRequester } from "../../services/ajax-requester";
import { RolesPermissions } from "../../helpers/roles-permissions";
import AdminApi from "../../iam/api/admin-api";

class PlanActions extends Component {
  state = { isActivityLog: false, customersList: [] };

  onFinish = (values) => {
    const { customersList } = this.state;
    const { action } = this.props;
    if (action === "merge") {
      values.customer_id = customersList?.find(
        (x) => x?.customer_name === values?.customer_id
      ).customer_id;
    }
    this.props.handleActions(values);
  };

  // Get Customer List from Lookup
  getCustomers = (employee_id) => {
    let reqUrl = "customer/lookup?employeeid=" + employee_id;
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({
          customersList: data.response,
        });
      },
      "GET"
    );
  };

  // Get Sagment List from Lookup
  getSegmentList = () => {
    let reqUrl = "lookup/segment";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ segmentList: data.response });
      },
      "GET"
    );
  };

  ValuesChange = (value, values) => {
    const { action } = this.props;
    if (action === "merge") {
      if (value["employee_id"]) {
        this.getCustomers(values["employee_id"]);
      }
    }
  };

  componentDidMount() {
    const { action } = this.props;
    // action === "merge" && this.getCustomers();
    action === "merge" && this.getSegmentList();
    action === "merge" &&
      RolesPermissions("sr") &&
      this.getCustomers(AdminApi.getCurrentUser()?.Id);
  }

  render() {
    const { action, record, activityLog } = this.props;
    const { customersList, segmentList } = this.state;
    const { Option } = Select;

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };

    return (
      <React.Fragment>
        {(action === "delete" ||
          action === "block" ||
          action === "unblock") && (
          <h3 className="ant-text-center">
            <ExclamationCircleTwoTone
              twoToneColor="#f81d22"
              style={{ marginRight: "8px" }}
            />
            Are you sure {record.title}?
            <br />
            <Button
              type="primary"
              onClick={() => this.props.handleActions()}
              style={{ marginTop: "16px" }}
            >
              {record.title}
            </Button>
          </h3>
        )}

        {(action === "copy" || action === "share") && (
          <Form
            name="plan_actions"
            {...layout}
            onFinish={this.onFinish}
            className="plan_actions"
          >
            <React.Fragment>
              {action === "copy" && (
                <Form.Item
                  name="display_name"
                  label="Display Name"
                  rules={[
                    {
                      required: true,
                      pattern: new RegExp(/^[^/]*$/),
                      message: "Please Input Display Name ! ( / not allowed)",
                    },
                  ]}
                >
                  <Input placeholder="Display Name" />
                </Form.Item>
              )}

              {action === "share" && (
                <Form.Item name="employee_id" label="Employee Name">
                  <UserPickerDropDown />
                </Form.Item>
              )}

              <Form.Item
                name="copy_documents"
                valuePropName="checked"
                wrapperCol={{ offset: 8 }}
              >
                <Checkbox>Copy Documents</Checkbox>
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 8 }}>
                <Button type="primary" htmlType="submit">
                  {record.title}
                </Button>
              </Form.Item>
            </React.Fragment>
          </Form>
        )}

        {action === "merge" && (
          <Form
            name="plan_actions"
            {...layout}
            onFinish={this.onFinish}
            className="plan_actions"
            onValuesChange={this.ValuesChange}
          >
            <React.Fragment>
              <Form.Item
                name="display_name"
                label="Display Name"
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(/^[^/]*$/),
                    message: "Please Input Display Name ! ( / not allowed)",
                  },
                ]}
              >
                <Input placeholder="Display Name" />
              </Form.Item>

              {RolesPermissions("ad") && (
                <Form.Item name="employee_id" label="Employee Name">
                  <UserPickerDropDown />
                </Form.Item>
              )}

              <Form.Item
                name="customer_id"
                label="Customer"
                rules={[{ required: true, message: "Please Input Customer !" }]}
              >
                {customersList && (
                  <Select placeholder="Customer Name" showSearch={true}>
                    {customersList.map((x) => (
                      <Option key={x.customer_name} value={x.customer_name}>
                        {x.customer_name}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>

              <Form.Item
                name="segment_id"
                label="Segment"
                rules={[{ required: true, message: "Please Input Segment !" }]}
              >
                {segmentList && (
                  <Select placeholder="Select Segment">
                    {segmentList.map((x) => (
                      <Option key={x.segment_id} value={x.segment_id}>
                        {x.segment}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 8 }}>
                <Button type="primary" htmlType="submit">
                  {record.title}
                </Button>
              </Form.Item>
            </React.Fragment>
          </Form>
        )}

        {action === "activity" && (
          <List
            className="comment-list"
            itemLayout="horizontal"
            dataSource={activityLog}
            loading={activityLog ? false : true}
            style={{ maxHeight: "320px", overflowY: "auto" }}
            renderItem={(item) => (
              <li>
                <Comment
                  author={item.updated_by}
                  content={item.message}
                  datetime={item.updated_date}
                  avatar={<Avatar size="large" icon={<UserOutlined />} />}
                />
              </li>
            )}
          />
        )}
      </React.Fragment>
    );
  }
}

export default PlanActions;
