import AdminApi from "../iam/api/admin-api";

export const RolesPermissions = (element) => {
  const currentUserRoles = AdminApi.getCurrentUser().Roles;

  let isAccess = currentUserRoles.includes("su")
    ? true
    : currentUserRoles.includes(element);

  return isAccess;
};
