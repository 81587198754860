import React, { Component } from "react";
import { ajaxRequester } from "../../services/ajax-requester";
import { Upload, message, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";

class UploadPhotos extends Component {
  state = { loading: false, fileList: [] };

  getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  handleUpload = (file, imageUrl) => {
    let reqUrl = this.props.uploadPath;

    let reqObj = {
      request: {
        reference_id:
          this.props.page !== "monitoring"
            ? this.props.id
            : this.props?.selectedRows?.operation_id || this?.props?.id,
        operation_monitoring_id:
          this?.props?.selectedRows?.operation_monitoring_id,
        is_public: true,
        tags: [file.name.split(".")[0]],
        data_extension: "." + [...file.name.split(".")].pop(),
        type: file.type,
        data: imageUrl.split(",")[1],
        category: this.props?.category,
        name: file.name,
      },
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      if (data) {
        if (data?.success) {
          message.success(`${file.name} file uploaded successfully`);
          this.setState({ fileList: [] });
          data.name = file.name;
          this.props.updateData(data);
        } else {
          data?.detail
            ? message.error(data?.detail)
            : message.error(`${file.name} file upload failed.`);
          this.setState({ fileList: [] });
        }
      } else {
        message.error(`${file.name} file upload failed.`);
        this.setState({ fileList: [] });
      }
    });
  };

  uploadImage = ({ file }) => {
    let fileList = this.state.fileList;

    fileList.push({
      uid: file.uid,
      name: file.name,
      status: "uploading",
    });

    this.setState({ fileList });

    this.getBase64(file, (image) => {
      this.handleUpload(file, image);
    });
  };

  render() {
    const { fileList } = this.state;

    return (
      <React.Fragment>
        <Upload fileList={fileList} customRequest={this.uploadImage}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
      </React.Fragment>
    );
  }
}

export default UploadPhotos;
