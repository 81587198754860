import React, { Component } from "react";
import {
  Form,
  Input,
  Button,
  DatePicker,
  Table,
  Row,
  Col,
  InputNumber,
  Radio,
} from "antd";
import { ajaxRequester } from "../../services/ajax-requester";

class AddCreative extends Component {
  state = {
    selectedRowKeys: [],
    selectedRows: [],
    columns: [],
    filterColumns: [],
    inventory_details: [],
    inventory_details_default: [],
    inventory_details_default_filter: [],
  };

  formRef = React.createRef();

  onFinish = (values) => {
    const { selectedRowKeys, selectedRows } = this.state;
    const { modalAction } = this.props;

    let data = {};

    if (modalAction === "add-additional-creative") {
      data = {
        creative_name: values?.creative_name,
        mounting_date: values?.mounting_date,
        campaign_inventory_id: selectedRowKeys,
        printing_supplier_id: "ashokm",
        printing_install_charges: [
          {
            criteria_name: "all",
            criteria_value: "inventories",
            print_fl_rate: values?.print_fl_rate || 0,
            print_bl_rate: values?.print_bl_rate || 0,
            install_fl_rate: values?.install_fl_rate || 0,
            install_bl_rate: values?.install_bl_rate || 0,
          },
        ],
      };
      this.props.handleAddCreative(data);
    }

    if (modalAction === "add-campaign-monitoring") {
      let operation_ids = [];
      selectedRows.map((x) => operation_ids.push(x.operation_id));

      data = {
        monitoring_name: values?.monitoring_name,
        monitoring_date: values?.monitoring_date,
        operation_id: operation_ids,
        monitoring_by: "ashokm",
        monitoring_type: values?.monitoring_type || "Day",
      };
      this.props.handleAddMonitoring(data);
    }
  };

  disabledDate = (current) => {
    // let start_date = this?.props?.selectedRows[0]?.start_date;
    // return current && current < moment(start_date, "YYYY-MM-DD");
  };

  setColumns = () => {
    let columns = [
      {
        title: "Media",
        dataIndex: "media_type",
      },
      {
        title: "Qty",
        dataIndex: "quantity",
      },
      {
        title: "IID",
        dataIndex: "inventory_code",
      },
      {
        title: "City",
        dataIndex: "city",
      },
      {
        title: "Area",
        dataIndex: "area",
      },
      {
        title: "Location",
        dataIndex: "location",
      },
      {
        title: "Size",
        dataIndex: "size",
      },
      {
        title: "Light",
        dataIndex: "light_type",
      },
    ];

    this.setState({ columns });
  };

  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedRowKeys, selectedRows });
  };

  getResults = () => {
    var reqUrl =
      this?.props?.listReq.replace("monitoring", "mounting") +
      "showMountedSites=true";
    var reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ inventory_details: data?.response || [] });
      },
      "GET"
    );
  };

  ValuesChange = (value, values) => {
    if (value["monitoring_type"]) {
      this.setState({
        monitoring_type: value["monitoring_type"],
        selectedRowKeys: [],
        selectedRows: [],
      });
    }
  };

  setDisabled = (record) => {
    const { monitoring_type } = this.state;
    return (
      monitoring_type === "Night" &&
      record?.light_type === "NL" &&
      "disabled-row"
    );
  };

  componentDidMount() {
    this.getResults();
    this.setColumns();
  }

  render() {
    const { selectedRowKeys, columns, inventory_details, monitoring_type } =
      this.state;
    const { modalAction } = this.props;

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };

    const layout2 = {
      labelCol: { span: 12 },
      wrapperCol: { span: 12 },
    };

    const rowSelection = {
      selectedRowKeys,
      preserveSelectedRowKeys: true,
      onChange: this.onSelectChange,
      getCheckboxProps: (record) => ({
        disabled:
          monitoring_type === "Night" &&
          record?.light_type === "NL" &&
          "disabled-row",
      }),
    };

    const hasSelected = selectedRowKeys.length > 0;

    return (
      <Form
        name="add_creative"
        {...layout}
        onFinish={this.onFinish}
        className="add_creative"
        ref={this.formRef}
        onValuesChange={this.ValuesChange}
      >
        <React.Fragment>
          <Row className="campaign-operation-pop-form">
            <Col span={12} xs={24} xl={12}>
              {modalAction === "add-additional-creative" && (
                <React.Fragment>
                  <Form.Item
                    name="creative_name"
                    label="Creative Name"
                    rules={[
                      {
                        required: true,
                        message: "Please Input Creative Name !",
                      },
                    ]}
                  >
                    <Input placeholder="Creative Name" />
                  </Form.Item>

                  <Form.Item
                    name="mounting_date"
                    label="Mounting Date"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Mounting Date !",
                      },
                    ]}
                  >
                    <DatePicker
                      disabledDate={this.disabledDate}
                      inputReadOnly={true}
                    />
                  </Form.Item>
                </React.Fragment>
              )}

              {modalAction === "add-campaign-monitoring" && (
                <React.Fragment>
                  <Form.Item
                    name="monitoring_name"
                    label="Monitoring Name"
                    rules={[
                      {
                        required: true,
                        message: "Please Input Monitoring Name !",
                      },
                    ]}
                  >
                    <Input placeholder="Monitoring Name" />
                  </Form.Item>

                  <Form.Item
                    name="monitoring_date"
                    label="Monitoring Date"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Monitoring Date !",
                      },
                    ]}
                  >
                    <DatePicker
                      disabledDate={this.disabledDate}
                      inputReadOnly={true}
                    />
                  </Form.Item>
                </React.Fragment>
              )}
            </Col>

            {modalAction === "add-additional-creative" && (
              <Col span={12} xs={24} xl={12}>
                <Row>
                  <Col span={10}>
                    <Form.Item
                      name="print_fl_rate"
                      label="Printing"
                      {...layout2}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        placeholder="FL"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <div
                      style={{
                        display: "block",
                        textAlign: "center",
                        marginTop: "5px",
                      }}
                      className="without-label-text"
                    >
                      &amp;
                    </div>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="print_bl_rate" className="without-label">
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        placeholder="BL"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={10}>
                    <Form.Item
                      name="install_fl_rate"
                      label="Mounting"
                      {...layout2}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        placeholder="FL"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <div
                      style={{
                        display: "block",
                        textAlign: "center",
                        marginTop: "5px",
                      }}
                      className="without-label-text"
                    >
                      &amp;
                    </div>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="install_bl_rate" className="without-label">
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        placeholder="BL"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            )}

            {modalAction === "add-campaign-monitoring" && (
              <Col span={12}>
                <Form.Item name="monitoring_type" label="Monitoring Type">
                  <Radio.Group defaultValue="Day">
                    <Radio value="Day">Day</Radio>
                    <Radio value="Night">Night</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            )}
          </Row>

          <Table
            columns={columns}
            rowKey={(record) => record.campaign_inventory_id}
            dataSource={inventory_details}
            onChange={this.handleTableChange}
            bordered
            scroll={{ x: 400 }}
            size="small"
            rowSelection={{
              ...rowSelection,
            }}
            pagination={false}
            rowClassName={(record) => this.setDisabled(record)}
          />

          <Form.Item wrapperCol={{ offset: 0 }} style={{ marginTop: "16px" }}>
            <Button type="primary" htmlType="submit" disabled={!hasSelected}>
              {modalAction === "add-additional-creative" &&
                "Add Additional Creative"}

              {modalAction === "add-campaign-monitoring" && "Add Monitoring"}
            </Button>
          </Form.Item>
        </React.Fragment>
      </Form>
    );
  }
}

export default AddCreative;
