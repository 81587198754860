import React, { Component } from "react";
import { InputNumber, Input } from "antd";
import { DatePicker } from "antd";
import moment from "moment";
import { UndoOutlined } from "@ant-design/icons";
import { dateFormatter } from "../../components/common/common-formatter";

class EditInvoicePriceDate extends Component {
  state = {
    value: this.props.value,
    defaultValue: this.props.value,
    column: this.props.column,
    isDateDisabled: false,
  };

  onFinish = () => {
    this.props.handleInvoiceEdit(
      this.state.value,
      this.props.record,
      this.props.column
    );
  };

  onChange = (value) => {
    this.state.column !== "notes"
      ? this.setState({ value })
      : this.setState({ value: value.target.value });
  };

  onDateChange = (value) => {
    let date = value._d;
    this.setState({ value: date }, () => this.onFinish());
  };

  setDateDisabled = () => {
    let isDateDisabled = false;

    const { column, record } = this.props;

    if (
      column === "start_date" &&
      (record?.campaign_status === "Running" ||
        record?.campaign_status === "Completed")
    ) {
      isDateDisabled = true;
    }
    if (column === "end_date" && record?.campaign_status === "Completed") {
      isDateDisabled = true;
    }

    this.setState({ isDateDisabled });
  };

  handleBaseRateReset = () => {
    this.setState({ value: this.props.record.inv_base_rate }, () =>
      this.onFinish()
    );
  };

  disabledDate = (current) => {
    const { record } = this.props;

    return (
      current < moment(record?.start_date, "YYYY-MM-DD") ||
      current > moment(record?.originalEndDate, "YYYY-MM-DD")
    );
  };

  componentDidMount() {
    this.setDateDisabled();
  }

  render() {
    const { value, defaultValue, column, isDateDisabled } = this.state;

    const customFormat = () => `Available`;

    return (
      <React.Fragment>
        {(column === "start_date" || column === "end_date") && (
          <DatePicker
            defaultValue={moment(value, "YYYY-MM-DD")}
            format="DDMMMYY"
            bordered={false}
            allowClear={false}
            onChange={this.onDateChange}
            disabled={isDateDisabled}
            style={{
              margin: "0px",
              padding: "0px",
            }}
            disabledDate={this.disabledDate}
            inputReadOnly={true}
          />
        )}

        {column !== "start_date" &&
          column !== "end_date" &&
          column !== "rate_per_sqft" &&
          column !== "notes" && (
            <React.Fragment>
              <InputNumber
                name="input"
                value={parseInt(value) || parseInt(defaultValue)}
                onChange={this.onChange}
                onBlur={this.onFinish}
              />
            </React.Fragment>
          )}

        {column === "rate_per_sqft" && (
          <React.Fragment>
            <InputNumber
              name="input"
              value={value?.toFixed(2) || defaultValue?.toFixed(2)}
              onChange={this.onChange}
              onBlur={this.onFinish}
            />
          </React.Fragment>
        )}

        {column === "notes" && (
          <React.Fragment>
            <Input
              name="input"
              value={value || defaultValue}
              onChange={this.onChange}
              onBlur={this.onFinish}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default EditInvoicePriceDate;
