import React, { Component } from "react";
import { Button, Row, Col, Table, InputNumber } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import EstimatePayeeListInput from "./estimate-payee-list-input";

class IncrementSlabList extends Component {
  state = {
    loading: false,
    increment_slab: [],
    key: 1,
    columns: [],
  };

  setColumns = () => {
    let columns = [
      {
        title: "Increment Slab",
        dataIndex: "from_date",
        render: (text, record) => record?.from_date + " - " + record?.to_date,
      },
      {
        title: "Increment Per %",
        dataIndex: "increment_per",
        render: (text, record) => (
          <EstimatePayeeListInput
            key={text}
            value={text}
            record={record}
            column={"increment_per"}
            handlePayeeInput={this.handlePerInput}
          />
        ),
      },
    ];

    this.setState({ columns });
  };

  handlePerInput = (value, record, column) => {
    let { increment_slab, key } = this.state;

    increment_slab = increment_slab.map((x) => ({
      ...x,
      increment_per:
        x.from_date === record.from_date && x.to_date === record.to_date
          ? parseInt(value)
          : x.increment_per,
    }));

    this.setState({ increment_slab, key: key + 1 });

    this.props.handleIncrementSlab(increment_slab);
  };

  componentDidMount() {
    this.setColumns();
    const { increment_slab } = this.props || [];
    this.setState({ increment_slab });
  }

  render() {
    const { loading, increment_slab, columns, key } = this.state;

    return (
      <Table
        columns={columns}
        rowKey={(record) => record?.from_date + " - " + record?.to_date}
        dataSource={increment_slab}
        pagination={false}
        loading={loading}
        onChange={this.handleTableChange}
        bordered
        scroll={{ x: 400 }}
        size="small"
        key={key}
      />
    );
  }
}

export default IncrementSlabList;
