import React from "react";
import {
  Row,
  Col,
  Table,
  Modal,
  Drawer,
  Button,
  Switch,
  DatePicker,
  message,
} from "antd";
import {
  CloseOutlined,
  CheckOutlined,
  PlusOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import PageTitle from "../../components/common/page-title";
import ListFilters from "../../components/common/list-filters";
import InventoryNav from "../../components/inventories/inventory-nav";
import InventoriesListBase from "../../base/inventories-list-base";
import { ajaxRequester } from "../../services/ajax-requester";
import PlanAdd from "../../components/plan/plan-add";
import InventoryAdvancedFilters from "../../components/inventories/inventory-advanced-filters";
import { globalVal } from "../../helpers/global";
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import L from "leaflet";
import iconMarker from "../../assets/images/marker-icon.png";
import iconMarkerSelected from "../../assets/images/marker-icon-selected.png";
import "leaflet/dist/leaflet.css";
import "../../assets/css/leaflet.css";
import MapSearchBox from "../../components/common/map-searchbox";
import PhotoGallery from "../../components/common/photo-gallery";
import AdminApi from "../../iam/api/admin-api";
import moment from "moment";
import { dateFormatter } from "../../components/common/common-formatter";
import { RolesPermissions } from "../../helpers/roles-permissions";

class InventoriesMaster extends InventoriesListBase {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pagination: {
        current: 1,
        pageSize: 20,
      },
      loading: false,
      columns: [],
      columnsDefault: [],
      filterColumns: [],
      inventoryType: this.props.match.path.includes("ta-")
        ? "tainventory"
        : "hainventory",
      navKey: 1,
      filters: "",
      isModel: false,
      isDrawer: false,
      selectedRowKeys: [],
      selectedRows: [],
      isListView: true,
      isMapview: false,
      defaultPosition: {
        zoom: 5,
        center: {
          lat: 20.5937,
          lng: 78.9629,
        },
      },
      isHistoricalPurchase: true,
      screen: "inventories-master",
    };

    this.popup = React.createRef();
  }

  callBack = () => {
    let columns = [...this.state.columns];
    columns = columns.filter(
      (x) =>
        x.title !== "Actions" &&
        x.title !== "Type" &&
        x.title !== "Media" &&
        x.title !== "Qty" &&
        x.title !== "State" &&
        x.title !== "District" &&
        x.title !== "Area" &&
        x.title !== "Lat" &&
        x.title !== "Long" &&
        x.title !== "Traffic From" &&
        x.title !== "Traffic To" &&
        x.title !== "Width" &&
        x.title !== "Height" &&
        x.title !== "SQFT" &&
        x.title !== "Custom Photo" &&
        x.title !== "Available Gap" &&
        x.title !== "Campaign Start" &&
        x.title !== "Campaign End" &&
        x.title !== "Display" &&
        x.title !== "Employee" &&
        x.title !== "Occupancy %" &&
        x.title !== "Rating" &&
        x.title !== "Log" &&
        x.title !== "Inventory Status"
    );

    // this.setState({ columns });
  };

  // Check Box Selection
  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedRowKeys, selectedRows });
  };

  // Add To Plan
  addToPlan = (data) => {
    let discounting_per = 0;
    let print_fl_rate_amount = 0;
    let print_bl_rate_amount = 0;
    let install_fl_rate_amount = 0;
    let install_bl_rate_amount = 0;

    let total_display_cost = 0;
    let total_sqft = 0;
    let print_fl_rate = 0;
    let print_bl_rate = 0;
    let total_printing_cost = 0;
    let total_installing_cost = 0;

    if (data.discount_criteria) {
      let discount_criteria = data?.discount_criteria?.find(
        (x) => x.criteria_name === "all"
      );
      discounting_per = discount_criteria.rate_percentage || 0;
    }

    if (data.printing_install_charges) {
      let printing_install_charges = data?.printing_install_charges?.find(
        (x) => x.criteria_name === "all"
      );
      print_fl_rate_amount = printing_install_charges.print_fl_rate || 0;
      print_bl_rate_amount = printing_install_charges.print_bl_rate || 0;
      install_fl_rate_amount = printing_install_charges.install_fl_rate || 0;
      install_bl_rate_amount = printing_install_charges.install_bl_rate || 0;
    }

    let inventories = this.state.selectedRows;
    if (data.existingInventories) {
      var ids = new Set(data.existingInventories.map((d) => d.ha_inventory_id));
      inventories = [
        ...data.existingInventories,
        ...inventories.filter((d) => !ids.has(d.ha_inventory_id)),
      ];
    }

    inventories.map((x) => {
      return (
        (total_display_cost = total_display_cost + x.card_rate),
        (total_sqft = total_sqft + x.sqft),
        x.light_type === "FL" && (print_fl_rate = print_fl_rate + x.sqft),
        x.light_type === "NL" && (print_fl_rate = print_fl_rate + x.sqft),
        x.light_type === "BL" && (print_bl_rate = print_bl_rate + x.sqft)
      );
    });

    total_printing_cost =
      print_fl_rate * print_fl_rate_amount +
      print_bl_rate * print_bl_rate_amount;

    total_installing_cost =
      print_fl_rate * install_fl_rate_amount +
      print_bl_rate * install_bl_rate_amount;

    total_display_cost =
      parseInt((total_display_cost / 30) * data.no_of_days) -
      parseInt(
        ((total_display_cost / 30) * data.no_of_days * discounting_per) / 100
      );
    let gst_amount =
      parseInt((total_display_cost * globalVal.gst_per) / 100) +
      parseInt((total_printing_cost * globalVal.gst_per) / 100) +
      parseInt((total_installing_cost * globalVal.gst_per) / 100);

    let total_amount =
      total_display_cost +
      gst_amount +
      total_printing_cost +
      total_installing_cost;

    var reqUrl = "plan/" + (data.plan_type === "new" ? "create" : "update");
    var reqObj = {
      request: {
        plan_id: data.plan_id,
        display_name: data.display_name,
        employee_id: data.employee_id || AdminApi.getCurrentUser().Id,
        customer_id: data.customer_id,
        start_date: data.start_date,
        end_date: data.end_date,
        no_of_days: data.no_of_days,
        notes: data.notes,
        total_display_cost: total_display_cost,
        gst_per: globalVal.gst_per,
        gst_amount: gst_amount,
        total_amount: total_amount,
        total_sqft: total_sqft,
        total_printing_cost: total_printing_cost,
        total_installing_cost: total_installing_cost,
        plan_status: "",
        created_by: globalVal.created_by,
        plan_inventory_details: inventories.map((inventory) => {
          return {
            ...inventory,
            ha_inventory_id: inventory.ha_inventory_id,
            start_date: data.start_date,
            end_date: data.end_date,
            no_of_days: data.no_of_days,
            base_rate: inventory.base_rate,
            card_rate: inventory.card_rate,
            monthly_rate:
              inventory.card_rate -
              (inventory.card_rate * discounting_per) / 100,
            rental_cost:
              parseInt((inventory.card_rate / 30) * data.no_of_days) -
              parseInt(
                ((inventory.card_rate / 30) *
                  data.no_of_days *
                  discounting_per) /
                  100
              ),
            sqft: inventory.sqft,
          };
        }),
        printing_install_charges: data.printing_install_charges,
        discount_criteria: data.discount_criteria,
        plan_key: data?.plan_key,
        is_rotational: data?.is_rotational || false,
      },
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      if (data.success) {
        message.success("Successfully done");
        this.props.history.push(`/plans/plans`);
      } else {
        message.error(
          data?.detail || "An error occured while processing your request."
        );
        this.handleModal();
      }
    });
  };

  // Handle Popup
  handleModal = () => {
    this.setState({ isModel: !this.state.isModel });
  };

  // Handle Drawer
  handleDrawer = () => {
    this.setState({ isDrawer: !this.state.isDrawer });
  };

  handleAdvancedFilters = (data) => {
    let { pagination } = this.state;
    pagination.current = 1;

    this.setState(
      { isDrawer: !this.state.isDrawer, advanced_filter: data },
      () => this.getInventories({ pagination })
    );
  };

  handleListMapToggle = () => {
    let { pagination } = this.state;
    pagination.current = 1;

    this.setState(
      {
        isListView: !this.state.isListView,
        isMapview: !this.state.isMapview,
      },
      () => this.getInventories({ pagination })
    );
  };

  addtoPlanfromMap = (record) => {
    let selectedRowKeys = this.state.selectedRowKeys;
    let selectedRows = this.state.selectedRows;

    if (selectedRowKeys.includes(record.ha_inventory_id)) {
      selectedRowKeys = selectedRowKeys.filter(
        (x) => x !== record.ha_inventory_id
      );
      selectedRows = selectedRows.filter(
        (x) => x.ha_inventory_id !== record.ha_inventory_id
      );
    } else {
      selectedRowKeys.push(record.ha_inventory_id);
      selectedRows.push(record);
    }

    this.setState({ selectedRowKeys, selectedRows });
  };

  getMarker = (item) => {
    const markerIcon = new L.Icon({
      iconUrl: iconMarker,
      iconRetinaUrl: iconMarker,
      popupAnchor: [-0, -0],
      iconSize: [25, 41],
    });

    const markerIconSelected = new L.Icon({
      iconUrl: iconMarkerSelected,
      iconRetinaUrl: iconMarkerSelected,
      popupAnchor: [-0, -0],
      iconSize: [25, 41],
    });

    return this.state.selectedRowKeys.includes(item.ha_inventory_id)
      ? markerIconSelected
      : markerIcon;
  };

  handleAvailableDate = (date, dateString) => {
    let { pagination } = this.state;
    pagination.current = 1;

    let data = {};
    if (dateString) {
      data.available_from = dateString;
      data.advanced_filter = true;
    } else {
      delete data.available_from;
      delete data.advanced_filter;
    }

    this.setState({ advanced_filter: data }, () =>
      this.getInventories({ pagination })
    );
  };

  disabledDate = (current) => {
    return current && current < moment().startOf("day");
  };

  handleClosePopup = () => {
    this.popup.current.leafletElement.options.leaflet.map.closePopup();
  };

  getMapPos = (data) => {
    let position = {
      zoom: data[0]?.lat_long?.x && data[0]?.lat_long?.x ? 8 : 5,
      center: {
        lat: data[0]?.lat_long?.x || 20.5937,
        lng: data[0]?.lat_long?.y || 78.9629,
      },
    };

    return position;
  };

  componentDidMount() {
    // Get Inventories List
    const { pagination } = this.state;
    this.getInventories({ pagination });
    this.setColumnsMaster();
  }

  render() {
    const {
      data,
      columns,
      pagination,
      loading,
      inventoryType,
      navKey,
      filterColumns,
      selectedRowKeys,
      isModel,
      isDrawer,
      isListView,
      isMapview,
      defaultPosition,
      isShowPhotos,
      photos,
      advancedFilters,
      screen,
      photosTitle,
    } = this.state;

    const rowSelection = {
      selectedRowKeys,
      preserveSelectedRowKeys: true,
      onChange: this.onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    return (
      <React.Fragment>
        <Row>
          <Col span={24}>
            {inventoryType === "hainventory" && (
              <PageTitle titleText="HA Inventory Master List" />
            )}

            {inventoryType === "tainventory" && (
              <PageTitle titleText="TA Inventory Master List" />
            )}

            {inventoryType === "hagroupinventory" && (
              <PageTitle titleText="HA Grouped Inventory Master List" />
            )}

            {inventoryType === "tagroupinventory" && (
              <PageTitle titleText="TA Grouped Inventory Master List" />
            )}

            {(inventoryType === "tainventory" ||
              inventoryType === "tagroupinventory") && (
              <div
                style={{
                  top: "-58px",
                  float: "right",
                  marginBottom: "-58px",
                  position: "relative",
                }}
                className="inventory-ta-switch"
              >
                <Switch
                  onChange={this.handleHistoricalPurchase}
                  size="small"
                ></Switch>
                <label
                  style={{ marginLeft: "8px" }}
                  className="inventory-ta-switch-lbl"
                >
                  Historical <span className="mobile-hide">Purchase</span>
                </label>
              </div>
            )}

            <ListFilters
              items={filterColumns}
              handleSettings={this.handleSettings}
              handleFilters={this.handleFilters}
              key={filterColumns}
              screen={screen}
            />

            <div
              style={{
                top: "-48px",
                float: "right",
                right: "48px",
                marginBottom: "-48px",
                position: "relative",
              }}
              className="title-btns"
            >
              <DatePicker
                style={{ marginRight: "16px", width: "140px" }}
                placeholder="Available From"
                onChange={this.handleAvailableDate}
                disabledDate={this.disabledDate}
                className="mobile-hide"
                inputReadOnly={true}
              />

              {(RolesPermissions("ad") ||
                RolesPermissions("sr") ||
                RolesPermissions("pl")) && (
                <Button
                  type="primary"
                  disabled={!hasSelected}
                  loading={loading}
                  onClick={this.handleModal}
                  icon={<PlusOutlined />}
                  className="icon-btn"
                >
                  Add to Plan{" "}
                  {hasSelected && "(" + selectedRowKeys.length + ")"}
                </Button>
              )}

              <Button
                type="primary"
                onClick={this.handleDrawer}
                style={{ marginLeft: "16px" }}
                icon={<FilterOutlined />}
                className="icon-btn"
              >
                Advanced Filters
              </Button>
            </div>

            <InventoryNav
              {...this.state}
              handleToggle={this.handleToggle}
              handleNav={this.handleNav}
              showMapToggle={true}
              handleListMapToggle={this.handleListMapToggle}
              key={navKey}
            />

            {isListView && (
              <Table
                columns={columns}
                rowKey={(record) => record.ha_inventory_id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={this.handleTableChange}
                bordered
                scroll={{ x: 400 }}
                size="small"
                expandable={
                  inventoryType === "hainventory" ||
                  inventoryType === "tainventory"
                    ? {
                        expandedRowRender: (record) =>
                          this.getMultiDetails(record),
                        rowExpandable: (record) =>
                          record.ha_base_inventories &&
                          record.ha_base_inventories.length > 0,
                      }
                    : {
                        expandedRowRender: (record) =>
                          this.getGroupDetails(record),
                      }
                }
                rowSelection={rowSelection}
                rowClassName={(record) => this.setColors(record)}
              />
            )}

            {isMapview && (
              <div
                style={{
                  width: "100%",
                  height: "600px",
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                {data && (
                  <Map
                    center={this.getMapPos(data)?.center}
                    zoom={this.getMapPos(data)?.zoom}
                  >
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

                    {data.map(
                      (item, key) =>
                        item.lat_long.x &&
                        item.lat_long.y && (
                          <Marker
                            key={key}
                            position={[item.lat_long.x, item.lat_long.y]}
                            icon={this.getMarker(item)}
                          >
                            <Popup closeButton={false} ref={this.popup}>
                              <div className="leaflet-map-popup">
                                <b style={{ fontSize: "15px" }}>
                                  {item?.inventory_code} - {item?.media_type}
                                </b>

                                <div>
                                  <b>Location : </b>
                                  {item?.location}, {item?.inventory_location}
                                </div>

                                <div>
                                  <b>Size : </b> {item.size}
                                </div>

                                {item?.quantity > 1 && (
                                  <div>
                                    <b>Quantity : </b> {item.quantity}
                                  </div>
                                )}

                                <div>
                                  <b>Card Rate : </b>
                                  {this.currencyFormatter(item?.card_rate)}
                                </div>

                                <div>
                                  <b>Available From : </b>
                                  {dateFormatter(item?.available_from) ===
                                  dateFormatter(new Date())
                                    ? "Available"
                                    : dateFormatter(item?.available_from)}
                                </div>

                                {item?.ha_images?.length > 0 && (
                                  <div>
                                    <Button
                                      type="link"
                                      className="ant-btn-reset"
                                      onClick={() => this.handlePhotos(item)}
                                    >
                                      View Photos
                                    </Button>
                                  </div>
                                )}

                                <Button
                                  icon={<CloseOutlined />}
                                  onClick={this.handleClosePopup}
                                  style={{
                                    position: "absolute",
                                    right: "4px",
                                    top: "4px",
                                    border: "0px none",
                                    background: "none",
                                  }}
                                ></Button>
                              </div>

                              <Switch
                                style={{ marginTop: "16px" }}
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                onChange={() => this.addtoPlanfromMap(item)}
                                checked={
                                  selectedRowKeys.includes(item.ha_inventory_id)
                                    ? true
                                    : false
                                }
                              />
                            </Popup>
                          </Marker>
                        )
                    )}

                    <MapSearchBox />
                  </Map>
                )}
              </div>
            )}
          </Col>
        </Row>

        <Modal
          centered
          title="Add to Plan"
          visible={isModel}
          onCancel={this.handleModal}
          footer={null}
        >
          <PlanAdd key={isModel} addToPlan={this.addToPlan} />
        </Modal>

        <Drawer
          title={"Advanced Filters"}
          placement="right"
          visible={isDrawer}
          onClose={this.handleDrawer}
          width={"64%"}
        >
          <InventoryAdvancedFilters
            handleAdvancedFilters={this.handleAdvancedFilters}
            key={isDrawer}
            advancedFilters={advancedFilters}
            inventoryType={inventoryType}
          />
        </Drawer>

        {isShowPhotos && (
          <PhotoGallery
            photos={photos}
            photosTitle={photosTitle}
            handlePhotos={this.handlePhotos}
          />
        )}

        {hasSelected &&
          (RolesPermissions("ad") ||
            RolesPermissions("sr") ||
            RolesPermissions("pl")) && (
            <Button
              type="primary"
              loading={loading}
              onClick={this.handleModal}
              className="fixed-actions"
            >
              <b>{selectedRowKeys.length}</b> Add to Plan
            </Button>
          )}
      </React.Fragment>
    );
  }
}

export default InventoriesMaster;
