import React, { Component } from "react";
import {
  Row,
  Col,
  Table,
  Button,
  Popover,
  Badge,
  Popconfirm,
  message,
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import PageTitle from "../../components/common/page-title";
import { ajaxRequester } from "../../services/ajax-requester";
import ListFilters from "../../components/common/list-filters";
import PortalUser from "../../iam/components/portal-user";
import { RolesPermissions } from "../../helpers/roles-permissions";
import AdminApi from "../../iam/api/admin-api";

class Customers extends Component {
  state = {
    data: [],
    pagination: {
      current: 1,
      pageSize: 20,
    },
    loading: false,
    columns: [],
    columnsDefault: [],
    filterColumns: [],
    screen: "customers",
  };

  // Get Customer List
  getCustomers = (params = {}) => {
    this.setState({ loading: true });

    let reqUrl =
      "customer/list?pageno=" +
      params.pagination.current +
      "&pagesize=" +
      params.pagination.pageSize +
      (params.filters ? params.filters : "");

    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({
          loading: false,
          data: data?.response,
          pagination: {
            ...params?.pagination,
            total: data?.page_info?.total_records,
          },
        });
      },
      "GET"
    );
  };

  // Setting Columns
  setColumns = () => {
    let columns = [
      {
        title: "Code",
        dataIndex: "customer_code",
      },
      {
        title: "Customer Name",
        dataIndex: "customer_name",
        render: (text, record) => (
          <Button
            style={{ padding: "0px" }}
            type="link"
            onClick={() => this.detailsCustomer(record)}
          >
            {text}
          </Button>
        ),
      },
      {
        title: "GST",
        dataIndex: "companies",
        render: (text) => text[0].gst_detail[0]?.gstin,
      },
      {
        title: "POC Name",
        dataIndex: "point_of_contact",
        render: (text) => this.showPopover(text),
      },
      {
        title: "POC Number",
        dataIndex: "point_of_contact",
        render: (text) => this.showPopoverPoc(text),
      },
      {
        title: "Assigned Employee",
        dataIndex: "assigned_employee",
        render: (text) => this.showPopoverEmployee(text),
      },
      {
        title: "Actions",
        dataIndex: "",
        key: "x",
        render: (record) =>
          RolesPermissions("ad") && (
            <React.Fragment>
              <Button
                onClick={() => this.editCustomer(record)}
                icon={<EditOutlined />}
                className="ant-btn-reset"
              />
              <Popconfirm
                title="Are you sure delete?"
                placement="left"
                okText="Yes"
                cancelText="No"
                onConfirm={() => this.deleteCustomer(record)}
              >
                <Button
                  icon={<DeleteOutlined />}
                  className="ant-btn-reset"
                  style={{ marginLeft: "12px" }}
                />
              </Popconfirm>
            </React.Fragment>
          ),
      },
    ];

    const filterColumns = [
      { columnName: "customer_code", displayName: "Code" },
      {
        columnName: "customer_name",
        displayName: "Customer Name",
        default: true,
      },

      { columnName: "gst_detail", displayName: "GST" },
      { columnName: "contact_name", displayName: "POC Name" },
      { columnName: "phone_no", displayName: "POC Number" },
      { columnName: "employee_name", displayName: "Assigned Employee" },
    ];

    this.setState({ columnsDefault: columns, filterColumns }, () =>
      this.setDefaultColumns(columns)
    );
  };

  // Change Pagging Sorting and Filters
  handleTableChange = (pagination, filters, sorter) => {
    this.getCustomers({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  };

  // Handle Global Filters
  handleFilters = (filterColumn, filterValue) => {
    const { pagination } = this.state;

    let filters = "";

    if (filterValue) {
      filters =
        "&columnName=" +
        filterColumn.columnName +
        "&columnValue=" +
        filterValue;
    }

    this.getCustomers({ pagination, filters });
  };

  // Handle Setting show hide columns
  handleSettings = (values) => {
    let columnsDefault = this.state.columnsDefault;
    let columns = this.state.columns;
    columns = columnsDefault.filter(({ title }) =>
      values.some((exclude) => exclude.title === title)
    );
    this.setState({ columns });
  };

  // Show Tooltip for multiple records
  showPopover = (text) => {
    let content =
      text &&
      text.length > 0 &&
      text.map((x, key) => {
        let name =
          (x.first_name && x.first_name + " " + x.last_name) || x.contact_name;
        return (
          <div key={key} style={{ margin: "8px 0px" }}>
            {name}
          </div>
        );
      });

    let popover =
      content.length > 1 ? (
        <Popover content={content}>
          <Badge
            count={content.length}
            size="small"
            offset={[8, 12]}
            style={{ background: "none", color: "#2db7f5" }}
          >
            {content[0]}
          </Badge>
        </Popover>
      ) : (
        content[0]
      );

    return popover;
  };

  // Show Tooltip for multiple records
  showPopoverEmployee = (text) => {
    let content =
      text &&
      text.length > 0 &&
      text.map((x, key) => {
        return (
          <div key={key} style={{ margin: "8px 0px 8px 0px" }}>
            <PortalUser user={x.employee_id} />
          </div>
        );
      });

    let popover =
      content.length > 1 ? (
        <Popover content={content}>
          <Badge
            count={content.length}
            size="small"
            offset={[8, 12]}
            style={{ background: "none", color: "#2db7f5" }}
          >
            {content[0]}
          </Badge>
        </Popover>
      ) : (
        content[0]
      );

    return popover;
  };

  // Show Tooltip for multiple records for Point of Contacts
  showPopoverPoc = (text) => {
    let content =
      text &&
      text.length > 0 &&
      text.map((x, key) => {
        let name = x.phone_no + " - " + x.contact_name;
        return (
          <div key={key} style={{ margin: "8px 0px" }}>
            {name}
          </div>
        );
      });

    let contentPhone =
      text &&
      text.length > 0 &&
      text.map((x, key) => {
        let phone = x.phone_no;
        return (
          <div key={key} style={{ margin: "8px 0px" }}>
            {phone}
          </div>
        );
      });

    let popover =
      content.length > 1 ? (
        <Popover content={content}>
          <Badge
            count={content.length}
            size="small"
            offset={[8, 12]}
            style={{ background: "none", color: "#2db7f5" }}
          >
            {contentPhone[0]}
          </Badge>
        </Popover>
      ) : (
        contentPhone[0]
      );

    return popover;
  };

  // Delete Customer List based on Id
  deleteCustomer = (customer) => {
    this.setState({ isLoading: true });
    var reqUrl = "customer/delete?customerid=" + customer.customer_id;
    var reqObj = "";
    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        if (data.success) {
          message.success("Customer has been deleted successfully");
          const { pagination } = this.state;
          this.getCustomers({ pagination });
        } else {
          message.error(
            data?.detail || "An error occured while processing your request."
          );
        }
      },
      "DELETE"
    );
  };

  // Redirect to customer add form
  addCustomer = () => {
    this.props.history.push(`/customers/customer-add`);
  };

  // Redirect to customer edit form
  editCustomer = (customer) => {
    this.props.history.push(`/customers/customer-edit/${customer.customer_id}`);
  };

  // Redirect to customer details screen
  detailsCustomer = (customer) => {
    this.props.history.push(
      `/customers/customer-details/${customer.customer_id}`
    );
  };

  // Set Default Columns
  setDefaultColumns = (columns) => {
    AdminApi.syncColumsSettings()
      .then((columnsSettings) => {
        this.setState({ columnsSettings: columnsSettings || {} }, () =>
          this.applyDefaultColums(columns)
        );
      })
      .catch((nothing) => {});
  };

  // Apply Default Columns
  applyDefaultColums = (columns) => {
    let { columnsSettings, columnsDefault, screen } = this.state;
    if (screen && columnsSettings) {
      let colConfig = columnsSettings[screen];

      if (colConfig && colConfig.length > 1) {
        columns = columnsDefault.filter(({ title }) =>
          colConfig.some((exclude) => exclude.title === title)
        );
      }
      this.setState({ columns });
    }
  };

  componentDidMount() {
    const currentUserRoles = AdminApi.getCurrentUser().Roles;
    if (!currentUserRoles.includes("pl")) {
      // Get Customer List
      const { pagination } = this.state;
      this.getCustomers({ pagination });
      this.setColumns();
    } else {
      this.props.history.push(`/plans/plans`);
    }
  }

  render() {
    const { data, columns, pagination, loading, filterColumns, screen } =
      this.state;

    return (
      <React.Fragment>
        <Row>
          <Col span={24}>
            <PageTitle
              titleText="Customer List"
              isAddBtn={RolesPermissions("ad") && "Add Customer"}
              handleAdd={this.addCustomer}
            />

            <ListFilters
              items={filterColumns}
              handleSettings={this.handleSettings}
              handleFilters={this.handleFilters}
              key={filterColumns}
              screen={screen}
            />

            <Table
              columns={columns}
              rowKey={(record) => record.customer_id}
              dataSource={data}
              pagination={pagination}
              loading={loading}
              onChange={this.handleTableChange}
              bordered
              scroll={{ x: 400 }}
              size="small"
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default Customers;
