import {useState} from "react";
import {Alert, Form, Input, Modal} from "antd";
import AdminApi from "../api/admin-api";


const ActivateAccount = props => {

    const [formValid, setFormValid] = useState(false);
    const [formData, setFormData] = useState({});
    const [error, setError] = useState(null);

    return (
        <Modal okButtonProps={{
            disabled: !formValid
        }} destroyOnClose={true} centered title={"Activate Account"} visible={props.visible} onCancel={props.onComplete} onOk={()=> new Promise((success, reject)=> {
            AdminApi.activateAccount(formData.userName, formData.otp, formData.password).then(()=> {

                props.onComplete();
                Modal.success({
                    content: "You account has been activated, you can continue by logging in with you credentials"
                })
            }).catch((err) => {
                setError(err);
            })
        })}>
            <Form onValuesChange={(val, all) => {

                let isValid = true;
                isValid = isValid && (all.userName !== undefined && all.userName.trim() !== "");
                isValid = isValid && ("" + all.otp).length === 6;
                isValid = isValid && (all.password !== undefined && all.password.trim() !== "" && all.password.trim().length > 4);
                isValid = isValid && (all.confirmPassword !== undefined);
                isValid = isValid && (all.password === all.confirmPassword);

                setFormValid(isValid);
                setFormData(all);
                setError(null);

            }} layout={"vertical"}>

                {!error &&
                <Alert message="Fill up below details to activate your account" type="info" showIcon /> }

                { error &&  <Alert message={error} type="error" showIcon />}

                <br />

                <Form.Item label={"User Name"} name={"userName"}>
                    <Input placeholder={"Enter user name received in activation message"} />
                </Form.Item>
                <Form.Item label={"OTP"} name={"otp"}>
                    <Input type={"number"} placeholder={"Enter OTP received in activation message"} />
                </Form.Item>
                <Form.Item label={"Password"} name={"password"}>
                    <Input type={"password"} placeholder={"Create password, at least 5 characters"} />
                </Form.Item>
                <Form.Item label={"Confirm Password"} name={"confirmPassword"}>
                    <Input type={"password"} placeholder={"Enter same password"} />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default ActivateAccount;
