import React, { Component } from "react";
import { Avatar, Comment, List } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { ajaxRequester } from "../../services/ajax-requester";

class PointOfContactLog extends Component {
  state = { data: "", loading: true };

  // Get Point Of Contacts Activity Log
  getPointOfContactLog = (params = {}) => {
    let customer_contact_id = this.props.customer_contact_id;

    let reqUrl =
      "pointofcontact/detail/activitylog?customercontactid=" +
      customer_contact_id;
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        console.log(data.response);
        this.setState({
          data: data.response,
          loading: false,
        });
      },
      "GET"
    );
  };

  componentDidMount() {
    this.getPointOfContactLog();
  }

  render() {
    const { data, loading } = this.state;

    return (
      <List
        className="comment-list"
        itemLayout="horizontal"
        dataSource={data}
        loading={loading}
        renderItem={(item) => (
          <li>
            <Comment
              author={item.updated_by}
              content={item.message}
              datetime={item.updated_date}
              avatar={<Avatar size="large" icon={<UserOutlined />} />}
            />
          </li>
        )}
      />
    );
  }
}

export default PointOfContactLog;
