import {useState, useEffect} from "react";
import {
    Alert,
    Avatar,
    Button,
    Col,
    Descriptions,
    Divider,
    Form,
    Input,
    Modal,
    PageHeader,
    Radio,
    Row, Steps,
    Tag,
    Upload
} from "antd";
import StringToColor from "string-to-color";
import AdminApi from "../api/admin-api";
import {EditOutlined} from "@ant-design/icons";
import {ContentContainer} from "./forgot-password";

const UpdateEmailModal = (props) => {

    const [email, setEmail] = useState("");
    const [formData, setFormData] = useState({});
    const [current, setCurrent] = useState(0);

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const steps = [
        {
            title: 'Enter Email',
            content: <>

                <Input onChange={(e) => {
                    setEmail(e.target.value);
                }} placeholder={"Enter new email"}/>
                <br />
            </>,
        },
        {
            title: 'Verify your email',
            content: <>
                <br/>
                <Alert message="An OTP has been sent to your email address, enter it below to verify" type="info"
                       showIcon/>
                <br/>
                <Form layout={"vertical"} onValuesChange={(item, formData) => {
                    setFormData(formData);
                }}>
                    <Form.Item name={"otp"} label={"OTP"}>
                        <Input placeholder={"Enter OTP"} type={"number"}/>
                    </Form.Item>
                </Form>

            </>,
        }
    ];

    const verifyOtp = () => {
        AdminApi.updateUserEmail(email, formData.otp).then(()=> {
            Modal.success({
                centered: true,
                content: "Email updated successfully"
            });
            props.onComplete();
        }).catch((e)=> {
            Modal.error({
                centered: true,
                content: e
            })
        })
    }

    return (
        <Modal title={"Update Email"} destroyOnClose={true} centered visible={props.visible} onCancel={props.onComplete}
               footer={[
                   current === 1 ? <Button onClick={prev} style={{
                       float: "left"
                   }}>Back</Button> : <></>,
                   current === 0 ? <Button onClick={props.onComplete} style={{
                       float: "left"
                   }}>Cancel</Button> : <></>,
                   current === 0 ? <Button type={"primary"} onClick={() => {
                       // call forgot password otp server
                       AdminApi.getOtpForEmailUpdate(email).then(()=> {
                           next();
                       }).catch(()=> {
                           Modal.error({
                               content : "Couldn't connect to server, please try again later"
                           })
                       })

                   }}  disabled={(email?.trim() === "") ?? true}>Next</Button> : <></>,
                   current === 1 ? <Button type={"primary"} onClick={verifyOtp} disabled={
                       !(formData.otp && ("" + formData.otp).length === 6)
                   }>Verify</Button> : <></>,
                   <div style={{
                       "clear": "both"
                   }}></div>
               ]}
        >
            <Steps current={current}>
                {steps.map(item => (
                    <Steps.Step key={item.title} title={item.title}/>
                ))}
            </Steps>
            <ContentContainer>
                {steps[current].content}
            </ContentContainer>
        </Modal>
    )
}

const UpdatePhoneNumberModal = (props) => {

    const [phoneNumber, setPhoneNumber] = useState("");
    const [formData, setFormData] = useState({});
    const [current, setCurrent] = useState(0);

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const steps = [
        {
            title: 'Enter Phone Number',
            content: <>

                <Input onChange={(e) => {
                    setPhoneNumber(e.target.value);
                }} placeholder={"Enter new phone number, e.g. +91-9898989898"}/>
                <br />
            </>,
        },
        {
            title: 'Verify your phone number',
            content: <>
                <br/>
                <Alert message="An OTP has been sent to your phone number, enter it below to verify" type="info"
                       showIcon/>
                <br/>
                <Form layout={"vertical"} onValuesChange={(item, formData) => {
                    setFormData(formData);
                }}>
                    <Form.Item name={"otp"} label={"OTP"}>
                        <Input placeholder={"Enter OTP"} type={"number"}/>
                    </Form.Item>
                </Form>

            </>,
        }
    ];

    const verifyOtp = () => {
        AdminApi.updateUserPhoneNumber(phoneNumber, formData.otp).then(()=> {
            Modal.success({
                centered: true,
                content: "Phone number updated successfully"
            });
            props.onComplete();
        }).catch((e)=> {
            Modal.error({
                centered: true,
                content: e
            })
        })
    }

    return (
        <Modal title={"Update Phone Number"} destroyOnClose={true} centered visible={props.visible} onCancel={props.onComplete}
               footer={[
                   current === 1 ? <Button onClick={prev} style={{
                       float: "left"
                   }}>Back</Button> : <></>,
                   current === 0 ? <Button onClick={props.onComplete} style={{
                       float: "left"
                   }}>Cancel</Button> : <></>,
                   current === 0 ? <Button type={"primary"} onClick={() => {
                       // call forgot password otp server
                       AdminApi.getOtpFormPhoneUpdate(phoneNumber).then(()=> {
                           next();
                       }).catch((e)=> {
                           Modal.error({
                               content : e != null ? e : "Couldn't connect to server, please try again later"
                           })
                       })

                   }}  disabled={(phoneNumber?.trim() === "" || phoneNumber?.search(/^\+\d{1,3}-\d{8,10}$/gm) === -1) ?? true}>Next</Button> : <></>,
                   current === 1 ? <Button type={"primary"} onClick={verifyOtp} disabled={
                       !(formData.otp && ("" + formData.otp).length === 6)
                   }>Verify</Button> : <></>,
                   <div style={{
                       "clear": "both"
                   }}></div>
               ]}
        >
            <Steps current={current}>
                {steps.map(item => (
                    <Steps.Step key={item.title} title={item.title}/>
                ))}
            </Steps>
            <ContentContainer>
                {steps[current].content}
            </ContentContainer>
        </Modal>
    )
}

const EditProfile = (props) => {

    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(true);
    const [nameFormData, setNameFormData] = useState({});
    const [updateEmailModal, setUpdateEmailModal] = useState(false);
    const [updatePhoneNumberModal, setUpdatePhoneNumberModal] = useState(false);

    const reload = () => {
        setLoading(true);
        AdminApi.getUserDetails(AdminApi.getCurrentUser().Id).then(user => {
            setUser(user);
            setNameFormData(user);
            setLoading(false);
        }).catch(() => {

        })
    }

    useEffect(() => {
        reload();
    }, [])

    return (
        <PageHeader title={"Edit Profile"}>
            {!loading &&
            <Row>
                <UpdateEmailModal user={user} visible={updateEmailModal} onComplete={()=> {
                    setUpdateEmailModal(false);
                    reload();
                }} />
                <UpdatePhoneNumberModal user={user} visible={updatePhoneNumberModal} onComplete={()=> {
                    setUpdatePhoneNumberModal(false);
                    reload();
                }} />
                <Col span={3} style={{
                    layout: "flex",
                    alignContent: "center",
                    flexDirection: "column",
                    paddingRight: "20px",
                    display: "flex"
                }}>
                    <Avatar size={128} style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginBottom: "20px",
                        backgroundColor: StringToColor(user.id),
                        fontSize: "80px"
                    }} src={user.details.picture}>{(user.firstName ?? user.userName).toUpperCase()[0]}</Avatar>
                    <br/>

                    <Upload accept={"image/jpg, image/png, image/jpeg, image/gif"} showUploadList={false}
                            multiple={false} beforeUpload={(file) => {
                        const fileReader = new FileReader();
                        fileReader.readAsDataURL(file);
                        fileReader.onload = ()=> {
                            let imageData = fileReader.result.replace(/^data:.+,/, "");
                            // upload
                            try {
                                AdminApi.setProfilePicture(file.name.match(/\.\w+$/)[0], file.type, imageData).then(()=> {
                                    reload();
                                }).catch(()=> {
                                    Modal.error({
                                        centered: true,
                                        content: "Error while updating profile picture"
                                    })
                                })
                            } catch (e) {

                            }
                        }
                    }}>
                        <Button style={{
                            width: "140px",
                            marginLeft: "24px"
                        }} icon={<EditOutlined/>}>Edit Picture</Button>
                    </Upload>
                </Col>
                <Col span={14} style={{
                    paddingTop: "10px",
                    paddingLeft: "20px",
                    borderLeft: "1px solid rgba(0, 0, 0, 0.06)"
                }}>
                    <Form layout={"vertical"} onValuesChange={(field, data) => {
                        setNameFormData(data);
                    }}>
                        <Row>
                            <Col>
                                <Form.Item label={"First Name"} name={"firstName"}>
                                    <Input defaultValue={user.firstName} />
                                </Form.Item>
                            </Col>
                            <Col style={{
                                marginLeft: "10px"
                            }}>
                                <Form.Item label={"Last Name"} name={"lastName"}>
                                    <Input defaultValue={user.lastName} />
                                </Form.Item>
                            </Col>
                            <Col style={{
                                marginTop: "30px",
                                marginLeft: "20px"
                            }}>
                                <Button disabled={nameFormData.firstName === user.firstName && nameFormData.lastName === user.lastName} onClick={()=>{
                                    AdminApi.updateUser({
                                        ...user,
                                        firstName : nameFormData.firstName,
                                        lastName: nameFormData.lastName
                                    }).then(()=> {
                                        reload();
                                    }).catch(()=> {
                                        reload();
                                    })
                                }}>Update</Button>
                            </Col>
                        </Row>
                    </Form>
                    <Divider style={{
                        margin: "0px",
                        paddingBottom: "20px"
                    }} />
                    <Form layout={"vertical"}>
                        <Row>
                            <Col span={8}>
                                <Form.Item label={"Email"}>
                                    <Input type={"email"} disabled={true} defaultValue={user.email} />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Button style={{
                                    marginTop: "30px",
                                    marginLeft: "20px"
                                }} type={"primary"} ghost onClick={()=> setUpdateEmailModal(true)}>Change</Button>
                            </Col>
                        </Row>
                    </Form>
                    <Divider style={{
                        margin: "0px",
                        paddingBottom: "20px"
                    }} />
                    <Form layout={"vertical"}>
                        <Row>
                            <Col span={8}>
                                <Form.Item label={"Phone Number"}>
                                    <Input type={"tel"} disabled={true} defaultValue={user.phoneNumber} />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Button style={{
                                    marginTop: "30px",
                                    marginLeft: "20px"
                                }} type={"primary"} ghost onClick={()=> setUpdatePhoneNumberModal(true)}>Change</Button>
                            </Col>
                        </Row>
                    </Form>
                    <Divider style={{
                        margin: "0px",
                        paddingBottom: "20px"
                    }} />
                    <Form layout={"vertical"}>
                        <Row>
                            <Col span={8}>
                                <Form.Item label={"Roles"}>
                                    {user.roles.map(x => <Tag color={"blue"} key={x}>{Object.keys(AdminApi.roles)[Object.values(AdminApi.roles).indexOf(x)]}</Tag>)}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            }
        </PageHeader>
    )
};

export default EditProfile;
