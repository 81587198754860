import React, { Component } from "react";
import { Avatar, Comment, List } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { ajaxRequester } from "../../services/ajax-requester";

class TransactionsActivityLog extends Component {
  state = { data: "", loading: true };

  // Get Point Of Contacts Activity Log
  getTransactionsActivityLog = () => {
    const { pending_estimate_id } = this.props;

    let reqUrl =
      "scheduledtransaction/activitylog?pendingEstimateId=" +
      pending_estimate_id;
    let reqObj = "";
    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({
          data: data.response,
          loading: false,
        });
      },
      "GET"
    );
  };

  componentDidMount() {
    this.getTransactionsActivityLog();
  }

  render() {
    const { data, loading } = this.state;

    return (
      <List
        className="comment-list"
        itemLayout="horizontal"
        dataSource={data}
        loading={loading}
        renderItem={(item) => (
          <li>
            <Comment
              author={item.updated_by}
              content={item.message}
              datetime={item.updated_date}
              avatar={<Avatar size="large" icon={<UserOutlined />} />}
            />
          </li>
        )}
      />
    );
  }
}

export default TransactionsActivityLog;
