import {useEffect, useState} from "react";
import AdminApi from "../api/admin-api";
import {Avatar, Descriptions, Tag} from "antd";
import styled from "styled-components";
import StringToColor from "string-to-color";

import {
    CheckCircleTwoTone,
    CloseCircleTwoTone, MailOutlined, PhoneOutlined, UnlockOutlined,
    UserOutlined
} from "@ant-design/icons";

const ProfileContainer = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const PortalUserProfile = props => {

    const [user, setUser] = useState();

    useEffect(() => {
        if(props.user && props.user !== "") {
            AdminApi.getCachedUser(props.user).then(setUser).catch();
        }

    }, [props.user]);

    return (
        <>
            {user && <ProfileContainer>
                <div>
                    <Avatar size={128} style={{
                        backgroundColor : StringToColor(user.id),
                        fontSize: "80px"
                    }} src={user.details.picture}>{(user.firstName ?? user.userName).toUpperCase()[0]}</Avatar>
                </div>
                <div style={{
                    margin: "10px"
                }}>
                    <h1>{user && user.firstName && `${user.firstName} ${user.lastName}`}
                        {user && !user.firstName && `${user.userName}`}</h1>
                </div>
                <div>
                    <Descriptions bordered layout={"horizontal"} column={1}>
                        <Descriptions.Item label={<UnlockOutlined style={{fontSize: "24px"}} />}>
                            {user.roles.map(x => <Tag color={"blue"} key={x}>{Object.keys(AdminApi.roles)[Object.values(AdminApi.roles).indexOf(x)]}</Tag>)}
                        </Descriptions.Item>
                        <Descriptions.Item label={<MailOutlined style={{fontSize: "24px"}} />}><a href={`mailto:${user.email}`}>{user.email}</a></Descriptions.Item>
                        <Descriptions.Item label={<PhoneOutlined style={{fontSize: "24px"}} />}>
                            <a href={`tel:${user.phoneNumber}`}>{user.phoneNumber ?? "-"}</a>
                        </Descriptions.Item>
                        <Descriptions.Item label="Active">{user.isActive ? <CheckCircleTwoTone twoToneColor={"#52c41a"} /> : <CloseCircleTwoTone twoToneColor={"#c41a28"} />}</Descriptions.Item>
                    </Descriptions>
                </div>
            </ProfileContainer>}
        </>
    )
}

export default PortalUserProfile;