import React from "react";
import {
  Form,
  Input,
  Select,
  InputNumber,
  Button,
  Space,
  Row,
  Col,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import CustomerAddGstin from "./customer-add-gstin";
import CustomerAddBankDetails from "./customer-add-bankdetails";

const CustomerAddCompany = (props) => {
  const { segmentList, companyType } = props;
  const isMultiCompany =
    props?.results?.companies.length > 1 || companyType === "multi"
      ? true
      : false;
  const { Option } = Select;
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <React.Fragment>
      <Form.List name="companies">
        {(fields, { add, remove }) => {
          return (
            <div>
              {fields.map((field) => (
                <React.Fragment key={field.key}>
                  <Space className={field.key > 0 ? "multi-company" : ""}>
                    <Row>
                      <Col span="12" xs={24} xl={12}>
                        <Form.Item
                          {...field}
                          name={[field.name, "company_name"]}
                          fieldKey={[field.fieldKey, "company_name"]}
                          label="Billing Name"
                          {...layout}
                          rules={[
                            {
                              required: true,
                              message: "Please input Billing Name!",
                            },
                          ]}
                        >
                          <Input placeholder="Billing Name" />
                        </Form.Item>

                        <Form.Item
                          {...field}
                          name={[field.name, "address"]}
                          fieldKey={[field.fieldKey, "address"]}
                          label="Delivery Address"
                          {...layout}
                        >
                          <Input placeholder="Delivery Address" />
                        </Form.Item>

                        <Form.Item
                          {...field}
                          name={[field.name, "office_phone"]}
                          fieldKey={[field.fieldKey, "office_phone"]}
                          label="Office Phone"
                          {...layout}
                        >
                          <Input placeholder="Office Phone" />
                        </Form.Item>

                        <Form.Item
                          {...field}
                          name={[field.name, "office_email"]}
                          fieldKey={[field.fieldKey, "office_email"]}
                          label="Office Email"
                          {...layout}
                          rules={[
                            {
                              type: "email",
                              message: "Office Email is not a valid email!",
                            },
                          ]}
                        >
                          <Input placeholder="Office Email" />
                        </Form.Item>

                        <Form.Item
                          {...field}
                          name={[field.name, "segment_id"]}
                          fieldKey={[field.fieldKey, "segment_id"]}
                          label="Segment"
                          {...layout}
                        >
                          {segmentList && (
                            <Select
                              disabled={field.key > 0 ? true : false}
                              placeholder="Select Segment"
                            >
                              {segmentList.map((x) => (
                                <Option key={x.segment_id} value={x.segment_id}>
                                  {x.segment}
                                </Option>
                              ))}
                            </Select>
                          )}
                        </Form.Item>

                        <Form.Item
                          {...field}
                          name={[field.name, "credit_days"]}
                          fieldKey={[field.fieldKey, "credit_days"]}
                          label="Credit Days"
                          {...layout}
                          rules={[
                            {
                              required: true,
                              message: "Please input Credit Days!",
                            },
                          ]}
                        >
                          <InputNumber
                            style={{ width: "100%" }}
                            placeholder="Credit Days"
                          />
                        </Form.Item>

                        <Form.Item
                          {...field}
                          name={[field.name, "pan"]}
                          fieldKey={[field.fieldKey, "pan"]}
                          label="PAN"
                          {...layout}
                        >
                          <Input placeholder="PAN" />
                        </Form.Item>

                        <Form.Item
                          {...field}
                          name={[field.name, "company_id"]}
                          fieldKey={[field.fieldKey, "company_id"]}
                          label="Company Id"
                          {...layout}
                          hidden="true"
                        >
                          <Input placeholder="Company Id" />
                        </Form.Item>

                        <Form.Item
                          {...field}
                          name={[field.name, "customer_id"]}
                          fieldKey={[field.fieldKey, "customer_id"]}
                          label="Customer Id"
                          {...layout}
                          hidden="true"
                        >
                          <Input placeholder="Customer Id" />
                        </Form.Item>
                      </Col>

                      <Col span="12" xs={24} xl={12}>
                        <CustomerAddGstin gstfields={field} />
                        <CustomerAddBankDetails field={field} />
                      </Col>
                    </Row>

                    {fields.length > 1 ? (
                      <Button
                        onClick={() => {
                          remove(field.name);
                        }}
                        className="dynamic-delete-button"
                        style={{ top: "0px", right: "0px", left: "inherit" }}
                        icon={<MinusCircleOutlined />}
                      ></Button>
                    ) : null}
                  </Space>
                </React.Fragment>
              ))}

              {isMultiCompany && (
                <Form.Item
                  {...{ wrapperCol: { offset: 4, span: 10 } }}
                  className="fields-list-btn"
                >
                  <Button
                    type="dashed"
                    onClick={() => {
                      add();
                    }}
                  >
                    <PlusOutlined /> Add Another Company
                  </Button>
                </Form.Item>
              )}
            </div>
          );
        }}
      </Form.List>
    </React.Fragment>
  );
};

export default CustomerAddCompany;
