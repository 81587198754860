import React, { Component } from "react";
import { ajaxRequester } from "../services/ajax-requester";
import { Button, message, Popconfirm } from "antd";
import { currencyFormatter } from "../components/common/common-formatter";
import EditInvoicePriceDate from "../components/sales-estimate/edit-invoice-price-date";
import moment from "moment";
import { DeleteOutlined } from "@ant-design/icons";
import PortalUser from "../iam/components/portal-user";
import { globalVal } from "../helpers/global";

class SalesEstimateBase extends Component {
  state = {};

  // Set Data if Edit Mode
  setData = () => {
    const { customer_code } = this.state.customer;
    this.formRef.current.setFieldsValue({
      customer_code,
    });
  };

  // Get Customer List
  getCustomers = () => {
    let reqUrl = "customer/lookup";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({
          customersList: data.response,
        });
      },
      "GET"
    );
  };

  // Get Customer Details
  getCustomerDetails = (customerId) => {
    var reqUrl = "customer/detail?customerid=" + Number(customerId);
    var reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ customer: data.response }, () =>
          this.setCustomerData()
        );
      },
      "GET"
    );
  };

  setCustomerData = () => {
    const { customer, mode } = this.state;
    let companyList = customer.companies.map((x) => x);
    this.setState({ companyList }, () => this.setData());
    mode === "Edit" && this.setDetails();
  };

  ValuesChange = (value, values) => {
    const { companyList, gstList } = this.state;

    if (value["customer_id"]) {
      this.getCustomerDetails(value["customer_id"]);
      this.formRef.current.setFieldsValue({ company_id: null });
      this.formRef.current.setFieldsValue({ gstin: null });
      this.formRef.current.setFieldsValue({ address: null });
    }

    if (value["company_id"]) {
      let gst_detail = companyList.find(
        (x) => x.company_id === value["company_id"]
      ).gst_detail;
      this.setState({ gstList: gst_detail });
      this.formRef.current.setFieldsValue({ gstin: null });
      this.formRef.current.setFieldsValue({ address: null });
    }

    if (value["gstin"]) {
      let addresses = gstList.find((x) => x.gstin === value["gstin"]).addresses;
      this.setState({ addressList: addresses });
      this.formRef.current.setFieldsValue({ address: null });
    }
  };

  getDetails = () => {
    const { id } = this.props.match.params;

    var reqUrl =
      "salesestimate/" +
      this.state.page +
      "/detail?" +
      this.state.page +
      "id=" +
      Number(id);
    var reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ results: data.response }, () =>
          this.getCustomerDetails(data.response.customer_id)
        );
      },
      "GET"
    );
  };

  setDetails = () => {
    const { customer, results, page } = this.state;

    let selectedInvoices = [];
    if (page === "journalvoucher") {
      selectedInvoices = results.journal_voucher_details.map((x) => ({
        ...x,
        credit_note_amount: x.invoice_amount,
      }));
    } else {
      selectedInvoices = results.credit_note_details.map((x) => ({
        ...x,
        credit_note_amount: x.invoice_amount,
      }));
    }

    let companyList = customer?.companies || [];
    let gstList =
      companyList?.find((x) => x.company_id === results?.company_id)
        ?.gst_detail || [];
    let addressList =
      gstList?.find((x) => x.gstin === results?.gstin)?.addresses || [];

    this.setState({ companyList, gstList, addressList, selectedInvoices });

    this.formRef.current.setFieldsValue({
      customer_id: results?.customer_id,
      customer_code: results?.customer_code,
      company_id:
        customer?.customer_id === results?.customer_id
          ? results?.company_id
          : null,
      gstin:
        customer?.customer_id === results?.customer_id ? results?.gstin : null,
      address:
        customer?.customer_id === results?.customer_id
          ? results?.address
          : null,
      credit_note_amount: results?.credit_note_amount,
      credit_note_date: moment(results?.credit_note_date),
      gst_type: results?.gst_type,
      credit_note_type: results?.credit_note_type,
      remarks: results?.remarks,
      purchase_invoice_no: results?.purchase_invoice_no,
      journal_voucher_no: results?.journal_voucher_no,
    });
  };

  handleDrawer = () => {
    this.setState({
      isDrawer: !this.state.isDrawer,
    });
  };

  // Handle Selection from Lists
  handleSelection = (selectedRows) => {
    let selectedInvoices = [...this.state.selectedInvoices];
    selectedRows.map((x) => selectedInvoices.push(x));
    this.setState({ selectedInvoices, isDrawer: false });
  };

  setColumns = () => {
    let columns = [
      {
        title: "Invoice Number",
        dataIndex: "invoice_no",
        render: (text, record) =>
          text ? text : "Invoice-id-" + record.invoice_id,
      },
      {
        title: "Employee",
        dataIndex: "employee_id",
        render: (text) => <PortalUser user={text} />,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Display",
        dataIndex: "display_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Invoice Amount",
        dataIndex: "invoice_amount",
        render: (text, record) =>
          currencyFormatter(
            parseInt(record?.total_invoice_amount || record?.invoice_amount)
          ),
      },
      {
        title:
          this.state.page === "journalvoucher"
            ? "Journal Voucher Amount"
            : "Credit Note Amount",
        dataIndex: "credit_note_amount",
        render: (text, record) => (
          <EditInvoicePriceDate
            key={text}
            value={text || 0}
            record={record}
            column={"credit_note_amount"}
            handleInvoiceEdit={this.handleInvoiceEditPrice}
          />
        ),
      },
      {
        title: "Delete",
        dataIndex: "",
        key: "x",
        render: (record) => (
          <React.Fragment>
            <Popconfirm
              title="Are you sure want to delete?"
              placement="left"
              okText="Yes"
              cancelText="No"
              onConfirm={() => this.deleteSelectedInvoice(record)}
            >
              <Button
                icon={<DeleteOutlined />}
                className="ant-btn-reset"
                style={{ marginLeft: "12px" }}
              />
            </Popconfirm>
          </React.Fragment>
        ),
      },
    ];

    this.setState({ columns });
  };

  // Invoice Price Edit
  handleInvoiceEditPrice = (value, record, column) => {
    let selectedInvoices = [...this.state.selectedInvoices];

    selectedInvoices = selectedInvoices.map((x) => ({
      ...x,
      credit_note_amount:
        x.invoice_id === record.invoice_id ? value : x.credit_note_amount || 0,
    }));

    this.setState(
      {
        selectedInvoices,
      },
      () => this.setTotalAmount()
    );
  };

  setTotalAmount = () => {
    let credit_note_amount = 0;
    this.state.selectedInvoices.map(
      (x) => (credit_note_amount = credit_note_amount + x.credit_note_amount)
    );
    this.formRef.current.setFieldsValue({ credit_note_amount });
  };

  deleteSelectedInvoice = (record) => {
    let selectedInvoices = [...this.state.selectedInvoices];
    selectedInvoices = selectedInvoices.filter(
      (x) => x.invoice_id !== record.invoice_id
    );
    this.setState({ selectedInvoices }, () => this.setTotalAmount());
  };

  // Redirect to Credit Notes List
  handleBack = () => {
    this.props.history.goBack();
  };

  // Gettng Seller Details
  getSallerDetails = () => {
    let reqUrl = "configuration/detail";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({
          sallerDetails: data?.configuration_details,
        });
      },
      "GET"
    );
  };

  // Gettng Invoice Formats
  getPlaceOfSupply = () => {
    let reqUrl = "lookup/placeofsupply";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({
          placeOfSupplyList: data?.response,
        });
      },
      "GET"
    );
  };

  // Gettng Invoice Formats
  getInvoiceFormat = () => {
    let reqUrl = "lookup/invoiceformat";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({
          formatList: data?.response,
        });
      },
      "GET"
    );
  };

  onFinish = (values) => {
    const { customer, companyList, selectedInvoices, mode, results, page } =
      this.state;

    let igst_per = globalVal?.igst_per || 0;
    let sgst_per = globalVal?.sgst_per || 0;
    let cgst_per = globalVal?.cgst_per || 0;

    let igst_amount =
      values.gst_type === "I"
        ? (values.credit_note_amount * igst_per) / 100
        : 0;

    let sgst_amount =
      values.gst_type === "C"
        ? (values.credit_note_amount * sgst_per) / 100
        : 0;

    let cgst_amount =
      values.gst_type === "C"
        ? (values.credit_note_amount * cgst_per) / 100
        : 0;

    let total_amount =
      values.credit_note_amount + igst_amount + sgst_amount + cgst_amount;

    let credit_note_no = "CN" + Math.floor(1000 + Math.random() * 9000);

    let credit_note_details = selectedInvoices.map((x) => ({
      invoice_id: x.invoice_id,
      invoice_no: x.invoice_no,
      invoice_amount: x.credit_note_amount,
    }));

    let journal_voucher_details = [];

    if (page === "journalvoucher") {
      values.journal_voucher_amount = values.credit_note_amount;
      values.journal_voucher_date = values.credit_note_date;
      journal_voucher_details = credit_note_details;
    }

    let data = {
      ...values,
      customer_id: customer?.customer_id,
      customer_name: customer?.customer_name,
      company_name: companyList.find(
        (x) => x.company_id === values["company_id"]
      )?.company_name,
      igst_per,
      igst_amount,
      total_amount,
      credit_note_no,
      cgst_amount,
      sgst_amount,
      credit_note_details,
      journal_voucher_details,
    };
    this.setState({ loading: true });

    page === "creditnote" &&
      mode === "Edit" &&
      (data.credit_note_id = results.credit_note_id);

    page === "journalvoucher" &&
      mode === "Edit" &&
      (data.journal_voucher_id = results.journal_voucher_id);

    let reqUrl =
      "salesestimate/" + page + (mode === "Edit" ? "/update" : "/create");
    let reqObj = {
      request: {
        ...data,
      },
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      if (data.success) {
        message.success("Credit Note Created successfully");
        this.handleBack();
      } else {
        message.error(
          data?.title
            ? "An error occured while processing your request."
            : data && data
        );
      }
    });
  };

  addManualInvoice = (values) => {
    const { customer, companyList } = this.state;

    let igst_per = globalVal?.igst_per || 0;
    let sgst_per = globalVal?.sgst_per || 0;
    let cgst_per = globalVal?.cgst_per || 0;
    let invoice_amount = 0;

    let start_date = values?.manual_inventory_details[0]?.start_date;
    let end_date = values?.manual_inventory_details[0]?.end_date;

    values?.manual_inventory_details.map(
      (x) => (
        (invoice_amount = invoice_amount + x?.manual_cost),
        x?.start_date &&
          (moment(start_date).diff(moment(x?.start_date), "days") > 0
            ? (start_date = x?.start_date)
            : start_date),
        x?.end_date &&
          (moment(x?.end_date).diff(moment(end_date), "days") > 0
            ? (end_date = x?.end_date)
            : end_date)
      )
    );

    values?.manual_inventory_details.map(
      (x) => (
        !x.start_date && delete x.start_date,
        !x.end_date && delete x.end_date,
        !x.monthly_rate && delete x.monthly_rate
      )
    );

    let no_of_days =
      start_date &&
      end_date &&
      moment(end_date).diff(moment(start_date), "days") + 1;

    let igst_amount =
      values.gst_type === "I" ? (invoice_amount * igst_per) / 100 : 0;

    let sgst_amount =
      values.gst_type === "C" ? (invoice_amount * sgst_per) / 100 : 0;

    let cgst_amount =
      values.gst_type === "C" ? (invoice_amount * cgst_per) / 100 : 0;

    let total_amount = invoice_amount + igst_amount + sgst_amount + cgst_amount;

    let data = {
      ...values,
      customer_id: customer?.customer_id,
      customer_name: customer?.customer_name,
      company_name: companyList.find(
        (x) => x.company_id === values["company_id"]
      )?.company_name,
      customer_code: customer?.customer_code,
      invoice_type: "M",
      duration_from: start_date,
      duration_to: end_date,
      due_date: end_date,
      no_of_days,
      employee_id: values?.employee_id,
      igst_per,
      cgst_per,
      sgst_per,
      igst_amount,
      cgst_amount,
      sgst_amount,
      total_display_cost: invoice_amount,
      invoice_amount: invoice_amount,
      total_amount,
      campaign_difference: 0,
      ha_markup: 0,
      ta_markup: 0,
      sac_code: globalVal?.sac_code,
    };

    !data.duration_from && delete data.duration_from;
    !data.duration_to && delete data.duration_to;
    !data.due_date && delete data.due_date;
    !data.no_of_days && delete data.no_of_days;

    this.setState({ loading: true });

    let reqUrl = "salesestimate/invoice/create";
    let reqObj = {
      request: {
        ...data,
      },
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      if (data.success) {
        message.success("Manual Invoice successfully");
        this.handleBack();
      } else {
        message.error(
          data?.title
            ? "An error occured while processing your request."
            : data && data
        );
      }
    });
  };
}

export default SalesEstimateBase;
