import React from "react";
import { Row, Col, Table, Button, Switch, Drawer, message } from "antd";
import { EditOutlined } from "@ant-design/icons";
import PageTitle from "../../components/common/page-title";
import ListFilters from "../../components/common/list-filters";
import PortalUser from "../../iam/components/portal-user";
import { dateFormatter } from "../../components/common/common-formatter";
import CommonListBase from "../../base/common-list-base";
import PhotoBucketUpdate from "../../components/photobucket/photobucket-update";

class PhotoBucketReviewer extends CommonListBase {
  state = {
    listReq: "photolibrary/reviewphoto/list?",
    delReq: "photolibrary/reviewphoto/list?",
    dtlReq: "photolibrary/reviewphoto/list?",
    data: [],
    pagination: {
      current: 1,
      pageSize: 20,
    },
    loading: false,
    columns: [],
    columnsDefault: [],
    filterColumns: [],
    sorting: [],
    record: "",
    selectedRowKeys: [],
    selectedRows: [],
    screen: "photobucket",
  };

  // Setting Columns
  setColumns = () => {
    let columns = [
      {
        title: "Type",
        dataIndex: "operation_photo_type",
        render: (text) =>
          text === "M"
            ? "Mounting"
            : text === "U"
            ? "Unmounting"
            : "Monitoring",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "IID",
        dataIndex: "inventory_code",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "District",
        dataIndex: "district",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "City",
        dataIndex: "city",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Location",
        dataIndex: "location",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Width",
        dataIndex: "width",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Height",
        dataIndex: "height",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "SQFT",
        dataIndex: "sqft",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Employee",
        dataIndex: "employee_id",
        render: (text) => <PortalUser user={text} />,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 140,
      },
      {
        title: "Display Name",
        dataIndex: "display_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Photographer",
        dataIndex: "photographer_id",
        render: (text) => text && <PortalUser user={text} />,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 140,
      },
      {
        title: "Arrival",
        dataIndex: "task_arrival_date",
        render: (text) => dateFormatter(text),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Actions",
        dataIndex: "",
        render: (record) => (
          <Button
            className="ant-btn-reset"
            icon={<EditOutlined />}
            onClick={() => this.handleReview(record)}
          />
        ),
      },
    ];

    const filterColumns = [
      {
        columnName: "general",
        displayName: "General",
        default: true,
        hideFromSettings: true,
      },
      {
        columnName: "operation_photo_type",
        displayName: "Type",
        hideFilter: true,
      },
      { columnName: "inventory_code", displayName: "IID" },
      { columnName: "district", displayName: "District" },
      { columnName: "city", displayName: "City" },
      { columnName: "location", displayName: "Location" },
      { columnName: "width", displayName: "Width" },
      { columnName: "height", displayName: "Height" },
      { columnName: "sqft", displayName: "SQFT" },
      { columnName: "employee_id", displayName: "Employee" },
      {
        columnName: "display_name",
        displayName: "Display Name",
        default: true,
      },
      { columnName: "photographer_id", displayName: "Photographer" },
      { columnName: "task_arrival_date", displayName: "Arrival" },
    ];

    this.setState({ columnsDefault: columns, filterColumns }, () =>
      this.setDefaultColumns(columns)
    );
  };

  // Handle Closed Switch
  handleToggle = (e) => {
    const { pagination } = this.state;

    this.setState(
      {
        listReq: e
          ? "photolibrary/reviewphoto/history?"
          : "photolibrary/reviewphoto/list?",
      },
      () => this.getResults({ pagination })
    );
  };

  handleReview = (record) => {
    this.setState({ record: record, isDrawer: !this.state.isDrawer });
  };

  // Handle Drawer
  handleDrawer = () => {
    this.setState({ isDrawer: !this.state.isDrawer });
  };

  getTitle = () => {
    const { record } = this.state;

    let title =
      record?.inventory_code +
      " - " +
      record?.city +
      ", " +
      record?.location +
      " - " +
      record?.width +
      "x" +
      record?.height +
      " - " +
      record?.display_name;

    return title;
  };

  refreshList = () => {
    message.success("Record updated successfully.");
    const { pagination } = this.state;
    this.getResults({ pagination });
  };

  updateRecords = () => {
    this.setState({ isDrawer: false }, () => this.refreshList());
  };

  callBack = () => {
    let { data } = this.state;
    data.map((x) => ({ ...x, uuid: this.generateUUID() }));
    this.setState({ data });
  };

  generateUUID = () => {
    let dt = new Date().getTime();
    let uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return uuid;
  };

  componentDidMount() {
    // Get List
    const { pagination } = this.state;
    this.getResults({ pagination });
    this.setColumns();
  }

  render() {
    const {
      data,
      columns,
      pagination,
      loading,
      filterColumns,
      selectedRowKeys,
      isDrawer,
      screen,
      record,
    } = this.state;

    const rowSelection = {
      selectedRowKeys,
      preserveSelectedRowKeys: true,
      onChange: this.onSelectChange,

      getCheckboxProps: (record) => ({
        disabled: record.is_read,
        is_read: record.is_read,
      }),
    };

    return (
      <React.Fragment>
        <Row>
          <Col span={24}>
            <PageTitle titleText="Reviewer Photolibrary" />

            <div
              style={{
                top: "-64px",
                float: "right",
                marginBottom: "-44px",
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
              class="title-btns title-btns-photobucket"
            >
              <Switch size="small" onChange={this.handleToggle}></Switch>
              <label style={{ marginLeft: "8px", marginRight: "16px" }}>
                History
              </label>
            </div>

            <ListFilters
              items={filterColumns}
              handleSettings={this.handleSettings}
              handleFilters={this.handleFilters}
              key={filterColumns}
              screen={screen}
            />

            <Table
              columns={columns}
              rowKey={(record) => record?.uuid}
              dataSource={data}
              pagination={pagination}
              loading={loading}
              onChange={this.handleTableChange}
              bordered
              scroll={{ x: 400 }}
              size="small"
            />
          </Col>
        </Row>

        <Drawer
          title={this.getTitle()}
          placement="right"
          visible={isDrawer}
          onClose={this.handleDrawer}
          width={"88%"}
        >
          <PhotoBucketUpdate
            key={isDrawer}
            {...record}
            updateRecords={this.updateRecords}
          />
        </Drawer>
      </React.Fragment>
    );
  }
}

export default PhotoBucketReviewer;
