import React, { Component } from "react";
import { Form, Button, DatePicker, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import moment from "moment";
import AddEditOperationsPhotos from "./add-edit-operations-photos";

class AddMounting extends Component {
  state = {};

  formRef = React.createRef();

  onFinish = (values) => {
    this.props.handleAddMounting(values);
  };

  disabledDate = (current) => {
    return current && current > moment();
  };

  setData = () => {
    let data = this?.props?.editRecord;

    let mounting_date =
      this?.props?.modalAction === "edit-mounting"
        ? data?.mounting_date
        : this?.props?.modalAction === "edit-monitoring"
        ? data?.monitoring_performed_date
        : data?.unmounting_date;

    this.formRef.current.setFieldsValue({
      mounting_date: mounting_date && moment(mounting_date),
    });
  };

  componentDidMount() {
    (this?.props?.modalAction === "edit-mounting" ||
      this?.props?.modalAction === "edit-unmounting-photo" ||
      this?.props?.modalAction === "edit-monitoring") &&
      this.setData();
  }

  render() {
    const { modalTitle, modalAction, page, selectedRows } = this.props;
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };

    return (
      <Form
        name="operation-modal"
        {...layout}
        onFinish={this.onFinish}
        ref={this.formRef}
      >
        <Form.Item
          name="mounting_date"
          label={
            page === "pending-unmounting-photo"
              ? "Unmounting Date"
              : page === "monitoring"
              ? "Monitoring Date"
              : "Mounting Date"
          }
        >
          <DatePicker
            disabled={
              this?.props?.modalAction === "edit-mounting" ? true : false
            }
            inputReadOnly={true}
            disabledDate={this.disabledDate}
          />
        </Form.Item>

        {(this.props.selectedRowKeys.length === 1 ||
          modalAction === "edit-mounting" ||
          modalAction === "edit-unmounting-photo") && (
          <AddEditOperationsPhotos
            selectedId={
              modalAction !== "edit-mounting"
                ? modalAction !== "edit-unmounting-photo"
                  ? this.props.selectedRowKeys[0]
                  : this.props.editRecord.operation_id
                : this.props.editRecord.operation_id
            }
            selectedRows={selectedRows[0]}
            modalAction={modalAction}
          />
        )}

        <Form.Item
          wrapperCol={{ offset: 8 }}
          style={{ marginTop: "16px", marginBottom: "0px" }}
          className="model-btns"
        >
          <Button type="primary" htmlType="submit">
            {modalTitle}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default AddMounting;
