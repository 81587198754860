import moment from "moment";

export const currencyFormatter = (currency) => {
  let x = Number(currency);
  x = x.toString();
  let lastThree = x.substring(x.length - 3);
  let otherNumbers = x.substring(0, x.length - 3);
  if (otherNumbers !== "") lastThree = "," + lastThree;
  let res =
    x.length > 3
      ? !x.includes("-")
        ? otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree
        : x.length > 4
        ? otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree
        : x
      : x;
  return res;
};

export const dateFormatter = (date) => {
  let res = moment(date).format("DDMMMYY");
  return res;
};

export const timeFormatter = (date) => {
  let res = moment(date).format("h:mm a");
  return res;
};
