import React, { Component } from "react";
import { Button, Collapse, List } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import UploadPhotos from "../common/upload-photos";
import { ajaxRequester } from "../../services/ajax-requester";

class AddEditOperationsPhotos extends Component {
  state = {};

  handleRemove = (item) => {
    const { page } = this.state;
    var reqUrl =
      "operation/" +
      page +
      "/photo/delete?operationMediaId=" +
      item.operation_media_id +
      "&photoId=" +
      item.id;
    var reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      () => {
        this.getPhotoDetails();
      },
      "DELETE"
    );
  };

  getPhotoDetails = () => {
    const { selectedId, page } = this.state;
    let reqUrl =
      "operation/" +
      page +
      "/detail?" +
      (page !== "monitoring" ? "operationId=" : "operationMonitoringId=") +
      selectedId;
    let reqObj = "";

    if (selectedId) {
      ajaxRequester(
        reqUrl,
        reqObj,
        (data) => {
          let existingPhotos = "";

          switch (page) {
            case "mounting":
              existingPhotos = data?.response?.mounting_photos;
              break;

            case "unmounting":
              existingPhotos = data?.response?.unmounting_photos;
              break;

            case "monitoring":
              existingPhotos = data?.response?.monitoring_photos;
              break;

            default:
              break;
          }

          this.setState({ existingPhotos });
        },
        "GET"
      );
    }
  };

  updateData = () => {
    this.getPhotoDetails();
  };

  viewDocument = (item) => {
    window.open(item.data_url);
  };

  getPage = () => {
    const { modalAction } = this.state;

    let page = "";

    switch (modalAction) {
      case "add-mounting":
        page = "mounting";
        break;

      case "edit-mounting":
        page = "mounting";
        break;

      case "add-unmounting-photo":
        page = "unmounting";
        break;

      case "edit-unmounting-photo":
        page = "unmounting";
        break;

      case "add-monitoring":
        page = "monitoring";
        break;

      case "edit-monitoring":
        page = "monitoring";
        break;

      case "add-mounting-unmounting":
        page = this?.props?.currentTab;
        break;

      case "edit-mounting-unmounting":
        page = this?.props?.currentTab;
        break;

      default:
        break;
    }

    this.setState({ page }, () => this.getPhotoDetails());
  };

  componentDidMount() {
    const { selectedId, modalAction, selectedRows } = this.props;
    this.setState({ selectedId, modalAction, selectedRows }, () =>
      this.getPage()
    );
  }

  render() {
    const { existingPhotos, selectedId, page, selectedRows } = this.state;
    const { Panel } = Collapse;

    let uploadPath = "operation/" + page + "/photo/upload";
    let deletePath = "operation/" + page + "/photo/delete";

    let newspaper_photos = [];
    let long_photos = [];
    let short_photos = [];

    existingPhotos &&
      existingPhotos.map((x) => {
        return (
          x.photo_category === "N" && newspaper_photos.push(x),
          x.photo_category === "L" && long_photos.push(x),
          x.photo_category === "S" && short_photos.push(x)
        );
      });

    let newspaper_count = newspaper_photos?.length || 0;
    let long_count = long_photos?.length || 0;
    let short_count = short_photos?.length || 0;

    return (
      <div>
        <Collapse defaultActiveKey={["1"]} accordion>
          <Panel header={"Newspaper (" + newspaper_count + ")"} key="1">
            <UploadPhotos
              id={selectedId}
              category={"N"}
              uploadPath={uploadPath}
              deletePath={deletePath}
              updateData={(item) => this.updateData(item, "email_confirmation")}
              selectedRows={selectedRows}
              page={page}
            />
            {newspaper_photos && newspaper_photos.length > 0 && (
              <List
                dataSource={newspaper_photos}
                renderItem={(item) => (
                  <List.Item>
                    <Button
                      type="link"
                      className="ant-btn-reset"
                      onClick={() => this.viewDocument(item)}
                      style={{ width: "calc(100% - 48px)" }}
                    >
                      <span
                        style={{
                          width: "100%",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          float: "left",
                          textAlign: "left",
                        }}
                      >
                        {item.name}
                      </span>
                    </Button>
                    <Button
                      style={{ float: "right" }}
                      icon={<DeleteOutlined />}
                      size="small"
                      className="ant-btn-reset"
                      onClick={() => this.handleRemove(item)}
                    />
                  </List.Item>
                )}
                size="small"
                style={{ marginTop: "16px" }}
              />
            )}
          </Panel>

          <Panel header={"Long (" + long_count + ")"} key="2">
            <UploadPhotos
              id={selectedId}
              document_type={2}
              category={"L"}
              uploadPath={uploadPath}
              deletePath={deletePath}
              updateData={(item) => this.updateData(item, "purchase_order")}
              selectedRows={selectedRows}
              page={page}
            />
            {long_photos && long_photos.length > 0 && (
              <List
                dataSource={long_photos}
                renderItem={(item) => (
                  <List.Item>
                    <Button
                      type="link"
                      className="ant-btn-reset"
                      onClick={() => this.viewDocument(item)}
                      style={{ width: "calc(100% - 48px)" }}
                    >
                      <span
                        style={{
                          width: "100%",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          float: "left",
                          textAlign: "left",
                        }}
                      >
                        {item.name}
                      </span>
                    </Button>
                    <Button
                      style={{ float: "right" }}
                      icon={<DeleteOutlined />}
                      size="small"
                      className="ant-btn-reset"
                      onClick={() => this.handleRemove(item)}
                    />
                  </List.Item>
                )}
                size="small"
                style={{ marginTop: "16px" }}
              />
            )}
          </Panel>

          <Panel header={"Short (" + short_count + ")"} key="3">
            <UploadPhotos
              id={selectedId}
              category={"S"}
              uploadPath={uploadPath}
              deletePath={deletePath}
              updateData={(item) => this.updateData(item, "others")}
              selectedRows={selectedRows}
              page={page}
            />

            {short_photos && short_photos.length > 0 && (
              <List
                dataSource={short_photos}
                renderItem={(item) => (
                  <List.Item>
                    <Button
                      type="link"
                      className="ant-btn-reset"
                      onClick={() => this.viewDocument(item)}
                      style={{ width: "calc(100% - 48px)" }}
                    >
                      <span
                        style={{
                          width: "100%",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          float: "left",
                          textAlign: "left",
                        }}
                      >
                        {item.name}
                      </span>
                    </Button>
                    <Button
                      style={{ float: "right" }}
                      icon={<DeleteOutlined />}
                      size="small"
                      className="ant-btn-reset"
                      onClick={() => this.handleRemove(item)}
                    />
                  </List.Item>
                )}
                size="small"
                style={{ marginTop: "16px" }}
              />
            )}
          </Panel>
        </Collapse>
      </div>
    );
  }
}

export default AddEditOperationsPhotos;
