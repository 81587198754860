import React, { Component } from "react";
import {
  Form,
  Input,
  InputNumber,
  Button,
  Row,
  Col,
  Select,
  message,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";

class InventoryAddBaseInventory extends Component {
  state = {};

  formRef = React.createRef();

  setDefaultData = () => {
    this.formRef.current.setFieldsValue({
      side_type: "single",
      quantity: 1,
    });
  };

  setData = () => {
    const data = this.props.haBaseMode;

    this.formRef.current.setFieldsValue({
      side_type: data.side_type,
      quantity: data.quantity,
      width: data.width,
      height: data.height,
      sqft: data.sqft,
      light_type: data.light_type,
      base_rate: data.base_rate,
      card_rate: data.card_rate,
      description: data.description,
      ha_inventory_id: data?.ha_inventory_id,
      inventory_code: data?.inventory_code,
      uuid: data?.uuid,
    });
  };

  onFinish = (values) => {
    !this.props.haBaseMode && (values.uuid = this.generateUUID());
    this.props.addBaseInventory(values);
    this.success();
  };

  success = () => {
    message.success("Base Inventory added successfully.");
  };

  ValuesChange = (value, values) => {
    if (
      value["width"] ||
      value["height"] ||
      value["quantity"] ||
      value["side_type"]
    ) {
      let total =
        (values["width"] || 1) *
        (values["height"] || 1) *
        (values["quantity"] || 1) *
        (values["side_type"] === "double" ? 2 : 1);
      this.formRef.current.setFieldsValue({ sqft: total });
    }
  };

  generateUUID = () => {
    let dt = new Date().getTime();
    let uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return uuid;
  };

  componentDidMount() {
    this.setDefaultData();
    const haBaseMode = this.props.haBaseMode;
    haBaseMode && this.setData();
  }

  render() {
    const { Option } = Select;

    const { haBaseMode, sideTypeList, lightTypeList, inventoryType } =
      this.props;

    const layout = {
      labelCol: { span: 10 },
      wrapperCol: { span: 14 },
    };

    const layout2Col = {
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
    };

    return (
      <Form
        {...layout}
        name="baseInventoryAddForm"
        onFinish={this.onFinish}
        ref={this.formRef}
        initialValues={{}}
        onValuesChange={this.ValuesChange}
      >
        <Row>
          <Col span="10" xs={24} xl={10}>
            <Form.Item
              label="Side Type"
              name="side_type"
              rules={[{ required: true, message: "Select Side Type!" }]}
            >
              {sideTypeList && (
                <Select placeholder="Side Type">
                  {sideTypeList.map((x) => (
                    <Option key={x.side_type_id} value={x.side_type_id}>
                      {x.side_type}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>

          <Col span="10" xs={24} xl={10}>
            <Form.Item
              label="Quantity"
              name="quantity"
              rules={[{ required: true, message: "Input Quantity!" }]}
            >
              <InputNumber
                min={0}
                style={{ width: "100%" }}
                placeholder="Quantity"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span="10" xs={24} xl={10}>
            <Form.Item
              label="Width"
              name="width"
              rules={[{ required: true, message: "Input Width!" }]}
            >
              <InputNumber
                style={{ width: "100%" }}
                placeholder="Width"
                min={0}
              />
            </Form.Item>
          </Col>

          <Col span="10" xs={24} xl={10}>
            <Form.Item
              label="Height"
              name="height"
              rules={[{ required: true, message: "Input Height!" }]}
            >
              <InputNumber
                style={{ width: "100%" }}
                placeholder="Height"
                min={0}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span="10" xs={24} xl={10}>
            <Form.Item label="SQFT" name="sqft">
              <Input
                disabled
                style={{
                  background: "rgba(0, 0, 0, 0.02)",
                  color: "rgba(0, 0, 0, 0.85)",
                }}
              />
            </Form.Item>
          </Col>

          <Col span="10" xs={24} xl={10}>
            <Form.Item
              label="Light Type"
              name="light_type"
              rules={[{ required: true, message: "Select Light Type!" }]}
            >
              {lightTypeList && (
                <Select placeholder="Select Light Type">
                  {lightTypeList.map((x) => (
                    <Option key={x.light_type_id} value={x.light_type_id}>
                      {x.light_type}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          {inventoryType !== "tainventory" && (
            <Col span="10" xs={24} xl={10}>
              <Form.Item
                label="Base Rate"
                name="base_rate"
                rules={[{ required: true, message: "Input Base Rate!" }]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  placeholder="Base Rate"
                  min={0}
                />
              </Form.Item>
            </Col>
          )}

          {inventoryType === "tainventory" && (
            <Col span="10" xs={24} xl={10}>
              <Form.Item label="Best Rate" name="base_rate">
                <InputNumber
                  style={{ width: "100%" }}
                  placeholder="Best Rate"
                  disabled
                />
              </Form.Item>
            </Col>
          )}

          <Col span="10" xs={24} xl={10}>
            <Form.Item
              label="Card Rate"
              name="card_rate"
              rules={[{ required: true, message: "Input Card Rate!" }]}
            >
              <InputNumber
                style={{ width: "100%" }}
                placeholder="Card Rate"
                min={0}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span="20" xs={24} xl={20}>
            <Form.Item
              label="Description"
              name="description"
              {...layout2Col}
              rules={[{ required: true, message: "Input Description!" }]}
            >
              <Input.TextArea placeholder="Description" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item name="ha_inventory_id" hidden>
          <Input />
        </Form.Item>

        <Form.Item name="inventory_code" hidden>
          <Input />
        </Form.Item>

        <Form.Item name="uuid" hidden>
          <Input />
        </Form.Item>

        <Form.Item
          style={{ textAlign: "center" }}
          wrapperCol={{ span: 24 }}
          className="model-btns"
        >
          <Button type="primary" icon={<PlusOutlined />} htmlType="submit">
            {haBaseMode ? "Update" : "Add"} Base Inventory
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default InventoryAddBaseInventory;
