import React, { Component } from "react";
import { Button, Select, Form, DatePicker, message } from "antd";
import { ajaxRequester } from "../../services/ajax-requester";
import moment from "moment";
import { exportOperationsToXlsx } from "../../components/operations/operations-to-xlsx";

class ExportOperations extends Component {
  state = {
    cityList: [],
    mediaTypeList: [],
    isDownloading: false,
  };

  formRef = React.createRef();

  onFinish = (values) => {
    let filters = "";

    filters =
      filters +
      (values?.jobDateFrom
        ? "&jobDateFrom=" + moment(values?.jobDateFrom).format("YYYY-MM-DD")
        : "") +
      (values?.jobDateTo
        ? "&jobDateTo=" + moment(values?.jobDateTo).format("YYYY-MM-DD")
        : "") +
      (values?.media_type ? "&mediaTypeId=" + values?.media_type : "") +
      (values?.city ? "&city=" + values?.city : "");

    filters && (filters = filters.replace("&", ""));

    this.getMountingUnmountingList(filters);
  };

  // Get Mounting List
  getMountingUnmountingList = (filters) => {
    this.setState({ isDownloading: true });

    var reqUrl =
      "operation/" +
      (this?.props?.screen === "mounting-unmounting"
        ? "advancedpendingmountingunmounting"
        : "advancedmountingunmounting/history") +
      "/export?" +
      filters;
    var reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ isDownloading: false });

        if (data?.response && data?.response?.length > 0) {
          this.handleDownload(data?.response || []);
        } else {
          message.error("No Data to Export.");
        }
      },
      "GET"
    );
  };

  handleDownload = (data) => {
    let inventory_details = data;
    let exportColumns = [
      "Sr",
      "Location",
      "SQFT",
      "Light",
      "Qty",
      "Display Qty",
      "Unmount Display",
      "Mount Display",
      "Assigned Mounter",
      "Job Date",
      "Operations Status",
    ];

    if (this?.props?.screen !== "mounting-unmounting") {
      exportColumns.pop();
    }

    exportOperationsToXlsx(
      "Job Sheet",
      inventory_details,
      {
        exportName: `${"Job Sheet"} - ${moment().format("Do MMMM YYYY")}.xlsx`,
      },
      exportColumns
    );
  };

  ValuesChange = (value, values) => {};

  getCityList = () => {
    var reqUrl = "city/list?pageno=1&pagesize=9999";
    var reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ cityList: data?.response || [] });
      },
      "GET"
    );
  };

  getMediaTypeList = () => {
    var reqUrl = "lookup/mediatype";
    var reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ mediaTypeList: data?.response || [] });
      },
      "GET"
    );
  };

  componentDidMount() {
    this.getCityList();
    this.getMediaTypeList();
  }

  render() {
    const layout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 12 },
    };
    const { cityList, mediaTypeList, isDownloading } = this.state;
    const { Option } = Select;

    return (
      <div>
        <Form
          name="operation_report"
          {...layout}
          onFinish={this.onFinish}
          ref={this.formRef}
          className="operation_report"
          onValuesChange={this.ValuesChange}
        >
          <Form.Item name="jobDateFrom" label="From">
            <DatePicker inputReadOnly={true} />
          </Form.Item>

          <Form.Item name="jobDateTo" label="To">
            <DatePicker inputReadOnly={true} />
          </Form.Item>

          <Form.Item name="media_type" label="Media Type">
            <Select placeholder="Media Type" allowClear={true}>
              {mediaTypeList.map((x) => (
                <Option key={x.media_type_id} value={x.media_type_id}>
                  {x.media_type}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="city" label="City">
            <Select placeholder="City" allowClear={true} showSearch={true}>
              {cityList.map((x) => (
                <Option key={x.city_name} value={x.city_name}>
                  {x.city_name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8 }} className="model-btns">
            <Button type="primary" htmlType="submit" loading={isDownloading}>
              Download
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default ExportOperations;
