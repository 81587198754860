import React from "react";
import PageTitleDetails from "../../components/common/page-title-detail";
import { Row, Col } from "antd";
import CustomerAddForm from "../../components/customers/customer-add-form";
import CustomersBase from "../../base/customers-base";

class CustomerAdd extends CustomersBase {
  state = {
    isSuccess: false,
    customerKey: 1,
    assignedEmployeeList: "",
    segmentList: "",
    designationList: "",
    emailnotificationsList: "",
  };

  componentDidMount() {
    this.getLookupData();
  }

  render() {
    const { customerKey } = this.state;

    return (
      <Row>
        <Col span={24}>
          <PageTitleDetails
            titleText="Add Customer"
            isBackBtn="true"
            handleBack={this.handleBack}
          />
          <CustomerAddForm
            {...this.state}
            key={customerKey}
            handleAddCustomers={this.handleAddCustomers}
            handleBack={this.handleBack}
            handleUploadLogoReq={this.handleUploadLogoReq}
          />
        </Col>
      </Row>
    );
  }
}

export default CustomerAdd;
