import React from "react";
import { Form, Button, Space, Input } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import UserPickerDropDown from "../../iam/components/user-picker-dropdown";

const CustomerAddEmployees = (props) => {
  return (
    <Form.List name="assigned_employee">
      {(fields, { add, remove }) => {
        return (
          <div>
            {fields.map((field) => (
              <Space key={field.key}>
                <Form.Item
                  {...field}
                  name={[field.name, "employee_id"]}
                  fieldKey={[field.fieldKey, "employee_id"]}
                  label="Assigned Employee"
                >
                  <UserPickerDropDown
                    user={
                      props?.results?.assigned_employee[field.key]?.employee_id
                    }
                  />
                </Form.Item>

                <Form.Item
                  {...field}
                  name={[field.name, "assigned_employee_id"]}
                  fieldKey={[field.fieldKey, "assigned_employee_id"]}
                  label="Assigned Employee Id"
                  hidden="true"
                >
                  <Input placeholder="Assigned Employee Id" />
                </Form.Item>

                {fields.length > 1 ? (
                  <MinusCircleOutlined
                    className="dynamic-delete-button"
                    onClick={() => {
                      remove(field.name);
                    }}
                  />
                ) : null}
              </Space>
            ))}

            <Form.Item
              {...{ wrapperCol: { offset: 4, span: 10 } }}
              className="fields-list-btn"
            >
              <Button
                type="dashed"
                onClick={() => {
                  add();
                }}
              >
                <PlusOutlined /> Add Another Employee
              </Button>
            </Form.Item>
          </div>
        );
      }}
    </Form.List>
  );
};

export default CustomerAddEmployees;
