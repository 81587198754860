import React, { Component } from "react";
import { Form, Input, Select, Button, Switch, Row, Col, Modal } from "antd";
import {
  UserAddOutlined,
  CheckOutlined,
  CloseOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import PointOfContactLog from "./pointofcontact-log";

class PointOfContactAdd extends Component {
  state = { loading: false, isActivityLog: false };
  formRef = React.createRef();

  // Set Data in case of Edit Mode
  setData = () => {
    const data = this.props.editMode;
    this.formRef.current.setFieldsValue({
      contact_name: data.contact_name,
      phone_no: data.phone_no,
      email: data.email,
      designation_id: data.designation_id,
      customer_id: data.customer_id,
      notes: data.notes,
      is_phone_verified: data.is_phone_verified,
      is_email_verified: data.is_email_verified,
      customer_contact_id: data.customer_contact_id,
    });
  };

  onFinish = (values) => {
    this.setState({ loading: true });

    const editMode = this.props.editMode;
    !editMode && this.props.handleAdd(values);
    editMode && this.props.handleUpdate(values);
  };

  onFinishFailed = () => {
    //this.warning();
  };

  handleActivityLog = () => {
    this.setState({ isActivityLog: !this.state.isActivityLog });
  };

  componentDidMount() {
    const editMode = this.props.editMode;
    editMode && this.setData();
  }

  // componentDidUpdate(prevProps) {
  //   if (this.props.isError !== prevProps.isError) {
  //     this.setState({ loading: false });
  //   }
  // }

  render() {
    const { loading, isActivityLog } = this.state;
    const { designationList, mappedCustomerlist, editMode } = this.props;
    const { Option } = Select;

    const layout = {
      labelCol: { span: 7 },
      wrapperCol: { span: 17 },
    };

    const tailLayout = {
      wrapperCol: { span: 24 },
    };
    return (
      <React.Fragment>
        <Form
          name="pointofcontact_add"
          {...layout}
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
          ref={this.formRef}
          className="pointofcontact_add"
        >
          <Form.Item
            name="contact_name"
            label="Name"
            rules={[{ required: true, message: "Please input Name!" }]}
          >
            <Input placeholder="Name" />
          </Form.Item>

          <Row>
            <Col span="24">
              <Form.Item
                name="phone_no"
                label="Number"
                // rules={[{ required: true, message: "Please input Number!" }]}
              >
                <Input placeholder="Number" />
              </Form.Item>

              {editMode && (
                <Form.Item
                  name="is_phone_verified"
                  className="is_phone_verified"
                  {...tailLayout}
                >
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    defaultChecked={editMode?.is_phone_verified}
                  />
                </Form.Item>
              )}
            </Col>
          </Row>

          <Row>
            <Col span="24">
              <Form.Item
                name="email"
                label="Email"
                // rules={[
                //   {
                //     required: true,
                //     type: "email",
                //     message: "Please input Valid Email!",
                //   },
                // ]}
              >
                <Input placeholder="Email" />
              </Form.Item>

              {editMode && (
                <Form.Item
                  name="is_email_verified"
                  className="is_email_verified"
                  {...tailLayout}
                >
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    defaultChecked={editMode?.is_email_verified}
                  />
                </Form.Item>
              )}
            </Col>
          </Row>

          <Form.Item
            label="Designation"
            name="designation_id"
            rules={[{ required: true, message: "Please select Designation!" }]}
          >
            {designationList && (
              <Select placeholder="Select Designation">
                {designationList.map((x) => (
                  <Option key={x.designation_id} value={x.designation_id}>
                    {x.designation}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <Form.Item
            label="Mapped Customer"
            name="customer_id"
            rules={[
              { required: true, message: "Please select Mapped Customer!" },
            ]}
          >
            {mappedCustomerlist && (
              <Select placeholder="Select Mapped Customer">
                {mappedCustomerlist.map((x) => (
                  <Option key={x.customer_id} value={x.customer_id}>
                    {x.customer_name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <Form.Item name="notes" label="Notes">
            <Input.TextArea placeholder="Notes" />
          </Form.Item>

          <Form.Item
            name="customer_contact_id"
            label="Customer Contact Id"
            hidden="true"
          >
            <Input placeholder="Customer Contact Id" />
          </Form.Item>

          <Form.Item
            //className="ant-text-center"
            // style={{ marginTop: "24px" }}
            wrapperCol={{ offset: 7 }}
            className="model-btns"
          >
            <Button
              type="primary"
              icon={<UserAddOutlined />}
              htmlType="submit"
              loading={loading}
            >
              {!editMode ? "Add" : "Update"} POC
            </Button>
            {editMode && (
              <Button
                onClick={this.handleActivityLog}
                icon={<UnorderedListOutlined />}
                style={{ marginLeft: "16px" }}
              >
                Activity Log
              </Button>
            )}
          </Form.Item>
        </Form>

        {editMode && (
          <Modal
            title={"Activity Log"}
            visible={isActivityLog}
            onCancel={() => this.setState({ isActivityLog: false })}
            footer={null}
            centered
          >
            <PointOfContactLog {...editMode} />
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

export default PointOfContactAdd;
