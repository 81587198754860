import React, { Component } from "react";
import { ajaxRequester } from "../services/ajax-requester";
import { Table, Button, Popconfirm, message, Badge } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  FileImageOutlined,
  BulbTwoTone,
} from "@ant-design/icons";
import {
  dateFormatter,
  timeFormatter,
} from "../components/common/common-formatter";
import PortalUser from "../iam/components/portal-user";
import AdminApi from "../iam/api/admin-api";

class InventoriesListBase extends Component {
  state = {};

  // Get Inventories List
  getInventories = (params = {}) => {
    this.setState({ loading: true });
    let reqUrl =
      this.state.inventoryType +
      (this.state.pageName ? "/" + this.state.pageName : "/list");

    let reqObj = {
      request: this.getReqParams(params),
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      this.setState(
        {
          loading: false,
          data: data.response,
          pagination: {
            ...params.pagination,
            total: data.page_info.total_records,
          },
          mediaTypeList: data.mediaTypeList,
          navKey: this.state.navKey + 1,
          filters: params.filters ? params.filters : this.state.filters,
          sortField: params.sortField ? params.sortField : this.state.sortField,
          sortOrder: params.sortOrder ? params.sortOrder : this.state.sortOrder,
          advancedFilters: reqObj.request,
        },
        () => this.callBack()
      );
    });
  };

  // Getting Request Parameters
  getReqParams = (params) => {
    let reqParams = {};

    const {
      column_name,
      column_value,
      column_media_type,
      historical_purchase,
    } = this.state.appliedFilters || "";

    reqParams = {
      page_no: params.pagination.current,
      page_size: this.state?.isMapview ? 5000 : params.pagination.pageSize,
      column_name,
      column_value,
      column_media_type,
      sort_column: params.sortField,
      sort_order:
        params.sortOrder && (params.sortOrder === "ascend" ? "1" : "-1"),
      historical_purchase,
    };

    let advanced_filter = this.state.advanced_filter;
    if (advanced_filter) {
      reqParams = { ...reqParams, ...advanced_filter };
    }

    return reqParams;
  };

  // Setting Columns
  setColumns = () => {
    let columns = [
      {
        title: "Media",
        dataIndex: "media_type",
        render: (text) => <span style={{ whiteSpace: "nowrap" }}>{text}</span>,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "IID",
        dataIndex: "inventory_code",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text, record) => (
          <Button
            style={{ padding: "0px" }}
            type="link"
            onClick={() => this.detailsInventory(record)}
          >
            {text}
          </Button>
        ),
      },
      {
        title: "City",
        dataIndex: "city",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Area",
        dataIndex: "area",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Location",
        dataIndex: "location",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text, record) =>
          text +
          (record?.quantity > 1
            ? " (Qty : " +
              record?.quantity +
              " / Display : " +
              (record?.side_type === "double"
                ? record?.quantity * 2
                : record?.quantity) +
              ")"
            : ""),
      },
      {
        title: "Size",
        dataIndex: "size",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "SQFT",
        dataIndex: "sqft",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Light",
        dataIndex: "light_type",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Qty",
        dataIndex: "quantity",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Photo",
        dataIndex: "ha_images",
        render: (text, record) => (
          <Button
            className="ant-btn-reset"
            disabled={record?.ha_images?.length > 0 ? false : true}
            icon={<FileImageOutlined />}
            onClick={() => this.handlePhotos(record)}
          />
        ),
      },
      {
        title: "Card Rate",
        dataIndex: "card_rate",
        render: (text) => this.currencyFormatter(text),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Log",
        dataIndex: "is_inventory_status_read",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text, record) => (
          <Button
            className="ant-btn-reset"
            onClick={() => this.statusInventory(record)}
          >
            {text ? (
              <span style={{ whiteSpace: "nowrap" }}>
                <BulbTwoTone twoToneColor="#cccccc" /> Read
              </span>
            ) : (
              <span style={{ whiteSpace: "nowrap" }}>
                <BulbTwoTone twoToneColor="#fadb14" /> Unread
              </span>
            )}
          </Button>
        ),
      },
      {
        title: "Booking Status",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        dataIndex: "booking_status",

        render: (text, record) => (
          <Button
            className="ant-btn-reset"
            type="link"
            onClick={() => this.statusInventory(record, "booking")}
          >
            {text === "A" ? (
              <Badge
                count={record?.total_blocked_count}
                size="small"
                offset={[8, 0]}
                style={{ background: "none", color: "#2db7f5" }}
                className="ant-btn-link"
              >
                Available
              </Badge>
            ) : text === "B" ? (
              <Badge
                count={record?.total_blocked_count}
                size="small"
                offset={[8, 0]}
                style={{
                  background: "none",
                  color: "#2db7f5",
                  marginTop: "-4px",
                  marginRight: "4px",
                }}
                className="ant-btn-link"
              >
                Blocked till{" "}
                {dateFormatter(record.blocked_till_date) +
                  " " +
                  timeFormatter(record.blocked_till_date)}
              </Badge>
            ) : (
              <Badge
                count={record?.total_blocked_count}
                size="small"
                offset={[8, 0]}
                style={{ background: "none", color: "#2db7f5" }}
                className="ant-btn-link"
              >
                Not Available
              </Badge>
            )}
          </Button>
        ),
      },
      {
        title: "Actions",
        dataIndex: "",
        key: "x",
        render: (record) => (
          <React.Fragment>
            <Button
              onClick={() => this.editInventory(record)}
              icon={<EditOutlined />}
              className="ant-btn-reset"
            />
            <Popconfirm
              title="Are you sure delete?"
              placement="left"
              okText="Yes"
              cancelText="No"
              onConfirm={() => this.deleteInventory(record)}
            >
              <Button
                icon={<DeleteOutlined />}
                className="ant-btn-reset"
                style={{ marginLeft: "12px" }}
              />
            </Popconfirm>
          </React.Fragment>
        ),
      },
    ];

    const filterColumns = [
      {
        columnName: "general",
        displayName: "General",
        default: true,
        hideFromSettings: true,
      },
      { columnName: "media_type", displayName: "Media" },
      { columnName: "inventory_code", displayName: "IID" },
      { columnName: "city", displayName: "City" },
      { columnName: "area", displayName: "Area" },
      { columnName: "location", displayName: "Location" },
      { columnName: "size", displayName: "Size" },
      { columnName: "sqft", displayName: "SQFT" },
      { columnName: "light_type", displayName: "Light" },
      { columnName: "quantity", displayName: "Qty" },
      { columnName: "images", displayName: "Photo" },
      { columnName: "card_rate", displayName: "Card Rate" },
      { columnName: "is_inventory_status_read", displayName: "Log" },
      { columnName: "booking_status", displayName: "Booking Status" },
      { columnName: "campaign_start_date", displayName: "Site Start Date" },
      { columnName: "campaign_end_date", displayName: "Site End Date" },
    ];

    if (this.state.inventoryType === "tainventory") {
      columns.splice(0, 0, {
        title: "Supplier",
        dataIndex: "supplier_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      });

      filterColumns.splice(1, 0, {
        columnName: "supplier_name",
        displayName: "Supplier",
      });
    }

    this.setState({ columnsDefault: columns, filterColumns }, () =>
      this.setDefaultColumns(columns)
    );
  };

  // Setting Columns
  setColumnsMaster = () => {
    let columns = [
      {
        title: "Type",
        dataIndex: "inventory_type",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Media",
        dataIndex: "media_type",
        render: (text) => <span style={{ whiteSpace: "nowrap" }}>{text}</span>,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "IID",
        dataIndex: "inventory_code",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text, record) => (
          <Button
            style={{ padding: "0px" }}
            type="link"
            onClick={() => this.detailsInventory(record)}
          >
            {text}
          </Button>
        ),
      },
      {
        title: "State",
        dataIndex: "state",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "District",
        dataIndex: "district",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "City",
        dataIndex: "city",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Area",
        dataIndex: "area",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Location",
        dataIndex: "location",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text, record) =>
          text +
          (record?.quantity > 1
            ? " (Qty : " +
              record?.quantity +
              " / Display : " +
              (record?.side_type === "double"
                ? record?.quantity * 2
                : record?.quantity) +
              ")"
            : ""),
      },
      {
        title: "Lat",
        dataIndex: "lat",
        render: (text, record) => record?.lat_long?.x,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Long",
        dataIndex: "long",
        render: (text, record) => record?.lat_long?.y,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Traffic From",
        dataIndex: "traffic_facing_from",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Traffic To",
        dataIndex: "traffic_going_to",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },

      {
        title: "Width",
        dataIndex: "width",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Height",
        dataIndex: "height",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Size",
        dataIndex: "size",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "SQFT",
        dataIndex: "sqft",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Light",
        dataIndex: "light_type",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Qty",
        dataIndex: "quantity",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Photo",
        dataIndex: "ha_images",
        render: (text, record) => (
          <Button
            className="ant-btn-reset"
            disabled={record?.ha_images?.length > 0 ? false : true}
            icon={<FileImageOutlined />}
            onClick={() => this.handlePhotos(record)}
          />
        ),
      },
      {
        title: "Card Rate",
        dataIndex: "card_rate",
        render: (text) => this.currencyFormatter(text),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Base Rate",
        dataIndex: "base_rate",
        render: (text) => this.currencyFormatter(text),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Available Gap",
        dataIndex: "available_gap",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Site Start Date",
        dataIndex: "campaign_start_date",
        render: (text) => text && text.length > 5 && dateFormatter(text),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Site End Date",
        dataIndex: "campaign_end_date",
        render: (text) => text && text.length > 5 && dateFormatter(text),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Available From",
        dataIndex: "available_from",
        // render: (text) => dateFormatter(text),
        render: (text) =>
          dateFormatter(text) === dateFormatter(new Date())
            ? "Available"
            : dateFormatter(text),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Display",
        dataIndex: "display_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Employee",
        dataIndex: "employee_id",
        render: (text) => text && <PortalUser user={text} />,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Occupancy %",
        dataIndex: "occupancy_per",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      // {
      //   title: "Rating",
      //   dataIndex: "rating",
      //   sorter: true,
      //   sortDirections: ["ascend", "descend", "ascend"],
      // },
      {
        title: "Inventory Status",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        dataIndex: "inventory_status",
      },
      {
        title: "Booking Status",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        dataIndex: "booking_status",

        render: (text, record) => (
          <Button
            className="ant-btn-reset"
            type="link"
            onClick={() => this.statusInventory(record, "booking")}
          >
            {text === "A" ? (
              <Badge
                count={record?.total_blocked_count}
                size="small"
                offset={[8, 0]}
                style={{ background: "none", color: "#2db7f5" }}
                className="ant-btn-link"
              >
                Available
              </Badge>
            ) : text === "B" ? (
              <Badge
                count={record?.total_blocked_count}
                size="small"
                offset={[8, 0]}
                style={{
                  background: "none",
                  color: "#2db7f5",
                  marginTop: "-4px",
                  marginRight: "4px",
                }}
                className="ant-btn-link"
              >
                Blocked till{" "}
                {dateFormatter(record.blocked_till_date) +
                  " " +
                  timeFormatter(record.blocked_till_date)}
              </Badge>
            ) : (
              <Badge
                count={record?.total_blocked_count}
                size="small"
                offset={[8, 0]}
                style={{ background: "none", color: "#2db7f5" }}
                className="ant-btn-link"
              >
                Not Available
              </Badge>
            )}
          </Button>
        ),
      },
      {
        title: "Log",
        dataIndex: "is_inventory_status_read",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text, record) => (
          <Button
            className="ant-btn-reset"
            onClick={() => this.statusInventory(record)}
          >
            {text ? (
              <span style={{ whiteSpace: "nowrap" }}>
                <BulbTwoTone twoToneColor="#cccccc" /> Read
              </span>
            ) : (
              <span style={{ whiteSpace: "nowrap" }}>
                <BulbTwoTone twoToneColor="#fadb14" /> Unread
              </span>
            )}
          </Button>
        ),
      },
    ];

    const filterColumns = [
      {
        columnName: "general",
        displayName: "General",
        default: true,
        hideFromSettings: true,
      },
      { columnName: "inventory_type", displayName: "Type", unchecked: true },
      { columnName: "media_type", displayName: "Media", unchecked: true },
      { columnName: "inventory_code", displayName: "IID" },
      { columnName: "state", displayName: "State", unchecked: true },
      { columnName: "district", displayName: "District", unchecked: true },
      { columnName: "city", displayName: "City" },
      { columnName: "area", displayName: "Area", unchecked: true },
      { columnName: "location", displayName: "Location" },
      { columnName: "lat", displayName: "Lat", unchecked: true },
      { columnName: "long", displayName: "Long", unchecked: true },
      {
        columnName: "traffic_facing_from",
        displayName: "Traffic From",
        unchecked: true,
      },
      {
        columnName: "traffic_going_to",
        displayName: "Traffic To",
        unchecked: true,
      },
      { columnName: "width", displayName: "Width", unchecked: true },
      { columnName: "height", displayName: "Height", unchecked: true },
      { columnName: "size", displayName: "Size" },
      { columnName: "sqft", displayName: "SQFT", unchecked: true },
      { columnName: "light_type", displayName: "Light" },
      { columnName: "quantity", displayName: "Qty", unchecked: true },
      { columnName: "ha_images", displayName: "Photo" },
      // { columnName: "ha_images", displayName: "Custom Photo", unchecked: true },
      { columnName: "card_rate", displayName: "Card Rate" },
      { columnName: "base_rate", displayName: "Base Rate" },
      {
        columnName: "available_gap",
        displayName: "Available Gap",
        unchecked: true,
      },
      {
        columnName: "campaign_start_date",
        displayName: "Site Start Date",
        unchecked: true,
      },
      {
        columnName: "campaign_end_date",
        displayName: "Site End Date",
        unchecked: true,
      },
      {
        columnName: "available_from",
        displayName: "Available From",
      },
      { columnName: "display_name", displayName: "Display", unchecked: true },
      { columnName: "employee_id", displayName: "Employee", unchecked: true },
      {
        columnName: "occupancy_per",
        displayName: "Occupancy %",
        unchecked: true,
      },
      // { columnName: "rating", displayName: "Rating", unchecked: true },
      {
        columnName: "is_inventory_status_read",
        displayName: "Log",
        unchecked: true,
      },
      { columnName: "booking_status", displayName: "Booking Status" },
    ];

    if (this.state.inventoryType === "tainventory") {
      columns.splice(0, 0, {
        title: "Supplier",
        dataIndex: "supplier_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      });

      filterColumns.splice(1, 0, {
        columnName: "supplier_name",
        displayName: "Supplier",
      });
    }

    this.setState({ columnsDefault: columns, filterColumns }, () =>
      this.setDefaultColumns(columns)
    );
  };

  // Change Pagging Sorting and Filters
  handleTableChange = (pagination, filters, sorter) => {
    this.getInventories({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  };

  // Handle Global Filters
  handleFilters = (filterColumn, filterValue) => {
    let { pagination } = this.state;
    pagination.current = 1;

    let appliedFilters = this.state.appliedFilters || {};

    if (filterValue) {
      appliedFilters.column_name = filterColumn.columnName;
      appliedFilters.column_value = filterValue;
    } else {
      appliedFilters.column_name = "";
      appliedFilters.column_value = "";
    }

    this.setState({ appliedFilters }, () =>
      this.getInventories({ pagination })
    );
  };

  // Handle Setting show hide columns
  handleSettings = (values) => {
    let columnsDefault = this.state.columnsDefault;
    let columns = this.state.columns;
    columns = columnsDefault.filter(({ title }) =>
      values.some((exclude) => exclude.title === title)
    );
    this.setState({ columns });
  };

  // Delete inventory List based on Id
  deleteInventory = (inventory) => {
    this.setState({ isLoading: true });
    var reqUrl =
      this.state.inventoryType +
      "/delete?inventoryId=" +
      inventory.ha_inventory_id;
    var reqObj = "";
    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        if (data?.success) {
          message.success("Deleted Successfully.");
          const { pagination } = this.state;
          this.getInventories({ pagination });
        } else {
          if (!data?.group_inventory_list) {
            message.error(data?.detail || "Someting went wrong.");
          } else {
            if (data?.group_inventory_list?.length > 0) {
              message.error(
                "Group " +
                  data?.group_inventory_list.toString() +
                  " will be deleted"
              );
            } else {
              message.success("Deleted Successfully.");
            }
            const { pagination } = this.state;
            this.getInventories({ pagination });
          }
        }
      },
      "DELETE"
    );
  };

  // Redirect to inventory add form
  addInventory = () => {
    this.state.inventoryType === "hainventory"
      ? this.props.history.push(`/inventories/inventory-add`)
      : this.state.inventoryType === "tainventory"
      ? this.props.history.push(`/inventories/ta-inventory-add`)
      : this.state.inventoryType === "tagroupinventory"
      ? this.props.history.push(`/inventories/ta-inventory-add-group`)
      : this.props.history.push(`/inventories/inventory-add-group`);
  };

  // Redirect to inventory edit form
  editInventory = (inventory) => {
    this.props.history.push(
      `/inventories/${
        this.state.inventoryType === "hainventory"
          ? "inventory-edit"
          : this.state.inventoryType === "tainventory"
          ? "ta-inventory-edit"
          : this.state.inventoryType === "tagroupinventory"
          ? "ta-inventory-group-edit"
          : "inventory-group-edit"
      }/${inventory.ha_inventory_id}`
    );
  };

  // Redirect to inventory details screen
  detailsInventory = (inventory) => {
    this.props.history.push(
      `/inventories/inventory-details/${this.state.inventoryType}/${inventory.ha_inventory_id}`
    );
  };

  // Toggle between inventories and grouped inventories
  handleToggle = () => {
    let { pagination } = this.state;
    pagination.current = 1;

    let inventoryType = this.state.inventoryType;

    if (inventoryType === "hainventory") {
      inventoryType = "hagroupinventory";
    } else if (inventoryType === "tainventory") {
      inventoryType = "tagroupinventory";
    } else if (inventoryType === "hagroupinventory") {
      inventoryType = "hainventory";
    } else if (inventoryType === "tagroupinventory") {
      inventoryType = "tainventory";
    }

    this.setState(
      {
        inventoryType,
      },
      () => {
        this.getInventories({ pagination });
      }
    );
  };

  // Handle Nv Changes update the list bases on selected tabs
  handleNav = (nav) => {
    let { pagination } = this.state;
    pagination.current = 1;

    let appliedFilters = this.state.appliedFilters || {};
    appliedFilters.column_media_type = nav;

    let filters = "&mediaType=" + nav;
    this.setState({ appliedFilters }, () =>
      this.getInventories({ pagination, filters })
    );
  };

  // Get Group Inventory Details when expanded
  getGroupDetails = (record) => {
    const inventories = record.ha_group_inventories;

    let columns = [
      {
        title: "Media",
        dataIndex: "media_type",
      },
      {
        title: "Qty",
        dataIndex: "quantity",
      },
      {
        title: "IID",
        dataIndex: "inventory_code",
      },
      {
        title: "City",
        dataIndex: "city",
      },
      {
        title: "Area",
        dataIndex: "area",
      },
      {
        title: "Location",
        dataIndex: "location",
      },
      {
        title: "Size",
        dataIndex: "size",
      },
      {
        title: "SQFT",
        dataIndex: "sqft",
      },
      {
        title: "Light",
        dataIndex: "light_type",
      },
      {
        title: "Photo",
        dataIndex: "images",
        render: (text, record) => (
          <Button
            className="ant-btn-reset"
            disabled={record?.ha_images?.length > 0 ? false : true}
            icon={<FileImageOutlined />}
            onClick={() => this.handlePhotos(record)}
          />
        ),
      },
      {
        title: "Card Rate",
        dataIndex: "card_rate",
        render: (text) => this.currencyFormatter(text),
      },
    ];

    let data = inventories;

    return (
      <Table
        columns={this.state.columns}
        rowKey={(record) => record.ha_inventory_id}
        dataSource={data}
        pagination={false}
      />
    );
  };

  // Get Multi Inventory Details when expanded
  getMultiDetails = (record) => {
    const inventories = record.ha_base_inventories;

    let columns = [
      {
        title: "IID",
        dataIndex: "inventory_code",
        width: 200,
      },
      {
        title: "Side Type",
        dataIndex: "side_type",
        render: (text) => <span className="ant-text-capitalize">{text}</span>,
      },

      {
        title: "Qty",
        dataIndex: "quantity",
      },
      {
        title: "Description",
        dataIndex: "description",
      },
      {
        title: "Size",
        dataIndex: "size",
      },
      {
        title: "SQFT",
        dataIndex: "sqft",
      },
      {
        title: "Light",
        dataIndex: "light_type",
      },
      {
        title: "Photo",
        dataIndex: "images",
        render: (text, record) => (
          <Button
            className="ant-btn-reset"
            disabled={record?.ha_images?.length > 0 ? false : true}
            icon={<FileImageOutlined />}
            onClick={() => this.handlePhotos(record)}
          />
        ),
      },
      {
        title: "Card Rate",
        dataIndex: "card_rate",
        render: (text) => this.currencyFormatter(text),
      },
    ];

    let data = inventories;

    return (
      <Table
        columns={columns}
        rowKey={(record) => record.ha_inventory_id}
        dataSource={data}
        pagination={false}
      />
    );
  };

  // Redirect to inventory status screen
  statusInventory = (inventory, type) => {
    let inventoryTitle =
      inventory.inventory_code +
      " - " +
      inventory.city +
      ", " +
      inventory.location +
      " - " +
      inventory.width +
      "x" +
      inventory.height;

    if (type === "booking") {
      window.open(
        `/#/inventories/${
          type === "booking" ? "inventory-booking-status" : "inventory-status"
        }/${inventory.ha_inventory_id}/${inventoryTitle}`,
        "_blank"
      );
    } else {
      this.props.history.push(
        `/inventories/${
          type === "booking" ? "inventory-booking-status" : "inventory-status"
        }/${inventory.ha_inventory_id}/${inventoryTitle}`
      );
    }
  };

  // Currency Formatter
  currencyFormatter = (currency) => {
    let x = currency;
    x = x.toString();
    let lastThree = x.substring(x.length - 3);
    let otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers !== "") lastThree = "," + lastThree;
    let res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    return res;
  };

  // Handle Photos SlideShoe
  handlePhotos = (record) => {
    this.setState({
      isShowPhotos: !this.state.isShowPhotos,
      photos: record ? record?.ha_images : "",
      photosTitle: record ? record : "",
    });
  };

  // Callback for update state
  callBack = () => {};

  // Setup Background Colors
  setColors = (record) => {
    return record.booking_status === "A"
      ? "row-bg-green"
      : record.booking_status === "B"
      ? "row-bg-orange"
      : "";
  };

  handleHistoricalPurchase = () => {
    this.setState({ isHistoricalPurchase: !this.state.isHistoricalPurchase });

    let { pagination } = this.state;
    pagination.current = 1;

    let appliedFilters = {
      historical_purchase: this.state.isHistoricalPurchase,
    };

    this.setState({ appliedFilters }, () =>
      this.getInventories({ pagination })
    );
  };

  // Set Default Columns
  setDefaultColumns = (columns) => {
    AdminApi.syncColumsSettings()
      .then((columnsSettings) => {
        this.setState({ columnsSettings: columnsSettings || {} }, () =>
          this.applyDefaultColums(columns)
        );
      })
      .catch((nothing) => {});
  };

  // Apply Default Columns
  applyDefaultColums = (columns) => {
    let { columnsSettings, columnsDefault, screen } = this.state;
    if (screen && columnsSettings) {
      let colConfig = columnsSettings[screen];

      if (colConfig && colConfig.length > 1) {
        columns = columnsDefault.filter(({ title }) =>
          colConfig.some((exclude) => exclude.title === title)
        );
      }
      this.setState({ columns });
    }
  };
}

export default InventoriesListBase;
