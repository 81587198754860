import React, { Component } from "react";
import { Form, Button, DatePicker, InputNumber, Radio } from "antd";
import moment from "moment";

class CampaignChangeEndDates extends Component {
  state = {
    selectedRadio: "extend_by_date",
    selectedRows: [],
    selectedDate: moment(),
  };

  formRef = React.createRef();

  onFinish = (values) => {
    let extend_date = moment();

    const { selectedRows, selectedRadio } = this.state;

    if (selectedRadio === "extend_by_date") {
      extend_date = values.extend_date;
    } else {
      extend_date =
        selectedRows &&
        selectedRows.length > 0 &&
        selectedRows.map((x) =>
          moment(x.end_date).add(values.extend_days, "days")
        );
    }
    this.props.changeEndDates(extend_date, selectedRows, selectedRadio);
  };

  // Change radio of discount, pricing and sqft
  onChangeRadio = (e) => {
    this.setState({
      selectedRadio: e.target.value,
    });
  };

  setData = () => {
    let selectedRows = [];
    let selectedDate = moment();

    this.props.selectedRows.map(
      (x) =>
        (x.campaign_status === "Approved" || x.campaign_status === "Running") &&
        selectedRows.push(x)
    );

    selectedRows.length > 0 && (selectedDate = selectedRows[0].end_date);
    selectedRows.length > 0 &&
      selectedRows.map(
        (x) =>
          moment(x.end_date).diff(moment(selectedDate), "days") > 0 &&
          (selectedDate = x.end_date)
      );

    this.formRef.current.setFieldsValue({
      extend_date: moment(selectedDate),
    });

    this.setState({ selectedDate, selectedRows });
  };

  disabledEndDate = (current) => {
    return current < moment().startOf("day");
  };

  componentDidMount() {
    this.setData();
  }

  render() {
    const { selectedRadio } = this.state;

    const layout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
    };

    return (
      <Form
        name="campaign_change_end_dates"
        {...layout}
        onFinish={this.onFinish}
        ref={this.formRef}
        className="campaign_change_end_dates"
        onValuesChange={this.ValuesChange}
      >
        <Form.Item
          name="extend_by"
          label="Extend By"
          onChange={this.onChangeRadio}
        >
          <Radio.Group value={selectedRadio} defaultValue={selectedRadio}>
            <Radio value="extend_by_date">Date</Radio>
            <Radio value="extend_by_days">Days</Radio>
          </Radio.Group>
        </Form.Item>

        {selectedRadio === "extend_by_date" && (
          <Form.Item name="extend_date" label="End Date">
            <DatePicker
              disabledDate={this.disabledEndDate}
              inputReadOnly={true}
            />
          </Form.Item>
        )}

        {selectedRadio === "extend_by_days" && (
          <Form.Item name="extend_days" label="Extend by Days">
            <InputNumber min={1} placeholder="Days" />
          </Form.Item>
        )}

        <Form.Item wrapperCol={{ offset: 6 }} className="model-btns">
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>

        <i>Notes: You can extend dates of Running or Approved sites</i>
      </Form>
    );
  }
}

export default CampaignChangeEndDates;
