import {useState, useEffect} from "react";
import {Avatar, Space, Skeleton, Modal} from "antd";
import AdminApi from "../api/admin-api";
import PortalUserProfile from "./portal-user-profile";
import StringToColor from "string-to-color";

const PortalUser = (props) => {

    const [user, setUser] = useState();
    const [loading, setLoading] = useState(true);
    const [modalVisible, setModalVisible] = useState(false);

    useEffect(() => {
        setLoading(true);
        if (props.user) {
            AdminApi.getCachedUser(props.user).then(user => {
                setUser(user);
                setLoading(false);
            }).catch(nothing => {

            })
        }

    }, [props.user])

    return (
        <div {...props}>
            {!loading &&
            <div style={{
                cursor: "pointer"
            }} onClick={() => !modalVisible && setModalVisible(true)}>
                <Avatar size={"small"} style={{
                    marginRight: "8px",
                    backgroundColor : StringToColor(user.id)
                }} src={user.details.picture}>{(user.firstName ?? user.userName).toUpperCase()[0]}</Avatar>
                {user && user.firstName && `${user.firstName} ${user.lastName}`}
                {user && !user.firstName && `${user.userName}`}
                <Modal title={null} visible={modalVisible} destroyOnClose={true} footer={null} onCancel={() => {
                    setModalVisible(false);
                }}>
                    <PortalUserProfile user={props.user}/>
                </Modal>
            </div>
            }
            {loading && <Space>
                <Skeleton.Avatar active={true} size={"small"} shape={"circle"}/>
                <Skeleton.Input style={{width: 100}} active={true} size={"small"}/>
            </Space>}
        </div>
    )
};

export default PortalUser;
