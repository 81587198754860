import React from "react";
import {
  Row,
  Col,
  Table,
  Descriptions,
  Card,
  Typography,
  Form,
  Button,
  InputNumber,
  Drawer,
  Menu,
  Dropdown,
  Radio,
  Modal,
  Switch,
} from "antd";
import {
  DeleteOutlined,
  EllipsisOutlined,
  CalendarOutlined,
  FileAddOutlined,
  EditOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { currencyFormatter } from "../../components/common/common-formatter";
import PageTitleDetails from "../common/page-title-detail";
import ListFilters from "../../components/common/list-filters";
import PlanSelectInventory from "../plan/plan-select-inventories";
import PlanAdvanced from "../plan//plan-advanced";
import PlanCampaignInventoryBase from "../../base/plan-campaign-inventory-base";
import CampaignChangeofsites from "./campaign-changeofsites";
import PhotoGallery from "../../components/common/photo-gallery";
import CampaignChangeEndDates from "./campaign-change-end-dates";
import PrintingMounting from "../../components/plan/printing-mounting";

class CampaignInventories extends PlanCampaignInventoryBase {
  state = {
    results: "",
    resultsDefault: "",
    selectedRowKeys: [],
    selectedRows: [],
    isDrawer: false,
    columns: [],
    filterColumns: [],
    inventory_details: [],
    inventory_details_default: [],
    inventory_details_default_filter: [],
    displayValues: "",
    displayValues_default: "",
    currentValues: "",
    currentValues_default: "",
    selectedRadio: "discounting",
    isAdvanced: false,
    page: "campaign",
    auto_adjust_campaign_dates: this.props?.results?.auto_adjust_campaign_dates,
    isEndDatesModal: false,
    inventoryType: "hainventory",
    screen: "campaign-inventories",
    isChanged: false,
    isSaveLoading: false,
  };

  componentDidMount() {
    this.setData();
  }

  render() {
    const {
      displayValues,
      columns,
      selectedRowKeys,
      selectedRows,
      results,
      isDrawer,
      filterColumns,
      inventory_details,
      selectedRadio,
      isAdvanced,
      isModal,
      isShowPhotos,
      photos,
      isEndDatesModal,
      screen,
      photosTitle,
      isChanged,
      isSaveLoading,
    } = this.state;
    const { Title } = Typography;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,

      getCheckboxProps: (record) => ({
        disabled: record.is_read,
        is_read: record.is_read,
      }),
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };

    const layout2 = {
      wrapperCol: { span: 24 },
    };

    const layout3 = {
      labelCol: { span: 4 },
      wrapperCol: { span: 8 },
    };

    const layout4 = {
      labelCol: { span: 12 },
      wrapperCol: { span: 12 },
    };

    return (
      <div className="plan-campaign-inv">
        <PageTitleDetails
          titleText={"Campaign - " + results.display_name}
          isBackBtn="true"
          handleBack={() => this.props.handleBack(isChanged)}
          is_rotational={results?.is_rotational}
        />

        {!results?.is_completed && (
          <Button
            type="primary"
            className="page-title-custom-btn icon-btn"
            onClick={this.handleUpdates}
            loading={isSaveLoading}
            icon={<SaveOutlined />}
          >
            Save Changes
          </Button>
        )}

        <Row gutter={16} className="plan-dtl-boxes">
          <Col span={12} xs={24} xl={12}>
            <Card
              title={<Title level={5}>Inventories</Title>}
              style={{ height: "100%" }}
              extra={
                <Button
                  style={{ margin: "-8px" }}
                  onClick={this.handleAdvanced}
                >
                  Printing & Mounting Cost
                </Button>
              }
            >
              <Form
                name="plan_add"
                {...layout}
                ref={this.formRef}
                onFinish={this.onFinish}
                className="plan_inventories"
                onValuesChange={this.onFinish}
              >
                <Row>
                  <Col span="24" offset="0">
                    <Radio.Group
                      value={selectedRadio}
                      onChange={this.onChangeRadio}
                      style={{ marginBottom: "24px" }}
                    >
                      <Radio value="discounting">Discounting</Radio>
                      <Radio value="pricing">Pricing</Radio>
                      <Radio value="rateSQFT">Rate / SQFT / Month </Radio>
                    </Radio.Group>
                  </Col>
                </Row>

                {selectedRadio === "discounting" && (
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="discounting_per"
                        label="Discounting"
                        {...layout3}
                      >
                        <InputNumber
                          style={{ width: "100%" }}
                          min={0}
                          placeholder="%"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}

                {selectedRadio === "pricing" && (
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="discounting_rate"
                        label="Pricing"
                        {...layout3}
                      >
                        <InputNumber
                          style={{ width: "100%" }}
                          min={0}
                          placeholder="lumpsum Rate"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}

                {selectedRadio === "rateSQFT" && (
                  <Row>
                    <Col span={24}>
                      <Row gutter={8}>
                        <Col span={8} xs={7} xl={8}>
                          <Form.Item
                            name="discounting_rate_all"
                            label="Rate / SQFT"
                            {...layout4}
                          >
                            <InputNumber
                              style={{ width: "100%" }}
                              min={0}
                              placeholder="ALL"
                            />
                          </Form.Item>
                        </Col>

                        <Col span={2} xs={2} xl={2}>
                          <div
                            style={{
                              display: "block",
                              textAlign: "center",
                              marginTop: "5px",
                            }}
                            className="without-label-text"
                          >
                            OR
                          </div>
                        </Col>

                        <Col span={4} xs={5} xl={4}>
                          <Form.Item
                            name="discounting_rate_fl"
                            {...layout2}
                            className="without-label"
                          >
                            <InputNumber
                              style={{ width: "100%" }}
                              min={0}
                              placeholder="FL"
                            />
                          </Form.Item>
                        </Col>

                        <Col span={4} xs={5} xl={4}>
                          <Form.Item
                            name="discounting_rate_bl"
                            {...layout2}
                            className="without-label"
                          >
                            <InputNumber
                              style={{ width: "100%" }}
                              min={0}
                              placeholder="BL"
                            />
                          </Form.Item>
                        </Col>

                        <Col span={4} xs={5} xl={4}>
                          <Form.Item
                            name="discounting_rate_nl"
                            {...layout2}
                            className="without-label"
                          >
                            <InputNumber
                              style={{ width: "100%" }}
                              min={0}
                              placeholder="NL"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col span={12} xs={11} xl={12}>
                    <Form.Item name="print_fl_rate" label="Printing">
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        placeholder="FL"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={2} xs={2} xl={2}>
                    <div
                      style={{
                        display: "block",
                        textAlign: "center",
                        marginTop: "5px",
                      }}
                      className="without-label-text"
                    >
                      &amp;
                    </div>
                  </Col>

                  <Col span={8} xs={11} xl={8}>
                    <Form.Item
                      name="print_bl_rate"
                      {...layout2}
                      className="without-label"
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        placeholder="BL"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={12} xs={11} xl={12}>
                    <Form.Item name="install_fl_rate" label="Mounting">
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        placeholder="FL"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={2} xs={2} xl={2}>
                    <div
                      style={{
                        display: "block",
                        textAlign: "center",
                        marginTop: "5px",
                      }}
                      className="without-label-text"
                    >
                      &amp;
                    </div>
                  </Col>

                  <Col span={8} xs={11} xl={8}>
                    <Form.Item
                      name="install_bl_rate"
                      {...layout2}
                      className="without-label"
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        placeholder="BL"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>

          <Col span={6} xs={24} xl={6}>
            <Card
              title={<Title level={5}>Statistics</Title>}
              bodyStyle={{ paddingBottom: "0px" }}
              style={{ height: "100%" }}
            >
              <Descriptions column={1}>
                <Descriptions.Item label="HA Markup" className="ant-text-right">
                  <b
                    className={
                      displayValues.base_rate_diff > 0
                        ? "ant-color-green"
                        : "ant-color-red"
                    }
                  >
                    {currencyFormatter(
                      parseInt(displayValues.base_rate_diff_amt)
                    )}{" "}
                    ({displayValues.base_rate_diff}%)
                  </b>
                </Descriptions.Item>

                <Descriptions.Item label="TA Markup" className="ant-text-right">
                  <b
                    className={
                      displayValues?.ta_base_rate_diff > 0
                        ? "ant-color-green"
                        : "ant-color-red"
                    }
                  >
                    {currencyFormatter(
                      parseInt(displayValues?.ta_base_rate_diff_amt)
                    )}{" "}
                    ({displayValues?.ta_base_rate_diff}%)
                  </b>
                </Descriptions.Item>

                <Descriptions.Item
                  label="Expense Difference"
                  className="ant-text-right"
                >
                  <b>{}</b>
                </Descriptions.Item>

                <Descriptions.Item
                  label="Total SQFT"
                  className="ant-text-right"
                >
                  <b>{displayValues?.total_sqft}</b>
                </Descriptions.Item>

                <Descriptions.Item
                  label="Price / SQFT"
                  className="ant-text-right"
                >
                  <b>{displayValues.rate_per_sqft}</b>
                </Descriptions.Item>

                <Descriptions.Item
                  label="Total Inventories"
                  className="ant-text-right"
                >
                  <b>{this.getTotalInventoriesCount()}</b>
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>

          <Col span={6} xs={24} xl={6}>
            <Card
              title={<Title level={5}>Summary</Title>}
              bodyStyle={{ paddingBottom: "0px" }}
            >
              <Descriptions column={1}>
                <Descriptions.Item
                  label="Display Cost"
                  className="ant-text-right"
                >
                  <b>
                    {currencyFormatter(
                      parseInt(displayValues.total_display_cost)
                    )}
                  </b>
                </Descriptions.Item>

                <Descriptions.Item
                  label="Printing Cost"
                  className="ant-text-right"
                >
                  <b>
                    {currencyFormatter(
                      parseInt(displayValues.total_printing_cost)
                    )}
                  </b>
                </Descriptions.Item>

                <Descriptions.Item
                  label="Installation Cost"
                  className="ant-text-right"
                >
                  <b>
                    {currencyFormatter(
                      parseInt(displayValues.total_installing_cost)
                    )}
                  </b>
                </Descriptions.Item>

                <Descriptions.Item
                  label="Total Without Tax"
                  className="ant-text-right"
                >
                  <b>
                    {currencyFormatter(
                      parseInt(displayValues.total_without_tax)
                    )}
                  </b>
                </Descriptions.Item>

                <Descriptions.Item
                  label={"GST (" + displayValues.gst_per + "%)"}
                  className="ant-text-right"
                >
                  <b>{currencyFormatter(parseInt(displayValues.gst_amount))}</b>
                </Descriptions.Item>

                <Descriptions.Item
                  label={<b>Grand Total</b>}
                  className="ant-text-right"
                  style={{
                    borderTop: "solid 1px #f0f0f0",
                    paddingTop: "12px",
                  }}
                >
                  <b>
                    {currencyFormatter(parseInt(displayValues.total_amount))}
                  </b>
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
        </Row>

        <Row style={{ marginTop: "16px" }}>
          <Col span={24}>
            <ListFilters
              items={filterColumns}
              handleSettings={this.handleSettings}
              handleFilters={this.handleFilters}
              key={filterColumns}
              screen={screen}
            />

            <div
              style={{
                float: "right",
                position: "relative",
                marginTop: "-48px",
                marginRight: "48px",
              }}
              className="plan-inv-list-actions"
            >
              <Switch
                size="small"
                onChange={this.handleExpiredInventoriesToggle}
              ></Switch>
              <label style={{ marginLeft: "8px", marginRight: "16px" }}>
                Hide Expired <span className="mobile-hide">Inventories</span>
              </label>

              <Switch
                size="small"
                onChange={this.handleAdjustCampaignDatesToggle}
                checked={this.state.auto_adjust_campaign_dates}
              ></Switch>
              <label style={{ marginLeft: "8px", marginRight: "16px" }}>
                Adjust <span className="mobile-hide">Campaign</span> Dates
              </label>

              {!results?.is_completed && (
                <Dropdown
                  overlay={
                    <Menu onClick={(key) => this.handleMenuClick(key)}>
                      <Menu.Item key="add" icon={<FileAddOutlined />}>
                        Add HA Inventory
                      </Menu.Item>

                      <Menu.Item key="add-ta" icon={<FileAddOutlined />}>
                        Add TA Inventory
                      </Menu.Item>

                      {selectedRowKeys.length === 1 &&
                        selectedRows[0].campaign_status === "Running" && (
                          <Menu.Item key="cos" icon={<EditOutlined />}>
                            Perform Change of Site
                          </Menu.Item>
                        )}

                      {selectedRowKeys.length > 0 && (
                        <Menu.Item key="dates" icon={<CalendarOutlined />}>
                          Change End Dates
                        </Menu.Item>
                      )}

                      {selectedRowKeys.length > 0 && (
                        <Menu.Item key="delete" icon={<DeleteOutlined />}>
                          Delete
                        </Menu.Item>
                      )}
                    </Menu>
                  }
                  placement="bottomRight"
                  trigger={["click"]}
                >
                  <Button style={{ paddingLeft: "8px", paddingRight: "8px" }}>
                    <EllipsisOutlined />
                  </Button>
                </Dropdown>
              )}
            </div>

            <Table
              columns={columns}
              rowKey={(record) => record.uuid}
              dataSource={inventory_details}
              onChange={this.handleTableChange}
              bordered
              scroll={{ x: 400 }}
              size="small"
              rowSelection={rowSelection}
              pagination={false}
              expandable={{
                expandedRowRender: (record) => this.getMultiDetails(record),
                rowExpandable: (record) =>
                  record.ha_base_inventories &&
                  record.ha_base_inventories.length > 0,
              }}
              rowClassName={(record) => this.setColors(record)}
            />
          </Col>
        </Row>

        <Drawer
          title={!isAdvanced ? "Add Inventories" : "Printing & Mounting Cost"}
          placement="right"
          visible={isDrawer}
          onClose={!isAdvanced ? this.handleDrawer : this.handleAdvanced}
          width={!isAdvanced ? "88%" : "88%"}
          key={isDrawer}
        >
          {!isAdvanced && (
            <PlanSelectInventory
              key={isDrawer}
              addSelectedInventory={
                !this.state.isChangeOfSite
                  ? this.calAddInventory
                  : this.changeOfSite
              }
              {...this.state}
            />
          )}

          {isAdvanced && (
            <PrintingMounting
              key={isDrawer}
              {...this.state}
              handleAdvancedPrinting={this.handleAdvancedPrinting}
            />

            // <PlanAdvanced
            //   key={isDrawer}
            //   {...this.state}
            //   handleAdvancedPrinting={this.handleAdvancedPrinting}
            // />
          )}
        </Drawer>

        {isShowPhotos && (
          <PhotoGallery
            photos={photos}
            photosTitle={photosTitle}
            handlePhotos={this.handlePhotos}
          />
        )}

        <Modal
          centered
          title={"Change of Sites"}
          visible={isModal}
          onCancel={this.handleModal}
          key={isModal}
          footer={false}
          width="320px"
        >
          <CampaignChangeofsites
            handleChangeOfSiteDate={this.handleChangeOfSiteDate}
          />
        </Modal>

        <Modal
          centered
          title={"Change End Dates of Selected Sites"}
          visible={isEndDatesModal}
          onCancel={this.handleEndDates}
          key={isEndDatesModal}
          footer={false}
        >
          <CampaignChangeEndDates
            {...this.state}
            changeEndDates={this.changeEndDates}
            key={isEndDatesModal}
          />
        </Modal>
      </div>
    );
  }
}

export default CampaignInventories;
