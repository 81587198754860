import * as JSZip from "jszip";
import { getRelayUrl } from "../../services/ajax-requester";
import { getImageBlobData } from "../../helpers/image-helper";

export const exportPlanToZip = (planDetails, configuration) => {
  let zip = new JSZip();

  let urls = [];
  let index = 1;

  let inventories =
    planDetails.plan_inventory_details ??
    planDetails.response?.plan_inventory_details ??
    planDetails.campaign_inventory_details ??
    planDetails.response.campaign_inventory_details;

  inventories
    .filter((inventory) => inventory.ha_images?.length > 0)
    .forEach((inventory, inventoryIndex) => {
      inventory.ha_images.forEach((image, imageIndex) => {
        try {
          urls.push({
            url: getRelayUrl(image.data_url),
            name: `${index++}) ${inventory.inventory_code} - ${
              inventory.district
            }, ${inventory.city} - ${inventory.area} - ${
              inventory.location
            } - ${inventory.size} - ${inventory.light_type}${
              inventory.quantity > 1
                ? " Qty:" +
                  inventory.quantity +
                  " Display:" +
                  (inventory?.side_type === "double"
                    ? inventory.quantity * 2
                    : inventory.quantity) +
                  ""
                : ""
            }${imageIndex > 0 ? "_" + (imageIndex + 1) : ""}${
              image.data_url.match(/\.\w+$/)[0]
            }`,
          });
        } catch (e) {
          // ignore
        }
      });
    });

  let imagePromises = urls.map((x) => getImageBlobData(x.name, x.url));

  Promise.all(imagePromises).then((results) => {
    results.forEach((x) => {
      zip.file(x.name, x.data, { base64: true });
    });
    zip.generateAsync({ type: "blob" }).then((buffer) => {
      let blob = new Blob([buffer], { type: "application/zip" });
      let a = document.createElement("a");
      a.href = window.URL.createObjectURL(blob);
      a.style.display = "none";
      a.download = configuration.exportName ?? "plan.zip";
      document.body.appendChild(a);
      a.click();
    });
  });
};
