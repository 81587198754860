import React, { Component } from "react";
import { Table, Radio, Button } from "antd";
import EditInvoicePriceDate from "../../components/sales-estimate/edit-invoice-price-date";
import moment from "moment";
import InvoiceBulkEdit from "./invoice-bulk-edit";
import { SwapOutlined } from "@ant-design/icons";

class InvoiceEditList extends Component {
  state = {
    data: [],
    loading: false,
    columns: [],
    options: [],
    active: "Rent",
    selectedRowKeys: [],
    selectedRows: [],
    bulkEditKey: 1,
  };

  // Setting Columns
  setColumns = () => {
    const { active } = this.state;

    let columns = [
      {
        title: "IID",
        dataIndex: "inventory_code",
        width: 92,
      },
      {
        title: "District",
        dataIndex: "district",
        width: 92,
      },
      {
        title: "City",
        dataIndex: "city",
        width: 92,
      },
      {
        title: "Location",
        dataIndex: "location",
        render: (text, record) =>
          text +
          (record?.quantity && record?.quantity > 1
            ? " (Qty : " +
              record?.quantity +
              " / Display : " +
              (record?.side_type === "double"
                ? record?.quantity * 2
                : record?.quantity) +
              ")"
            : ""),
      },
      {
        title: "Size",
        dataIndex: "size",
      },
      {
        title: "Start Date",
        dataIndex: "start_date",
        width: 102,
        render: (text, record) => (
          <EditInvoicePriceDate
            key={text}
            value={text}
            record={record}
            column={"start_date"}
            handleInvoiceEdit={this.handleInvoiceEditDate}
          />
        ),
      },
      {
        title: "End Date",
        dataIndex: "end_date",
        width: 102,
        render: (text, record) => (
          <EditInvoicePriceDate
            key={text}
            value={text}
            record={record}
            column={"end_date"}
            handleInvoiceEdit={this.handleInvoiceEditDate}
          />
        ),
      },
      {
        title: "Days",
        dataIndex: "no_of_days",
        width: 72,
      },
      {
        title: () => {
          return (
            <React.Fragment>
              Monthly Rate
              <Button
                size="small"
                shape="circle"
                style={{ marginLeft: "8px" }}
                icon={<SwapOutlined />}
                onClick={() => this.handleRates()}
                disabled={!this.checkBtnEnable()}
              />
            </React.Fragment>
          );
        },
        dataIndex: "monthly_rate",
        width: 142,
        render: (text, record) => (
          <EditInvoicePriceDate
            key={text}
            value={text}
            record={record}
            column={"monthly_rate"}
            handleInvoiceEdit={this.handleInvoiceEditPrice}
          />
        ),
      },
      {
        title: "Cost",
        dataIndex:
          active === "Rent"
            ? "rental_cost"
            : active === "Print"
            ? "printing_cost"
            : "installing_cost",
        width: 124,
        render: (text, record) => (
          <EditInvoicePriceDate
            key={text}
            value={text}
            record={record}
            column={
              active === "Rent"
                ? "rental_cost"
                : active === "Print"
                ? "printing_cost"
                : "installing_cost"
            }
            handleInvoiceEdit={this.handleInvoiceEditPrice}
          />
        ),
      },
      {
        title: "Notes",
        dataIndex: "notes",
        width: 162,
        render: (text, record) => (
          <EditInvoicePriceDate
            key={text}
            value={text}
            record={record}
            column={"notes"}
            handleInvoiceEdit={this.handleInvoiceEditNotes}
          />
        ),
      },
    ];

    const filterColumns = [
      { columnName: "inventory_code", displayName: "IID" },
      { columnName: "district", displayName: "District" },
      { columnName: "city", displayName: "City" },
      { columnName: "location", displayName: "Location", default: true },
      { columnName: "size", displayName: "Size" },
      { columnName: "start_date", displayName: "Start Date" },
      { columnName: "end_date", displayName: "End Date" },
      { columnName: "no_of_days", displayName: "Days" },
      { columnName: "rental_cost", displayName: "Cost" },
    ];

    if (active === "Print" || active === "Mount") {
      columns.splice(5, 0, {
        title: "Light",
        dataIndex: "light_type",
      });

      columns.splice(6, 0, {
        title: "Creative Name",
        dataIndex: "",
        width: 142,
      });

      columns.splice(7, 4, {
        title: "Price / SQFT",
        dataIndex: "rate_per_sqft",
        width: 142,
        render: (text, record) => (
          <EditInvoicePriceDate
            key={text}
            value={text}
            record={record}
            column={"rate_per_sqft"}
            handleInvoiceEdit={this.handleInvoiceEditPrice}
          />
        ),
      });
    }

    this.setState({ columns, columnsDefault: columns, filterColumns });
  };

  // Check Box Selection
  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedRowKeys, selectedRows });
  };

  checkBtnEnable = () => {
    let status = false;
    const { selectedRows } = this.state;
    selectedRows.map((x) =>
      x?.no_of_days > 30 ? (status = true) : (status = false)
    );

    return status;
  };

  // Set Tabs
  setTabs = () => {
    const options = [
      {
        label: "Rent (" + this.props?.rental_inventory_details?.length + ")",
        value: "Rent",
      },
      {
        label: "Print (" + this.props?.printing_inventory_details?.length + ")",
        value: "Print",
      },
      {
        label:
          "Mount (" + this.props?.installing_inventory_details?.length + ")",
        value: "Mount",
      },
    ];

    this.setState({ options });
  };

  // Tab Change
  onTabChange = (e) => {
    let active = e.target.value;
    this.setState({ active }, () => this.setColumns());
  };

  // Invoice Price Edit
  handleInvoiceEditPrice = (value, record, column) => {
    let rental_inventory_details = [...this.state.rental_inventory_details];
    let printing_inventory_details = [...this.state.printing_inventory_details];
    let installing_inventory_details = [
      ...this.state.installing_inventory_details,
    ];

    const { active } = this.state;

    if (active === "Rent") {
      rental_inventory_details = rental_inventory_details.map((x) => ({
        ...x,
        monthly_rate:
          active + x?.uniqueId === active + record?.uniqueId
            ? column === "monthly_rate"
              ? value
              : (value / this.getInventoryDays(x.start_date, x.end_date)) * 30
            : x.monthly_rate,
        rental_cost:
          active + x?.uniqueId === active + record?.uniqueId
            ? column === "rental_cost"
              ? value
              : (value / 30) * this.getInventoryDays(x.start_date, x.end_date)
            : x.rental_cost,
      }));
    }

    if (active === "Print") {
      printing_inventory_details = printing_inventory_details.map((x) => ({
        ...x,
        rate_per_sqft:
          active + x?.uniqueId === active + record?.uniqueId
            ? column === "rate_per_sqft"
              ? value
              : value / x.sqft
            : x.rate_per_sqft,
        printing_cost:
          active + x?.uniqueId === active + record?.uniqueId
            ? column === "printing_cost"
              ? value
              : x.sqft * value
            : x.printing_cost,
      }));
    }

    if (active === "Mount") {
      installing_inventory_details = installing_inventory_details.map((x) => ({
        ...x,
        rate_per_sqft:
          active + x?.uniqueId === active + record?.uniqueId
            ? column === "rate_per_sqft"
              ? value
              : value / x.sqft
            : x.rate_per_sqft,
        installing_cost:
          active + x?.uniqueId === active + record?.uniqueId
            ? column === "installing_cost"
              ? value
              : x.sqft * value
            : x.installing_cost,
      }));
    }

    this.setState({
      rental_inventory_details,
      printing_inventory_details,
      installing_inventory_details,
    });

    this.props.handleEdit(
      rental_inventory_details,
      printing_inventory_details,
      installing_inventory_details
    );
  };

  // Invoice Date Edit
  handleInvoiceEditDate = (value, record, column) => {
    value = moment(value).format("YYYY-MM-DD");

    let rental_inventory_details = [...this.state.rental_inventory_details];
    let printing_inventory_details = [...this.state.printing_inventory_details];
    let installing_inventory_details = [
      ...this.state.installing_inventory_details,
    ];

    const { active } = this.state;

    rental_inventory_details = rental_inventory_details.map((x) => ({
      ...x,
      start_date:
        active + x?.uniqueId === active + record?.uniqueId
          ? column === "start_date"
            ? value
            : moment(value, "YYYY-MM-DD").diff(
                moment(x.start_date, "YYYY-MM-DD"),
                "days"
              ) < 0
            ? value
            : x.start_date
          : x.start_date,
      end_date:
        active + x?.uniqueId === active + record?.uniqueId
          ? column === "end_date"
            ? value
            : moment(value, "YYYY-MM-DD").diff(
                moment(x.end_date, "YYYY-MM-DD"),
                "days"
              ) > 0
            ? value
            : x.end_date
          : x.end_date,
      rental_cost:
        (x.monthly_rate / 30) *
        moment(x.end_date, "YYYY-MM-DD").diff(
          moment(x.start_date, "YYYY-MM-DD"),
          "days"
        ),
    }));

    rental_inventory_details = rental_inventory_details.map((x) => ({
      ...x,
      rental_cost:
        (x.monthly_rate / 30) * this.getInventoryDays(x.start_date, x.end_date),
      no_of_days: this.getInventoryDays(x.start_date, x.end_date),
    }));

    this.setState({
      rental_inventory_details,
      printing_inventory_details,
      installing_inventory_details,
    });

    this.props.handleEdit(
      rental_inventory_details,
      printing_inventory_details,
      installing_inventory_details
    );
  };

  handleInvoiceEditNotes = (value, record, column) => {
    let rental_inventory_details = [...this.state.rental_inventory_details];
    let printing_inventory_details = [...this.state.printing_inventory_details];
    let installing_inventory_details = [
      ...this.state.installing_inventory_details,
    ];

    const { active } = this.state;

    if (this.state.active === "Rent") {
      rental_inventory_details = rental_inventory_details.map((x) => ({
        ...x,
        notes:
          active + x?.uniqueId === active + record?.uniqueId ? value : x.notes,
      }));
    }

    if (this.state.active === "Print") {
      printing_inventory_details = printing_inventory_details.map((x) => ({
        ...x,
        notes:
          active + x?.uniqueId === active + record?.uniqueId ? value : x.notes,
      }));
    }

    if (this.state.active === "Mount") {
      installing_inventory_details = installing_inventory_details.map((x) => ({
        ...x,
        notes:
          active + x?.uniqueId === active + record?.uniqueId ? value : x.notes,
      }));
    }

    this.setState({
      rental_inventory_details,
      printing_inventory_details,
      installing_inventory_details,
    });

    this.props.handleEdit(
      rental_inventory_details,
      printing_inventory_details,
      installing_inventory_details
    );
  };

  // Return days based on start date and end dates
  getInventoryDays = (start_date, end_date) => {
    let no_of_days = moment(end_date, "YYYY-MM-DD").diff(
      moment(start_date, "YYYY-MM-DD"),
      "days"
    );
    return no_of_days + 1;
  };

  // Setting Table Data
  setData = () => {
    let {
      rental_inventory_details,
      printing_inventory_details,
      installing_inventory_details,
    } = this.props;

    rental_inventory_details = rental_inventory_details.map((x, key) => ({
      ...x,
      uniqueId: 1 + key,
      originalEndDate: x?.end_date,
    }));

    printing_inventory_details = printing_inventory_details.map((x, key) => ({
      ...x,
      uniqueId: 1 + key,
      originalEndDate: x?.end_date,
    }));

    installing_inventory_details = installing_inventory_details.map(
      (x, key) => ({
        ...x,
        uniqueId: 1 + key,
        originalEndDate: x?.end_date,
      })
    );

    this.setState({
      rental_inventory_details,
      printing_inventory_details,
      installing_inventory_details,
      data: rental_inventory_details,
    });
  };

  handleBulkEdit = (value) => {
    const { active, selectedRowKeys } = this.state;

    let key = Object.keys(value)[0];
    let val = value[key];

    let rental_inventory_details = [...this.state.rental_inventory_details];
    let printing_inventory_details = [...this.state.printing_inventory_details];
    let installing_inventory_details = [
      ...this.state.installing_inventory_details,
    ];

    if (active === "Rent") {
      if (key !== "no_of_days") {
        rental_inventory_details = rental_inventory_details.map((x) => ({
          ...x,

          start_date:
            key === "start_date"
              ? selectedRowKeys.includes(active + x.uniqueId)
                ? moment(val).format("YYYY-MM-DD") + "T00:00:00"
                : moment(val, "YYYY-MM-DD").diff(
                    moment(x.start_date, "YYYY-MM-DD"),
                    "days"
                  ) < 0
                ? x.start_date
                : x.start_date
              : x.start_date,

          end_date:
            key === "end_date"
              ? selectedRowKeys.includes(active + x.uniqueId)
                ? moment(val).format("YYYY-MM-DD") + "T00:00:00"
                : moment(val, "YYYY-MM-DD").diff(
                    moment(x.end_date, "YYYY-MM-DD"),
                    "days"
                  ) > 0
                ? x.end_date
                : x.end_date
              : x.end_date,

          rental_cost:
            (x.monthly_rate / 30) *
            moment(x.end_date, "YYYY-MM-DD").diff(
              moment(x.start_date, "YYYY-MM-DD"),
              "days"
            ),

          notes:
            key === "rent_notes" &&
            selectedRowKeys.includes(active + x.uniqueId)
              ? val
              : x.notes,
        }));
      }

      if (key === "no_of_days") {
        rental_inventory_details = rental_inventory_details.map((x) => ({
          ...x,
          end_date: selectedRowKeys.includes(active + x.uniqueId)
            ? moment(x.start_date, "YYYY-MM-DD")
                .add(val - 1, "days")
                .format("YYYY-MM-DD") + "T00:00:00"
            : x.end_date,
          no_of_days: selectedRowKeys.includes(active + x.uniqueId)
            ? val
            : x.no_of_days,
        }));
      }

      rental_inventory_details = rental_inventory_details.map((x) => ({
        ...x,
        rental_cost:
          (x.monthly_rate / 30) *
          this.getInventoryDays(x.start_date, x.end_date),
        no_of_days: this.getInventoryDays(x.start_date, x.end_date),
      }));
    }

    if (active === "Print") {
      printing_inventory_details = printing_inventory_details.map((x) => ({
        ...x,
        printing_cost:
          key === "print_bl_rate" &&
          x.light_type === "BL" &&
          selectedRowKeys.includes(active + x.uniqueId)
            ? x.sqft * val
            : key === "print_fl_rate" &&
              x.light_type !== "BL" &&
              selectedRowKeys.includes(active + x.uniqueId)
            ? x.sqft * val
            : x.printing_cost,
        rate_per_sqft:
          key === "print_bl_rate" &&
          x.light_type === "BL" &&
          selectedRowKeys.includes(active + x.uniqueId)
            ? val
            : key === "print_fl_rate" &&
              x.light_type !== "BL" &&
              selectedRowKeys.includes(active + x.uniqueId)
            ? val
            : x.rate_per_sqft,
        notes:
          key === "print_notes" && selectedRowKeys.includes(active + x.uniqueId)
            ? val
            : x.notes,
      }));
    }

    if (active === "Mount") {
      installing_inventory_details = installing_inventory_details.map((x) => ({
        ...x,
        installing_cost:
          key === "install_bl_rate" &&
          x.light_type === "BL" &&
          selectedRowKeys.includes(active + x.uniqueId)
            ? x.sqft * val
            : key === "install_fl_rate" &&
              x.light_type !== "BL" &&
              selectedRowKeys.includes(active + x.uniqueId)
            ? x.sqft * val
            : x.installing_cost,
        rate_per_sqft:
          key === "install_bl_rate" &&
          x.light_type === "BL" &&
          selectedRowKeys.includes(active + x.uniqueId)
            ? val
            : key === "install_fl_rate" &&
              x.light_type !== "BL" &&
              selectedRowKeys.includes(active + x.uniqueId)
            ? val
            : x.rate_per_sqft,
        notes:
          key === "install_notes" &&
          selectedRowKeys.includes(active + x.uniqueId)
            ? val
            : x.notes,
      }));
    }

    this.setState({
      rental_inventory_details,
      printing_inventory_details,
      installing_inventory_details,
      bulkEditKey: this.state.bulkEditKey + 1,
    });

    this.props.handleEdit(
      rental_inventory_details,
      printing_inventory_details,
      installing_inventory_details
    );
  };

  handleRates = () => {
    const { active } = this.state;

    let rental_inventory_details = [...this.state.rental_inventory_details];
    let printing_inventory_details = [...this.state.printing_inventory_details];
    let installing_inventory_details = [
      ...this.state.installing_inventory_details,
    ];

    rental_inventory_details = rental_inventory_details.map((x) => ({
      ...x,
      rental_cost: x?.monthly_rate,
      monthly_rate:
        (x?.monthly_rate / this.getInventoryDays(x.start_date, x.end_date)) *
        30,
    }));

    this.setState({
      rental_inventory_details,
      printing_inventory_details,
      installing_inventory_details,
      bulkEditKey: this.state.bulkEditKey + 1,
    });

    this.props.handleEdit(
      rental_inventory_details,
      printing_inventory_details,
      installing_inventory_details
    );
  };

  componentDidMount() {
    this.setColumns();
    this.setTabs();
    this.setData();
  }

  render() {
    const {
      data,
      columns,
      loading,
      selectedRowKeys,
      options,
      active,
      rental_inventory_details,
      printing_inventory_details,
      installing_inventory_details,
      bulkEditKey,
    } = this.state;

    const rowSelection = {
      selectedRowKeys,
      preserveSelectedRowKeys: true,
      onChange: this.onSelectChange,
    };

    return (
      <React.Fragment>
        <Radio.Group
          options={options}
          onChange={this.onTabChange}
          value={active}
          optionType="button"
          buttonStyle="solid"
          loading={true}
          style={{ marginBottom: "16px" }}
        />

        <InvoiceBulkEdit {...this.state} handleBulkEdit={this.handleBulkEdit} />

        <Table
          columns={columns}
          rowKey={(record) => active + record.uniqueId}
          dataSource={
            active === "Rent"
              ? rental_inventory_details
              : active === "Print"
              ? printing_inventory_details
              : installing_inventory_details
          }
          pagination={false}
          loading={loading}
          bordered
          scroll={{ x: 400 }}
          size="small"
          rowSelection={rowSelection}
          key={active + bulkEditKey}
        />
      </React.Fragment>
    );
  }
}

export default InvoiceEditList;
