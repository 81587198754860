import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
import App from "./App";
import "antd/dist/antd.css";
import "./assets/css/common.css";
import "./assets/css/style.css";
import "./assets/css/theme.css";
import { init as initApm } from '@elastic/apm-rum'
import * as serviceWorker from "./serviceWorker";
serviceWorker.unregister();

if (!window.location.host.includes("localhost") && !window.location.host.includes("127.0.0.1")) {
    try {
        var apm = initApm({
            serviceName: 'mis-ui',
            serverUrl: 'https://eapm.ooh.systems',
            serviceVersion: '',
            environment: window.location.host.split('.')[0]
        })
    } catch (e) {
        console.warn(`Unable to initiate APM for host : ${window.location.host}`)
    }
}

ReactDOM.render(
  <HashRouter>
    <App />
  </HashRouter>,
  document.getElementById("root")
);
