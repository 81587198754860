import React from "react";
import { Form, Input } from "antd";

const CustomerAddBankDetails = ({ field }) => {
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
  };
  return (
    <React.Fragment>
      <Form.Item
        {...field}
        name={[field.name, "bank_acct_no"]}
        fieldKey={[field.fieldKey, "bank_acct_no"]}
        label="ACC Number"
        {...layout}
      >
        <Input placeholder="ACC Number" />
      </Form.Item>

      <Form.Item
        {...field}
        name={[field.name, "bank_ifsc_code"]}
        fieldKey={[field.fieldKey, "bank_ifsc_code"]}
        label="IFSC Code"
        {...layout}
      >
        <Input placeholder="IFSC Code" />
      </Form.Item>
    </React.Fragment>
  );
};

export default CustomerAddBankDetails;
