import React, { Component } from "react";
import { Button, Select, Form, DatePicker, message } from "antd";
import { ajaxRequester } from "../../services/ajax-requester";
import moment from "moment";
import { exportInvoicesToXlsx } from "../../components/sales-estimate/invoices-to-xlsx";
import UserPickerDropDown from "../../iam/components/user-picker-dropdown";
import { RolesPermissions } from "../../helpers/roles-permissions";
import AdminApi from "../../iam/api/admin-api";

class ExportInvoices extends Component {
  state = {
    customersList: [],
    isDownloading: false,
  };

  formRef = React.createRef();

  onFinish = (values) => {
    let filters = values || {};
    this.getPendingInvoiceList(filters);
  };

  // Get Mounting List
  getPendingInvoiceList = (filters) => {
    this.setState({ isDownloading: true });

    var reqUrl = "salesestimate/pendinginvoicelist/export";
    var reqObj = { request: filters || {} };

    ajaxRequester(reqUrl, reqObj, (data) => {
      this.setState({ isDownloading: false });

      if (data?.response && data?.response?.length > 0) {
        this.handleDownload(data?.response || []);
      } else {
        message.error("No Data to Export.");
      }
    });
  };

  handleDownload = (data) => {
    let inventory_details = data;
    let exportColumns = [
      "Sr",
      "Employee",
      "Customer Name",
      "Display Name",
      "City",
      "Media",
      "Location",
      "W",
      "H",
      "SQFT",
      "Start Date",
      "End Date",
      "Days",
      "Display Cost",
      "Printing Cost",
      "Installation Cost",
      "Ageing From DoD (in Days)",
    ];

    exportInvoicesToXlsx(
      "Pending Invoice",
      inventory_details,
      {
        exportName: `${"Pending Invoice"} - ${moment().format(
          "Do MMMM YYYY"
        )}.xlsx`,
      },
      exportColumns
    );
  };

  ValuesChange = (value, values) => {
    if (value["employee_id"]) {
      this.getCustomersByEmployee(values["employee_id"]);
    }
  };

  // Get Customer List
  getCustomersByEmployee = (employee_id) => {
    let reqUrl = "customer/lookup?employeeid=" + employee_id;
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({
          customersList: data.response,
        });
      },
      "GET"
    );
  };

  componentDidMount() {
    RolesPermissions("sr") &&
      this.getCustomersByEmployee(AdminApi.getCurrentUser()?.Id);
  }

  render() {
    const layout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
    };
    const { customersList, isDownloading } = this.state;
    const { Option } = Select;

    return (
      <div>
        <Form
          name="pending_invoice_export"
          {...layout}
          onFinish={this.onFinish}
          ref={this.formRef}
          className="pending_invoice_export"
          onValuesChange={this.ValuesChange}
        >
          {RolesPermissions("ad") && (
            <Form.Item name="employee_id" label="Employee">
              <UserPickerDropDown />
            </Form.Item>
          )}

          <Form.Item name="customer_id" label="Customer">
            <Select placeholder="Customer" allowClear>
              {customersList.map((x) => (
                <Option key={x.customer_id} value={x.customer_id}>
                  {x.customer_name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8 }} className="model-btns">
            <Button type="primary" htmlType="submit" loading={isDownloading}>
              Download
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default ExportInvoices;
