import React from "react";
import { Typography, Button } from "antd";
import {
  UserOutlined,
  PlusOutlined,
  ProfileOutlined,
  LeftOutlined,
  FileDoneOutlined,
  FileProtectOutlined,
  FileImageOutlined,
} from "@ant-design/icons";

const PageTitle = (props) => {
  const { Title } = Typography;
  const { titleText, isAddBtn, isBackBtn } = props;

  return (
    <div className="page-title">
      <Title level={4}>
        {titleText.includes("Inventor") ? (
          <ProfileOutlined />
        ) : titleText.includes("Plan") || titleText.includes("Campaign") ? (
          <FileDoneOutlined />
        ) : titleText.includes("Sales Estimate") ||
          titleText.includes("Credit Notes") ||
          titleText.includes("Invoice") ? (
          <FileProtectOutlined />
        ) : titleText.includes("Photo") ? (
          <FileImageOutlined />
        ) : (
          <UserOutlined />
        )}{" "}
        {titleText}
        {isAddBtn && (
          <Button
            onClick={props.handleAdd}
            type="primary"
            icon={<PlusOutlined />}
          >
            {isAddBtn}
          </Button>
        )}
        {isBackBtn && (
          <Button onClick={props.handleBack} icon={<LeftOutlined />}></Button>
        )}
      </Title>
    </div>
  );
};

export default PageTitle;
