import { Component } from "react";
import { ajaxRequester } from "../services/ajax-requester";
import { message, Modal } from "antd";
import { exportPlanToPPTx } from "../screens/plan/plan-to-ppt";
import moment from "moment";
import { exportPlanToZip } from "../screens/plan/plan-to-zip";

class PlanCampaignDetailsBase extends Component {
  state = {};

  // Get Campaign Details
  getCampaignDetails = () => {
    const planid = this.props.match.params.id;
    var reqUrl = "campaign/detail?campaignId=" + Number(planid);
    var reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({
          results: data.response,
          isLoading: false,
          isBusinessLoading: false,
          isInventoryLoading: false,
          isUpdateKey: this.state.isUpdateKey + 1,
        });
      },
      "GET"
    );
  };

  // Gettng Seller Details
  getConfigurationDetails = () => {
    let reqUrl = "configuration/detail";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ configurationDetails: data });
      },
      "GET"
    );
  };

  // Get Customer List from Lookup
  getCustomers = () => {
    let reqUrl = "customer/lookup";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({
          customersList: data.response,
        });
      },
      "GET"
    );
  };

  // Redirect to Plan List
  handleBack = () => {
    this.props.history.push(`/campaigns/campaigns`);
  };

  // Handle Drawer
  handleDrawer = (editDrawer) => {
    this.setState({
      isDrawer: !this.state.isDrawer,
      editDrawer,
    });
  };

  // Handle Business Edit
  handleBusiness = (data) => {
    const discounting_per =
      data?.discounting_criteria?.discount_criteria?.rate_percentage || 0;

    if (data.isDatesChanged) {
      data.campaign_inventory_details = data.campaign_inventory_details.map(
        (inventory) => ({
          ...inventory,
          start_date: data.start_date,
          end_date: data.end_date,
          no_of_days: data.no_of_days,
          monthly_rate: discounting_per
            ? inventory.card_rate -
              (inventory.card_rate * discounting_per) / 100
            : inventory.monthly_rate,
          rental_cost: discounting_per
            ? parseInt((inventory.card_rate / 30) * data.no_of_days) -
              parseInt(
                (inventory.card_rate / 30) * data.no_of_days * discounting_per
              ) /
                100
            : (inventory.monthly_rate / 30) * data.no_of_days,
        })
      );
    }

    let total_display_cost = 0;

    data.campaign_inventory_details.map(
      (x) => (total_display_cost = total_display_cost + x.rental_cost)
    );

    data.total_display_cost = total_display_cost;

    data.total_without_tax =
      data.total_display_cost +
      data.total_printing_cost +
      data.total_installing_cost;

    data.gst_amount =
      (data.total_display_cost * data.gst_per) / 100 +
      (data.total_printing_cost * data.gst_per) / 100 +
      (data.total_installing_cost * data.gst_per) / 100;

    data.total_amount = data.total_without_tax + data.gst_amount;

    this.setState(
      {
        isDrawer: !this.state.isDrawer,
        isBusinessLoading: true,
      },
      () => this.updateCampaign(data)
    );
  };

  handleInventory = (data) => {
    this.setState({
      // isEditMode: !this.state.isEditMode,
      isInventoryLoading: true,
    });
    this.updateCampaign(data);
  };

  // Update to Plan
  updatePlan = (data) => {
    var reqUrl = "plan/update";
    var reqObj = {
      request: {
        ...data,
        plan_status: "",
        created_by: "varun",
      },
    };

    ajaxRequester(reqUrl, reqObj, () => {
      this.getCampaignDetails();
    });
  };

  // Update to Plan
  updateCampaign = (data) => {
    var reqUrl = "campaign/update";
    var reqObj = {
      request: {
        ...data,
        plan_status: "",
        campaign_key: this.state?.results?.campaign_key,
      },
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      if (data.success) {
        message.success("Campaign has been updated successfully");
        this.getCampaignDetails();
      } else {
        message.error(
          data?.detail ||
            data ||
            "An error occured while processing your request."
        );
        this.getCampaignDetails();
      }
    });
  };

  // handle Edit Mode of Inventories
  handleEdit = (isChanged) => {
    const { confirm } = Modal;
    if (this.state.isEditMode && isChanged) {
      confirm({
        title: "Your all unsaved data will be deleted.",
        onOk: () => {
          this.setState({ isEditMode: !this.state.isEditMode });
        },
      });
    } else {
      this.setState({ isEditMode: !this.state.isEditMode });
    }
  };

  // handle Action Menu
  handleMenuClick = ({ key }) => {
    let record = this.state.results;
    record.action = key;

    switch (key) {
      case "delete":
        record.title = "Delete Plan";
        break;

      case "block":
        record.title = "Block Plan";
        break;

      case "unblock":
        record.title = "Unblock Plan";
        break;

      case "copy":
        record.title = "Copy Plan";
        break;

      case "share":
        record.title = "Share Plan";
        break;

      case "activity":
        record.title = "Campaign Activities";
        this.getActivityLog(record.campaign_id);
        break;

      case "publiclink":
        window.open(
          "/#/campaigns/campaign-view/" + record.publish_id,
          "_blank"
        );

        break;

      case "email":
        record.title = "Email Notifications";
        break;

      case "segment":
        record.title = "Assign Segment";
        break;

      case "notes":
        record.title = "Campaign Notes";
        break;

      case "creative":
        record.title = "Add Additional Creative";
        break;

      case "download":
        this.setState({ isExportDrawer: true, exportType: "ppt" });
        break;

      case "downloadXlsx":
        this.setState({ isExportDrawer: true, exportType: "excel" });
        break;

      case "downloadZip":
        exportPlanToZip(this.state.results, {
          exportName: `${this.state.results.display_name} - ${moment().format(
            "Do MMMM YYYY"
          )}.zip`,
        });
        break;

      case "report":
        this.setState({ isExportReportDrawer: true });
        break;

      default:
        break;
    }
    key !== "download" &&
      key !== "publiclink" &&
      key !== "downloadZip" &&
      key !== "downloadXlsx" &&
      key !== "report" &&
      this.setState({ record }, () => this.handleModal());
  };

  // Handle Popup
  handleModal = () => {
    this.setState({ isModal: !this.state.isModal });
  };

  // Handle Notification Model
  handleNotificationModal = () => {
    this.getCampaignDetails();
    this.setState({ isModal: !this.state.isModal });
  };

  // Handle Popup Actions
  handleActions = (data) => {
    this.handleModal();
    const { action, plan_id } = this.state.record;

    switch (action) {
      case "delete":
        this.deleteItem(plan_id);
        break;

      case "delete":
        this.deleteItem(plan_id);
        break;

      case "block":
        this.blockUnblockItem(action, plan_id);
        break;

      case "unblock":
        this.blockUnblockItem(action, plan_id);
        break;

      case "copy":
        this.planActions(action, plan_id, data);

      case "share":
        this.planActions(action, plan_id, data);
        break;

      default:
        break;
    }
  };

  // Plan Actions
  planActions = (action, plan_id, data) => {
    var reqUrl = "plan/" + action;
    var reqObj = {
      request: {
        plan_id: plan_id,
        display_name: data.display_name,
        shared_with_employee_id: data.employee_id,
        created_by: "varun",
      },
    };

    ajaxRequester(reqUrl, reqObj, () => {
      this.handleBack();
    });
  };

  // Plan Block Unblock
  blockUnblockItem = (action, plan_id) => {
    var reqUrl = "plan/" + action + "?planid=" + plan_id;
    var reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      () => {
        this.getCampaignDetails();
      },
      "GET"
    );
  };

  // Get Plan Activities
  getActivityLog = (id) => {
    this.setState({ activityLog: "" });

    var reqUrl = "campaign/activitylog?campaignid=" + id;
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ activityLog: data.response });
      },
      "GET"
    );
  };

  // Delete Item from List based on Id
  deleteItem = (id) => {
    var reqUrl = "plan/delete?planId=" + id;
    var reqObj = "";
    ajaxRequester(
      reqUrl,
      reqObj,
      () => {
        this.handleBack();
      },
      "DELETE"
    );
  };

  handleApprove = (action) => {
    const id = Number(this.props.match.params.id);
    var reqUrl = "campaign/" + action;
    var reqObj = {
      request: { campaign_id: id },
    };

    ajaxRequester(reqUrl, reqObj, () => {
      if (action === "approve") {
        message.success("Campaign Approved successfully");
        this.getCampaignDetails();
      } else {
        message.success("Campaign Rejected successfully");
        this.handleBack();
      }
    });
  };

  handleChangeOfSite = (selected_inventory, new_inventory, cos_date) => {
    let campaign_id = Number(this.props.match.params.id);
    var reqUrl = "campaign/changeofsite";
    var reqObj = {
      request: {
        campaign_id: campaign_id,
        from_inventory_details: {
          campaign_inventory_id: selected_inventory.campaign_inventory_id,
          ha_inventory_id: selected_inventory.ha_inventory_id,
        },
        to_inventory_details: new_inventory,
        cos_date: cos_date,
      },
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      if (data.success) {
        window.location.reload();
        message.success("Change of Site performed successfully.");
      } else {
        message.error(data?.detail || data);
      }
    });
  };

  handleOperations = () => {
    const id = this.props.match.params.id;
    this.props.history.push("/operations/campaign-operations/" + id);
  };

  handleAdjustCampaignDatesToggle = (e) => {
    const campaignid = this.props.match.params.id;
    var reqUrl =
      "campaign/update/campaigndatesflag?campaignId=" +
      Number(campaignid) +
      "&adjustCampaignDates=" +
      e;
    var reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        if (data.success) {
          message.success("Adjust Campaign Dates Successfully");
          let { results } = this.state;
          results.auto_adjust_campaign_dates = e;
          this.setState({ results });
        } else {
          message.error("Someting went wrong");
        }
      },
      "GET"
    );
  };

  handleHelp = () => {
    this.setState({ isHelpModel: !this.state.isHelpModel });
  };
}

export default PlanCampaignDetailsBase;
