import {
  dateFormatter,
  timeFormatter,
} from "../../components/common/common-formatter";
import moment from "moment";
import { globalVal } from "../../helpers/global";

const ExcelJS = require("exceljs");

export const exportPlanToXlsx = (planDetails, configuration, settings) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet("Plan");

  const provider_name =
    configuration?.configuration_details?.provider_name || "";

  let termsAndConditions =
    configuration?.configuration_details?.plan_settings?.terms_and_conditions ||
    [];

  termsAndConditions = termsAndConditions.map((x, key) => [key + 1 + ") " + x]);

  let inventories =
    planDetails.plan_inventory_details ??
    planDetails.response?.plan_inventory_details ??
    planDetails.campaign_inventory_details ??
    planDetails.response.campaign_inventory_details;

  let isReplaceWithAvailable = settings.includes("Replace Blocked");

  const getEmployeeDetails = (employee_id, employee_phone) => {
    let employeeDetails = localStorage.getItem("user:" + employee_id);
    employeeDetails = JSON.parse(employeeDetails);
    let employeeName =
      (employeeDetails?.data?.firstName || "") +
      " " +
      (employeeDetails?.data?.lastName || "");
    let employeeNumber = employeeDetails?.data?.phoneNumber;

    return !employee_phone ? employeeName || "" : employeeNumber || "";
  };

  inventories = inventories.map((x, key) => ({
    ...x,
    sr: key + 1,
    start_date: dateFormatter(x.start_date),
    end_date: dateFormatter(x.end_date),
    booking_status:
      x.booking_status === "B" && !isReplaceWithAvailable
        ? "Blocked till " +
          (x.blocked_till_date &&
            dateFormatter(x.blocked_till_date) +
              " " +
              timeFormatter(x.blocked_till_date))
        : x.booking_status === "N"
        ? x?.total_blocked_count > 0
          ? dateFormatter(x.available_from) +
            (!isReplaceWithAvailable ? " / Blocked" : "")
          : dateFormatter(x.available_from)
        : "Available Now",

    total_amount: x.rental_cost + x.printing_cost + x.installing_cost,
    gst_amount:
      ((x.rental_cost + x.printing_cost + x.installing_cost) * 18) / 100,
    grand_total:
      x.rental_cost +
      x.printing_cost +
      x.installing_cost +
      ((x.rental_cost + x.printing_cost + x.installing_cost) * 18) / 100,
    employee_id: x?.employee_id && getEmployeeDetails(x.employee_id),
    ha_base_inventories:
      x.ha_base_inventories?.length > 0 &&
      x.ha_base_inventories.map((y, index) => ({
        ...y,
        sr: key + 1 + String.fromCharCode(index + 65).toLocaleLowerCase(),
        location: y?.description,
        card_rate: "",
        booking_status: "",
      })),
    display_qty: x?.quantity * (x?.side_type === "double" ? 2 : 1),
    card_rate_unit: x?.card_rate / x?.quantity,
    discounted_rate_unit: x?.monthly_rate / x?.quantity,
    location:
      x?.location +
      (x?.quantity > 1
        ? " (Qty : " +
          x?.quantity +
          " / Display : " +
          (x?.side_type === "double" ? x?.quantity * 2 : x?.quantity) +
          ")"
        : ""),
  }));

  let columns = [
    { title: "Sr", columnName: "sr", width: 4, align: "center" },
    {
      title: "Supplier",
      columnName: "supplier_name",
      width: 10,
      align: "left",
    },
    {
      title: "Media Type",
      columnName: "media_type",
      width: 9,
      align: "left",
    },
    { title: "Lat", columnName: "latitude", width: 10, align: "center" },
    { title: "Long", columnName: "longitude", width: 10, align: "center" },
    { title: "IID", columnName: "inventory_code", width: 7, align: "left" },
    { title: "District", columnName: "district", width: 11, align: "left" },
    { title: "City", columnName: "city", width: 11, align: "left" },
    { title: "Area", columnName: "area", width: 15, align: "left" },
    { title: "Location", columnName: "location", width: 48, align: "left" },
    { title: "W", columnName: "width", width: 6, align: "center" },
    { title: "H", columnName: "height", width: 6, align: "center" },
    { title: "Size", columnName: "size", width: 6, align: "center" },
    { title: "SQFT", columnName: "sqft", width: 6, align: "center" },
    {
      title: "Traffic From",
      columnName: "traffic_facing_from",
      width: 12,
      align: "left",
    },
    {
      title: "Traffic To",
      columnName: "traffic_going_to",
      width: 10,
      align: "left",
    },
    { title: "Light", columnName: "light_type", width: 6, align: "center" },
    { title: "Qty", columnName: "quantity", width: 4, align: "center" },
    {
      title: "Display Qty",
      columnName: "display_qty",
      width: 9,
      align: "center",
    },
    {
      title: "Available From",
      columnName: "booking_status",
      width: !isReplaceWithAvailable ? 26 : 14,
      align: "center",
    },
    {
      title: "Start Date",
      columnName: "start_date",
      width: 8,
      align: "center",
    },
    { title: "End Date", columnName: "end_date", width: 8, align: "center" },
    { title: "Days", columnName: "no_of_days", width: 6, align: "center" },
    {
      title: "Card Rate / Unit",
      columnName: "card_rate_unit",
      width: 11,
      align: "right",
      numberFormat: true,
    },
    {
      title: "Card Rate Per Month",
      columnName: "card_rate",
      width: 11,
      align: "right",
      numberFormat: true,
    },
    {
      title: "Discounted Rate / Unit",
      columnName: "discounted_rate_unit",
      width: 11,
      align: "right",
      numberFormat: true,
    },
    {
      title: "Discounted Monthly Rate",
      columnName: "monthly_rate",
      width: 11,
      align: "right",
      numberFormat: true,
    },
    {
      title: "Display Cost",
      columnName: "rental_cost",
      width: 11,
      align: "right",
      numberFormat: true,
    },
    {
      title: "Printing Cost",
      columnName: "printing_cost",
      width: 10,
      align: "right",
      numberFormat: true,
    },
    {
      title: "Mounting Cost",
      columnName: "installing_cost",
      width: 10,
      align: "right",
      numberFormat: true,
    },
    {
      title: "Total",
      columnName: "total_amount",
      width: 10,
      align: "right",
      numberFormat: true,
    },
    {
      title: "GST (" + globalVal?.gst_per + "%)",
      columnName: "gst_amount",
      width: 10,
      align: "right",
      numberFormat: true,
    },
    {
      title: "Grand Total",
      columnName: "grand_total",
      width: 10,
      align: "right",
      numberFormat: true,
    },
    {
      title: "Current Display",
      columnName: "display_name",
      width: 15,
      align: "center",
    },
    {
      title: "Employee",
      columnName: "employee_id",
      width: 12,
      align: "center",
    },
    { title: "Remarks", columnName: "remarks", width: 14, align: "center" },
  ];

  settings?.length > 0 &&
    (columns = columns.filter((x) => settings.includes(x.title)));

  const getColAddress = (add) => {
    let address = add.replace(/[0-9]/g, "");
    return address;
  };

  // Header Rows
  sheet.addRow([provider_name]);
  sheet.addRow([]);
  sheet.addRow(["Date : " + moment().format("Do MMMM YYYY")]);
  sheet.addRow(["Project ID: " + planDetails?.project_id]);
  sheet.addRow([]);
  sheet.addRow(["To,"]);
  sheet.addRow(["POC Name,"]);
  sheet.addRow([(planDetails.customer_name ?? "My Customer") + ","]);
  sheet.addRow([]);
  sheet.addRow(["Site List"]);
  sheet.getCell("A1").font = {
    bold: true,
    size: 11,
  };
  sheet.getCell("A3").font = {
    italic: true,
  };
  sheet.getCell("A10").font = {
    bold: true,
  };

  // Adding Colums Title
  let titleRowNum = 11;
  sheet.addRow(columns.map((x) => x.title));
  sheet.getRow(titleRowNum).eachCell((cell, index) => {
    cell.font = {
      bold: true,
    };
    cell.border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    cell.alignment = {
      horizontal: columns[index - 1]?.align,
      vertical: "middle",
      wrapText: true,
    };
    sheet.getColumn(getColAddress(cell.address)).width =
      columns[index - 1].width || 0;
  });

  // Adding Sites
  const numFmt = "#,##0";
  let total = 0;
  let total_printing_cost = 0;
  let total_installing_cost = 0;
  let total_card_rate = 0;
  let total_card_rate_unit = 0;
  let total_monthly_rate = 0;
  let total_discounted_rate_unit = 0;
  let total_amount = 0;
  let gst_amount = 0;
  let grand_total = 0;
  let numRows = 0;
  let siteRows = [];

  inventories.forEach((inventory) => {
    total += inventory.rental_cost;
    total_printing_cost += inventory.printing_cost;
    total_installing_cost += inventory.installing_cost;
    total_card_rate += inventory.card_rate;
    total_card_rate_unit += inventory.card_rate / inventory.quantity;
    total_monthly_rate += inventory.monthly_rate;
    total_discounted_rate_unit += inventory.monthly_rate / inventory.quantity;
    total_amount += inventory.total_amount;
    gst_amount += inventory.gst_amount;
    grand_total += inventory.grand_total;
    numRows += 1;

    sheet.addRow(columns.map((x) => inventory[x.columnName] || ""));

    if (inventory.ha_base_inventories?.length > 0) {
      siteRows.push("subrow");
      numRows += 1;
      inventory.ha_base_inventories.forEach((groupInventory, gIndex) => {
        sheet.addRow(columns.map((x) => groupInventory[x.columnName] || ""));
        siteRows.push("subrow");
      });
    } else {
      siteRows.push("row");
    }
  });

  siteRows.forEach((row, count) => {
    sheet.getRow(titleRowNum).eachCell((cell, index) => {
      let newcell = sheet.getCell(
        getColAddress(cell.address) + (titleRowNum + count + 1)
      );
      newcell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      newcell.alignment = { horizontal: columns[index - 1]?.align };
      columns[index - 1]?.numberFormat && (newcell.numFmt = numFmt);
      row === "subrow" &&
        (newcell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "F2F2F2" },
        });
    });
  });

  // Row Adding Site Total
  sheet.addRow(
    columns.map((x) =>
      x.columnName === "rental_cost"
        ? total || ""
        : x.columnName === "printing_cost"
        ? total_printing_cost || ""
        : x.columnName === "installing_cost"
        ? total_installing_cost || ""
        : x.columnName === "card_rate"
        ? total_card_rate || ""
        : x.columnName === "card_rate_unit"
        ? total_card_rate_unit || ""
        : x.columnName === "monthly_rate"
        ? total_monthly_rate || ""
        : x.columnName === "discounted_rate_unit"
        ? total_discounted_rate_unit || ""
        : x.columnName === "total_amount"
        ? total_amount || ""
        : x.columnName === "gst_amount"
        ? gst_amount || ""
        : x.columnName === "grand_total"
        ? grand_total || ""
        : ""
    )
  );

  // Getting Current Row
  let cellRow = titleRowNum + siteRows.length + 2;
  sheet.getRow(cellRow - 1).eachCell((cell) => {
    cell.numFmt = numFmt;
  });

  // Adding Public Link
  sheet.addRow([]);
  sheet.addRow([]);
  sheet.getCell(cellRow + 1, 1).value = {
    text:
      "Public Link : " +
      window.location.origin +
      "/#/plans/plan-view/" +
      planDetails.publish_id,
    hyperlink:
      window.location.origin + "/#/plans/plan-view/" + planDetails.publish_id,
    tooltip:
      "Public Link : " +
      window.location.origin +
      "/#/plans/plan-view/" +
      planDetails.publish_id,
  };
  sheet.getCell(cellRow + 1, 1).font = {
    bold: true,
    italic: true,
    color: {
      argb: "000D5AD8",
    },
  };

  // Adding Terms
  sheet.addRow([]);
  sheet.addRow(["Terms and Conditions - "]);
  sheet.addRows(termsAndConditions);
  sheet.getCell(cellRow + 3, 1).font = {
    bold: true,
  };

  // Adding Documents
  sheet.addRow([]);
  sheet.addRow([
    "Essentail Documents of " +
      provider_name +
      ", Required to Issue Purchase Order :",
  ]);
  sheet.addRow([]);
  sheet.addRow([]);
  sheet.getCell(cellRow + 15, 1).value = {
    text: "1) GST Certificate",
    hyperlink:
      configuration?.configuration_details?.documents?.gst_certificate?.at(-1)
        ?.data_url,
    tooltip: "1) GST Certificate",
  };
  sheet.getCell(cellRow + 16, 1).value = {
    text: "2) Cancelled Cheque",
    hyperlink:
      configuration?.configuration_details?.documents?.cancelled_cheque?.at(-1)
        ?.data_url,
    tooltip: "2) Cancelled Cheque",
  };
  sheet.getCell(cellRow + 14, 1).font = {
    bold: true,
  };
  sheet.getCell(cellRow + 15, 1).font = {
    bold: true,
    italic: true,
    color: {
      argb: "000D5AD8",
    },
  };
  sheet.getCell(cellRow + 16, 1).font = {
    bold: true,
    italic: true,
    color: {
      argb: "000D5AD8",
    },
  };

  // Adding Footer
  sheet.addRow([]);
  sheet.addRow(["We value your business and hope for strong relation ahead."]);
  sheet.addRow([]);
  sheet.addRow(["Sincerely,"]);
  sheet.addRow([]);
  sheet.addRow(["For, "]);
  sheet.addRow([provider_name]);
  sheet.addRow([
    (planDetails?.employee_id &&
      getEmployeeDetails(planDetails?.employee_id)) ||
      "Employee Name",
  ]);
  sheet.addRow([
    (planDetails?.employee_id &&
      getEmployeeDetails(planDetails?.employee_id, "phone")) ||
      "Employee Number",
  ]);

  sheet.getCell(cellRow + 20, 1).font = {
    bold: true,
  };
  sheet.getCell(cellRow + 23, 1).font = {
    bold: true,
  };

  // Exporting File
  workbook.xlsx.writeBuffer().then((buffer) => {
    let blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    let a = document.createElement("a");
    a.href = window.URL.createObjectURL(blob);
    a.style.display = "none";
    a.download = configuration?.exportName ?? "export.xlsx";
    document.body.appendChild(a);
    a.click();
  });
};
